import {useIntl} from 'react-intl'

export default function useFindStoreFields({
    form: {
        control,
        formState: {errors}
    },
    prefix = ''
}) {
    const {formatMessage} = useIntl()

    const fields = {
        address: {
            name: `${prefix}address`,
            placeholder: formatMessage({
                defaultMessage: 'Your location',
                id: 'use_find_store_fields.placeholder.location'
            }),
            type: 'text',
            defaultValue: '',
            error: errors[`${prefix}address`],
            inputProps: {
                _placeholder: {color: 'stokkeCore.black'}
            },
            control
        },
        latitude: {
            name: `${prefix}latitude`,
            defaultValue: '',
            type: 'hidden',
            error: errors[`${prefix}latitude`],
            control
        },
        longitude: {
            name: `${prefix}longitude`,
            defaultValue: '',
            type: 'hidden',
            error: errors[`${prefix}longitude`],
            control
        }
    }

    return fields
}
