import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Button} from '@chakra-ui/react'
import useMultiSite from '../../hooks/use-multi-site'

const CookieSettingsLink = () => {
    const {site} = useMultiSite()
    const enableCookiebot = site?.gdpr?.enableCookiebot || false
    const renew = typeof window !== 'undefined' && window?.Cookiebot?.renew.bind(window.Cookiebot)

    return (
        enableCookiebot && (
            <Button
                variant="link"
                size="sm"
                onClick={() => {
                    if (renew) renew()
                }}
            >
                <FormattedMessage
                    defaultMessage="Cookie Settings"
                    id="footer.link.cookie_settings"
                />
            </Button>
        )
    )
}

export default CookieSettingsLink
