const accordionPanel = {
    flexDir: ['column', 'row'],
    gap: ['2.5rem', '6.25rem'],
    pb: '2rem',
    marginLeft: '-1rem'
}

export default {
    baseStyle: {
        container: {
            background: 'stokkeGray.bright',
            width: '100%',
            px: [4, 8],
            paddingBottom: '3rem'
        },
        accordionButton: {
            justifyContent: 'space-between',
            py: '2rem',
            px: 0
        },
        accordionButtonIcon: {
            width: '1rem'
        },
        accordionButtonLabel: {
            textAlign: 'left',
            textStyle: 'Regular XLarge'
        },
        accordionItem: {
            border: '2px solid stokkeGray.dark'
        },
        accordionPanel: accordionPanel,
        sparePartsAccordionPanel: {
            ...accordionPanel,
            flexDir: 'column',
            marginLeft: '0rem'
        },
        panelColumn: {
            flexDir: 'column',
            width: ['100%', '100%', '50%']
        },
        maintenance: {
            marginLeft: '0',
            mt: '1rem',
            _sx: {
                MozMarginStart: '1rem'
            }
        },
        documents: {
            mt: '1rem',
            listStyleType: 'none'
        },
        documentLink: {
            maxWidth: '100%'
        },
        assemblyContainer: {
            spacing: 4,
            // clear the default padding of the accordion panel
            mx: '-1rem'
        },
        assemblyHeader: {
            textStyle: 'Regular XLarge',
            py: {xl: 2.5},
            pr: {xl: 8}
        }
    }
}
