export default {
    /**
     * Override default line heights
     * @see https://chakra-ui.com/docs/theming/theme#typography
     * @example
     * <Box lineHeight="shorter">Lorem ipsum</Box>
     */
    lineHeights: {
        shorter: 1.2,
        short: 1.3
    },
    /**
     * Override default fonts
     * @see https://chakra-ui.com/docs/theming/theme#typography
     * @example
     * <Box fontFamily="stokkeLight">Lorem ipsum</Box>
     */
    fonts: {
        heading: `'Stokke-Regular', Arial, Helvetica, sans-serif`,
        body: `'Stokke', Arial, Helvetica, sans-serif`,
        stokkeLight: `'Stokke-Light', Arial, Helvetica, sans-serif`,
        stokkeRegular: `'Stokke-Regular', Arial, Helvetica, sans-serif`,
        stokkeSemiBold: `'Stokke-SemiBold', Arial, Helvetica, sans-serif`
    },
    /**
     * Override default font sizes.
     * @see https://chakra-ui.com/docs/theming/theme#typography
     * @example
     * <Box fontSize="2xl">Lorem ipsum</Box>
     */
    fontSizes: {
        '2xs': '0.625rem', //default not defined
        '3xl': '1.75rem', // default 1.875rem
        '4xl': '2rem', // default 2.25rem
        '4.5xl': '2.5rem', // new one
        '6xl': '3.625rem' // default 3.75rem
    }
}
