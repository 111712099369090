import React from 'react'
import {Button, Flex, Stack, Text} from '@chakra-ui/react'
import {FormattedMessage, useIntl} from 'react-intl'
import {EditIcon} from '../../../components/icons'
import Field from '../../../components/field'
import PropTypes from 'prop-types'
import useAccountDataFields from '../../../components/forms/useAccountDataFields'
import {SALUTATION_OPTIONS} from '../../../constants'
import MyStokkeLinkContainer from './link-container'

const MyStokkePersonalDataBasicData = ({
    form,
    submitForm,
    data,
    isEditing,
    onEdit,
    onCancel,
    isLoading
}) => {
    const {locale} = useIntl()
    const fields = useAccountDataFields({form, data})
    const {formatMessage} = useIntl()
    const salutation = SALUTATION_OPTIONS.find((option) => option.value === data.salutation)

    let personalDataFields, editPersonalDataFields
    switch (locale) {
        case 'ja-JP':
            personalDataFields = (
                <>
                    <Flex>
                        <Text textStyle="Regular Medium" flex="2">
                            <FormattedMessage
                                defaultMessage="Name"
                                id="mystokke.personal-information.name"
                            />
                        </Text>
                        <Text textStyle="Regular Medium" flex="1">
                            {data.firstName} {data.lastName}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text textStyle="Regular Medium" flex="2">
                            <FormattedMessage
                                defaultMessage="Phone"
                                id="use_address_fields.label.phone"
                            />
                        </Text>
                        <Text textStyle="Regular Medium" flex="1">
                            {data.phone}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text textStyle="Regular Medium" flex="2">
                            <FormattedMessage
                                defaultMessage="Birthday"
                                id="mystokke.personal-information.birthday"
                            />
                        </Text>
                        <Text textStyle="Regular Medium" flex="1">
                            {data.brithdayFormated}
                        </Text>
                    </Flex>
                </>
            )
            editPersonalDataFields = (
                <>
                    <Field {...fields.firstName} />
                    <Field {...fields.lastName} />
                    <Field {...fields.phone} />
                    <Field {...fields.birthday} />
                </>
            )
            break
        default:
            personalDataFields = (
                <>
                    <Flex>
                        <Text textStyle="Regular Medium" flex="2">
                            <FormattedMessage
                                defaultMessage="Salutation"
                                id="use_address_fields.label.salutation"
                            />
                        </Text>
                        <Text textStyle="Regular Medium" flex="1">
                            {salutation?.display && <Text>{formatMessage(salutation.label)}</Text>}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text textStyle="Regular Medium" flex="2">
                            <FormattedMessage
                                defaultMessage="Name"
                                id="mystokke.personal-information.name"
                            />
                        </Text>
                        <Text textStyle="Regular Medium" flex="1">
                            {data.firstName} {data.lastName}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text textStyle="Regular Medium" flex="2">
                            <FormattedMessage
                                defaultMessage="Phone"
                                id="use_address_fields.label.phone"
                            />
                        </Text>
                        <Text textStyle="Regular Medium" flex="1">
                            {data.phone}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text textStyle="Regular Medium" flex="2">
                            <FormattedMessage
                                defaultMessage="Birthday"
                                id="mystokke.personal-information.birthday"
                            />
                        </Text>
                        <Text textStyle="Regular Medium" flex="1">
                            {data.brithdayFormated}
                        </Text>
                    </Flex>
                </>
            )
            editPersonalDataFields = (
                <>
                    <Field {...fields.salutation} />
                    <Field {...fields.firstName} />
                    <Field {...fields.lastName} />
                    <Field {...fields.phone} />
                    <Field {...fields.birthday} />
                </>
            )
    }

    return (
        <>
            <Text as="h2" textStyle={{base: 'Regular 2XLarge'}} paddingTop={6} paddingBottom={6}>
                <FormattedMessage
                    defaultMessage="Personal data"
                    id="mystokke.personal-information.basic-data"
                />
            </Text>
            <Stack
                bgColor={!isEditing ? 'stokkeCore.white' : 'stokkeGray.light'}
                padding={4}
                spacing={6}
            >
                {!isEditing && (
                    <>
                        {personalDataFields}
                        <Button
                            variant="link"
                            width="fit-content"
                            onClick={() => {
                                form.reset({...data})
                                onEdit()
                            }}
                        >
                            <Flex gap={2} alignItems="center">
                                <EditIcon width={4} height={4} />
                                <FormattedMessage defaultMessage="Edit" id="global.edit" />
                            </Flex>
                        </Button>
                    </>
                )}
                {!!isEditing && (
                    <form
                        onSubmit={form.handleSubmit(submitForm)}
                        noValidate
                        name="personal-data-basic-data-form"
                        data-testid="mystokke-basic-data-form"
                    >
                        <Stack spacing={6}>
                            {editPersonalDataFields}
                            <Stack spacing={3} alignItems="center">
                                <Button type="submit" isLoading={isLoading} width="100%">
                                    <FormattedMessage
                                        defaultMessage="Save"
                                        id="form_action_buttons.button.save"
                                    />
                                </Button>
                                <MyStokkeLinkContainer>
                                    <Button variant="link" onClick={onCancel}>
                                        {
                                            <FormattedMessage
                                                id="form_action_buttons.button.cancel"
                                                defaultMessage="Cancel"
                                            />
                                        }
                                    </Button>
                                </MyStokkeLinkContainer>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Stack>
        </>
    )
}

export default MyStokkePersonalDataBasicData

MyStokkePersonalDataBasicData.propTypes = {
    form: PropTypes.object,
    submitForm: PropTypes.func,
    data: PropTypes.shape({
        salutation: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phone: PropTypes.string,
        birthday: PropTypes.string,
        brithdayFormated: PropTypes.string
    }),
    isEditing: PropTypes.bool,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool
}
