/**
 * Compiles template by substituting ${key} with data[key]
 *
 * @param {string} template
 * @param {object} data
 * @returns {string|undefined}
 */
export const compileTemplate = (template, data) => {
    if (!data || typeof data !== 'object') {
        throw new Error(`Invalid data type "${typeof data}"`)
    }
    return template?.replace?.(/\${(.*?)}/g, (_, key) => {
        if (!(key in data)) {
            console.error(`Missing key "${key}" in template "${template}"`)
            return `\${${key}}`
        }
        return data[key]
    })
}

/**
 * Truncates a html string preserving html tags.
 * @param htmlString
 * @param wordCount
 * @returns {string} The truncated string
 */
export const truncateHtml = (htmlString, wordCount) => {
    if (!htmlString) {
        return ''
    }
    let paragraphSplit = htmlString.split(/<p>|<\/p>/)

    // remove any empty values from Array
    paragraphSplit = paragraphSplit.filter((entry) => entry.trim() !== '')

    const outParagraphs = []
    let curLength = 0

    for (let i = 0; i < paragraphSplit.length; i++) {
        let paragraph = paragraphSplit[i]
        let paragraphLength = paragraph.split(/\s+/).length

        // if paragraph is shorter than wordCount, add it to output
        if (curLength + paragraphLength < wordCount) {
            outParagraphs.push(paragraph)
        } else {
            // if paragraph is longer than wordCount, truncate it,
            // add to output, then break since we don't need whatever is left
            let words = paragraph.split(/\s+/)
            words.splice(wordCount - curLength)
            outParagraphs.push(words.join(' ') + '…')
            break
        }
        curLength += paragraphLength
    }
    // Each value of Array add <p> & </p> tags
    let paragraphJoin = outParagraphs.map((value) => {
        return '<p>' + value + '</p>'
    })
    return paragraphJoin.join('')
}
