import {useCustomQuery, useCustomMutation} from '@salesforce/commerce-sdk-react'

export const useNavigationContent = (apiOptions, queryOptions) =>
    useCustomQuery(
        {
            options: {
                method: 'GET',
                customApiPathParameters: {
                    apiVersion: 'v1',
                    endpointPath: 'navigation',
                    apiName: 'navigation'
                },
                parameters: apiOptions.parameters
            },
            rawResponse: false
        },
        queryOptions
    )

export const useNavigationHeaderContent = (apiOptions, queryOptions) =>
    useCustomQuery(
        {
            options: {
                method: 'GET',
                customApiPathParameters: {
                    apiVersion: 'v1',
                    endpointPath: 'navigation-header',
                    apiName: 'navigation'
                },
                parameters: apiOptions.parameters
            },
            rawResponse: false
        },
        queryOptions
    )

export const useProductSetPrices = (apiOptions, queryOptions) =>
    useCustomQuery(
        {
            options: {
                method: 'GET',
                customApiPathParameters: {
                    apiVersion: 'v1',
                    endpointPath: 'get-products',
                    apiName: 'shopper-productset'
                },
                parameters: apiOptions.parameters
            },
            rawResponse: false
        },
        queryOptions
    )

export const useCustomerActivation = (apiOptions, queryOptions) =>
    useCustomQuery(
        {
            options: {
                method: 'POST',
                customApiPathParameters: {
                    apiVersion: 'v1',
                    endpointPath: 'activate',
                    apiName: 'customer'
                },
                parameters: apiOptions.parameters
            },
            rawResponse: false
        },
        queryOptions
    )

export const useCustomerResetPassword = (apiOptions, queryOptions) =>
    useCustomMutation(
        {
            options: {
                method: 'POST',
                customApiPathParameters: {
                    apiVersion: 'v1',
                    endpointPath: 'reset-password',
                    apiName: 'customer'
                },
                parameters: apiOptions.parameters,
                body: JSON.stringify(apiOptions.body)
            },
            rawResponse: false
        },
        queryOptions
    )
