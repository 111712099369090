import {useEffect, useState} from 'react'

/**
 * This hook returns the visual viewport dimensions and scale.
 * @returns {{width: null|number, height: null|number}}
 */
function useVisualViewportSize() {
    const [visualViewport, setVisualViewport] = useState({
        width: null,
        height: null
    })

    useEffect(() => {
        const updateVisualViewport = () => {
            setVisualViewport({
                width: window.visualViewport?.width || window.innerWidth,
                height: window.visualViewport?.height || window.innerHeight
            })
        }

        updateVisualViewport()

        if (window.visualViewport) {
            window.visualViewport.addEventListener('resize', updateVisualViewport)

            return () => {
                window.visualViewport.removeEventListener('resize', updateVisualViewport)
            }
        } else {
            window.addEventListener('resize', updateVisualViewport)

            return () => {
                window.removeEventListener('resize', updateVisualViewport)
            }
        }
    }, [])

    return visualViewport
}

export default useVisualViewportSize
