export default {
    baseStyle: {
        list: {
            display: 'flex',
            flexDirection: 'column',
            gap: 3
        },
        listItem: {
            display: 'flex'
        },
        link: {
            size: 'sm'
        }
    },
    variants: {
        checkoutFooter: {
            link: {
                size: 'md'
            }
        },
        footer: {
            list: {
                gap: 4
            }
        },
        footerLinks: {
            list: {
                flexDirection: {base: 'column', md: 'row'}
            }
        }
    }
}
