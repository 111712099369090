import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import {HStack, Box, Skeleton as ChakraSkeleton} from '@chakra-ui/react'
import SwatchGroup from '../swatch-group'
import Swatch from '../swatch-group/swatch'

/* Utils */
import {noop} from '../../utils/utils'

/* Hooks */
import {useMaterialVariation} from '../../hooks/use-variation-attributes'
import {useDerivedProduct} from '../../hooks'

export const Skeleton = () => {
    return (
        <HStack spacing={{base: 2, lg: 3}}>
            <ChakraSkeleton height={9} width={12} />
            <ChakraSkeleton height={9} width={12} />
            <ChakraSkeleton height={9} width={12} />
            <ChakraSkeleton height={9} width={12} />
        </HStack>
    )
}

const MaterialVariationSelection = ({product, onChange = noop}) => {
    const materialVariation = useMaterialVariation(product)
    const {masterSubtypeTooltipText} = useDerivedProduct(product)

    if (!materialVariation) {
        return null
    }

    return (
        <SwatchGroup
            value={materialVariation.selectedValue.value}
            label={materialVariation.name}
            displayName={materialVariation.selectedValue.name}
            spacing={3}
            onChange={(value, href) => {
                if (!href) return
                onChange(value, href)
            }}
            variant="chip"
            size="none"
            tooltip={masterSubtypeTooltipText}
        >
            {materialVariation.values.map(({href, name, image, value, orderable}) => {
                return (
                    <Swatch
                        key={value}
                        href={href}
                        disabled={!orderable}
                        value={value}
                        name={name}
                        orderable={orderable}
                        label={name}
                        className="variation"
                    >
                        {image && (
                            <Box
                                color="grey"
                                boxSize={6}
                                backgroundRepeat="no-repeat"
                                backgroundSize="cover"
                                backgroundImage={
                                    image
                                        ? `url("${encodeURI(image.disBaseLink || image.link)}")`
                                        : ''
                                }
                            />
                        )}
                    </Swatch>
                )
            })}
        </SwatchGroup>
    )
}

MaterialVariationSelection.propTypes = {
    product: PropTypes.object,
    onChange: PropTypes.func,
    isProductASet: PropTypes.bool
}

export default MaterialVariationSelection
