export default {
    sizes: {
        lgMin: {
            '--close-button-size': '42px',
            // this removes the vertical padding around the icon
            // so the button takes only the size of the icon
            // but the touchable area is still the same
            marginY: -13,
            fontSize: '16px'
        }
    }
}
