import React, {useMemo} from 'react'
import {MenuItem, Text} from '@chakra-ui/react'
import useMyStokkeSubroutes from '../hooks/use-subroutes'
import HybridLink from '../../../components/hybrid-link'
import {
    ACCOUNT_HREF,
    MY_STOKKE_ADDRESSES_PAGE_ID,
    MY_STOKKE_DASHBOARD_PAGE_ID,
    MY_STOKKE_ORDERS_PAGE_ID,
    MY_STOKKE_WARRANTY_PAGE_ID
} from '../../../constants'
import {FormattedMessage} from 'react-intl'

/**
 * The items by config ids in the order they should appear in the dropdown. Missing items in config will not be displayed.
 */
const ITEM_CONFIG_IDS = [
    MY_STOKKE_DASHBOARD_PAGE_ID,
    MY_STOKKE_ORDERS_PAGE_ID,
    MY_STOKKE_WARRANTY_PAGE_ID,
    MY_STOKKE_ADDRESSES_PAGE_ID
]

/**
 * List of menu items that represent the my stokke navigation items displayed on click of my account button in header
 */
const MyStokkeNavigationDropdown = () => {
    const subroutes = useMyStokkeSubroutes()

    // map ITEM_CONFIG_IDS to subroutes
    const items = useMemo(() => {
        const itemsByConfigId = Object.fromEntries(
            subroutes.map((route) => [route.configId, route])
        )
        return ITEM_CONFIG_IDS.map((configId) => itemsByConfigId[configId]).filter(
            // leave items defined in myStokkeConfig and dashboard (has label)
            (item) => item?.config || item?.label
        )
    }, [subroutes])

    return (
        <>
            {items.map((route, idx) => {
                const Icon = route.icon
                const isBroken =
                    // without headline or label there's no text to display
                    !route.config?.headline && !route.label
                return (
                    <MenuItem
                        key={idx}
                        as={HybridLink}
                        variant="unstyled"
                        page={route.hybridPageType}
                        href={route.config?.link}
                        to={route.path && `${ACCOUNT_HREF}${route.path}`}
                        color={isBroken ? 'stokkeFunctional.alertRed' : null}
                        icon={<Icon mt={0.75} />}
                        alignItems="start"
                    >
                        <Text as="span" minW={0}>
                            {route.config?.headline ? (
                                route.config.headline
                            ) : route.label ? (
                                <FormattedMessage {...route.label} />
                            ) : (
                                `Missing "${route.configId}"`
                            )}
                        </Text>
                    </MenuItem>
                )
            })}
        </>
    )
}

export default MyStokkeNavigationDropdown
