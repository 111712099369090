import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'

// Removes focus for non-keyboard interactions for the whole application
import 'focus-visible/dist/focus-visible'

import {UserInteractionProvider} from '../../hooks/use-user-interaction'
import {MyRegistryAPIProvider} from '../../myregistry-api'
import CommerceAPI from '../../commerce-api'
import {
    BasketProvider,
    CommerceAPIProvider,
    CustomerProductListsProvider,
    CustomerProvider
} from '../../commerce-api/contexts'
import GoogleMapsAPI from '../../googlemaps-api'
import {GoogleMapsAPIProvider} from '../../googlemaps-api/contexts'
import GoogleTranslateAPI from '../../google-translate-api'
import {GoogleTranslateAPIProvider} from '../../google-translate-api/contexts'
import CuralateAPI from '../../curalate-api'
import {CuralateAPIProvider} from '../../curalate-api/contexts'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {GtmConfigProvider} from '../../gtm-api/contexts'
import BazaarvoiceAPI from '../../bazaarvoice-api'
import {BazaarvoiceAPIProvider} from '../../bazaarvoice-api/context'
import {CroProvider} from '../cro'
import {compileTemplate} from '../../utils/text-utils'
import {localeToBVLocale} from '../../bazaarvoice-api/utils'
import {InteractionStudioProvider} from '../../interaction-studio-api'
import useOcapiSite from '../../hooks/useOcapiSite'
import useMultiSite from '../../hooks/use-multi-site'
import useAuthContext from '@salesforce/commerce-sdk-react/hooks/useAuthContext'

const LegacyProvidersWithOcapiSite = ({children}) => {
    const {app: appConfig} = getConfig()
    const {locale} = useMultiSite()
    const ocapiSite = useOcapiSite()
    const ocapiSitePreferences = ocapiSite.preferences
    const {
        bvApiEnv = 'stg',
        bvApiKey,
        bvApiScriptUrlTemplate,
        curalateDataSourceId,
        googleMapsApiKey,
        googleTranslateEnabled,
        googleTranslateApiKey
    } = ocapiSitePreferences

    const googleTranslateApi = useMemo(
        () =>
            new GoogleTranslateAPI({
                ...appConfig.googleTranslateAPI,
                apiKey: googleTranslateApiKey,
                apiEnabled: googleTranslateEnabled,
                locale
            }),
        [appConfig, locale, googleTranslateApiKey, googleTranslateEnabled]
    )
    const googleMapsApi = useMemo(
        () => new GoogleMapsAPI({...appConfig.googleMapsAPI, apiKey: googleMapsApiKey, locale}),
        [appConfig, locale, googleMapsApiKey]
    )
    const curalateApi = useMemo(
        () =>
            new CuralateAPI({...appConfig.curalateAPI, dataSourceId: curalateDataSourceId, locale}),
        [appConfig, locale, curalateDataSourceId]
    )
    const bazaarvoiceApi = useMemo(
        () =>
            new BazaarvoiceAPI({
                ...appConfig.bazaarvoiceAPI,
                ...appConfig.bazaarvoiceAPI?.proxies?.[bvApiEnv],
                bvApiKey,
                bvApiEnv,
                bvApiScriptUrl: compileTemplate(bvApiScriptUrlTemplate, {
                    locale: localeToBVLocale(locale)
                }),
                locale
            }),
        [appConfig, locale, bvApiEnv, bvApiKey, bvApiScriptUrlTemplate]
    )

    return (
        <GoogleTranslateAPIProvider value={googleTranslateApi}>
            <GoogleMapsAPIProvider value={googleMapsApi}>
                <CuralateAPIProvider value={curalateApi}>
                    <BazaarvoiceAPIProvider value={bazaarvoiceApi}>
                        <GtmConfigProvider value={appConfig.gtmAPI}>
                            <MyRegistryAPIProvider
                                enabled={ocapiSitePreferences.myRegistry}
                                siteKey={ocapiSitePreferences.myRegistrySiteKey}
                            >
                                <InteractionStudioProvider
                                    enabled={ocapiSitePreferences.isInteractionStudioEnabled}
                                    scriptUrl={ocapiSitePreferences.interactionStudioJS}
                                    overridesEnabled={
                                        ocapiSitePreferences.isInteractionStudioOverridesEnabled
                                    }
                                >
                                    {children}
                                </InteractionStudioProvider>
                            </MyRegistryAPIProvider>
                        </GtmConfigProvider>
                    </BazaarvoiceAPIProvider>
                </CuralateAPIProvider>
            </GoogleMapsAPIProvider>
        </GoogleTranslateAPIProvider>
    )
}

LegacyProvidersWithOcapiSite.propTypes = {
    children: PropTypes.node
}

const LegacyProviders = ({
    children,
    basket: initialBasket = null,
    customer: initialCustomer = null
}) => {
    const {app: appConfig} = getConfig()
    const {site, locale} = useMultiSite()
    const currency = locale.preferredCurrency
    const auth = useAuthContext()

    const [basket, setBasket] = useState(initialBasket)
    const [customer, setCustomer] = useState(initialCustomer)

    const commerceAPI = useMemo(
        () =>
            new CommerceAPI(
                {
                    ...appConfig.commerceAPI,
                    parameters: {
                        ...appConfig.commerceAPI.parameters,
                        siteId: site.id
                    },
                    einsteinConfig: {
                        ...appConfig.einsteinAPI,
                        siteId: `${appConfig.einsteinAPI.siteId.split('-')[0]}-${site.id}`
                    },
                    locale: locale.id,
                    currency
                },
                auth
            ),
        [auth, appConfig, locale.id, currency, site.id]
    )

    return (
        <CommerceAPIProvider value={commerceAPI}>
            <CustomerProvider value={{customer, setCustomer}}>
                <BasketProvider value={{basket, setBasket}}>
                    <LegacyProvidersWithOcapiSite>
                        <CroProvider>
                            <CustomerProductListsProvider>
                                <UserInteractionProvider>{children}</UserInteractionProvider>
                            </CustomerProductListsProvider>
                        </CroProvider>
                    </LegacyProvidersWithOcapiSite>
                </BasketProvider>
            </CustomerProvider>
        </CommerceAPIProvider>
    )
}

LegacyProviders.propTypes = {
    children: PropTypes.node,
    basket: PropTypes.object,
    customer: PropTypes.object
}

export default LegacyProviders
