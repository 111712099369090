import React from 'react'
import PropTypes from 'prop-types'
import LinksList from '../links-list'

export const FooterLinks = ({variant, spacing, links, ...props}) => {
    return (
        <LinksList
            links={links}
            color="stokkeCore.black"
            commonSpacing={spacing}
            variant={variant === 'horizontal' ? 'horizontalFooter' : variant}
            {...props}
        />
    )
}

FooterLinks.propTypes = {
    variant: PropTypes.string,
    links: PropTypes.array,
    spacing: PropTypes.number
}

export default FooterLinks
