import SUBROUTES from '../subroutes'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {getMyStokkeLabels} from '../../../utils/site-utils'
import useOcapiSite from '../../../hooks/useOcapiSite'

const useMyStokkeSubroutes = () => {
    const {myStokkeConfig} = useOcapiSite()
    const {formatMessage} = useIntl()

    return useMemo(
        () =>
            SUBROUTES.map((route) => {
                const routeConfig = myStokkeConfig[route.configId]
                if (routeConfig) {
                    const config = {
                        ...routeConfig
                    }
                    config.headline = getMyStokkeLabels(routeConfig?.id, 'headline', formatMessage)
                    config.subline = getMyStokkeLabels(routeConfig?.id, 'subline', formatMessage)
                    return {
                        ...route,
                        config
                    }
                }
                return {
                    ...route,
                    config: routeConfig
                }
            }),
        [myStokkeConfig, formatMessage]
    )
}

export default useMyStokkeSubroutes
