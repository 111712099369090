import {useEffect} from 'react'
import useEffectEvent from '../../hooks/use-effect-event'
import {useEmplifi} from '../emplifi'
import {useKindlyChat} from '../kindly-chat'

const EmplifiKindlyIntegration = () => {
    const {enabled: emplifiEnabled, show: showEmplifi, hide: hideEmplifi} = useEmplifi()
    const {
        enabled: kindlyEnabled,
        opened: kindlyOpened,
        show: showKindly,
        hide: hideKindly,
        open: openKindlyChat
    } = useKindlyChat()

    // expose window.openChat for emplifi to open kindly chat
    const onOpenChat = useEffectEvent(() => {
        if (emplifiEnabled) {
            hideEmplifi()
        }
        showKindly()
        openKindlyChat()
    })
    const onKindlyDisabled = useEffectEvent(() => {
        if (emplifiEnabled) {
            // hide kindly if it was opened
            hideKindly()
            showEmplifi()
        }
    })
    useEffect(() => {
        if (!kindlyEnabled) {
            return
        }

        window.openChat = onOpenChat
        return () => {
            delete window.openChat
            onKindlyDisabled()
        }
    }, [kindlyEnabled, onKindlyDisabled, onOpenChat])

    // show emplifi if enabled, otherwise kindly
    const onEmplifiKindlyEnabledChange = useEffectEvent((emplifi, kindly, kindlyOpened) => {
        if (emplifi) {
            if (kindlyOpened) {
                hideEmplifi()
            } else {
                if (kindly) {
                    hideKindly()
                }
                showEmplifi()
            }
        } else if (kindly) {
            showKindly()
        }
    })
    useEffect(() => {
        onEmplifiKindlyEnabledChange(emplifiEnabled, kindlyEnabled, kindlyOpened)
    }, [emplifiEnabled, kindlyEnabled, kindlyOpened, onEmplifiKindlyEnabledChange])

    return null
}

export default EmplifiKindlyIntegration
