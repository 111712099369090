import React from 'react'
import PropTypes from 'prop-types'
import {Stack} from '@chakra-ui/react'
import Field from '../field'
import useNewsletterBannerFields from './useNewsletterBannerFields'

const NewsletterBannerFields = ({
    form,
    prefix = '',
    type,
    validationDisabled,
    rightElement,
    ...ownProps
}) => {
    const fields = useNewsletterBannerFields({form, prefix})

    return (
        <Stack spacing={5} {...ownProps}>
            <Field
                {...fields[type]}
                rules={validationDisabled && type === 'emailWithButton' ? null : fields[type].rules}
                rightElement={rightElement}
            />
        </Stack>
    )
}

NewsletterBannerFields.propTypes = {
    // Object returned from `useForm`
    form: PropTypes.object.isRequired,

    // Optional prefix for field names
    prefix: PropTypes.string,

    rightElement: PropTypes.node,

    validationDisabled: PropTypes.bool,

    type: PropTypes.string.isRequired
}

export default NewsletterBannerFields
