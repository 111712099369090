import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {GenericModal} from '../../hooks/use-generic-modal'
import Slider from '../slider'
import FanreelOverlayItem from './fanreel-overlay-item'

function FanreelOverlay({
    initialIndex = 0,
    items,
    isOpen = true,
    onClose,
    productId,
    onQuickViewModalOpen
}) {
    const [localIndex, setLocalIndex] = useState(initialIndex)
    const [sliderRef, setSliderRef] = useState()

    // Reset local index when initial index changes
    useEffect(() => {
        setLocalIndex(initialIndex)
    }, [initialIndex])

    return (
        <GenericModal
            colorScheme="stokkeCore"
            isOpen={isOpen}
            isCentered
            scrollBehavior="inside"
            variant="fanreel"
            onClose={onClose}
        >
            <Slider
                containerHtmlProps={{cursor: null}}
                ref={setSliderRef}
                itemsPerSlide={1}
                initialIndex={initialIndex}
                hideArrows
                hidePagination
                onChange={setLocalIndex}
            >
                {items.map((item, idx) => (
                    <FanreelOverlayItem
                        key={idx}
                        item={item}
                        active={isOpen && !sliderRef?.stillScrolling && idx === localIndex}
                        prefetch={
                            isOpen && !sliderRef?.stillScrolling && Math.abs(idx - localIndex) <= 1
                        }
                        onScrollPrev={sliderRef?.scrollPrev}
                        onScrollNext={sliderRef?.scrollNext}
                        productId={productId}
                        onQuickViewModalOpen={onQuickViewModalOpen}
                    />
                ))}
            </Slider>
        </GenericModal>
    )
}

FanreelOverlay.propTypes = {
    initialIndex: PropTypes.number,
    items: PropTypes.array,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    productId: PropTypes.string,
    onQuickViewModalOpen: PropTypes.func
}

export default FanreelOverlay
