import PropTypes from 'prop-types'
import React, {useCallback, useContext, useMemo, useRef} from 'react'
import useAsync from '../commerce-api/hooks/useAsync'
import {PRODUCT_TILE_IMAGE_TYPE} from '../constants'
import {useColorVariation, useCurrency} from '../hooks'
import {findImagesBy} from '../utils/image-groups-utils'
import {isAbsoluteUrl, productUrlBuilder} from '../utils/url'
import {getDisplayPrice} from '../utils/product-utils'
import {useIntl} from 'react-intl'
import useMultiSite from '../hooks/use-multi-site'

const MyRegistryContext = React.createContext(null)

export const MyRegistryAPIProvider = ({children, enabled, siteKey}) => {
    const requestRef = useRef(false)
    const apiLoader = useCallback(async () => {
        if (!enabled) {
            throw new Error('MyRegistryAPI is not enabled')
        }
        if (!siteKey) {
            throw new Error('MyRegistryAPI enabled without siteKey')
        }

        if (requestRef.current) {
            return requestRef.current
        }

        requestRef.current = new Promise((resolve, reject) => {
            const script = document.createElement('script')
            script.src = `https://www.myregistry.com/ScriptPlatform/API/AddButtonSDK.js?siteKey=${siteKey}`
            script.addEventListener('load', () => {
                if (window.MyRegistrySDK) {
                    resolve(window.MyRegistrySDK)
                } else {
                    setTimeout(() => {
                        if (window.MyRegistrySDK) {
                            resolve(window.MyRegistrySDK)
                        } else {
                            reject(new Error('Failed to load MyRegistrySDK'))
                        }
                    }, 50)
                }
            })
            script.addEventListener('error', () => {
                reject(new Error('Failed to load MyRegistrySDK'))
            })
            document.body.appendChild(script)
        })
        return requestRef.current
    }, [enabled, siteKey])

    const value = useMemo(
        () => ({
            enabled,
            apiLoader
        }),
        [enabled, apiLoader]
    )

    return <MyRegistryContext.Provider value={value}>{children}</MyRegistryContext.Provider>
}

MyRegistryAPIProvider.propTypes = {
    children: PropTypes.any,
    enabled: PropTypes.bool,
    siteKey: PropTypes.string
}

export const useMyRegistryAPILoader = () => {
    const ctx = useContext(MyRegistryContext)
    if (!ctx) {
        throw new Error('useMyRegistryAPILoader must be used within a MyRegistryAPIProvider')
    }
    return ctx
}

export const useMyRegistryEnabled = () => {
    const {enabled} = useMyRegistryAPILoader()
    return enabled
}

export const useAddGiftToMyRegistry = (product) => {
    const {buildUrl} = useMultiSite()
    const {enabled, apiLoader} = useMyRegistryAPILoader()
    const {currency} = useCurrency()
    const {locale} = useIntl()
    const colorVariation = useColorVariation(product)

    const gift = useMemo(() => {
        if (!enabled || !product) {
            return null
        }
        const {displayPrice} = getDisplayPrice(product)
        const image = findImagesBy(
            product.imageGroups,
            product.c_extraMainImage,
            product.c_extraMainImage2,
            {
                viewType: PRODUCT_TILE_IMAGE_TYPE,
                selectedVariationAttributes: product.variationValues
            }
        )?.[0]
        const _href = productUrlBuilder(product, locale)
        const gift = {
            Title: product?.variant?.name || product.name,
            ImageUrl: image?.disBaseLink || image?.link,
            URL: !isAbsoluteUrl(_href) ? buildUrl(_href) : _href,
            Price: displayPrice,
            Quantity: 1,
            CurrencyCode: currency,
            Color: colorVariation?.selectedValue?.name,
            HideColor: !colorVariation?.selectedValue.name,
            HideSize: true,
            SKU: product.id
        }
        return gift
    }, [buildUrl, product, colorVariation, locale, currency, enabled])

    const {execute, loading, error} = useAsync(async (gift) => {
        const api = await apiLoader()

        const mrGift = new api(gift)

        return Promise.race([
            new Promise((resolve, reject) => {
                try {
                    // does not work on iframe
                    mrGift.on('popup_showed', () => {
                        resolve(mrGift)
                    })
                    // works with iframe
                    mrGift.on('popup_closed', () => {
                        resolve(mrGift)
                    })
                    mrGift.showPopup()
                } catch (e) {
                    reject(e)
                }
            }),
            // fallback to resolve after 10 seconds as on iframe popup_showed is not triggered
            new Promise((resolve) => {
                setTimeout(() => resolve(mrGift), 10000)
            })
        ])
    })

    return {enabled, loading, error, execute: () => execute(gift)}
}
