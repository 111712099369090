import React from 'react'
import Search from '../../components/search'
import {useIntl} from 'react-intl'
import {SearchIcon} from '../../components/icons'

/**
 * Display Searchbar and searchbutton
 *
 * @example
 *   <pwa-widget-search>
 *     $include(...)$
 *   </pwa-widget-search>
 */
const WidgetSearch = () => {
    const intl = useIntl()

    return (
        <Search
            placeholder={intl.formatMessage({
                id: 'global.search',
                defaultMessage: 'Search'
            })}
            variant="flushed"
            leftIcon={<SearchIcon boxSize={4} />}
            withSearchButton={true}
        />
    )
}

export default WidgetSearch
