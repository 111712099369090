import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: ({colorScheme, theme}) => {
        /* If the theme passed as prop is not one of valid themes reset [colorScheme] */
        if (!theme.colors[colorScheme]?.bright) {
            colorScheme = null
        }

        return {
            title: {
                color: colorScheme ? `${colorScheme}.dark` : 'stokkeCore.black'
            },
            stepper: {
                width: '100%',
                textAlign: 'center',
                ...textStyles['Semi Bold Small']
            },
            sliderButtons: {
                cursor: 'pointer'
            },
            scrollBar: {
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    height: 0
                },
                touchAction: 'pan-y'
            }
        }
    }
}
