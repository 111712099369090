import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {Flex, Text} from '@chakra-ui/react'
import NavigationItem from '../navigation-item'
import DrawerMenuError from './drawer-menu-error'
import Lvl2Link from './lvl2-link'

/**
 * Renders lvl2 items in the mobile and desktop drawer menu.
 */
const Lvl2Item = ({item, filterItems}) => {
    // filter lvl3 items that should be displayed
    const displayedItems = useMemo(() => item.items?.filter(filterItems), [item, filterItems])

    switch (item.type) {
        case 'NavigationLink':
            return <Lvl2Link item={item} />
        case 'NavigationItem':
            return (
                <Flex flexDirection="column">
                    <Text
                        paddingX={2}
                        height={4.5}
                        textStyle="Badge Regular"
                        color="stokkeGray.accessibleText"
                        textTransform="uppercase"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        {item.label}
                    </Text>

                    {displayedItems?.length ? (
                        displayedItems.map((subitem, index) => (
                            <NavigationItem
                                {...subitem}
                                key={index}
                                levelIdx={3}
                                trackingIndex={2}
                            />
                        ))
                    ) : (
                        <DrawerMenuError error="No items to display" details={item} />
                    )}
                </Flex>
            )
        default:
            return <DrawerMenuError error="Unknown type" details={item} />
    }
}

Lvl2Item.propTypes = {
    item: PropTypes.object.isRequired,
    filterItems: PropTypes.func
}

export default Lvl2Item
