import PropTypes from 'prop-types'
import React from 'react'
import NewsletterBanner from '../../components/newsletter-banner'

/**
 * Display newsletter subscription widget.
 *
 * @example
 *   <pwa-widget-newsletter>
 *     $include(...)$
 *   </pwa-widget-newsletter>
 */
const WidgetNewsletter = ({source = 'Web - Newsletter Block'}) => {
    return <NewsletterBanner source={source} />
}

WidgetNewsletter.propTypes = {
    source: PropTypes.string
}

export default WidgetNewsletter
