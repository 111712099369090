import prepareHtmlForInjection from '../util/prepare-html-for-injection'
// noinspection JSDeprecatedSymbols
import {useCommerceAPI} from '../../../commerce-api/contexts'
import {useQuery} from '@tanstack/react-query'

export const TYPE_CONTENT_ASSET = 'contentAsset'
export const TYPE_CONTENT_SLOT = 'contentSlot'

/**
 * @param {string} cid - content id
 * @param {TYPE_CONTENT_SLOT|TYPE_CONTENT_SLOT} type - content type
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions] additional options for the query
 * @return {import("@tanstack/react-query").UseQueryResult} the query result
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value.
 */
const useCmsContent = (cid, type = TYPE_CONTENT_ASSET, queryOptions) => {
    // noinspection JSDeprecatedSymbols - we can't use the new ocapi/sapi hooks because we have async post processing
    const api = useCommerceAPI()

    return useQuery(
        // TODO v3: figure a safe way to reuse the appropriate key based on which api is used to share the cache
        ['cms-content', cid, type],
        async ({queryKey: [, cid, type]}) => {
            const data = await (async () => {
                switch (type) {
                    case TYPE_CONTENT_ASSET:
                        return api.shopperContent.getContent({parameters: {cid, html: true}})
                    case TYPE_CONTENT_SLOT:
                        return {c_body: await api.sapi.getSlot({parameters: {slotId: cid}})}
                    default:
                        throw new Error(`Unknown CMS content type: ${type}`)
                }
            })()

            return {c_body: await prepareHtmlForInjection(data?.c_body)}
        },
        {
            ...queryOptions,
            enabled: !!cid && queryOptions?.enabled !== false
        }
    )
}

export default useCmsContent
