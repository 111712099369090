import {useMemo} from 'react'
import {tryParseJSONObject} from '../utils/utils'
import {useOcapiSiteRaw} from './useOcapi'
import {useServerContext} from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'

export default function useOcapiSite() {
    // use ocapi react query hook to fetch the site data
    const {data: ocapiSite} = useOcapiSiteRaw()

    // expose the loaded ocapi site to the server context so helmet config can use it in ssr
    const {res} = useServerContext()
    if (res) {
        res.locals.ocapiSite = ocapiSite
    }

    const self = useMemo(() => {
        // create class with getters for the different ocapiSite properties
        return new (class {
            get isLoaded() {
                return !!ocapiSite
            }
            get allowedLocales() {
                return ocapiSite?.default?.allowedLocales
            }
            get arEnabled() {
                return ocapiSite?.custom?.c_result?.preferences?.arEnabled
            }
            get arModelList() {
                // TODO: cache the result
                const value = ocapiSite?.custom?.c_result?.preferences?.arModelList
                if (!value) return null
                try {
                    // The structure is [{description: String, pids: String[], file: String}, ...]
                    const modelList = JSON.parse(value)
                    // convert to {[pid]: {description: String, file: String}}
                    return Object.fromEntries(
                        modelList.flatMap(({pids, ...item}) => pids.map((pid) => [pid, item]))
                    )
                } catch (e) {
                    if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
                        console.warn('Error parsing arModelList', e)
                    }
                    return null
                }
            }
            get brightcoveEnabled() {
                return ocapiSite?.custom?.c_result?.preferences?.BrightcoveEnabled
            }
            get customPreferences() {
                return ocapiSite?.custom?.c_result?.preferences || {}
            }
            get sitePrefGeoLocation() {
                return tryParseJSONObject(
                    ocapiSite?.custom?.c_result?.preferences?.countryGeoCoordinates
                )
            }
            get designerProgramEnabled() {
                return ocapiSite?.custom?.c_result?.preferences.designerProgramEnabled
            }
            get hybridConfig() {
                const hybridConfig = ocapiSite?.custom?.c_result?.preferences?.pwaHybridConfig
                if (typeof hybridConfig === 'string') {
                    try {
                        return JSON.parse(hybridConfig)
                    } catch (e) {
                        console.warn(`Error parsing hybridConfig = "${hybridConfig}"`, e)
                        return null
                    }
                }
                return hybridConfig
            }
            get myStokkeConfig() {
                const myStokkeConfig = ocapiSite?.custom?.c_result?.myStokkeConfig
                return Object.fromEntries(
                    myStokkeConfig?.map((config, idx) => [config.id, {...config, order: idx}]) || []
                )
            }
            get npsEnabled() {
                return Boolean(ocapiSite?.custom?.c_result?.preferences?.npsEnabled)
            }
            get npsIframeUrl() {
                return ocapiSite?.custom?.c_result?.preferences?.npsIframeUrl
            }
            get paymentMethods() {
                return ocapiSite?.custom?.c_result?.paymentMethods
            }
            get parcellabUserId() {
                return ocapiSite?.custom?.c_result?.preferences?.parcellabUserId || 1617747
            }
            get parcellabReturnOrderJS() {
                return ocapiSite?.custom?.c_result?.preferences?.parcellabReturnOrderJS
            }
            get parcellabReturnOrderCSS() {
                return ocapiSite?.custom?.c_result?.preferences?.parcellabReturnOrderCSS
            }
            get preferences() {
                return ocapiSite?.custom?.c_result?.preferences || {}
            }
            get reCaptchaPublicKey() {
                return ocapiSite?.custom?.c_result?.preferences?.reCaptchaPublicKey
            }
            get shippingMethods() {
                return ocapiSite?.custom?.c_result?.shippingMethods
            }
            get show3Dimages() {
                return ocapiSite?.custom?.c_result?.preferences?.show3Dimages
            }
            get shippingContentAssetId() {
                return ocapiSite?.custom?.c_result?.preferences?.shippingContentAssetId
            }
            get accountId() {
                return ocapiSite?.custom?.c_result?.preferences?.BrightcoveAccountID
            }
            get assemblyVideoPlayerId() {
                return ocapiSite?.custom?.c_result?.preferences?.BrightcoveAssemblyVideoPlayerId
            }
            get pdpContentEnabled() {
                return ocapiSite?.custom?.c_result?.preferences?.pwaPdpContentEnabled
            }
            get engravingImage() {
                return ocapiSite?.custom?.c_result?.imageUrls?.engraving
            }
            get bvApiKey() {
                return ocapiSite?.custom?.c_result?.preferences?.bvApiKey
            }
            get redirectLinkPartnerAllProducts() {
                return ocapiSite?.custom?.c_result?.preferences?.redirectLinkPartnerAllProducts
            }
            get bvEnabled() {
                return ocapiSite?.custom?.c_result?.preferences?.bvEnabled
            }
            get httpsCatalogContentUrl() {
                return ocapiSite?.custom?.c_result?.httpsCatalogContentUrl
            }
            get logo() {
                return ocapiSite?.current?.custom?.c_result?.imageUrls?.logo
            }
            get channelAdvisorURL() {
                return ocapiSite?.custom?.c_result?.preferences?.channelAdvisorURL
            }
            get channelAdvisorPID() {
                return ocapiSite?.custom?.c_result?.preferences?.channelAdvisorPID
            }
            get showAlwaysWheretobuyEnabled() {
                return ocapiSite?.custom?.c_result?.preferences?.showAlwaysWheretobuyEnabled
            }
            get googleVerificationTag() {
                return ocapiSite?.custom?.c_result?.preferences?.GoogleVerificationTag
            }
            get errorPageImage() {
                return ocapiSite?.custom?.c_result?.imageUrls?.errorPageImage
            }
        })()
    }, [ocapiSite])
    return self
}
