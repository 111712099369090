import fetch from 'cross-fetch'
import BrowserCache from '../utils/browser-cache'
import RequestMerger from '../utils/request-merger'
import {BV_REVIEWS_LIMIT} from '../constants'
import {trackRRSubmitReview} from '../tracking/analytics'

/**
 * @typedef {{
 *   rating?: array|null|undefined,
 * }} BazaarvoiceAPIFilters
 */

/**
 * @typedef {{
 *   Locale?: string|null|undefined,
 *   Offset?: number|null|undefined,
 *   Limit?: number|null|undefined,
 *   Search?: string|null|undefined,
 *   Sort?: string|null|undefined,
 * }} BazaarvoiceAPIQuery
 */

class BazaarvoiceAPI {
    constructor(bazaarvoiceConfig = {}) {
        this.config = bazaarvoiceConfig
        this.ratingCache = new BrowserCache({name: 'bv-product-ratings', ttl: this.config.ttl})
        this.ratingQueue = new RequestMerger({
            throttleInterval: this.config.throttleInterval,
            onExecute: this._getMergedProductRatings.bind(this)
        })

        this.getProductRating = this.getProductRating.bind(this)
        this.getProductReviewOverview = this.getProductReviewOverview.bind(this)
        this.getProductReviews = this.getProductReviews.bind(this)
        this.loadApiScript = this.loadApiScript.bind(this)
        this.showReviewSubmissionForm = this.showReviewSubmissionForm.bind(this)
        this.submitHelpfulnessVote = this.submitHelpfulnessVote.bind(this)
    }

    /**
     * @param {string} endpoint
     * @param {Record<String, String>|Array<Array<String>>} params
     * @param {RequestInit} [options]
     * @returns {Promise<{url: string, data: object}>}
     * @private
     */
    async _bazaarvoiceFetch(endpoint, params, options) {
        const {apiVersion, proxyPath, bvApiKey, host} = this.config

        const allParams = [
            ['ApiVersion', apiVersion],
            ['Passkey', bvApiKey],
            ...(Array.isArray(params) ? params : Object.entries(params))
        ]

        const realUrl = `${
            host || window?.location?.origin + proxyPath
        }/data/${endpoint}?${new URLSearchParams(allParams)}`
        const url = `${proxyPath || host}/data/${endpoint}?${new URLSearchParams(allParams)}`

        try {
            const response = await fetch(url, options)

            if (!response?.ok) {
                throw new Error(`Bazaarvoice API error: ${response?.statusText}`)
            }

            const data = await response.json()

            if (data.HasErrors) {
                throw new Error(`Bazaarvoice API error: ${data.Errors[0].Message}`)
            }

            return {data, url: realUrl}
        } catch (error) {
            if (!options?.signal?.aborted) {
                console.error('There was an error fetching from the Bazaarvoice API', {
                    url: realUrl,
                    error
                })
            }
            throw error
        }
    }

    /**
     * Loads the Bazaarvoice API script if it hasn't already been loaded and returns the global BV and $BV objects.
     * @returns {Promise<{BV?: object, $BV?: object}>}
     * @private
     * @see https://knowledge.bazaarvoice.com/wp-content/conversations-prr/en_US/technical_setup/appendix.html:
     */
    loadApiScript() {
        // need to fetch up to date $BV instance as it appear to change after first show of submit review form
        if (window.$BV || window.BV) {
            return Promise.resolve({
                ...(window.$BV && {$BV: window.$BV}),
                ...(window.BV && {BV: window.BV})
            })
        }
        if (this.apiScriptRef) {
            return this.apiScriptRef
        }
        this.apiScriptRef = new Promise((resolve, reject) => {
            const {bvApiScriptUrl} = this.config

            if (window.$BV || window.BV) {
                resolve({
                    ...(window.$BV && {$BV: window.$BV}),
                    ...(window.BV && {BV: window.BV})
                })
                return
            }
            if (!bvApiScriptUrl) {
                resolve()
                return
            }
            const script = document.createElement('script')
            script.addEventListener('load', () => {
                resolve({
                    ...(window.$BV && {$BV: window.$BV}),
                    ...(window.BV && {BV: window.BV})
                })
                window.$BV?.configure('global', {
                    // @see https://knowledge.bazaarvoice.com/wp-content/conversations/en_US/Display/display_integration.html#integrate-event-callbacks
                    events: {
                        /**
                         * @param {string} productId
                         * @param {'review'} contentType
                         */
                        submissionSubmitted: ({contentType}) => {
                            if (contentType === 'review') {
                                trackRRSubmitReview('')
                            }
                        }
                    }
                })
            })
            script.addEventListener('error', () => {
                reject(new Error(`Failed to load script ${bvApiScriptUrl}`))
            })
            script.src = bvApiScriptUrl
            script.async = true
            document.head.appendChild(script)
        })
        return this.apiScriptRef
    }

    /**
     * Call to get product ratings for multiple products.
     * @param {string[]} productIds
     * @param {RequestInit} options
     * @returns {Promise<{url: string, data: Object}>}
     * @private
     */
    _getMergedProductRatings(productIds, options) {
        const endpoint = 'statistics.json'
        const params = {
            Filter: `productid:${productIds.join(',')}`,
            Stats: 'Reviews'
        }

        return this._bazaarvoiceFetch(endpoint, params, options)
    }

    /**
     * Get product rating for a single product.
     * @param {string} productId
     * @param {RequestInit} options
     * @returns {Promise<{AverageOverallRating: number|null, TotalReviewCount: number, OverallRatingRange: number}|null>}
     */
    async getProductRating(productId, options) {
        return this.ratingCache.getOrProcess(productId, async () => {
            const {url, data} = (await this.ratingQueue.queue(productId, options)) || {}

            try {
                const {Results} = data || {}
                const review = Results?.find(
                    (review) => review?.ProductStatistics?.ProductId === productId
                )
                const product = review?.ProductStatistics
                const statistics = product?.ReviewStatistics || {}

                if (process.env.NODE_ENV !== 'test') {
                    if (!statistics) {
                        console.error(`No review statistics for ${productId}`, {
                            url,
                            response: data
                        })
                    } else if (statistics?.AverageOverallRating == null) {
                        console.warn(`No average overall rating for ${productId}`, {
                            url,
                            response: data
                        })
                    }
                }

                return statistics
            } catch (e) {
                console.error(`Error parsing review statistics for ${productId}`, {
                    url,
                    response: data,
                    error: e
                })
                throw e
            }
        })
    }

    /**
     * Convert map with filters into query filter params
     * @param {BazaarvoiceAPIFilters} rating
     * @returns {{Filter: string}[]}
     * @private
     * @see https://developer.bazaarvoice.com/conversations-api/reference/v5.4/reviews/review-display#filter-options
     */
    _compileFiltersQuery({rating} = {}) {
        return [...(rating?.length ? [['Filter', `Rating:eq:${rating.join(',')}`]] : [])]
    }

    /**
     * @param {string} productId
     * @param {RequestInit} options
     * @returns {Promise<{review: *, statistics: ({TotalReviewCount: number, AverageOverallRating: number, OverallRatingRange: number}|{FeaturedReviewCount: number, RecommendedCount: number, SecondaryRatingsAveragesOrder: [], AverageOverallRating: number, NotHelpfulVoteCount: number, RatingDistribution: [{RatingValue: number, Count: number},{RatingValue: number, Count: number},{RatingValue: number, Count: number},{RatingValue: number, Count: number},{RatingValue: number, Count: number}], RatingsOnlyReviewCount: number, FirstSubmissionTime: string, TagDistribution: {}, ContextDataDistribution: {}, TotalReviewCount: number, LastSubmissionTime: string, TagDistributionOrder: [], OverallRatingRange: number, HelpfulVoteCount: number, NotRecommendedCount: number, ContextDataDistributionOrder: [], SecondaryRatingsAverages: {}}|{TotalReviewCount: number, AverageOverallRating: number, OverallRatingRange: number}|*)}>}
     */
    async getProductReviewOverview(productId, options) {
        const language = this.config.locale.id.split('-')[0]
        const params = {
            Filter: `productid:${productId}`,
            Include: 'Products',
            Stats: 'Reviews',
            // highest rated review and if multiple the newest one
            Sort: 'Rating:desc,IsRatingsOnly:asc,SubmissionTime:desc',
            Limit: 1
        }

        // NOTE: Do not merge requests as then we'll need to check for the proper review to use considering product families
        // and the limit will be meaningless
        let data = null
        let url = null
        // load first for the current language
        const currentLanguageResponse = await this._bazaarvoiceFetch(
            'reviews.json',
            [['Filter', `ContentLocale:${language}*`], ...Object.entries(params)],
            options
        )
        if (currentLanguageResponse.data?.Results?.length) {
            data = currentLanguageResponse.data
            url = currentLanguageResponse.url
        } else {
            // if no reviews for the current language, load for all languages
            const allLanguagesResponse = await this._bazaarvoiceFetch(
                'reviews.json',
                params,
                options
            )
            data = allLanguagesResponse.data
            url = allLanguagesResponse.url
        }

        try {
            const {Results: results, Includes: includes} = data || {}
            // just use the first review as they should be already filtered and sorted
            const review = results?.[0]
            const product =
                includes?.Products?.[review?.ProductId] ||
                // fallback to use whatever product is there, as due to families we're not sure if the requested product will be included
                // but might be one of it's family members
                Object.values(includes?.Products || {})[0]
            const statistics = product?.ReviewStatistics

            if (process.env.NODE_ENV !== 'test') {
                if (!product) {
                    console.error(`No product for ${productId}`, {
                        url,
                        response: data
                    })
                } else {
                    if (!review) {
                        console.warn(`No review for ${productId}`, {
                            url,
                            response: data
                        })
                    }
                    if (!statistics) {
                        console.error(`No review statistics for ${productId}`, {
                            url,
                            response: data
                        })
                    } else if (statistics?.AverageOverallRating == null) {
                        console.warn(`No average overall rating for ${productId}`, {
                            url,
                            response: data
                        })
                    }
                }
            }

            return {review, statistics}
        } catch (e) {
            console.error(`Error parsing product review overview for ${productId}`, {
                url,
                response: data,
                error: e
            })
            throw e
        }
    }

    /**
     * @param {string} productId
     * @param {RequestInit | BazaarvoiceAPIQuery | BazaarvoiceAPIFilters} options
     * @returns {Promise<{reviews: *, statistics: ({TotalReviewCount: number, AverageOverallRating: number, OverallRatingRange: number}|{FeaturedReviewCount: number, RecommendedCount: number, SecondaryRatingsAveragesOrder: [], AverageOverallRating: number, NotHelpfulVoteCount: number, RatingDistribution: [{RatingValue: number, Count: number},{RatingValue: number, Count: number},{RatingValue: number, Count: number},{RatingValue: number, Count: number},{RatingValue: number, Count: number}], RatingsOnlyReviewCount: number, FirstSubmissionTime: string, TagDistribution: {}, ContextDataDistribution: {}, TotalReviewCount: number, LastSubmissionTime: string, TagDistributionOrder: [], OverallRatingRange: number, HelpfulVoteCount: number, NotRecommendedCount: number, ContextDataDistributionOrder: [], SecondaryRatingsAverages: {}}|{TotalReviewCount: number, AverageOverallRating: number, OverallRatingRange: number}|*)}>}
     * @throws {Error} - If there is an error parsing or fetching the product reviews.
     */
    async getProductReviews(
        productId,
        {rating, Limit = BV_REVIEWS_LIMIT, Locale, Offset, Search, Sort, ...requestOptions} = {}
    ) {
        const params = [
            ...Object.entries({
                Filter: `productid:${productId}`,
                ...(Limit ? {Limit} : {}),
                ...(Offset ? {Offset} : {}),
                ...(Search ? {Search, Locale} : {}),
                ...(Sort ? {Sort} : {})
            }),
            ...this._compileFiltersQuery({rating})
        ]

        // NOTE: Do not merge requests as then we'll need to check for the proper review to use considering product families
        const {url, data} =
            (await this._bazaarvoiceFetch('reviews.json', params, requestOptions)) || {}

        try {
            const {Results: reviews, TotalResults: totalCount} = data || {}

            if (!reviews) {
                return {reviews: [], totalCount: 0}
            }

            return {
                reviews,
                totalCount
            }
        } catch (e) {
            console.error(`Error parsing product reviews for ${productId}`, {
                url,
                response: data,
                error: e
            })
            throw e
        }
    }

    /**
     * Displays the Bazaarvoice review submission form for the given product ID.
     *
     * @param {string} productId
     * @param {AbortSignal} [signal]
     * @returns {Promise<void>}
     * @see https://knowledge.bazaarvoice.com/wp-content/conversations-prr/en_US/technical_setup/appendix.html#submit_review
     */
    async showReviewSubmissionForm(productId, {signal} = {}) {
        // Short-circuit if there's no product ID
        if (!productId) {
            return
        }

        // If we're already showing the review submission form for a different product, cancel that request
        this.showingReviewSubmissionForm = productId

        const res = await this.loadApiScript()
        const bvApi = res?.$BV
        // If there was another request to show the review submission form for a different product or the signal was aborted,
        // cancel this request and let the other request handle it
        if (this.showingReviewSubmissionForm !== productId || signal?.aborted) {
            return
        }
        // If the script failed to load, throw an error
        if (!bvApi) {
            throw new Error('Bazaarvoice API script not loaded')
        }

        bvApi.ui('rr', 'submit_review', {productId})
    }

    /**
     * Extracts specified parameters from options object.
     *
     * @param {object} options - The options object.
     * @param {string[]} params - The array of parameter names to extract.
     * @returns {object} - An object containing extracted parameters.
     */
    extractParamsFromOptions(options, params) {
        return params.reduce((result, param) => {
            if (options?.[param] !== undefined) {
                result[param] = options[param]
            }
            return result
        }, {})
    }

    /**
     * Submits a helpfulness vote for the given content ID.
     * @param {string} contentId
     * @param {'Review'} contentType
     * @param {'Positive'|'Negative'} vote
     * @returns {Promise<void>}
     * @see https://developer.bazaarvoice.com/conversations-api/reference/v5.4/feedback/feedback-submission#submitting-a-helpfulness-vote
     */
    async submitHelpfulnessVote({contentType, contentId, vote}) {
        await this._bazaarvoiceFetch(
            'submitfeedback.json',
            {
                ContentType: contentType,
                ContentId: contentId,
                FeedbackType: 'helpfulness',
                Vote: vote
            },
            {method: 'POST'}
        )
    }

    /**
     * Report inappropriate content
     * @param {string} contentId
     * @param {'Review'} contentType
     * @returns {Promise<void>}
     * @see https://developer.bazaarvoice.com/conversations-api/reference/v5.4/feedback/feedback-submission#submitting-inappropriate-feedback
     */
    async reportInappropriate({contentType, contentId}) {
        await this._bazaarvoiceFetch(
            'submitfeedback.json',
            {
                ContentType: contentType,
                ContentId: contentId,
                FeedbackType: 'inappropriate'
            },
            {method: 'POST'}
        )
    }
}

export default BazaarvoiceAPI
