import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Box, Text} from '@chakra-ui/react'
import {HYBRID_EXTERNAL} from '../../constants'
import HybridLink from '../hybrid-link'
import useOcapiSite from '../../hooks/useOcapiSite'
import PropTypes from 'prop-types'

const DesignerProgram = ({textColor}) => {
    const {designerProgramEnabled} = useOcapiSite().preferences

    return (
        <>
            {designerProgramEnabled && (
                <Box textAlign="center">
                    <Text fontSize="sm" color={textColor ? textColor : null}>
                        <FormattedMessage
                            defaultMessage="If you are a designer or interior architect, "
                            id="register_form.message.you_are_designer"
                        />
                    </Text>
                    {/*todo: url - https://dept-de.atlassian.net/browse/STOK-11246*/}
                    <HybridLink
                        page={HYBRID_EXTERNAL}
                        href="/home"
                        hybridHref="/home"
                        size="sm"
                        color={textColor ? textColor : null}
                    >
                        <FormattedMessage
                            defaultMessage="create an account here and discover your exclusive advantage."
                            id="register_form.message.create_designer_account"
                        />
                    </HybridLink>
                </Box>
            )}
        </>
    )
}

export default DesignerProgram

DesignerProgram.propTypes = {
    textColor: PropTypes.string
}
