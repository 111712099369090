import {Heading} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import textStyles from '../../theme/foundations/textStyles'

const OrderSummaryHeading = ({children, ...props}) => {
    if (React.Children.count(children) === 0) {
        return null
    }
    return (
        <Heading {...textStyles['Regular 2XLarge']} {...props}>
            {children}
        </Heading>
    )
}

OrderSummaryHeading.propTypes = {
    children: PropTypes.node
}

export default OrderSummaryHeading
