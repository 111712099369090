import PropTypes from 'prop-types'
import React, {useContext, useEffect, useMemo, useState} from 'react'

const INTERACTION_EVENTS = [
    // high level events
    'blur',
    'focus',
    'click',
    'dblclick',
    'contextmenu',
    'scroll',
    'beforeinput',
    'input',
    'resize',
    'select',

    // low level user events
    'compositionstart',
    'compositionupdate',
    'compositionend',

    'focusin',
    'focusout',

    'keydown',
    'keypress',
    'keyup',

    'mousemove',
    'mouseover',
    'mouseenter',
    'mouseout',
    'mouseleave',
    'mousedown',
    'mouseup',
    'mousewheel',
    'wheel',

    'pointerover',
    'pointerenter',
    'pointerdown',
    'pointermove',
    'pointerup',
    'pointercancel',
    'pointerout',
    'pointerleave',
    'gotpointercapture',
    'lostpointercapture',

    'touchstart',
    'touchend',
    'touchmove',
    'touchcancel'
]

const UserInteractionContext = React.createContext()

export const UserInteractionProvider = ({children}) => {
    const [userInteracted, setUserInteracted] = useState(false)

    useEffect(() => {
        const cleanup = () => {
            INTERACTION_EVENTS.forEach((event) => {
                window.removeEventListener(event, trigger)
            })
        }

        const trigger = () => {
            setUserInteracted(true)
            cleanup()
        }

        // listen for user interaction events
        INTERACTION_EVENTS.forEach((event) => {
            window.addEventListener(event, trigger, {once: true})
        })

        return cleanup
    }, [])

    const ctx = useMemo(
        () => ({
            userInteracted
        }),
        [userInteracted]
    )

    return <UserInteractionContext.Provider value={ctx}>{children}</UserInteractionContext.Provider>
}

UserInteractionProvider.propTypes = {
    children: PropTypes.any
}

export const useUserInteraction = () => {
    const ctx = useContext(UserInteractionContext)
    if (!ctx) {
        throw new Error('useUserInteraction must be used within a UserInteractionProvider')
    }
    return ctx
}
