import React from 'react'
import PropTypes from 'prop-types'

import ProductView from '../../../partials/product-view'
import {useProductViewModal} from '../../../hooks/use-product-view-modal'

const BonusProduct = ({bonusProduct, removeRestrictedVariantsAndAttributes, ...props}) => {
    const {product, isFetching} = useProductViewModal(
        bonusProduct,
        removeRestrictedVariantsAndAttributes
    )

    return (
        <ProductView
            showFullLink={true}
            imageSize="sm"
            product={product}
            isBonusProduct={true}
            isProductLoading={isFetching}
            {...props}
        />
    )
}

BonusProduct.propTypes = {
    bonusProduct: PropTypes.object,
    removeRestrictedVariantsAndAttributes: PropTypes.func
}

export default BonusProduct
