export default {
    baseStyle: () => ({
        listContainer: {
            spacing: {base: 0, md: 6},
            paddingX: {base: 4, md: 6, lg: 8},
            paddingY: 15
        },
        listHeader: {
            textStyle: 'Regular 2XLarge'
        },
        tileContainer: {
            position: 'relative',
            width: '100%',
            _hover: {
                '.overlay': {
                    visibility: 'visible'
                }
            }
        },
        tileAspectRatio: {
            width: '100%',
            ratio: 1
        },
        tileImage: {
            width: '100%'
        },
        tileOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            className: 'overlay',
            visibility: 'hidden',
            color: 'stokkeCore.white',
            bg: 'rgba(0, 0, 0, 0.80)',
            padding: 2.5,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5,
            textStyle: 'Semi Bold Medium'
        }
    }),
    variants: {
        widget: {
            listContainer: {
                paddingX: null,
                paddingY: null,
                paddingTop: {base: 8, md: 6},
                spacing: {base: 8, md: 6}
            }
        }
    }
}
