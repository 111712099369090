import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Button} from '@chakra-ui/react'
import {
    ProductQuickviewModal,
    useProductQuickviewModal
} from '../../components/product-quickview-modal'
import {useIntl} from 'react-intl'
import {productUrlBuilder} from '../../utils/url'
import {isProductSet} from '../../utils/product-utils'
import {useProduct} from '@salesforce/commerce-sdk-react'

/**
 * Display ProductQuickviewModal
 *
 * @param {string} pid - productId
 *
 * @example
 *   <pwa-widget-hotspot pid="5968">
 *     $include(...)$
 *   </pwa-widget-hotspot>
 */
const WidgetHotspot = ({pid}) => {
    const {formatMessage} = useIntl()
    const quickViewModalProps = useProductQuickviewModal()
    const [hotspotButton, setHotspotButton] = useState(null)
    const intl = useIntl()

    useEffect(() => {
        if (!hotspotButton?.parentElement?.parentElement) {
            return
        }
        if (quickViewModalProps.isOpen) {
            hotspotButton.parentElement.parentElement.classList?.add('active')
        } else {
            hotspotButton.parentElement.parentElement.classList?.remove('active')
        }
    }, [quickViewModalProps.isOpen, hotspotButton])

    const {data} = useProduct({parameters: {id: pid}}, {enabled: !!pid})

    if (!data) {
        return null
    }

    return (
        <Button
            onClick={() => {
                if (isProductSet(data)) {
                    window.location.href = productUrlBuilder(data, intl.locale)
                } else {
                    quickViewModalProps.onOpen(pid)
                }
            }}
            bg="transparent"
            height={10}
            width={10}
            zIndex="1"
            border="0"
            data-testid="hotspot-button"
            ref={setHotspotButton}
            aria-label={formatMessage({
                id: 'widget_hotspot.button.assistive_msg.hotspot',
                defaultMessage: 'Hotspot - Opens the related product'
            })}
        >
            <ProductQuickviewModal
                {...quickViewModalProps}
                showAddToCartModal={false}
                scrollToTop={false}
                isLoading={false}
            />
        </Button>
    )
}

WidgetHotspot.propTypes = {
    pid: PropTypes.string
}

export default WidgetHotspot
