import {useEffect} from 'react'
import useMultiSite from '../../hooks/use-multi-site'
import {useNonce} from '../../hooks'
import useEffectEvent from '../../hooks/use-effect-event'

const CookieBot = () => {
    const {site} = useMultiSite()
    const nonce = useNonce()
    const src = 'https://consent.cookiebot.com/uc.js'
    const enableCookiebot = site?.gdpr?.enableCookiebot || false
    const cookiebotID = site?.gdpr?.cookiebotID || 'e2f873d9-2ee6-420d-b222-9cf53fc30ec0'
    const cookiebotRegions = site?.gdpr?.cookiebotRegions || ''

    // load the script manually, since helmet replicates the script tag
    const onMount = useEffectEvent(() => {
        if (!enableCookiebot) {
            return
        }

        const script = document.createElement('script')
        script.async = true
        script.src = src
        script.setAttribute('data-cbid', cookiebotID)
        script.setAttribute('data-blockingmode', 'auto')
        script.setAttribute('data-georegions', cookiebotRegions)
        script.nonce = nonce
        document.head.appendChild(script)
    })
    useEffect(
        () => {
            onMount()
        },
        // intentionally no deps, as we can't change the script once it's loaded
        [onMount]
    )

    return null
}

export default CookieBot
