import React from 'react'
import PropTypes from 'prop-types'
import HybridLink from '../hybrid-link'
import {Text, Button, useStyleConfig, Flex} from '@chakra-ui/react'

const TeaserLink = ({icon, title, description, href, to, hybridHref, onClick, ...props}) => {
    const styles = useStyleConfig('TeaserLink')

    const Content = () => {
        return (
            <>
                <Flex {...styles.heading}>
                    {icon && React.cloneElement(icon, styles.icon)}
                    <Text layerStyle="insideLink" {...styles.title}>
                        {title}
                    </Text>
                </Flex>
                {description && <Text {...styles.description}>{description}</Text>}
            </>
        )
    }

    return href || to || hybridHref ? (
        <HybridLink
            variant="teaserLink"
            href={href}
            to={to}
            hybridHref={hybridHref}
            onClick={onClick}
            {...props}
        >
            <Content />
        </HybridLink>
    ) : (
        <Button variant="teaserLink" onClick={onClick} {...props}>
            <Content />
        </Button>
    )
}

TeaserLink.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    href: PropTypes.string,
    to: PropTypes.string,
    hybridHref: PropTypes.string,
    onClick: PropTypes.func
}

export default TeaserLink
