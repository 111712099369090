const VALID_SCRIPT_TYPES = [
    'text/javascript',
    'application/javascript',
    'application/ecmascript',
    'application/x-ecmascript',
    'application/x-javascript',
    'text/ecmascript Deprecated',
    'text/javascript1.0',
    'text/javascript1.1',
    'text/javascript1.2',
    'text/javascript1.3',
    'text/javascript1.4',
    'text/javascript1.5',
    'text/jscript',
    'text/livescript',
    'text/x-ecmascript',
    'text/x-javascript'
]

export const isJavascriptMimeType = (mime) =>
    mime === 'module' || VALID_SCRIPT_TYPES.includes(`${mime}`.split(';').shift().toLowerCase())
