import Swatch from '../swatch-group/swatch'
import {Box, HStack, SkeletonCircle} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import SwatchGroup from '../swatch-group'

export const ColorVariationSkeleton = () => {
    return (
        <HStack>
            <SkeletonCircle size={12} />
            <SkeletonCircle size={12} />
            <SkeletonCircle size={12} />
            <SkeletonCircle size={12} />
        </HStack>
    )
}

const ColorVariationSwatchGroup = ({
    currentProductId,
    loading,
    onChange,
    onHoverChange,
    label,
    value,
    displayName,
    colorVariationValues
}) => {
    return (
        <SwatchGroup
            loading={loading}
            onChange={onChange}
            value={value}
            label={label}
            displayName={displayName}
            spacing={2}
        >
            {!loading ? (
                colorVariationValues.map(({href, name, image, value, orderable, productId}) => (
                    <Swatch
                        key={value}
                        href={href}
                        disabled={!orderable}
                        value={value}
                        name={name}
                        className="variation"
                        onMouseEnter={() => {
                            onHoverChange?.({
                                currentId: currentProductId,
                                hoveredId: productId
                            })
                        }}
                        onMouseLeave={() => {
                            onHoverChange?.()
                        }}
                    >
                        {image ? (
                            <Box
                                height="100%"
                                width="100%"
                                minWidth="32px"
                                minHeight="32px"
                                backgroundRepeat="no-repeat"
                                backgroundSize="cover"
                                backgroundColor={name.toLowerCase()}
                                backgroundImage={
                                    image
                                        ? `url("${encodeURI(image.disBaseLink || image.link)}")`
                                        : ''
                                }
                                className="variation"
                            />
                        ) : (
                            name
                        )}
                    </Swatch>
                ))
            ) : (
                <ColorVariationSkeleton />
            )}
        </SwatchGroup>
    )
}

ColorVariationSwatchGroup.propTypes = {
    /**
     * The unique identifier of the selected product
     */
    currentProductId: PropTypes.string,
    /**
     * Loading state of the swatch group - renders skeleton instead of the displayName
     */
    loading: PropTypes.bool,
    /**
     * This function is called when a new option is selected
     */
    onChange: PropTypes.func,
    /**
     * This function is called when cursor is over or move out of a color value
     */
    onHoverChange: PropTypes.func,
    /**
     * The selected Swatch value
     */
    value: PropTypes.string,
    /**
     * The display value of the selected option
     */
    displayName: PropTypes.string,
    /**
     * The array of variation values part of the variation swatch group
     */
    colorVariationValues: PropTypes.array,
    /**
     * The attribute name of the swatch group (colour)
     */
    label: PropTypes.string
}

export default ColorVariationSwatchGroup
