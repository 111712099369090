import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import {Box, useMultiStyleConfig, omitThemingProps} from '@chakra-ui/react'

const Badge = (props) => {
    const styles = useMultiStyleConfig('BadgeGroup', props)
    const {text} = omitThemingProps(props)

    return (
        <Box data-testid="badge" {...styles.badge}>
            {text}
        </Box>
    )
}

Badge.displayName = 'Badge'

Badge.propTypes = {
    text: PropTypes.string.isRequired
}

export default Badge
