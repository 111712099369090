import React from 'react'
import PropTypes from 'prop-types'
import StyledContent from '../styled-content'
import {Stack, Skeleton, Text} from '@chakra-ui/react'
import {useIntl} from 'react-intl'
import {useOcapiContent} from '../../hooks/useOcapi'

const ContentAsset = ({id, ...props}) => {
    const {formatMessage} = useIntl()
    const {
        data,
        error,
        isLoading: loading
    } = useOcapiContent({
        parameters: {cid: id, html: true}
    })

    if (loading) {
        return (
            <Stack data-testid="content-asset-loading-skeleton">
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </Stack>
        )
    }

    if (error || !data?.c_body) {
        return (
            <Text data-testid="content-asset-error">
                {formatMessage({
                    id: 'global.error.something_went_wrong',
                    defaultMessage: 'Hmmm! Something went wrong. Please try again.'
                })}
            </Text>
        )
    }

    return <StyledContent content={data?.c_body || ''} {...props} />
}

ContentAsset.propTypes = {
    id: PropTypes.string
}

export default ContentAsset
