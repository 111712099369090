import React from 'react'
import PropTypes from 'prop-types'
import {useInteractionStudioOverriddenRecommendations} from '../../interaction-studio-api'

const InteractionStudioRecommendedProductsContext = React.createContext()

const InteractionStudioRecommendedProducts = ({children, id}) => {
    const overriddenProducts = useInteractionStudioOverriddenRecommendations(id)

    return (
        <InteractionStudioRecommendedProductsContext.Provider value={overriddenProducts}>
            {children}
        </InteractionStudioRecommendedProductsContext.Provider>
    )
}

InteractionStudioRecommendedProducts.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired
}

export const useInteractionStudioRecommendedProducts = () => {
    return React.useContext(InteractionStudioRecommendedProductsContext)
}

export default InteractionStudioRecommendedProducts
