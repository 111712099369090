import React from 'react'
import {FormattedMessage, defineMessages} from 'react-intl'
import {Box, Stack, Text} from '@chakra-ui/react'
import PropTypes from 'prop-types'

const GIFT_MESSAGE_VIEW_CONFIG = defineMessages({
    giftMessage: {
        defaultMessage: 'Your gift message text:',
        id: 'gift_msg.msg.label'
    },
    giftFrom: {
        defaultMessage: 'This gift is from:',
        id: 'gift_msg.input.from.label'
    },
    giftRecipientName: {
        defaultMessage: 'Recipient’s name:',
        id: 'gift_msg.recipient.name.label'
    },
    giftRecipientEmail: {
        defaultMessage: 'Recipient’s email:',
        id: 'gift_msg.recipient.email.label'
    }
})

const GiftMessageView = ({data}) => {
    const keys = Object.keys(GIFT_MESSAGE_VIEW_CONFIG)

    return (
        <Stack spacing={4}>
            {keys.map((key) => (
                <>
                    {data[key] && (
                        <Box key={key}>
                            <Text textStyle="Semi Bold Medium">
                                <FormattedMessage {...GIFT_MESSAGE_VIEW_CONFIG[key]} />
                            </Text>
                            <Text pb="2">{data[key]}</Text>
                        </Box>
                    )}
                </>
            ))}
        </Stack>
    )
}

GiftMessageView.propTypes = {
    data: PropTypes.object
}

export default GiftMessageView
