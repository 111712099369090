import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {defineMessage, FormattedMessage} from 'react-intl'
import {Text} from '@chakra-ui/react'

// these are synthetic statuses based on order properties:
//   status: created|new|failed|open|completed|cancelled|replaced
//   shippingStatus: not shipped|partially shipped|shipped
// but as of now we only deal with some meta states:
//   * default aka in progress
//   * cancelled if customer cancelled the order
//   * shipped if parcel has left the warehouse
const DISPLAY_STATUSES = {
    default: {
        color: 'stokkeCore.black',
        label: defineMessage({
            defaultMessage: 'In progress',
            id: 'order_status.in_progress'
        })
    },
    cancelled: {
        color: 'stokkeFunctional.alertRed',
        label: defineMessage({
            defaultMessage: 'Canceled',
            id: 'order_status.canceled'
        })
    },
    shipped: {
        color: 'stokkeFunctional.alertOrange', // TODO: verify the color
        label: defineMessage({
            defaultMessage: 'Shipped',
            id: 'order_status.shipped'
        })
    },
    delivered: {
        color: 'stokkeFunctional.alertGreen',
        label: defineMessage({
            defaultMessage: 'Delivered',
            id: 'order_status.delivered'
        })
    },
    returned: {
        color: 'stokkeCore.black',
        label: defineMessage({
            defaultMessage: 'Returned',
            id: 'order_status.returned'
        })
    },
    'partially-returned': {
        color: 'stokkeCore.black',
        label: defineMessage({
            defaultMessage: 'Partially returned',
            id: 'order_status.partially_returned'
        })
    }
}

const OrderStatus = ({order}) => {
    const orderStatus = useMemo(() => {
        const shippingStatus = order?.shippingStatus?.toLowerCase()
        const status = order?.status?.toLowerCase()
        // TODO: validate the logic
        if (shippingStatus === 'shipped') {
            return DISPLAY_STATUSES.shipped
        }
        return DISPLAY_STATUSES[status] || DISPLAY_STATUSES.default
    }, [order])

    return (
        <Text color={orderStatus.color} textStyle="Regular Small" noOfLines={1}>
            <FormattedMessage {...orderStatus.label} />
        </Text>
    )
}

OrderStatus.propTypes = {
    order: PropTypes.object
}

export default OrderStatus
