import {Stack} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'

const OrderSummaryList = ({children, ...props}) => {
    if (React.Children.count(children) === 0) {
        return null
    }

    return (
        <Stack as="ul" spacing={4} w="full" textStyle="Regular Medium" {...props}>
            {children}
        </Stack>
    )
}

OrderSummaryList.propTypes = {
    children: PropTypes.node
}

export default OrderSummaryList
