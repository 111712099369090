import React, {useEffect, useState, useRef} from 'react'
import FanreelOverlay from './fanreel-overlay'
import PropTypes from 'prop-types'
import Tiles from './fanreel-tiles'
import {useFanreelItems} from './hooks'
import {useDisclosure} from '@chakra-ui/react'
import useIntersectionObserver from '../../hooks/use-intersection-observer'
import {trackFanreelInViewport, trackFanreelSwipe} from '../../tracking/analytics'
import useEffectEvent from '../../hooks/use-effect-event'
import {ProductQuickviewModal, useProductQuickviewModal} from '../product-quickview-modal'

const ioOptions = {useOnce: true}

const Fanreel = ({product, category, headline, dataSourceId, variant, headlineHtmlProps}) => {
    const {items, loading} = useFanreelItems(product, category, dataSourceId)
    const overlayProps = useDisclosure()
    const [initialIndex, setInitialIndex] = useState(0)
    const containerRef = useRef(null)
    const isElementInViewPort = useIntersectionObserver(containerRef, ioOptions)
    const quickViewModalProps = useProductQuickviewModal()

    const onProductChange = useEffectEvent(() => {
        overlayProps.onClose()
        quickViewModalProps.onClose()
    })
    useEffect(() => {
        onProductChange(product)
    }, [product, onProductChange])

    useEffect(() => {
        if (isElementInViewPort) {
            trackFanreelInViewport()
        }
    }, [isElementInViewPort])

    if (!loading && !items?.length) {
        return null
    }

    return (
        <>
            <ProductQuickviewModal
                {...quickViewModalProps}
                onAfterAddToCart={() => {
                    overlayProps.onClose()
                    quickViewModalProps.onClose()
                }}
                showAddToCartModal={false}
                scrollToTop={false}
            />
            <Tiles
                items={items}
                loading={loading}
                product={product}
                headline={headline}
                variant={variant}
                headlineHtmlProps={headlineHtmlProps}
                onClick={(_, idx) => {
                    setInitialIndex(idx)
                    overlayProps.onOpen()
                }}
                onChange={() => {
                    trackFanreelSwipe()
                }}
                ref={containerRef}
            />
            {overlayProps.isOpen && items && (
                <FanreelOverlay
                    {...overlayProps}
                    initialIndex={initialIndex}
                    items={items}
                    productId={product?.id}
                    onQuickViewModalOpen={quickViewModalProps.onOpen}
                />
            )}
        </>
    )
}

Fanreel.propTypes = {
    product: PropTypes.object,
    category: PropTypes.string,
    headline: PropTypes.string,
    dataSourceId: PropTypes.string,
    variant: PropTypes.string,
    headlineHtmlProps: PropTypes.object
}

export default Fanreel
