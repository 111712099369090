import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {API_ERROR_MESSAGE} from '../../constants'
import {useToast} from '../../hooks/use-toast'
import LoadingSpinner from '../loading-spinner'
import ProductViewModal from '../product-view-modal'
import useNestedProduct from '../../hooks/use-nested-product'
import useEffectEvent from '../../hooks/use-effect-event'
import {useProduct} from '@salesforce/commerce-sdk-react'

export const ProductQuickviewModal = ({product, isLoading, isOpen, onClose, context, ...props}) => {
    const {NestedProduct} = useNestedProduct()

    if (isLoading) {
        return <LoadingSpinner />
    }

    if (!product) {
        return null
    }
    return (
        <NestedProduct>
            <ProductViewModal
                context={context}
                {...props}
                product={product}
                onClose={onClose}
                isOpen={isOpen}
            />
        </NestedProduct>
    )
}

ProductQuickviewModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    product: PropTypes.object,
    context: PropTypes.string,
    isLoading: PropTypes.bool
}

export const useProductQuickviewModal = () => {
    const {formatMessage} = useIntl()
    const location = useLocation()
    const toast = useToast()
    const [productId, setProductId] = useState(null)
    const [context, setContext] = useState(null)

    const {
        isInitialLoading: isLoading,
        error,
        data: product
    } = useProduct(
        {
            parameters: {
                id: productId,
                allImages: true
            }
        },
        {enabled: !!productId}
    )

    const onError = useEffectEvent((error) => {
        console.error(error)
        toast({
            title: formatMessage(API_ERROR_MESSAGE),
            status: 'error'
        })
        setProductId(null)
    })
    useEffect(() => {
        if (error) {
            onError(error)
        }
    }, [error, onError])

    useEffect(() => {
        setProductId((p) => (p ? null : p))
    }, [location.pathname])

    return {
        onOpen: (pid, context) => {
            setProductId(pid)
            setContext(context)
        },
        onClose: () => {
            setProductId(null)
        },
        isOpen: !!productId,
        isLoading,
        context,
        product
    }
}

export const ExposedProductQuickviewModal = () => {
    const modal = useProductQuickviewModal()

    const onShowQuickshop = useEffectEvent((productId, context) => modal.onOpen(productId, context))
    useEffect(() => {
        window.showQuickshop = onShowQuickshop
        return () => {
            delete window.showQuickshop
        }
    }, [onShowQuickshop])

    return <ProductQuickviewModal {...modal} />
}
