import {useCallback} from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {getInitPasswordErrorMessage} from '../utils/password-utils'

const useRegistrationErrorMessage = () => {
    const {formatMessage} = useIntl()
    return useCallback(
        async (error) => {
            const message = await getInitPasswordErrorMessage(error)
            const errorMsg = formatMessage(
                /Login Already In Use/i.test(message)
                    ? {
                          defaultMessage: 'This email is already registered.',
                          id: 'account-registration.msg.account_exists'
                      }
                    : {
                          defaultMessage: 'Hmmm! Something went wrong. Please try again.',
                          id: 'global.error.something_went_wrong'
                      }
            )

            return errorMsg
        },
        [formatMessage]
    )
}

useRegistrationErrorMessage.propTypes = {
    error: PropTypes.object
}

export default useRegistrationErrorMessage
