import textStyles from '../../foundations/textStyles'

export default {
    parts: ['container', 'starContainer', 'star', 'full', 'ratingValue', 'ratingCount'],
    baseStyle: {
        container: {
            align: 'center'
        },
        starContainer: {
            spacing: 4,
            alignItems: 'center'
        },
        star: {
            width: '0.5rem',
            height: '0.5rem'
        },
        full: {
            color: 'stokkeCore.black'
        },
        ratingCount: {display: 'none'},
        ratingValue: {display: 'none'}
    },
    variants: {
        productTile: {
            starContainer: {
                spacing: '0.1875rem'
            },
            ratingCount: {
                display: 'block',
                ...textStyles['Regular Extra Small'],
                marginLeft: 2
            },
            ratingValue: {display: 'none'}
        },
        cockpit: {
            starContainer: {
                spacing: '0.25rem'
            },
            ratingCount: {
                display: 'block',
                ...textStyles['Regular Small'],
                marginLeft: 2
            },
            ratingValue: {display: 'none'},
            container: {
                align: 'center',
                cursor: 'pointer'
            }
        },
        pdpSpec: {
            starContainer: {
                spacing: 1
            },
            star: {
                width: '1rem',
                height: '1rem'
            },
            ratingCount: {display: 'none'},
            ratingValue: {
                display: 'block',
                ...textStyles['Regular XLarge'],
                marginRight: 2
            }
        },
        reviewsModule: {
            starContainer: {
                spacing: 1
            },
            star: {
                width: '1rem',
                height: '1rem'
            },
            ratingCount: {
                display: 'block',
                color: 'stokkeGray.accessibleText',
                textStyle: 'Regular Small',
                marginLeft: 2.5
            },
            ratingValue: {
                display: 'block',
                textStyle: 'Regular 4XLarge',
                marginRight: 2.5
            }
        },
        review: {
            starContainer: {
                spacing: 1
            },
            star: {
                width: '1rem',
                height: '1rem'
            },
            ratingCount: {display: 'none'},
            ratingValue: {display: 'none'}
        }
    },
    defaultProps: {
        variant: 'baseStyle'
    }
}
