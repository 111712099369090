import {keyframes} from '@chakra-ui/react'

const colorsIndicatorKeyframes = keyframes`
    0% {opacity: 1;}
    70% {opacity: 0; max-width: 6.25rem; margin: 0 0.375rem;}
    100% {opacity: 0; max-width: 0rem; margin: 0 0.125rem;}`

export default {
    colorsIndicator: `${colorsIndicatorKeyframes} 2s linear 3s 1 normal forwards running`
}
