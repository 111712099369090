import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {HYBRID_CONTENT} from '../../constants'
import Checkbox from '../checkbox'
import HybridLink from '../hybrid-link'

const TermsAndConditions = ({testid, value, onChange}) => {
    onChange = onChange || ((newValue) => (value = newValue))

    const values = {
        privacy: (
            <HybridLink
                page={HYBRID_CONTENT}
                href="/customer-service/legal/privacy-policy.html"
                isExternal
            >
                <FormattedMessage defaultMessage="Privacy Policy" id="footer.link.privacy_policy" />
            </HybridLink>
        ),

        terms: (
            <HybridLink
                page={HYBRID_CONTENT}
                href="/customer-service/legal/terms/terms.html"
                isExternal
            >
                <FormattedMessage
                    defaultMessage="Terms and Conditions"
                    id="footer.link.terms_conditions"
                />
            </HybridLink>
        )
    }

    return (
        <Checkbox
            size="sm"
            name="tocOptIn"
            isChecked={value}
            data-testid={testid}
            onChange={(e) => {
                onChange(e.target.checked)
            }}
        >
            <FormattedMessage
                defaultMessage="I read and agree to the {privacy} and {terms}."
                id="global.tnc"
                values={values}
            />
        </Checkbox>
    )
}

TermsAndConditions.propTypes = {
    testid: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func
}

export default TermsAndConditions
