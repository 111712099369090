import React, {useCallback, useMemo} from 'react'
import PropTypes from 'prop-types'
import {Container, DrawerBody, Flex, useMultiStyleConfig} from '@chakra-ui/react'
import Lvl1Item from './lvl1-item'
import Lvl2Item from './lvl2-item'
import DrawerMenuError from './drawer-menu-error'
import ContentSlot from '../content-slot'
import TeaserItem from './teaser-item'

/**
 * Helper to use when filtering items that should not be displayed in the desktop drawer menu
 * @param {Object} item - the item to filter
 * @return {boolean} - true if the item should be displayed, false otherwise
 */
const filterItems = (item) => item.hide !== 'desktop'

/**
 * Renders desktop version of the drawer menu. First column always displays lvl 1 items from a selected lvl 0 item.
 * In case there is no lvl 0 item selected, the first lvl 0 item with children is considered as selected.
 */
const DrawerMenuDesktop = ({
    items,
    selectedLvl0,
    selectedLvl1,
    setSelectedLvl0,
    setSelectedLvl1
}) => {
    const styles = useMultiStyleConfig('DrawerMenu', {variant: 'desktop'})

    // compute which item's children to display in the first column
    // handles the case where menu is opened on mobile without anything selected and then switched to desktop
    // also handles the case where on mobile a lvl0 item was selected that is hidden on desktop
    const displayedLvl0Item = useMemo(() => {
        // if there is a selected lvl0 item and it should be displayed, use it
        if (selectedLvl0 && filterItems(selectedLvl0)) {
            return selectedLvl0
        }
        // if there are items, find the first lvl0 item that should be displayed and has children
        if (items?.length) {
            return items.find((item) => filterItems(item) && item.items?.length)
        }
    }, [selectedLvl0, items])

    // list of lvl1 items to display in the first column - those are the children of the selected lvl0 item that are not hidden on desktop
    const firstColumnItems = useMemo(
        () => displayedLvl0Item?.items?.filter(filterItems),
        [displayedLvl0Item]
    )

    // compute which lvl1 item to consider as selected for display in the second, third and forth columns
    // handles the initial case where menu is opened with only lvl0 item selected
    // handles also the case where on mobile a lvl1 item was selected that is hidden on desktop
    const displayedLvl1Item = useMemo(() => {
        // if there is a selected lvl1 item, and it should be displayed, use it
        if (selectedLvl1 && filterItems(selectedLvl1)) {
            return selectedLvl1
        }
        // if there are items in first column, find the first lvl1 item that has children
        if (firstColumnItems?.length) {
            return firstColumnItems.find((item) => item.items?.length)
        }
    }, [selectedLvl1, firstColumnItems])

    // group children of the displayed lvl1 item per column where they should be displayed
    const {secondColumnItems, thirdColumnItems, fourthColumnItems, teaserColumnItems} =
        useMemo(() => {
            const col2 = []
            const col3 = []
            const col4 = []
            const colTeaser = []
            displayedLvl1Item?.items?.filter(filterItems).forEach((item) => {
                switch (item.type) {
                    case 'NavigationItem':
                        col2.push(item)
                        break
                    case 'NavigationLink':
                        if (item.variant === 'teaser') {
                            colTeaser.push(item)
                        } else {
                            col3.push(item)
                        }
                        break
                    case 'ContentSlot':
                        col4.push(item)
                        break
                    default:
                        console.error(`Unexpected lvl2 item type "${item.type}"`, {
                            lvl2: item,
                            lvl1: displayedLvl1Item
                        })
                        break
                }
            })
            return {
                secondColumnItems: col2,
                thirdColumnItems: col3,
                fourthColumnItems: col4,
                teaserColumnItems: colTeaser
            }
        }, [displayedLvl1Item])

    // callback to select lvl1 item and select the matching lvl0 item in case it is not already selected
    const selectLvl1Item = useCallback(
        (item) => {
            setSelectedLvl0?.(displayedLvl0Item)
            setSelectedLvl1?.(item)
        },
        [setSelectedLvl0, setSelectedLvl1, displayedLvl0Item]
    )

    return (
        <DrawerBody>
            <Container variant="drawerMenu">
                {displayedLvl0Item?.variant === 'teaser' ? (
                    firstColumnItems?.length ? (
                        firstColumnItems.map((item, index) => <TeaserItem {...item} key={index} />)
                    ) : (
                        <DrawerMenuError
                            error="No teaser items to display"
                            details={displayedLvl0Item}
                        />
                    )
                ) : (
                    <>
                        <Flex {...styles.columns}>
                            <Flex {...styles.column1} className="navigation-container">
                                {firstColumnItems?.length ? (
                                    firstColumnItems.map((item, index) => (
                                        <Lvl1Item
                                            key={index}
                                            item={item}
                                            isActive={displayedLvl1Item === item}
                                            onClick={selectLvl1Item}
                                        />
                                    ))
                                ) : (
                                    <DrawerMenuError
                                        error="No items to display"
                                        details={displayedLvl0Item}
                                    />
                                )}
                            </Flex>
                            {displayedLvl1Item?.variant !== 'teaser' && (
                                <Flex flexDirection={{base: 'column', xl: 'row'}}>
                                    <Flex
                                        {...styles.column2}
                                        className="navigation-container"
                                        marginRight={4}
                                    >
                                        {secondColumnItems.map((item, index) => (
                                            <Lvl2Item
                                                key={index}
                                                item={item}
                                                filterItems={filterItems}
                                            />
                                        ))}
                                    </Flex>
                                    <Flex {...styles.column3}>
                                        {thirdColumnItems.map((item, index) => (
                                            <Lvl2Item
                                                key={index}
                                                item={item}
                                                filterItems={filterItems}
                                            />
                                        ))}
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>
                        {displayedLvl1Item?.variant !== 'teaser' ? (
                            <Flex {...styles.column4}>
                                {fourthColumnItems?.length
                                    ? fourthColumnItems.map((item, index) => (
                                          <ContentSlot
                                              key={index}
                                              slotId={item.id}
                                              showError={false}
                                              variant="simple-cms"
                                          />
                                      ))
                                    : null}
                            </Flex>
                        ) : (
                            <Flex {...styles.columnTeaser}>
                                {teaserColumnItems?.length ? (
                                    teaserColumnItems.map((item, index) => (
                                        <TeaserItem {...item} key={index} />
                                    ))
                                ) : (
                                    <DrawerMenuError
                                        error="No teaser items to display"
                                        details={displayedLvl1Item}
                                    />
                                )}
                            </Flex>
                        )}
                    </>
                )}
            </Container>
        </DrawerBody>
    )
}

DrawerMenuDesktop.propTypes = {
    items: PropTypes.array,
    selectedLvl0: PropTypes.object,
    selectedLvl1: PropTypes.object,
    setSelectedLvl0: PropTypes.func,
    setSelectedLvl1: PropTypes.func
}

export default DrawerMenuDesktop
