import React, {useCallback, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import useEffectEvent from '../hooks/use-effect-event'
import {useLocation} from 'react-router-dom'

const InteractionStudioContext = React.createContext(null)

const InteractionStudioBrowserApi = ({
    contentsListRef,
    enabled,
    recommendationsListRef,
    setStore
}) => {
    useEffect(() => {
        // noinspection JSUnusedGlobalSymbols
        window.browserApi = {
            contentsList: contentsListRef?.current || [],
            recommendationsList: recommendationsListRef?.current || [],
            reserveSection: (section) => {
                console.debug('IS: reserveSection', section)
                if (enabled) {
                    setStore?.((prev) => ({...prev, [`reserved_${section}`]: true}))
                } else {
                    console.info('IS Api is disabled, skipping reserveSection')
                }
            },
            showRecommendations: (section, recos) => {
                console.debug('IS: showRecommendations', section, recos)
                if (enabled) {
                    if (!recommendationsListRef?.current?.includes(section)) {
                        console.warn(
                            'IS: showRecommendations called with unknown section',
                            section,
                            'Available sections:',
                            recommendationsListRef?.current || 'none'
                        )
                    }
                    if (!recos || !recos.length) {
                        console.warn(
                            'IS: showRecommendations called with empty recos',
                            section,
                            recos
                        )
                    } else if (!recos?.every?.((r) => typeof r === 'string')) {
                        console.warn(
                            'IS: showRecommendations called with invalid recos. Must be array of strings',
                            section,
                            recos
                        )
                    } else {
                        setStore?.((prev) => ({...prev, [`recommendations_${section}`]: recos}))
                    }
                } else {
                    console.info('IS Api is disabled, skipping showRecommendations')
                }
            },
            showContent: (section, content) => {
                console.debug('IS: showContent', section, content)
                if (enabled) {
                    if (!contentsListRef?.current?.includes(section)) {
                        console.warn(
                            'IS: showContent called with unknown section',
                            section,
                            'Available sections:',
                            contentsListRef?.current || 'none'
                        )
                    }
                    if (typeof content !== 'string') {
                        console.warn(
                            'IS: showContent called with invalid content. Must be string',
                            section,
                            content
                        )
                    } else {
                        setStore?.((prev) => ({...prev, [`content_${section}`]: content}))
                    }
                } else {
                    console.info('IS Api is disabled, skipping showContent')
                }
            }
        }
    }, [contentsListRef, enabled, recommendationsListRef, setStore])

    return null
}
InteractionStudioBrowserApi.propTypes = {
    contentsListRef: PropTypes.object,
    enabled: PropTypes.bool,
    recommendationsListRef: PropTypes.object,
    setStore: PropTypes.func
}

export const InteractionStudioProvider = ({children, enabled, overridesEnabled, scriptUrl}) => {
    const [load, setLoad] = useState(false)
    // expose a function to load the script, instead of setter as there's no turning back once it's loaded
    const doLoad = useCallback(() => setLoad(true), [])

    // store for IS data
    const [store, setStore] = useState({})
    // clear the store on navigation
    const {pathname} = useLocation()
    useEffect(() => {
        setStore({})
    }, [pathname])

    // load the scrip when the load state is true and enabled
    const onLoad = useEffectEvent(() => {
        // directly modify the dom as opposed to using helmet:
        // 1. helmet requires to return component
        // 2. this way the script is not rendered by ssr
        const script = document.createElement('script')
        script.src = scriptUrl
        script.async = true
        document.body.appendChild(script)
    })
    useEffect(() => {
        if (enabled && load) {
            onLoad()
        }
    }, [enabled, load, onLoad])

    // a list of available content ids to be used by IS
    const contentsListRef = React.useRef([])
    const registerContent = useCallback((id) => {
        if (id) {
            contentsListRef.current?.push?.(id)
        }
    }, [])
    const unregisterContent = useCallback((id) => {
        if (id) {
            const idx = contentsListRef.current?.indexOf?.(id)
            if (idx >= 0) {
                contentsListRef.current?.splice?.(idx, 1)
            }
        }
    }, [])

    // a list of available recommendation slots to be used by IS
    const recommendationsListRef = React.useRef([])
    const registerRecommendation = useCallback((id) => {
        if (id) {
            recommendationsListRef.current?.push?.(id)
        }
    }, [])
    const unregisterRecommendation = useCallback((id) => {
        if (id) {
            const idx = recommendationsListRef.current?.indexOf?.(id)
            if (idx >= 0) {
                recommendationsListRef.current?.splice?.(idx, 1)
            }
        }
    }, [])

    // exposed context
    const context = useMemo(
        () => ({
            store: overridesEnabled ? store : {},
            onLoad: doLoad,
            registerContent,
            registerRecommendation,
            unregisterContent,
            unregisterRecommendation
        }),
        [
            doLoad,
            overridesEnabled,
            registerContent,
            registerRecommendation,
            store,
            unregisterContent,
            unregisterRecommendation
        ]
    )

    return (
        <InteractionStudioContext.Provider value={context}>
            {enabled && (
                <InteractionStudioBrowserApi
                    contentsListRef={contentsListRef}
                    enabled={overridesEnabled}
                    recommendationsListRef={recommendationsListRef}
                    setStore={setStore}
                />
            )}
            {children}
        </InteractionStudioContext.Provider>
    )
}

InteractionStudioProvider.propTypes = {
    children: PropTypes.node.isRequired,
    enabled: PropTypes.bool,
    overridesEnabled: PropTypes.bool,
    scriptUrl: PropTypes.string
}

export const useInteractionStudioLoader = () => {
    const context = React.useContext(InteractionStudioContext)
    if (!context) {
        throw new Error(
            'useInteractionStudioLoader must be used within an InteractionStudioProvider'
        )
    }
    return context.onLoad
}

export const useInteractionStudioOverriddenContent = (id) => {
    const context = React.useContext(InteractionStudioContext)
    if (!context) {
        throw new Error(
            'useInteractionStudioOverriddenContent must be used within an InteractionStudioProvider'
        )
    }

    // register the content to ease integration from IS side
    const {registerContent, unregisterContent} = context
    useEffect(() => {
        if (id) {
            registerContent(id)
            return () => {
                unregisterContent(id)
            }
        }
    }, [id, registerContent, unregisterContent])

    return context.store[`content_${id}`]
}

export const useInteractionStudioOverriddenRecommendations = (id) => {
    const context = React.useContext(InteractionStudioContext)
    if (!context) {
        throw new Error(
            'useInteractionStudioOverriddenRecommendations must be used within an InteractionStudioProvider'
        )
    }

    // register the recommendations slot to ease integration from IS side
    const {registerRecommendation, unregisterRecommendation} = context
    useEffect(() => {
        if (id) {
            registerRecommendation(id)
            return () => {
                unregisterRecommendation(id)
            }
        }
    }, [id, registerRecommendation, unregisterRecommendation])

    return context.store[`recommendations_${id}`]
}
