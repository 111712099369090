import {
    ShopperCustomersMutations,
    useCustomerProductLists,
    useShopperCustomersMutation
} from '@salesforce/commerce-sdk-react'
import {useMutation} from '@tanstack/react-query'
import {useCurrentCustomer} from './use-current-customer'

const LIST_NAME = 'notifyWhenInStock'
const LIST_TYPE = 'custom_1'

const useProductNotification = () => {
    const {data: customer} = useCurrentCustomer()

    return useCustomerProductLists({
        parameters: {customerId: customer.customerId}
    })
}

export default useProductNotification

export const useCreateProductNotificationMutation = () => {
    const {data: customer} = useCurrentCustomer()

    const {data: lists} = useProductNotification()

    const {mutateAsync: createList} = useShopperCustomersMutation(
        ShopperCustomersMutations.CreateCustomerProductList
    )
    const createCustomerProductList = () =>
        createList({
            body: {name: LIST_NAME, type: LIST_TYPE},
            parameters: {customerId: customer.customerId}
        })

    const {mutateAsync: createCustomerProductListItem} = useShopperCustomersMutation(
        ShopperCustomersMutations.CreateCustomerProductListItem
    )

    return useMutation({
        mutationFn: async ({product}) => {
            let list = lists?.data?.find((list) => list.name === LIST_NAME)

            const createListItem = async (list) => {
                await createCustomerProductListItem({
                    parameters: {
                        listId: list.id,
                        customerId: customer.customerId
                    },
                    body: {
                        quantity: 1,
                        productId: product.id,
                        public: false,
                        priority: 1,
                        type: 'product'
                    }
                })
            }

            if (!list) {
                list = await createCustomerProductList()
                createListItem(list)
            } else {
                const isItemInList = list?.customerProductListItems?.find(
                    (i) => i.productId === product?.id
                )
                if (!isItemInList) {
                    createListItem(list)
                }
            }
        }
    })
}
