import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
    useCallback
} from 'react'
import PropTypes from 'prop-types'
import {ArrowLeftRoundIcon, ArrowRightRoundIcon, LineIcon} from '../icons'
import {FormattedMessage, useIntl} from 'react-intl'

/* Components */
import {
    Stack,
    HStack,
    Box,
    Progress,
    AspectRatio,
    Skeleton,
    useMultiStyleConfig,
    useBreakpointValue,
    omitThemingProps,
    IconButton
} from '@chakra-ui/react'
import useEffectEvent from '../../hooks/use-effect-event'
import {isHydrated} from '../../utils/utils'

/* Currently [itemsPerSlide] prop is the indicator to choose between two variants (Emo. gallery / Recommendations) for the Slider */
const Slider = forwardRef((props, ref) => {
    const {
        children,
        containerHtmlProps,
        slideLeftIcon = <ArrowLeftRoundIcon boxSize={8} />,
        slideRightIcon = <ArrowRightRoundIcon boxSize={8} />,
        itemsPerSlide = 1,
        loading = false,
        initialIndex = 0,
        hideArrows,
        hidePagination,
        onChange,
        ariaLabel
    } = omitThemingProps(props)
    const {formatMessage} = useIntl()
    const styles = useMultiStyleConfig('Slider', props)
    const spacingOffset = useBreakpointValue({base: '8px', lg: '12px'}, {ssr: !isHydrated()})
    const childrenCount = React.Children.count(children)
    const halfItem = itemsPerSlide % 1 === 0.5
    /* Touch event slide/swipe starting point used to calculate left or right direction */
    const touchStart = useRef(0)
    /* Current selected item index */
    const [selectedIndex, _setSelectedIndex] = useState(initialIndex)
    const setSelectedIndex = useCallback(
        (index, notifyChange) => {
            _setSelectedIndex(index)
            if (notifyChange) {
                onChange?.(index)
            }
        },
        [onChange]
    )
    /* Indicator that sets to true when slider starts scrolling to prevent user clicks while slider still scrolling to next/prev item */
    const [stillScrolling, setStillScrolling] = useState(false)
    const scrollRef = useRef()

    const onChildrenOrItemsPerSlideChange = useEffectEvent((childrenCount, itemsPerSlide) => {
        setSelectedIndex(
            itemsPerSlide > 1 ? Math.min(childrenCount, Math.trunc(itemsPerSlide)) : initialIndex
        )
        resetScrolling()
    })
    useEffect(() => {
        onChildrenOrItemsPerSlideChange(childrenCount, itemsPerSlide)
    }, [childrenCount, itemsPerSlide, onChildrenOrItemsPerSlideChange])

    /* This auto scrolling will work only for single element per slide variant.
    If we want to use it for multiple elements per slide variant (e.g. recommendations) - it should be extended */
    const onInitialIndexChange = useEffectEvent((initialIndex) => {
        if (initialIndex && itemsPerSlide === 1 && scrollRef.current) {
            setSelectedIndex(initialIndex)
            scrollRef.current.scrollLeft = scrollRef.current.children[initialIndex]?.offsetLeft || 0
        }
    })
    useEffect(() => {
        onInitialIndexChange(initialIndex)
    }, [initialIndex, onInitialIndexChange])

    // Scroll the container left or right by 1 item. Passing no args or `1`
    // scrolls to the right, and passing `-1` scrolls left.
    const scroll = useCallback(
        (direction = 1, touch) => {
            if (scrollRef.current) {
                /* If scrolling to next/prev item is not finished yet or - return */
                if (stillScrolling) return

                /* Calculate slide direction (left/right) from touchevent in mobileview */
                const {touchStart, touchEnd} = touch || {}

                if (touchStart) {
                    direction = touchEnd < touchStart ? 1 : -1
                }

                const nextStep = selectedIndex + direction

                /* Disabling infinite scrolling when items > 1 */
                const cannotScroll =
                    itemsPerSlide > 1 &&
                    (nextStep > childrenCount || nextStep < Math.trunc(itemsPerSlide))

                if (cannotScroll) return

                /* In mobileview prevent the user from scrolling just by clicking the item */
                if ((touchStart && Math.abs(touchStart - touchEnd) > 50) || !touchStart) {
                    // next selected index with wrap around
                    const nextSelectedIndex =
                        Math.trunc(itemsPerSlide) +
                        ((nextStep + childrenCount - Math.trunc(itemsPerSlide)) % childrenCount)
                    // next scroll position from the next selected child
                    const nextScrollPosition =
                        scrollRef.current.children[
                            itemsPerSlide > 1
                                ? nextSelectedIndex - Math.trunc(itemsPerSlide)
                                : nextSelectedIndex
                        ]?.offsetLeft || 0

                    if (itemsPerSlide === 1 && nextSelectedIndex === childrenCount) {
                        // go to first slide
                        setSelectedIndex(0, true)
                    } else {
                        setSelectedIndex(nextSelectedIndex, true)
                    }

                    if (scrollRef.current.scrollTo instanceof Function) {
                        scrollRef.current.scrollTo({
                            top: 0,
                            left: nextScrollPosition,
                            behavior: 'smooth'
                        })

                        /* Prevent the user from clicking slider arrows while scrolling animation is not done yet */
                        setStillScrolling(true)
                        const timer = setTimeout(() => {
                            setStillScrolling(false)
                        }, 500)
                        return () => clearTimeout(timer)
                    } else {
                        // tests and older browser that lack scrollTo
                        scrollRef.current.scrollLeft = nextScrollPosition
                    }
                }
            }
        },
        [childrenCount, itemsPerSlide, selectedIndex, setSelectedIndex, stillScrolling]
    )

    const resetScrolling = () => {
        scrollRef?.current?.scrollBy?.({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    const scrollPrev = useCallback(() => scroll(-1), [scroll])
    const scrollNext = useCallback(() => scroll(1), [scroll])

    useImperativeHandle(
        ref,
        () => ({
            scrollPrev,
            scrollNext,
            stillScrolling,
            selectedIndex
        }),
        [scrollPrev, scrollNext, stillScrolling, selectedIndex]
    )

    if (childrenCount === 0 && !loading) {
        return null
    }

    const getSkeletons = () => {
        return (
            <>
                {[...Array(halfItem ? Math.ceil(itemsPerSlide) : itemsPerSlide)].map((_, i) => (
                    <Box
                        key={i}
                        {...styles.slide}
                        width={
                            halfItem
                                ? `calc(${80 / Math.trunc(itemsPerSlide)}% - ${spacingOffset})`
                                : itemsPerSlide > 1
                                ? `calc(${100 / itemsPerSlide}% - ${spacingOffset})`
                                : '100%'
                        }
                    >
                        <Stack>
                            <AspectRatio ratio={1}>
                                <Skeleton />
                            </AspectRatio>
                            <Stack spacing={2}>
                                <Skeleton width="150px" height={5} />
                                <Skeleton width="75px" height={5} />
                            </Stack>
                        </Stack>
                    </Box>
                ))}
            </>
        )
    }

    const getItems = () =>
        React.Children.map(children, (child, index) => (
            <Box
                data-testid="slider-child"
                key={index}
                {...styles.slide}
                width={
                    halfItem
                        ? `calc(${80 / Math.trunc(itemsPerSlide)}% - ${spacingOffset})`
                        : itemsPerSlide >= 2
                        ? `calc(${100 / itemsPerSlide}% - ${spacingOffset})`
                        : '100%'
                }
            >
                {child}
            </Box>
        ))

    return (
        <Box {...styles.container} {...containerHtmlProps}>
            <Box {...styles.slider}>
                <Stack
                    data-testid="slider"
                    ref={scrollRef}
                    {...styles.itemsContainer}
                    direction="row"
                    spacing={4}
                    wrap="nowrap"
                    overflowX="scroll"
                    sx={{
                        scrollSnapType: 'x mandatory',
                        WebkitOverflowScrolling: 'touch' // Safari touch scrolling needed for scroll snap
                    }}
                    css={{
                        ...styles.scrollBar
                    }}
                    onTouchStart={(e) => {
                        touchStart.current =
                            !e.target?.className?.includes?.('variation') &&
                            e.changedTouches[0].clientX
                    }}
                    onTouchEnd={(e) => {
                        if (touchStart.current !== false) {
                            scroll(1, {
                                touchStart: touchStart.current,
                                touchEnd: e.changedTouches[0].clientX
                            })
                            touchStart.current = null
                        }
                    }}
                >
                    {/* Slider items */}
                    {!loading ? getItems() : getSkeletons()}
                </Stack>

                {childrenCount > 0 && (
                    <>
                        {/* Slider left & right buttons */}
                        {!hideArrows && itemsPerSlide < childrenCount && (
                            <>
                                <IconButton
                                    data-testid="slide-left-button"
                                    {...styles.sliderButton}
                                    {...styles.leftSliderButton}
                                    aria-label={formatMessage({
                                        id: 'global.gallery.scroll_prev',
                                        defaultMessage: 'Scroll previous'
                                    })}
                                    icon={slideLeftIcon}
                                    variant="flush"
                                    onClick={scrollPrev}
                                />

                                <IconButton
                                    data-testid="slide-right-button"
                                    {...styles.sliderButton}
                                    {...styles.rightSliderButton}
                                    aria-label={formatMessage({
                                        id: 'global.gallery.scroll_next',
                                        defaultMessage: 'Scroll next'
                                    })}
                                    icon={slideRightIcon}
                                    variant="flush"
                                    onClick={scrollNext}
                                />
                            </>
                        )}
                    </>
                )}
            </Box>

            {childrenCount > 0 && (
                <>
                    {/* Pagination */}
                    {!hidePagination ? (
                        itemsPerSlide > 1 ? (
                            <>
                                <Progress
                                    value={(selectedIndex / childrenCount) * 100}
                                    aria-label={ariaLabel ? ariaLabel : ''}
                                    {...styles.progressBar}
                                />

                                {!halfItem && (
                                    <HStack data-testid="slider-pagination">
                                        {itemsPerSlide < childrenCount && (
                                            <IconButton
                                                data-testid="slide-left-button"
                                                {...styles.paginationButton}
                                                {...styles.leftPaginationButton}
                                                aria-label={formatMessage({
                                                    id: 'global.gallery.scroll_prev',
                                                    defaultMessage: 'Scroll previous'
                                                })}
                                                icon={slideLeftIcon}
                                                variant="flush"
                                                onClick={scrollPrev}
                                            />
                                        )}

                                        <Box {...styles.stepper}>
                                            <FormattedMessage
                                                defaultMessage="{current} of {total}"
                                                id="pagination_legend.showing"
                                                values={{
                                                    current: selectedIndex,
                                                    total: childrenCount
                                                }}
                                            />
                                        </Box>

                                        {itemsPerSlide < childrenCount && (
                                            <IconButton
                                                data-testid="slide-right-button"
                                                {...styles.paginationButton}
                                                {...styles.rightPaginationButton}
                                                aria-label={formatMessage({
                                                    id: 'global.gallery.scroll_next',
                                                    defaultMessage: 'Scroll next'
                                                })}
                                                icon={slideRightIcon}
                                                variant="flush"
                                                onClick={scrollNext}
                                            />
                                        )}
                                    </HStack>
                                )}
                            </>
                        ) : (
                            <HStack data-testid="slider-pagination" {...styles.pagination}>
                                {React.Children.map(children, (_, index) => {
                                    return (
                                        <Stack
                                            key={index}
                                            spacing={1}
                                            cursor="pointer"
                                            onClick={() => scroll(index - selectedIndex)}
                                            /* Maximum amount of 6 lines for pagination (mobile.) */
                                            display={
                                                index > 5 ? {base: 'none', sm: 'flex'} : 'flex'
                                            }
                                            role="button"
                                        >
                                            <LineIcon
                                                height="1px"
                                                visibility={selectedIndex !== index && 'hidden'}
                                            />
                                            <LineIcon height="1px" />
                                        </Stack>
                                    )
                                })}
                            </HStack>
                        )
                    ) : null}
                </>
            )}
        </Box>
    )
})

if (process.env.NODE_ENV === 'development') {
    Slider.displayName = 'Slider'
}

Slider.propTypes = {
    containerHtmlProps: PropTypes.object,
    itemsPerSlide: PropTypes.number,
    /**
     * Icon to use for left item navigation
     */
    navLeftIcon: PropTypes.any,
    /**
     * Icon to use for right item navigation
     */
    navRightIcon: PropTypes.any,
    /**
     * The slider variant
     */
    variant: PropTypes.string,
    /**
     * Handler for click on item
     */
    onClick: PropTypes.func
}

export default Slider
