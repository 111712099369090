import {ShopperProducts} from 'commerce-sdk-isomorphic'

class CustomShopperProducts extends ShopperProducts {
    constructor(config = {}) {
        super(config)
    }

    async getProduct(...args) {
        if (!args[0].parameters.expand) {
            args[0].parameters.expand =
                'availability,promotions,images,prices,variations,recommendations,set_products'
        }
        return await super.getProduct(...args)
    }

    async getProducts(...args) {
        if (!args[0].parameters.expand) {
            args[0].parameters.expand =
                'availability,promotions,images,prices,variations,recommendations'
        }
        return await super.getProducts(...args)
    }
}

export default CustomShopperProducts
