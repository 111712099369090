import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        mainWrapper: {
            height: '100%'
        },
        aboveFoldContentWrapper: {
            display: 'flex',
            flexDir: 'column',
            alignItems: 'center',
            gap: '2.5rem',
            padding: '2.5rem 1rem',
            textAlign: 'center'
        },
        textsWrapper: {
            display: 'flex',
            flexDir: 'column',
            alignItems: 'center',
            gap: '1rem'
        },
        headline: {
            ...textStyles['Semi Bold Small']
        },
        subheadline: {
            ...textStyles['Regular 2XLarge']
        },
        paragraph: {
            paddingTop: '0.5rem',
            ...textStyles['Regular Medium']
        },
        img: {
            didplay: 'block',
            maxWidth: '100%',
            height: 'auto'
        },
        btn: {
            width: '100%',
            maxWidth: '25rem'
        },
        searchWrapper: {
            padding: {base: '3rem 1rem', md: '3rem 2rem'},
            backgroundColor: 'stokkeGray.mid'
        },
        searchIcon: {
            marginLeft: '0.5rem'
        },
        inputGroup: {
            width: '100%',
            maxWidth: {xl: '50rem'},
            margin: '0 auto'
        },
        searchInput: {
            paddingLeft: '2.75rem'
        }
    }
}
