import {useIntl} from 'react-intl'
import {
    VALIDATE_EMAIL_PATTERN,
    GENERAL_FORM_VALIDATION_MESSAGES as generalMessages
} from '../../constants'

export default function useNotifyFields({
    form: {
        control,
        formState: {errors}
    },
    prefix = ''
}) {
    const {formatMessage} = useIntl()
    const invalidEmailMessage = formatMessage({
        defaultMessage: 'Please enter a valid email address.',
        id: 'use_profile_fields.error.required_email'
    })
    const labelFirstName = formatMessage({
        defaultMessage: 'First Name*',
        id: 'notify.modal.first_name'
    })

    const fields = {
        firstName: {
            name: `${prefix}firstName`,
            label: labelFirstName,
            placeholder: formatMessage({
                defaultMessage: 'First Name*',
                id: 'notify.modal.first_name'
            }),
            type: 'text',
            defaultValue: '',
            rules: {
                required: formatMessage({
                    defaultMessage: 'Please enter your first name.',
                    id: 'use_profile_fields.error.required_first_name'
                }),
                maxLength: {
                    message: formatMessage(generalMessages.maxLength, {
                        label: labelFirstName,
                        n: 40
                    }),
                    value: 40
                }
            },
            error: errors[`${prefix}firstName`],
            control
        },
        lastName: {
            name: `${prefix}lastName`,
            label: formatMessage({
                defaultMessage: 'Last Name*',
                id: 'notify.modal.last_name'
            }),
            placeholder: formatMessage({
                defaultMessage: 'Last Name*',
                id: 'notify.modal.last_name'
            }),
            type: 'text',
            defaultValue: '',
            rules: {
                required: formatMessage({
                    defaultMessage: 'Please enter your last name.',
                    id: 'use_profile_fields.error.required_last_name'
                })
            },
            error: errors[`${prefix}lastName`],
            control
        },
        email: {
            name: `${prefix}email`,
            label: formatMessage({
                defaultMessage: 'Email address*',
                id: 'notify.modal.email.address'
            }),
            placeholder: formatMessage({
                defaultMessage: 'Email address*',
                id: 'notify.modal.email.address'
            }),
            type: 'email',
            defaultValue: '',
            rules: {
                required: invalidEmailMessage,
                pattern: {
                    value: VALIDATE_EMAIL_PATTERN,
                    message: invalidEmailMessage
                }
            },
            error: errors[`${prefix}email`],
            control
        },
        phone: {
            name: `${prefix}phone`,
            label: formatMessage({
                defaultMessage: 'Phone',
                id: 'use_profile_fields.label.phone.number'
            }),
            placeholder: formatMessage({
                defaultMessage: 'Optional',
                id: 'notify.modal.optional'
            }),
            defaultValue: '',
            type: 'text',
            rules: {
                pattern: {
                    value: /^[0-9\s+\-()]+$/,
                    message: formatMessage({
                        defaultMessage: 'Please enter a valid phone number.',
                        id: 'use_profile_fields.error.please_enter_valid_phone_number'
                    })
                }
            },
            error: errors[`${prefix}phone`],
            control
        }
    }

    return fields
}
