import React, {useMemo} from 'react'
import {VStack} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import {SpecialOffer} from './index'
import {useCurrentBasket} from '../../hooks/use-current-basket'
import {usePromotions} from '@salesforce/commerce-sdk-react/hooks/ShopperPromotions/query'

/**
 * Extracts promotion identifiers associated with the passed product.
 * @param {array} bonusDiscountLineItems is the basket with all promotions
 * @param {object} bonusPromotion is the bonus promotion that should be rendered separately
 * @param {object} product is the product where the promotions should be applied
 * @param {string} variant is the variant of the product
 * @return array of promotion identifiers
 */
const getPromotionIds = function (bonusDiscountLineItems, bonusPromotion, product, variant) {
    if (!bonusPromotion) {
        return product?.productPromotions
            ?.map(({promotionId}) => promotionId)
            ?.filter((promotionId) => {
                if (variant === 'bonus') {
                    // we should restrict filtering by bonus promotions
                    if (!bonusDiscountLineItems) {
                        return false
                    }
                    return (
                        bonusDiscountLineItems.filter((bonus) => bonus.promotionId === promotionId)
                            .length !== 0
                    )
                }

                return true
            })
    }
    return [bonusPromotion.promotionId]
}

export const useProductSpecialOffers = (product, bonusPromotion, variant) => {
    const {data: basket} = useCurrentBasket()

    const promotionIds = useMemo(() => {
        const ids = getPromotionIds(
            basket?.bonusDiscountLineItems,
            bonusPromotion,
            product,
            variant
        )
        return ids
    }, [product, bonusPromotion, basket?.bonusDiscountLineItems, variant])

    const {data, isInitialLoading: loading} = usePromotions(
        {
            parameters: {
                ids: promotionIds?.join(',')
            }
        },
        {
            enabled: promotionIds?.length > 0
        }
    )

    const promotions = useMemo(() => {
        if (!promotionIds) {
            return []
        }
        const promotionResults = (data?.data || []).filter(({id}) => promotionIds.includes(id))
        if (variant === 'bonus') {
            if (bonusPromotion && promotionResults?.length) {
                const bonusLineItemId = bonusPromotion.id
                promotionResults[0].productItem = basket?.productItems.filter(
                    (item) => item?.bonusDiscountLineItemId === bonusLineItemId
                )
            }
            return promotionResults.filter(({c_discountProducts}) => c_discountProducts)
        } else {
            return promotionResults
        }
    }, [bonusPromotion, data, promotionIds, variant, basket?.productItems])

    return {product, variant, promotions, loading}
}

const ProductSpecialOffers = ({product, variant, promotions, ...props}) => {
    if (!promotions?.length) {
        return null
    }

    return (
        <VStack spacing="10px">
            {promotions.map((promotion) => (
                <SpecialOffer
                    key={promotion.id}
                    promotion={promotion}
                    product={product}
                    variant={variant || 'default'}
                    {...props}
                />
            ))}
        </VStack>
    )
}

ProductSpecialOffers.propTypes = {
    product: PropTypes.object,
    variant: PropTypes.oneOf(['default', 'bonus']),
    promotions: PropTypes.array
}
export default ProductSpecialOffers
