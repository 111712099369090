/* istanbul ignore file */

import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Code, Stack} from '@chakra-ui/react'
import React, {memo, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../loading-spinner'
import useCmsLazyImgLoad from './hooks/use-cms-lazy-img-load'
import useCmsScripts from './hooks/use-cms-scripts'
import {useWidgets} from '../../widgets'
import {Helmet} from 'react-helmet'
import tns from 'tiny-slider/dist/tiny-slider.css'
import useCmsContent, {TYPE_CONTENT_ASSET, TYPE_CONTENT_SLOT} from './hooks/use-cms-content'

const CMSContent = ({
    id,
    type = TYPE_CONTENT_ASSET,
    content: preloadedContent,
    nonce,
    showError = true,
    ...htmlProps
}) => {
    const [root, setRoot] = useState(null)
    const [scriptError, setScriptError] = useState(null)

    const {
        data: fetchedContent,
        isInitialLoading: loading,
        error: fetchError
    } = useCmsContent(id, type, {enabled: !preloadedContent})

    const content = preloadedContent || fetchedContent
    const html = !loading && !fetchError && content?.c_body

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') {
            if (fetchError) {
                console.error(`Error loading cms "${id}"`, fetchError)
            }
            if (scriptError) {
                console.error(`Error executing cms scripts for "${id}"`, scriptError)
            }
        }
    }, [fetchError, scriptError, id])

    // need to manually execute the embedded script tags
    useCmsScripts({
        html,
        root,
        onError: setScriptError,
        displayName: id,
        nonce
    })

    useCmsLazyImgLoad(root, html)

    const widgets = useWidgets(root, html)

    // loading state
    if (loading) {
        return (
            <Stack>
                <LoadingSpinner wrapperStyles={{position: 'relative', height: '4em'}} />
            </Stack>
        )
    }

    // error state
    if (fetchError || scriptError) {
        if (showError) {
            return (
                <Alert {...htmlProps} status="error">
                    <AlertIcon />
                    <Box>
                        <AlertTitle>
                            Something bad happened while loading <Code>{id}</Code>!
                        </AlertTitle>
                        <AlertDescription>
                            {(fetchError || scriptError).toString()}
                        </AlertDescription>
                    </Box>
                </Alert>
            )
        } else {
            return null
        }
    }

    const tnsStyle = typeof tns === 'string' ? tns : ''

    // render the module's html
    return (
        <>
            <Helmet>
                <style>{tnsStyle}</style>
            </Helmet>
            <Box
                className="cms-page-module"
                {...htmlProps}
                ref={setRoot}
                dangerouslySetInnerHTML={{__html: html}}
            />
            {widgets}
        </>
    )
}

// prevent unnecessary re-renders that will override the modified DOM from inline scripts
const MemoizedCMSContent = memo(CMSContent, (prevProps, nextProps) => {
    if (prevProps.id !== nextProps.id) {
        return false
    }
    // we only care about the c_body property that contains the raw html
    if (prevProps.content?.c_body !== nextProps.content?.c_body) {
        return false
    }
    // the nonce and showError are not expected to change during the lifetime of a component so no need to check them
    return true
})

if (process.env.NODE_ENV !== 'production') {
    MemoizedCMSContent.displayName = 'MemoizedCMSContent'
}

MemoizedCMSContent.propTypes = CMSContent.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([TYPE_CONTENT_ASSET, TYPE_CONTENT_SLOT]),
    content: PropTypes.object,
    nonce: PropTypes.string,
    showError: PropTypes.bool
}

export default MemoizedCMSContent
