export default {
    baseStyle: (props) => ({
        borderColor:
            props.colorScheme === 'stokkeCore' ? 'stokkeCore.black' : `${props.colorScheme}.mid`,
        opacity: null
    }),
    defaultProps: {
        colorScheme: 'stokkeCore'
    }
}
