import {useCallback, useEffect, useMemo, useState} from 'react'
import useCustomRecommendations from './use-custom-recommendations'
import {useInteractionStudioRecommendedProducts} from '../components/interaction-studio/recommended-products'
import {useQuery} from '@tanstack/react-query'
import useEinstein from './use-einstein'
import {useProducts} from '@salesforce/commerce-sdk-react'

/**
 * Hook to get recommendations from Einstein or Custom recommender
 * @param {string} [recommender]
 * @param {string} [zone]
 * @param {number} [maxRecommendations] - Maximum number of recommendations to fetch
 * @param {object} [productParameters] - Parameters to pass to the product API
 * @return {{
 *   isAvailable: boolean,
 *   isLoading: boolean,
 *   recommendations: Object,
 *   getRecommendations: Function,
 * }}
 */
const useRecommendations = (
    {recommender, zone},
    {maxRecommendations, productParameters = {allImages: true}} = {}
) => {
    const einsteinApi = useEinstein()
    const customerRecommendationsApi = useCustomRecommendations()
    const overriddenProducts = useInteractionStudioRecommendedProducts()
    // flag if the recommender is custom
    const isCustom = useMemo(
        () => (zone ? false : recommender.startsWith('custom')),
        [recommender, zone]
    )
    // get the recommender API based on the type
    const recommenderApi = useMemo(
        () => (isCustom ? customerRecommendationsApi : einsteinApi),
        [customerRecommendationsApi, einsteinApi, isCustom]
    )

    // load the recommendations
    const [apiArgs, _setApiArgs] = useState(null)
    const setApiArgs = useCallback((args) => {
        _setApiArgs((current) =>
            // prevent rerender when the args are the same
            JSON.stringify(current) === JSON.stringify(args) ? current : args
        )
    }, [])
    const {
        isInitialLoading: recommendationsLoading,
        data: loadedRecommendations,
        error: recommendationsError
    } = useQuery({
        queryKey: ['recommendations', zone ? {zone} : {recommender}, apiArgs],
        queryFn: ({queryKey: [_, __, [method, ...args]]}) => recommenderApi[method](...args),
        enabled: !!apiArgs && !overriddenProducts
    })

    // load the products for the recommendations
    const recommendedProductIds = useMemo(() => {
        if (overriddenProducts) {
            return overriddenProducts
        }

        const ids = loadedRecommendations?.recs?.map((rec) => rec.id)

        // limit recommendations based on the max recommendations parameter
        if (maxRecommendations > 0 && ids?.length > maxRecommendations) {
            ids.length = maxRecommendations
        }

        return ids
    }, [loadedRecommendations, maxRecommendations, overriddenProducts])
    const {
        isInitialLoading: productsLoading,
        data: loadedProducts,
        error: productsError
    } = useProducts(
        {
            parameters: {
                // TODO v3: validate perPricebook and expand parameters from pwa 3.x ref app
                perPricebook: true,
                expand: [
                    'availability',
                    'links',
                    'promotions',
                    'options',
                    'images',
                    'prices',
                    'variations'
                ],

                ...productParameters,
                ids: recommendedProductIds?.join(',')
            }
        },
        {
            enabled: recommendedProductIds?.length > 0
        }
    )

    // combined isLoading state
    const isLoading = useMemo(
        () => recommendationsLoading || productsLoading,
        [productsLoading, recommendationsLoading]
    )

    // notify about recommendations error
    useEffect(() => {
        if (recommendationsError) {
            console.error('Error fetching recommendations', recommendationsError)
        }
    }, [recommendationsError])
    // notify about products error
    useEffect(() => {
        if (productsError) {
            console.error('Error fetching product data for recommendations', productsError)
        }
    }, [productsError])

    // combine the recommendations with the product details
    const recommendations = useMemo(() => {
        if ((!overriddenProducts && !loadedRecommendations) || !loadedProducts) {
            return null
        }

        // Merge the product detail into the recommendations response
        return {
            ...loadedRecommendations,
            recs:
                (overriddenProducts?.map((id) => ({id})) || loadedRecommendations.recs)
                    ?.map((rec) => {
                        const product = loadedProducts.data?.find(
                            (product) => product?.id === rec?.id
                        )
                        // skip if the product is not found - might be due to offline product or limit of max recommendations
                        if (!product) {
                            return null
                        }
                        return {
                            ...rec,
                            ...product,
                            productId: rec.id,
                            ...(rec.imageUrl && {
                                image: {disBaseLink: rec.imageUrl, alt: rec.productName}
                            })
                        }
                    })
                    // filter out the null values
                    .filter(Boolean) || []
        }
    }, [loadedRecommendations, loadedProducts, overriddenProducts])

    const getRecommendations = useCallback(
        async (products, args) => {
            // If no zone or recommender is provided, reset the recommendations and products and return
            if (!zone && !recommender) {
                setApiArgs(null)
                return
            }

            // prepare the arguments for the API call - this is used to prevent multiple calls for the same data
            let apiArgs
            if (zone) {
                apiArgs = [
                    'getRawZoneRecommendations',
                    recommenderApi.prepareRawZoneRecommendations(zone, products, args)
                ]
            } else if (isCustom) {
                apiArgs = [
                    `get${recommender.split('-')[1]}`,
                    recommenderApi[`prepare${recommender.split('-')[1]}`](products, args)
                ]
            } else {
                apiArgs = [
                    'getRawRecommendations',
                    recommenderApi.prepareRawRecommendations(recommender, products, args)
                ]
            }

            setApiArgs(apiArgs)
        },
        [isCustom, recommender, recommenderApi, setApiArgs, zone]
    )

    return {
        isAvailable: true,
        isCustom,
        isLoading,
        recommendations,
        getRecommendations
    }
}

export default useRecommendations
