import {useHistory} from 'react-router-dom'
import {useColorVariation} from '../../hooks'
import PropTypes from 'prop-types'
import React from 'react'
import ColorVariationSwatchGroup from './color-variation-swatch-group'

const ColorVariationSelection = ({
    product,
    loading,
    onChange,
    isProductASet,
    onHoverChange,
    labelPrefix
}) => {
    const history = useHistory()

    const colorVariation = useColorVariation(product, isProductASet)

    if (!colorVariation?.values?.length) {
        return null
    }

    return (
        <ColorVariationSwatchGroup
            currentProductId={product.id}
            loading={loading}
            onChange={(value, href) => {
                if (!href) return
                history.replace(href)
                onChange?.(value, href)
            }}
            onHoverChange={onHoverChange}
            label={labelPrefix ? labelPrefix + ' ' + colorVariation.name : colorVariation.name}
            value={colorVariation.selectedValue.value}
            displayName={colorVariation.selectedValue.name}
            colorVariationValues={colorVariation.values}
        ></ColorVariationSwatchGroup>
    )
}

ColorVariationSelection.propTypes = {
    product: PropTypes.object,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    isProductASet: PropTypes.bool,
    onHoverChange: PropTypes.func,
    labelPrefix: PropTypes.string
}

export default ColorVariationSelection
