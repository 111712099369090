export default {
    baseStyle: {
        container: {
            templateColumns: {base: 'minmax(0, 1fr) minmax(0, 1fr)', lg: '1fr 21.25rem'},
            columnGap: {base: 4, lg: 12},
            templateRows: 'auto 1fr',
            rowGap: {base: 4, lg: 8}
        },
        imageContainer: {
            rowSpan: {base: 1, lg: 2},
            display: 'flex',
            mb: {base: 0, lg: '26px'},
            // set minHeight to gallery container to prevent bonus products modal height jumping effect
            minHeight: {lg: '34rem'},
            position: 'relative'
        },
        availabilityPriceContainer: {
            mt: {base: 5, xl: 8},
            mb: {base: 5, xl: 4}
        },
        variationsContainer: {
            mt: 4
        }
    },
    variants: {
        setProduct: {
            container: {
                templateColumns: {
                    base: '0.4401fr 0.5599fr',
                    xl: '476px 1fr'
                }
            },
            imageContainer: {
                mb: 0
            },
            availabilityPriceContainer: {
                mt: {base: '1.5rem', xl: '2rem'},
                mb: {base: '1rem', xl: '0.5rem'}
            },
            variationsContainer: {
                mt: null
            }
        }
    }
}
