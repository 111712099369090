import {isServer} from '../utils/utils'
import useMultiSite from '../hooks/use-multi-site'

export const useCookieConsent = () => {
    const {site} = useMultiSite()
    const enableCookiebot = site?.gdpr?.enableCookiebot || false
    const consent = () =>
        enableCookiebot
            ? {...window.Cookiebot?.consent}
            : {
                  marketing: true,
                  necessary: true,
                  preferences: true
              }
    return isServer ? {} : consent()
}
