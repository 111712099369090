import {createOcapiFetch} from './utils'

class OcapiShopperContentSearch {
    constructor(config) {
        this.fetch = createOcapiFetch(config)
    }

    async getSearchResult(...args) {
        const {queryString, fdid, count, sortAttribute} = args[0].parameters
        let url = 'content_search?'
        if (fdid) url = url + `&refine=fdid=${encodeURIComponent(fdid)}`
        if (count) url = url + `&count=${encodeURIComponent(count)}`
        if (queryString) url = url + `&q=${encodeURIComponent(queryString)}`
        if (sortAttribute) url = url + `&sort=${encodeURIComponent(sortAttribute)}=asc`
        return await this.fetch(url, 'GET', args)
    }

    async getNextResults(...args) {
        const {next} = args[0].parameters
        const nextSubstr = next.substring(next.indexOf('content_search'), next.length)
        return await this.fetch(nextSubstr, 'GET', args)
    }
}

export default OcapiShopperContentSearch
