import React, {forwardRef} from 'react'
import {Stack, Text, useBreakpointValue, useMultiStyleConfig} from '@chakra-ui/react'
import Slider from '../slider'
import PropTypes from 'prop-types'
import Tile from './fanreel-tile'
import {FormattedMessage} from 'react-intl'

const FanreelTiles = forwardRef(
    ({items, loading, product, headline, variant, headlineHtmlProps, onClick, onChange}, ref) => {
        const styles = useMultiStyleConfig('Fanreel', {
            variant: variant
        })
        const sliderSize = useBreakpointValue({base: 2, md: 3, lg: 4, xl: 5, '2xl': 6})

        return (
            <Stack {...styles.listContainer} ref={ref}>
                <Text {...styles.listHeader} {...headlineHtmlProps}>
                    {headline ? (
                        headline
                    ) : (
                        <FormattedMessage
                            defaultMessage="{name} in social"
                            id="fanreel.title"
                            values={product || {}}
                        />
                    )}
                </Text>
                <Slider itemsPerSlide={sliderSize} variant="fanreel" onChange={onChange}>
                    {loading
                        ? Array.from({length: 6}, (_, i) => <Tile key={i} loading />)
                        : items.map((item, idx) => (
                              <Tile
                                  key={item.id}
                                  index={idx}
                                  item={item}
                                  lazy={idx >= sliderSize}
                                  onClick={onClick}
                              />
                          ))}
                </Slider>
            </Stack>
        )
    }
)

FanreelTiles.propTypes = {
    items: PropTypes.array,
    loading: PropTypes.bool,
    product: PropTypes.object,
    headline: PropTypes.string,
    variant: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    headlineHtmlProps: PropTypes.object
}

if (process.env.NODE_ENV !== 'production') {
    FanreelTiles.displayName = 'FanreelTiles'
}

export default FanreelTiles
