import {useCallback, useRef} from 'react'

/**
 * `useEffectEvent` is a React Hook that lets you extract non-reactive logic
 * into an [Effect Event](https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event).
 *
 * TLDR; allows you to execute code that has access to the latest state and props from inside useEffect, without triggering effect re-runs.
 *
 * Recommended usage is for tracking pixels, analytics, and other side effects that should not be re-run when the state or props used change.
 *
 * **Note 1:** Use only in useEffect.
 *
 * **Note 2:** Do not pass the function to child components, custom hooks, context providers, or other functions.
 *
 * @see https://react.dev/reference/react/experimental_useEffectEvent
 * @param {function(...[*]): *} callback
 * @returns {function(...[*]): *}
 */
const useEffectEvent = (callback) => {
    const ref = useRef(callback)
    ref.current = callback
    return useCallback((...args) => ref.current?.apply(undefined, args), [])
}

export default useEffectEvent
