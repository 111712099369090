import {useEffect, useState} from 'react'
import {useGoogleMapsApiLoader} from '../contexts'
import useAsync from '../../commerce-api/hooks/useAsync'

/**
 * @param {null|google.maps.GeocoderRequest}request
 * @returns {{loading?: boolean, error?: google.maps.GeocoderStatus, results?: google.maps.GeocoderResult[]|null}}
 */
const useGeocoder = (request) => {
    const getGoogleMapsAPI = useGoogleMapsApiLoader()
    const [state, setState] = useState({})
    const {
        data: googleMapsAPI,
        execute,
        loading: apiLoading,
        error: apiError
    } = useAsync(getGoogleMapsAPI)

    // init google maps api
    useEffect(() => {
        if (!request) return
        if (googleMapsAPI) return
        if (apiLoading) return
        if (apiError) return
        execute()
    }, [execute, request, googleMapsAPI, apiLoading, apiError])

    useEffect(() => {
        setState({})
        if (!googleMapsAPI) return
        if (!request) return

        setState({loading: true})
        try {
            const geocoder = new googleMapsAPI.Geocoder()
            let canceled = false
            let requestWithLatLng = request

            const {position, ...rest} = requestWithLatLng
            if (position?.coords) {
                const {latitude: lat, longitude: lng} = position.coords
                requestWithLatLng = {...rest, location: new googleMapsAPI.LatLng(lat, lng)}
            }

            geocoder.geocode(requestWithLatLng, (results, status) => {
                if (canceled) return
                if (status !== 'OK' && status !== 'ZERO_RESULTS') {
                    setState({error: status || 'UNKNOWN_ERROR'})
                    return
                }

                setState({results})
            })
            return () => {
                canceled = true
            }
        } catch (error) {
            setState({error})
        }
    }, [request, googleMapsAPI])

    return {
        ...state,
        loading: state.loading || apiLoading,
        error: state.error || apiError
    }
}

export default useGeocoder
