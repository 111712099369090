import {useEffect, useMemo, useState} from 'react'
import {useToast} from './use-toast'
import {useAddToCartModalContext} from './use-add-to-cart-modal'
import {useBonusProductsModalContext} from './use-bonus-products-modal'
import {trackAddProductSetToCart, trackAddToCart} from '../tracking/analytics'
import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {API_ERROR_MESSAGE} from '../constants'
import {useCurrentBasket} from './use-current-basket'
import useEinstein from './use-einstein'
import {useDerivedProduct} from './use-derived-product'
import {useProductSpecialOffers} from '../components/special-offer/product-special-offers'
import {useSpecialOffer} from '../components/special-offer'
import {getProductAddToBasketItems, isProductSet} from '../utils/product-utils'
import useEffectEvent from './use-effect-event'
import useProductItemsDetail from './useProductItemsDetail'
import {useStokkeAddItemToBasketMutation} from './useStokkeHelpers'

export const useAddToCart = (
    product,
    {isLoading: isProductLoading, showAddToCartModal = true, onRecommendedProductClick} = {}
) => {
    const showToast = useToast()
    const isProductASet = isProductSet(product)
    const location = useLocation()
    const {formatMessage} = useIntl()
    const {data: basket} = useCurrentBasket()
    const {data: productItemsDetail} = useProductItemsDetail(basket)
    const einstein = useEinstein()
    const {variant, isOrderable, setIncludesBonusProduct} = useDerivedProduct(product)
    // marks that bonus product modal should be displayed after the bonus products are loaded
    const [isShowBonusProductModal, setIsShowBonusProductModal] = useState(false)
    // activates loading of the bonus products of particular product
    // - tracked separately from showing the modal to avoid reloading on second add to cart
    // - the value is the product id for which to load the bonus product, as using effect to reset on product change leaves
    //   a render cycle where the bonus product loading is triggered for the new product
    const [bonusProductLoadEnabledFor, setBonusProductLoadEnabledFor] = useState(null)

    // keep track of basket loading state
    const {isInitialLoading: isBasketLoading} = useCurrentBasket()

    // get all promotions available for the product
    const {promotions, loading: productSpecialOffersLoading} = useProductSpecialOffers(
        product,
        null,
        null
    )

    // compute the bonus promotion for the product
    const bonusPromotion = useMemo(() => {
        return promotions?.find((p) => p?.c_discountProducts?.length > 0)
    }, [promotions])

    // load the bonus products for the bonus promotion when activated for the current product
    const {
        products: bonusProducts,
        colorScheme,
        fontColor,
        loading: specialOffersLoading
    } = useSpecialOffer(bonusPromotion, bonusProductLoadEnabledFor === product?.id)

    // combined loading state
    const isLoading =
        isBasketLoading || isProductLoading || productSpecialOffersLoading || specialOffersLoading

    // add to cart modal context
    const {
        isOpen: isAddToCartModalOpen,
        onOpen: onAddToCartModalOpen,
        onClose: onAddToCartModalClose,
        data,
        addProduct
    } = useAddToCartModalContext()

    // bonus product modal context
    const {isOpen: isBonusProductsModalOpen, onOpen: onBonusProductsModalOpen} =
        useBonusProductsModalContext() || {}

    // clear the flag to show the bonus product modal when it is closed
    useEffect(() => {
        if (!isBonusProductsModalOpen) {
            setIsShowBonusProductModal(false)
        }
    }, [isBonusProductsModalOpen])

    // close add to cart modal on location change
    const onLocationChange = useEffectEvent(() => {
        if (isAddToCartModalOpen) {
            onAddToCartModalClose()
        }
    })
    useEffect(() => {
        onLocationChange(location.pathname)
    }, [location.pathname, onLocationChange])

    // show bonus product modal when the bonus products are loaded and the flag is set
    const onShowBonusProductModal = useEffectEvent(() => {
        onBonusProductsModalOpen({
            products: bonusProducts?.data,
            bonusProductVariationsMap: bonusPromotion?.c_discountVariantProducts,
            colorScheme,
            fontColor,
            showAddToCartModal: true,
            setSelectedItem: () => {}
        })
    })
    useEffect(() => {
        if (isShowBonusProductModal && bonusProducts) {
            onShowBonusProductModal()
        }
    }, [isShowBonusProductModal, bonusProducts, onShowBonusProductModal])

    // show add to cart overlay when bonus product modal is closed or not requested and there are products added
    const onBonusProductsModalClose = useEffectEvent(() => {
        onAddToCartModalOpen(onRecommendedProductClick)
    })
    useEffect(() => {
        if (showAddToCartModal && !isShowBonusProductModal && data) {
            onBonusProductsModalClose()
        }
    }, [
        data,
        isBonusProductsModalOpen,
        isShowBonusProductModal,
        showAddToCartModal,
        specialOffersLoading,
        onBonusProductsModalClose
    ])

    const showError = () => {
        showToast({
            title: formatMessage(API_ERROR_MESSAGE),
            status: 'error'
        })
    }

    const addItemToBasketMutation = useStokkeAddItemToBasketMutation()
    const handleAddToCart = async ({onAfterAddToCart, engraveText} = {}) => {
        try {
            if (!isOrderable || isLoading) return
            const productItems = getProductAddToBasketItems(product, engraveText)
            if (!productItems?.length) return

            const updatedBasket = await addItemToBasketMutation.mutateAsync({productItems})
            if (onAfterAddToCart) {
                onAfterAddToCart()
            }
            // einstein
            productItems.map((item) =>
                einstein.sendAddToCart({
                    ...item,
                    masterId: product?.master?.masterId
                })
            )
            // analytics
            if (isProductASet) {
                trackAddProductSetToCart(product, updatedBasket, productItemsDetail)
            } else {
                trackAddToCart(
                    {
                        ...product,
                        productName: variant?.name || product?.name
                    },
                    updatedBasket,
                    productItemsDetail
                )
            }

            // if product has bonus product promotions show bonus products modal first - then add to cart modal
            const variationKey = product.master ? product.master.masterId : product.id
            const isCurrentProductBonus =
                bonusPromotion?.c_discountVariantProducts?.[variationKey]?.indexOf(product.id) >= 0
            // if we have multiple promotions for the product - use only first one and only in case it is already applied to the basket
            const appliedBonusPromotions = updatedBasket?.bonusDiscountLineItems?.filter(
                (bonus) => bonus.promotionId === bonusPromotion?.id
            )
            if (
                appliedBonusPromotions &&
                bonusPromotion &&
                !isBonusProductsModalOpen &&
                !isCurrentProductBonus &&
                !setIncludesBonusProduct
            ) {
                setBonusProductLoadEnabledFor(product.id)
                setIsShowBonusProductModal(true)
            }

            // add current product to add to cart modal
            addProduct(product)
        } catch (error) {
            console.error(error)
            showError()
        }
    }

    return {
        handleAddToCart,
        isLoading
    }
}
