import {isProductSet} from '../../utils/product-utils'
import {stringToSHA256} from '../../utils/crypto'
import {
    TRACKING_TIMEOUT,
    NAVIGATION_TRACKING_LEVEL_HERO_LABEL,
    NAVIGATION_TRACKING_LEVEL_1_LABEL,
    NAVIGATION_TRACKING_LEVEL_2_LABEL,
    NAVIGATION_TRACKING_LEVEL_GROUPS_LABEL
} from '../../constants'

export const DATA_LAYER_INITIALIZATION_EVENT = 'page_load'

let resolveDataLayerInitialized = null
let dataLayerInitializedPromise = new Promise((resolve) => {
    resolveDataLayerInitialized = resolve
})

/**
 * exported only for tests, usually no need to reset that data layer was initialized
 */
export const resetDataLayerInitialized = () => {
    dataLayerInitializedPromise = new Promise((resolve) => {
        resolveDataLayerInitialized = resolve
    })
}

const getItemStock = (product) => (product?.inventory?.orderable ? 'stock-in' : 'stock-out')

const getColorName = (product) => {
    const colorName = product?.variationAttributes
        ?.reduce(
            (colorVariationAttribute, variationAttribute) =>
                variationAttribute.id === 'color' ? variationAttribute : colorVariationAttribute,
            null
        )
        ?.values?.reduce(
            (color, current) => (current.value === product.c_color ? current.name : color),
            ''
        )
    return colorName || ''
}

export const createProductLineItems = (product, details) => {
    const productDetails = details?.[product.productId] || product
    const itemId = product.productId || product.id
    const itemName = product?.variant?.name || product.productName || product.name

    return {
        item_id: itemId,
        item_name: itemName,
        price: product.price,
        item_brand: productDetails.brand || product.brand || 'stokke',
        item_category: productDetails.primaryCategoryId || product.primaryCategoryId || '',
        item_variant: getColorName(productDetails) || getColorName(product) || '',
        quantity: product.quantity || 1,
        item_stock: getItemStock(productDetails) || getItemStock(product) || ''
    }
}

const createEcommerceObject = (basket, productItemsDetail) => ({
    items:
        basket.productItems?.map((product) =>
            createProductLineItems(product, productItemsDetail)
        ) || [],
    currency: basket.currency,
    value: basket.orderTotal || basket?.productTotal
})

const createEcommerceObjectForProduct = (product) => ({
    items: isProductSet(product)
        ? product.setProducts.map((product) => createProductLineItems(product))
        : [createProductLineItems(product)],
    currency: product.currency,
    value: product.price * (product.quantity || 1)
})

/**
 * Track an event with Google Tag Manager, resolves when the event has been processed by all tags or times out.
 *
 * Tracking an event will wait for DATA_LAYER_INITIALIZATION_EVENT to be tracked before tracking any other events.
 * In that case the timeout will not start until the DATA_LAYER_INITIALIZATION_EVENT event has been processed.
 *
 * @param {string} [event] - the event name
 * @param {string} [event_action] - the event action
 * @param {string} [event_label] - the event label
 * @param {object} [ecommerce] - the ecommerce object
 * @param {string} [email] - user's email address (will be hashed)
 * @param {object} [props] - additional properties
 * @return {Promise<void>} resolves when the event has been processed by all tags or times out
 */
export async function trackEvent({event, event_action, event_label, ecommerce, email, ...props}) {
    if (!window) return
    window.dataLayer = window.dataLayer || []
    const tag = {
        event,
        event_action,
        event_label,
        ecommerce,
        ...props,
        // include hashed email if provided
        ...(email && {email_sha256: await stringToSHA256(email)})
    }

    // wait for data layer to be initialized before tracking any other events
    if (event !== DATA_LAYER_INITIALIZATION_EVENT && dataLayerInitializedPromise) {
        await dataLayerInitializedPromise
    }

    await Promise.race([
        new Promise((resolve) =>
            window.dataLayer.push({
                ...tag,
                // use `eventCallback` to resolve the promise when the event has been processed by all tags
                // https://www.simoahava.com/gtm-tips/hitcallback-eventcallback/
                eventCallback: resolve,
                // use `eventTimeout` to limit the time to wait for the event to be processed
                // https://www.simoahava.com/gtm-tips/use-eventtimeout-eventcallback/
                eventTimeout: TRACKING_TIMEOUT
            })
        ),
        // timeout fallback in case gtm is not loaded (e.g. adblocker)
        new Promise((resolve) => setTimeout(resolve, TRACKING_TIMEOUT))
    ])

    // notify pending events that the data layer has been initialized
    if (event === DATA_LAYER_INITIALIZATION_EVENT && resolveDataLayerInitialized) {
        resolveDataLayerInitialized(tag)
        resolveDataLayerInitialized = null
        dataLayerInitializedPromise = null
    }
}

//Used to push custom Events with GTM when interacting with the Field IF analyticsEvent is set
export function trackFormField(analyticsEvent, event_label, error, value, currency, productItems) {
    if (!analyticsEvent || error || !value) return
    return trackEvent({
        event: analyticsEvent,
        event_label: event_label,
        ecommerce: {
            currency,
            items: productItems
        }
    })
}

export function trackBeginCheckout(basket, productItemsDetail) {
    if (!basket) return
    const ecommerce = createEcommerceObject(basket, productItemsDetail)
    return trackEvent({event: 'begin_checkout', ecommerce})
}

export function trackPurchase(order, details, trackConfig) {
    if (!order) return
    const ecommerce = {
        transaction_id: order.orderNo,
        value: order.orderTotal,
        tax: order.taxTotal,
        shipping: order.shippingTotal,
        currency: order.currency,
        coupon: order.couponItems?.[0]?.code,
        items: order.productItems?.map((product) => createProductLineItems(product, details))
    }
    if (trackConfig?.giftMessageEnabled) {
        ecommerce.gift_message_added = !!order.c_giftMessage
    }
    return trackEvent({
        event: 'purchase',
        email: order.customerInfo?.email,
        ecommerce
    })
}

export function trackPaymentMethod(paymentMethodType, basket, subscription, productItemsDetail) {
    if (!paymentMethodType) return
    const coupon = basket?.couponItems?.length > 0 ? basket.couponItems[0].code : undefined

    return trackEvent({
        event: 'add_payment_info',
        ecommerce: {
            payment_type: paymentMethodType,
            coupon,
            subscription,
            currency: basket?.currency,
            items: basket?.productItems?.map((product) =>
                createProductLineItems(product, productItemsDetail)
            )
        }
    })
}

export function trackViewCart(basket, productItemsDetail) {
    if (!basket) return
    const ecommerce = createEcommerceObject(basket, productItemsDetail)
    return trackEvent({event: 'view_cart', ecommerce})
}

export function trackViewProduct(product, productItemsDetail) {
    if (!product) return
    const ecommerce = {
        items: [createProductLineItems(product, productItemsDetail)],
        currency: product.currency
    }
    return trackEvent({
        event: 'view_item',
        ecommerce,
        is_product_set: false
    })
}

export function trackViewProductSet(product, productItemsDetail) {
    if (!product) return
    const ecommerce = {
        currency: product.currency,
        items: product.setProducts.map((product) =>
            createProductLineItems(product, productItemsDetail)
        )
    }
    return trackEvent({
        event: 'view_item',
        ecommerce,
        is_product_set: true
    })
}

export function trackAddToCart(product, basket, productItemsDetail) {
    if (!product) return
    const ecommerce = {
        items: [createProductLineItems(product, productItemsDetail)],
        currency: basket.currency,
        value: product.price * (product.quantity || 1)
    }
    return trackEvent({
        event: 'add_to_cart',
        ecommerce,
        is_product_set: false
    })
}

export function trackAddProductSetToCart(product, basket, productItemsDetail) {
    if (!product) return
    const ecommerce = {
        items: product.setProducts.map((product) =>
            createProductLineItems(product, productItemsDetail)
        ),
        currency: basket.currency,
        value: product.price * (product.quantity || 1)
    }
    return trackEvent({
        event: 'add_to_cart',
        ecommerce,
        is_product_set: true
    })
}

export function trackAddToWishlist(product, _basket, productItemsDetail) {
    if (!product) return
    const ecommerce = {
        items: [createProductLineItems(product, productItemsDetail)],
        currency: product.currency,
        value: product.price
    }
    return trackEvent({
        event: 'add_to_wishlist',
        ecommerce
    })
}

export function trackViewItemList(products, basket, label, productItemsDetail) {
    if (products.length < 1) return
    const ecommerce = {
        item_list_name: label,
        items: products.map((product) => createProductLineItems(product, productItemsDetail)),
        currency: basket.currency
    }
    return trackEvent({
        event: 'view_item_list',
        event_label: label,
        ecommerce
    })
}
export function trackSelectItem(product, _basket, label, productItemsDetail) {
    if (!product) return
    const ecommerce = {
        item_list_name: label,
        items: [createProductLineItems(product, productItemsDetail)],
        currency: product.currency
    }
    return trackEvent({
        event: 'select_item',
        event_label: label,
        ecommerce
    })
}

export const trackNotifyMe = (product) => {
    if (!product) return
    return trackEvent({
        event: 'notify_me',
        event_category: 'notify me',
        event_action: 'form submit',
        event_label: product?.name
    })
}

export function trackEngraving(product) {
    return trackEvent({
        event: 'engraving',
        event_category: 'engraving',
        event_action: 'form submit',
        event_label: product?.name
    })
}

export function trackRemoveFromCart(product, basket, productItemsDetail) {
    if (!product) return
    const ecommerce = {
        items: [createProductLineItems(product, productItemsDetail)],
        currency: basket.currency,
        value: product.price
    }
    return trackEvent({
        event: 'remove_from_cart',
        ecommerce
    })
}

export async function trackUpdateCartItem(currentProduct, newProduct, basket, productItemsDetail) {
    await trackRemoveFromCart(currentProduct, basket, productItemsDetail)
    await trackAddToCart(newProduct, basket, productItemsDetail)
}

export function trackCartSkeletonLoading(time) {
    if (!time) return
    return trackEvent({
        event: 'cart_loading',
        milliseconds: time
    })
}

const trackRefund = (order, event, productItemsDetail) => {
    if (!order) return
    const ecommerce = {
        transaction_id: order.orderNo,
        value: order.orderTotal,
        tax: order.taxTotal,
        shipping: order.shippingTotal,
        currency: order.currency,
        coupon: order.couponItems?.[0]?.code,
        items: order.productItems?.map((product) =>
            createProductLineItems(product, productItemsDetail)
        )
    }
    return trackEvent({event: event, ecommerce})
}

export function trackBeginCancelOrder(order, productItemsDetail) {
    return trackRefund(order, 'begin_refund', productItemsDetail)
}

export function trackCancelOrder(order, productItemsDetail) {
    return trackRefund(order, 'refund', productItemsDetail)
}

export function trackCountrySelection(currentLocation, newLocation) {
    if (!currentLocation || !newLocation) return

    return trackEvent({
        event: 'country_selector',
        event_category: 'country selector',
        event_action: `from ${currentLocation?.continent}/${currentLocation?.country}`,
        event_label: `to ${newLocation?.continent}/${newLocation?.country}`
    })
}

export function trackChangeCountry(action) {
    if (!action) return

    return trackEvent({
        event: 'pop_up_change_country',
        event_category: 'pop_up_change_country',
        event_action: action,
        event_label: ''
    })
}

export function trackPsdpClickProductTile(product) {
    if (!product) {
        return
    }
    return trackEvent({
        event: 'PSDP_click_product_tile',
        ecommerce: createEcommerceObjectForProduct(product)
    })
}

export function trackPsdpSwipeProductTile(product) {
    if (!product) {
        return
    }
    return trackEvent({
        event: 'PSDP_swipe_product_tile',
        ecommerce: createEcommerceObjectForProduct(product)
    })
}

export function trackPsdpColorSelectProductTile(product) {
    if (!product) {
        return
    }
    return trackEvent({
        event: 'PSDP_color_select_product_tile',
        ecommerce: createEcommerceObjectForProduct(product)
    })
}

const trackEcommerceEvent = (event, context, productId) => {
    if (!event || !context || !productId) {
        return
    }
    return trackEvent({
        event: `${context}_${event}`,
        event_category: context,
        event_action: event,
        event_label: productId
    })
}

export function trackProductViewColorSelect(context, productId) {
    return trackEcommerceEvent('select_color', context, productId)
}

export function trackProductTileClick(context, productId) {
    return trackEcommerceEvent('pop_up', context, productId)
}

export function trackProductViewConfigurator(context, productId) {
    return trackEcommerceEvent('click_3d_configurator', context, productId)
}

export function trackProductViewAddToCart(context, productId) {
    return trackEcommerceEvent('add_to_cart', context, productId)
}

export function trackProductViewViewProduct(context, productId) {
    return trackEcommerceEvent('view_product', context, productId)
}

/**
 * fired when the stars of reviews being clicked (distinguish between page top / review section)
 * @param {'page top'|'review section'} section
 * @return Promise<void>
 */
export function trackRRClickStars(section) {
    if (!section) {
        return
    }
    return trackEvent({
        event: 'RR_click_stars',
        event_category: 'Ratings_Reviews',
        event_action: 'button_click',
        event_label: section
    })
}

/**
 * fired when the write a review button is clicked
 * @return Promise<void>
 */
export function trackRRClickForReview() {
    return trackEvent({
        event: 'RR_click_for_review',
        event_category: 'Ratings_Reviews',
        event_action: 'button_click',
        event_label: ''
    })
}

/**
 * fired when image from a review being clicked
 * @param {string} image - the image url
 * @return Promise<void>
 */
export function trackRRPhotoEnlarge(image) {
    return trackEvent({
        event: 'RR_click_photo_enlarge',
        event_category: 'Ratings_Reviews',
        event_action: 'image_enlarge',
        event_label: image
    })
}

/**
 * fired when the reviews modal is shown to the visitor
 * @return Promise<void>
 */
export function trackRRElementVisibility() {
    return trackEvent({
        event: 'RR_element_visibility',
        event_category: 'Ratings_Reviews',
        event_action: 'element_visible',
        event_label: ''
    })
}

/**
 * fire when the form of review is successfully submitted
 * @param {string} content
 * @return Promise<void>
 */
export function trackRRSubmitReview(content) {
    return trackEvent({
        event: 'RR_submit_review',
        event_category: 'Ratings_Reviews',
        event_action: 'form_submit',
        event_label: content
    })
}

/**
 * fired when click to translate reviews
 * @param {string} originalLanguage
 * @return Promise<void>
 */
export function trackRRClickTranslation(originalLanguage) {
    return trackEvent({
        event: 'RR_click_translation',
        event_category: 'Ratings_Reviews',
        event_action: 'button_click',
        event_label: originalLanguage
    })
}

/**
 * fired when Fanreel component is in viewport
 * @return Promise<void>
 */
export function trackFanreelInViewport() {
    return trackEvent({
        event: 'UGC_element_visibility',
        event_category: 'Curalate',
        event_action: 'pageview',
        event_label: ''
    })
}

/**
 * fired when Fanreel gallery is swiped/clicked left or right
 * @return Promise<void>
 */
export function trackFanreelSwipe() {
    return trackEvent({
        event: 'UGC_swipe_gallery',
        event_category: 'Curalate',
        event_action: 'swipe',
        event_label: ''
    })
}

/**
 * fired when Fanreel gallery image is clicked
 * @param {string} imageId
 * @return Promise<void>
 */
export function trackFanreelClickImage(imageId) {
    return trackEvent({
        event: 'UGC_click_slider_image',
        event_category: 'Curalate',
        event_action: 'image_click',
        event_label: imageId
    })
}

/**
 * fired when clicked product related to an image in Fanreel gallery
 * @param {string} imageId
 * @return Promise<void>
 */
export function trackFanreelClickProduct(productId) {
    return trackEvent({
        event: 'UGC_click_product',
        event_category: 'Curalate',
        event_action: 'product_click',
        event_label: productId
    })
}

/**
 * fired when clicked the share button in Fanreel
 * @param {string} productId
 * @return Promise<void>
 */
export function trackFanreelClickShare(productId) {
    return trackEvent({
        event: 'UGC_click_share_button',
        event_category: 'Curalate',
        event_action: 'button_click',
        event_label: productId
    })
}

/**
 * Track the navigation click event
 * @param {number} level - level of the clicked link in the navigation
 * @param {string} action - action of the navigation (Label of the clicked link)
 * @return Promise<void>
 */
export function trackNavigationClick(level, action) {
    const trackingLevels = [
        NAVIGATION_TRACKING_LEVEL_HERO_LABEL,
        NAVIGATION_TRACKING_LEVEL_1_LABEL,
        NAVIGATION_TRACKING_LEVEL_2_LABEL,
        NAVIGATION_TRACKING_LEVEL_GROUPS_LABEL
    ]

    if (!trackingLevels[level] || !action) {
        return
    }

    return trackEvent({
        event: 'navigation_click',
        event_category: 'navigation',
        event_action: action,
        event_label: trackingLevels[level]
    })
}

/**
 * Track the navigation click event
 * @param {string} event - tracking event name
 * @param {object} basket - current basket
 * @param {object} productItemsDetail
 * @return Promise<void>
 */
export function trackGiftMessage(event, basket, productItemsDetail) {
    return trackEvent({
        event: event,
        event_category: 'gift message',
        item_id: basket.productItems
            ?.map((product) => {
                const item = createProductLineItems(product, productItemsDetail)
                return item.item_id
            })
            .join('|')
    })
}
