import React from 'react'
import {Box, Text, Button, Flex} from '@chakra-ui/react'
import MyStokkeLayoutDefault from '../layouts/default'
import {BasketIcon} from '../../../components/icons'
import {FormattedMessage, useIntl} from 'react-intl'
import {
    HOME_HREF,
    HYBRID_HOME,
    API_ERROR_MESSAGE,
    MY_ACCOUNT_ORDERS_LIMIT
} from '../../../constants'
import {useEffect} from 'react'
import useEffectEvent from '../../../hooks/use-effect-event'
import HybridLink from '../../../components/hybrid-link'
import MyStokkeBackToStokke from '../partials/back-to-stokke'
import MyStokkeFooter from '../partials/footer'
import MyStokkeLayoutEmpty from '../layouts/empty'
import OrderTile from '../partials/order-tile'
import LoadingSpinner from '../../../components/loading-spinner'
import {useToast} from '../../../hooks/use-toast'
import {useCustomerId, useCustomerOrders} from '@salesforce/commerce-sdk-react'

const MyStokkeOrders = () => {
    const customerId = useCustomerId()
    const {formatMessage} = useIntl()

    const {
        data: {data: ordersData} = {},
        isLoading: ordersLoading,
        error: ordersError
    } = useCustomerOrders(
        {
            parameters: {customerId, limit: MY_ACCOUNT_ORDERS_LIMIT, offset: 0}
        },
        {enabled: !!customerId}
    )

    const toast = useToast()
    const onError = useEffectEvent(() => {
        toast({
            title: formatMessage(API_ERROR_MESSAGE),
            status: 'error'
        })
    })

    useEffect(() => {
        if (ordersError) {
            console.error('Error fetching customer orders', ordersError)
            onError()
        }
    }, [ordersError, onError])

    return ordersLoading ? (
        <Box textAlign="center">
            <LoadingSpinner />
        </Box>
    ) : !ordersError && ordersData && ordersData.length > 0 ? (
        <MyStokkeLayoutDefault>
            <Text textStyle="Regular Small" color="stokkeGray.accessibleText">
                <FormattedMessage
                    defaultMessage="{orderCount, plural, one {Total of # order} other {Total of # orders}}"
                    id="mystokke.orders.counter"
                    values={{orderCount: ordersData.length}}
                />
            </Text>
            <Flex direction="column" gap={8} mt={8} mb={8}>
                {ordersData.map((order) => {
                    return <OrderTile order={order} key={order.orderNo} />
                })}
            </Flex>
            <MyStokkeFooter>
                <MyStokkeBackToStokke />
            </MyStokkeFooter>
        </MyStokkeLayoutDefault>
    ) : (
        <MyStokkeLayoutEmpty
            icon={<BasketIcon />}
            mainLabel={formatMessage({
                defaultMessage: 'No orders so far.',
                id: 'mystokke.orders.empty.headline'
            })}
            secondaryLabel={formatMessage({
                defaultMessage: "When you order items, you'll see them here.",
                id: 'mystokke.orders.empty.text'
            })}
        >
            <MyStokkeFooter>
                <Button as={HybridLink} to={HOME_HREF} page={HYBRID_HOME} width="full">
                    <FormattedMessage
                        id="mystokke.orders.empty.button"
                        defaultMessage="Explore our products"
                    />
                </Button>
                <MyStokkeBackToStokke />
            </MyStokkeFooter>
        </MyStokkeLayoutEmpty>
    )
}

export default MyStokkeOrders
