import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {useGoogleMapsApiLoader} from '../../googlemaps-api/contexts'
import {useToast} from '../../hooks/use-toast'
import useMultiSite from '../../hooks/use-multi-site'
import {STORE_LOCATOR_HREF} from '../../constants'
import FindStoreFields from './find-store-fields'

const FindStoreLink = ({prefix = '', onSubmit, onError}) => {
    const form = useForm()
    const intl = useIntl()
    const showToast = useToast()
    const mounted = useRef(true)
    const {buildUrl} = useMultiSite()
    const getGoogleMapsAPI = useGoogleMapsApiLoader()

    useEffect(() => {
        return () => {
            mounted.current = false
        }
    }, [])

    onError =
        onError ||
        (() => {
            showToast({
                title: intl.formatMessage({
                    id: 'find_store_link.error.title',
                    defaultMessage: 'Error obtaining location'
                }),
                status: 'error'
            })
        })

    onSubmit =
        onSubmit ||
        (async (data) => {
            try {
                if (
                    data.latitude == null ||
                    data.latitude === '' ||
                    data.longitude == null ||
                    data.longitude === ''
                ) {
                    const googleMapsAPI = (await getGoogleMapsAPI()) || {}

                    if (!googleMapsAPI) {
                        throw new Error('Google maps api unavailable.')
                    }

                    const location = await new Promise((resolve, reject) => {
                        const geocoder = new googleMapsAPI.Geocoder()
                        geocoder.geocode({address: data.address}, (results, status) => {
                            if (status !== 'OK' && status !== 'ZERO_RESULTS') {
                                return reject(status || 'UNKNOWN_ERROR')
                            }

                            const location = results?.[0]?.geometry?.location
                            resolve(location)
                        })
                    })
                    data.latitude = location?.lat() || ''
                    data.longitude = location?.lng() || ''
                }
                if (mounted.current) {
                    location.href = `${buildUrl(STORE_LOCATOR_HREF)}?${new URLSearchParams(data)}`
                }
            } catch (e) {
                onError(e)
            }
        })

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <FindStoreFields
                form={form}
                prefix={prefix}
                onGpsLocation={form.handleSubmit(onSubmit)}
                onError={onError}
            />
        </form>
    )
}

FindStoreLink.propTypes = {
    /** Optional prefix for field names */
    prefix: PropTypes.string,
    /** Function to call when form is submitted */
    onSubmit: PropTypes.func,
    /** Function to call when error occurs */
    onError: PropTypes.func
}

export default FindStoreLink
