import React from 'react'
import {Button, Link, Skeleton as ChakraSkeleton} from '@chakra-ui/react'
import {BasketIcon} from '../icons'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {useLocation} from 'react-router-dom'
import {useNotifyMeModal, NotifyModal} from '../../hooks/use-notify-me-modal'
import useOcapiSite from '../../hooks/useOcapiSite'
import {resolveLocaleFromUrl} from '../../utils/utils'
import {noop} from '../../utils/utils'
import {useDerivedProduct} from '../../hooks'
import {useAddToCart} from '../../hooks/use-add-to-cart'
import useMultiSite from '../../hooks/use-multi-site'

// Component Skeleton
export const Skeleton = () => (
    <ChakraSkeleton data-testid="sf-add-to-cart-skeleton" width="100%" height="32px" />
)

const AddToCartCTA = ({
    label,
    product,
    promotions: _promotions,
    showAddToCartModal = true,
    disableNotifyMe = false,
    isLoading: isProductLoading,
    scrollToTop = true,
    onClick = noop,
    onAfterAddToCart = noop,
    onRecommendedProductClick,
    ...styles
}) => {
    const {site} = useMultiSite()
    const location = useLocation()
    const {id: locale} = resolveLocaleFromUrl(location.pathname)
    const {redirectLinkPartnerAllProducts} = useOcapiSite()
    const redirectToPartner =
        redirectLinkPartnerAllProducts &&
        JSON.parse(redirectLinkPartnerAllProducts)?.[locale.replace('-', '_')]
    const {onOpen: onOpenNotifyMeModal, ...notifyMeModalProps} = useNotifyMeModal()
    const {isOrderable, redirectLinkPartner} = useDerivedProduct(product)
    const {handleAddToCart, isLoading} = useAddToCart(product, {
        isLoading: isProductLoading,
        showAddToCartModal,
        onRecommendedProductClick
    })
    const isOrderableOnOtherSites = redirectLinkPartner || redirectToPartner

    // content only sites do not render add to cart/notify me buttons
    if (site?.isContentOnlySite && !isOrderableOnOtherSites) {
        return null
    }

    return (
        <>
            {isOrderable || isOrderableOnOtherSites ? (
                <>
                    <Button
                        {...(redirectToPartner
                            ? {
                                  as: Link,
                                  isExternal: true,
                                  href: redirectLinkPartner || redirectToPartner,
                                  onClick
                              }
                            : {
                                  onClick: () => {
                                      scrollToTop &&
                                          window.scrollTo({
                                              top: 0,
                                              behavior: 'smooth'
                                          })
                                      onClick()
                                      handleAddToCart({
                                          onAfterAddToCart,
                                          onRecommendedProductClick
                                      })
                                  }
                              })}
                        key="cart-button"
                        width="100%"
                        variant="solid"
                        rightIcon={<BasketIcon boxSize={6} />}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        className="add-to-cart"
                        {...styles}
                    >
                        {label || (
                            <FormattedMessage
                                id="product_view.button.add_to_cart"
                                defaultMessage="Add to Cart"
                            />
                        )}
                    </Button>
                </>
            ) : (
                <>
                    {!disableNotifyMe && <NotifyModal {...notifyMeModalProps} />}

                    <Button
                        key="cart-button"
                        onClick={() => {
                            onClick()
                            !disableNotifyMe && onOpenNotifyMeModal(product)
                        }}
                        width="100%"
                        variant="solid"
                        data-testid="notify"
                        isDisabled={disableNotifyMe}
                        {...styles}
                    >
                        <FormattedMessage id="notify.modal.submit" defaultMessage="Notify me" />
                    </Button>
                </>
            )}
        </>
    )
}

AddToCartCTA.propTypes = {
    product: PropTypes.object,
    promotions: PropTypes.array,
    label: PropTypes.string,
    showAddToCartModal: PropTypes.bool,
    disableNotifyMe: PropTypes.bool,
    scrollToTop: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    onAfterAddToCart: PropTypes.func,
    onRecommendedProductClick: PropTypes.func
}
export default AddToCartCTA
