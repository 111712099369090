import React, {useMemo} from 'react'
import {Box, Stack, useToast} from '@chakra-ui/react'
import {useIntl} from 'react-intl'
import MyStokkeLayoutDefault from '../layouts/default'
import MyStokkePersonalDataBasicData from '../partials/my-stokke-personal-data-basic-data'
import MyStokkePersonalDataChildren from '../partials/my-stokke-personal-data-children'
import MyStokkePersonalDataCredentials from '../partials/my-stokke-personal-data-credentials'
import {useCurrentCustomer} from '../../../hooks/use-current-customer'
import {useForm} from 'react-hook-form'
import {API_ERROR_MESSAGE} from '../../../constants'
import MyStokkeBackToStokke from '../partials/back-to-stokke'
import MyStokkeFooter from '../partials/footer'
import {
    ShopperCustomersMutations,
    useShopperCustomersMutation
} from '@salesforce/commerce-sdk-react'

const MyStokkePersonalData = () => {
    const intl = useIntl()
    const {formatMessage} = useIntl()
    const {data: customer} = useCurrentCustomer()
    const form = useForm()
    const toast = useToast()

    const updateCustomerPassword = useShopperCustomersMutation(
        ShopperCustomersMutations.UpdateCustomerPassword
    )
    const updateCustomerMutation = useShopperCustomersMutation(
        ShopperCustomersMutations.UpdateCustomer
    )

    const credentials = useMemo(() => {
        return {
            email: customer.email
        }
    }, [customer.email])
    const basicData = useMemo(() => {
        return {
            salutation: customer.salutation,
            firstName: customer.firstName,
            lastName: customer.lastName,
            phone: customer.phoneHome,
            birthday: customer.birthday,
            brithdayFormated: customer.birthday
                ? intl.formatDate(new Date(customer.birthday), {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                  })
                : ''
        }
    }, [customer, intl])

    const children = useMemo(() => {
        return customer.c_children
            ? JSON.parse(customer.c_children)
                  .map((child) => {
                      child.childBirthdateFormated = child.childBirthdate
                          ? intl.formatDate(new Date(child.childBirthdate), {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric'
                            })
                          : ''
                      return child
                  })
                  .sort((a, b) => a?.childName?.localeCompare(b?.childName))
            : []
    }, [customer.c_children, intl])

    const submitForm = async (data) => {
        if (data.email) {
            await updateCustomerPassword.mutateAsync(
                {
                    parameters: {customerId: customer.customerId},
                    body: {
                        password: data.password,
                        currentPassword: data.currentPassword
                    }
                },
                {
                    onSuccess: () => {
                        toast({
                            title: formatMessage({
                                defaultMessage: 'Your data has been successfully updated',
                                id: 'mystokke.personal-information.customer-updated'
                            }),
                            status: 'success'
                        })
                        setEdit(null)
                    },
                    onError: (error) => {
                        console.error('Error while updating personal data', error)
                        if (error.toString().includes('current password is not valid')) {
                            const message = /current password is not valid/i.test(error.message)
                                ? formatMessage({
                                      defaultMessage: 'The current password is not valid',
                                      id: 'mystokke.personal-information.password-not-valid'
                                  })
                                : error.message
                            form.setError('global', {type: 'manual', message})
                        } else {
                            toast({
                                title: formatMessage(API_ERROR_MESSAGE),
                                status: 'error'
                            })
                        }
                    }
                }
            )
        } else {
            if (data.birthday === '') {
                data.birthday = null
            }
            //childrenhandling
            if (data.childName || data.childBirthdate) {
                if (!data.ID) {
                    //add new child
                    const newChild = data
                    newChild.ID = crypto.randomUUID()
                    const updatedChildren = [...children, newChild]
                    data = {c_children: JSON.stringify(updatedChildren)}
                } else {
                    //update child
                    const filteredChildren = children.filter((child) => child.ID !== data.ID)
                    const updatedChildren = [...filteredChildren, data]
                    data = {c_children: JSON.stringify(updatedChildren)}
                }
            }
            const body = {
                salutation: data.salutation,
                firstName: data.firstName,
                lastName: data.lastName,
                phoneHome: data.phone,
                birthday: data.birthday,
                c_children: data.c_children
            }
            await updateCustomerMutation.mutateAsync(
                {
                    parameters: {customerId: customer.customerId},
                    body
                },
                {
                    onSuccess: () => {
                        toast({
                            title: formatMessage({
                                defaultMessage: 'Your data has been successfully updated',
                                id: 'mystokke.personal-information.customer-updated'
                            }),
                            status: 'success'
                        })
                        setEdit(null)
                    },
                    onError: (error) => {
                        console.error('Error while updating personal data', error)
                        if (error.toString().includes('current password is not valid')) {
                            const message = /current password is not valid/i.test(error.message)
                                ? formatMessage({
                                      defaultMessage: 'The current password is not valid',
                                      id: 'mystokke.personal-information.password-not-valid'
                                  })
                                : error.message
                            form.setError('global', {type: 'manual', message})
                        } else {
                            toast({
                                title: formatMessage(API_ERROR_MESSAGE),
                                status: 'error'
                            })
                        }
                    }
                }
            )
        }
    }

    const [edit, setEdit] = React.useState(null)

    return (
        <MyStokkeLayoutDefault>
            <Stack spacing={6}>
                <Box>
                    <MyStokkePersonalDataCredentials
                        data={credentials}
                        isEditing={edit === 'credentials'}
                        onEdit={() => setEdit('credentials')}
                        onCancel={() => setEdit(null)}
                        form={form}
                        submitForm={submitForm}
                        isLoading={form.formState.isSubmitting}
                    />
                </Box>
                <Box>
                    <MyStokkePersonalDataBasicData
                        data={basicData}
                        isEditing={edit === 'basic'}
                        onEdit={() => setEdit('basic')}
                        onCancel={() => setEdit(null)}
                        form={form}
                        submitForm={submitForm}
                        isLoading={form.formState.isSubmitting}
                    />
                </Box>
                <Box>
                    <MyStokkePersonalDataChildren
                        data={children}
                        isEditing={edit === 'children'}
                        onEdit={() => setEdit('children')}
                        onCancel={() => setEdit(null)}
                        form={form}
                        submitForm={submitForm}
                        isLoading={form.formState.isSubmitting}
                    />
                </Box>
            </Stack>
            <MyStokkeFooter>
                <MyStokkeBackToStokke />
            </MyStokkeFooter>
        </MyStokkeLayoutDefault>
    )
}

export default MyStokkePersonalData
