import React, {useEffect, useMemo} from 'react'
import {Helmet} from 'react-helmet'
import {isServer} from '../utils/utils.js'
import {useGtmConfig} from './contexts'
import PropTypes from 'prop-types'
import {useServerContext} from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'
import {useNonce} from '../hooks'
import useDisableGtm from '../hooks/use-disable-gtm'

const w = isServer ? {} : window

const Gtm = () => {
    const disabled = useDisableGtm()

    // expose the value for helmet-config to use in ssr
    const {res} = useServerContext()
    if (res) {
        res.locals.disableGtm = disabled
    }

    const gtmConfig = useGtmConfig()
    const nonce = useNonce()
    const l = 'dataLayer'

    const gtmSrc = useMemo(() => {
        if (!gtmConfig?.gtmId) {
            return null
        }
        const base = 'https://www.googletagmanager.com/gtm.js'
        const params = new URLSearchParams(
            Object.entries(
                process.env.NODE_ENV === 'production'
                    ? {
                          id: gtmConfig.gtmId,
                          ...(l !== 'dataLayer' ? {l} : null)
                      }
                    : {
                          id: gtmConfig.gtmId,
                          ...(l !== 'dataLayer' ? {l} : null),
                          gtm_auth: 'LJ6LTD_e8SBN0Nw5vRNpfA',
                          gtm_preview: 'env-448',
                          gtm_cookies_win: 'x'
                      }
            )
        )
        return `${base}?${params}`
    }, [gtmConfig?.gtmId])

    useEffect(() => {
        w[l] = w[l] || []
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
    }, [])

    if (!gtmSrc) {
        if (process.env.NODE_ENV !== 'test') {
            console.warn('GTM is not configured')
        }
        return null
    }

    if (disabled) {
        return null
    }

    return (
        <Helmet>
            <script async src={gtmSrc} nonce={nonce} />
        </Helmet>
    )
}

Gtm.propTypes = {
    disabled: PropTypes.bool
}

export default Gtm
