import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        container: {
            flexDirection: 'row'
        },
        starsAndBarsContainer: {
            flexDirection: 'column',
            alignItems: 'center',
            flexBasis: '100%',
            justifyContent: 'space-between'
        },
        ratingCountsContainer: {
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingLeft: {base: '1rem', xl: '3rem'},
            alignItems: 'flex-end'
        },
        starsAndBarItemWrapper: {
            width: '100%',
            alignItems: 'center',
            height: '1.25rem'
        },
        ratingCountItem: {
            ...textStyles['Regular Medium'],
            minWidth: '3.3125rem',
            justifyContent: 'end',
            height: '1.25rem'
        },
        ratingLabel: {
            flexDirection: 'row',
            ...textStyles['Regular Medium'],
            minWidth: {base: '4.75rem', xl: '6.25rem'}
        },
        progressBar: {
            height: '0.25rem',
            background: 'stokkeGray.mid',
            width: '100%',
            borderRadius: '0.125rem'
        }
    }
}
