import React, {useCallback, useMemo} from 'react'
import {HOME_HREF, HYBRID_COUNTRY_SELECTOR} from '../../constants'
import HybridLink from '../hybrid-link'
import {CheckIcon, FlagIcon, DropdownIcon} from '../icons'
import useMultiSite from '../../hooks/use-multi-site'
import PropTypes from 'prop-types'
import {
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuOptionGroup,
    useBreakpointValue
} from '@chakra-ui/react'
import {FormattedMessage} from 'react-intl'
import useHybridURL from '../../hooks/use-hybrid-url'
import {useLocaleName} from './hooks'

const englishLanguageNames = new Intl.DisplayNames(['en'], {type: 'language'})

const makeLocaleObject = (localeId) => {
    const languageId = localeId.split('-')[0]
    const englishLanguageName = englishLanguageNames.of(languageId) || languageId

    return {
        englishLanguageName,
        localeId
    }
}

/**
 * Render a styled menu item link.
 */
const StyledMenuItemLink = ({children, selected, ...props}) => {
    return (
        <MenuItem as="a" {...props}>
            {children}
            <CheckIcon boxSize="0.875rem" visibility={selected ? 'visible' : 'hidden'} />
        </MenuItem>
    )
}

StyledMenuItemLink.propTypes = {
    children: PropTypes.any,
    selected: PropTypes.bool
}

/**
 * Locale item component.
 */
const LocaleItem = ({localeId, selected}) => {
    const {buildUrl, site} = useMultiSite()
    const href = useMemo(() => buildUrl(HOME_HREF, site.id, localeId), [buildUrl, site, localeId])
    const label = useLocaleName(localeId)

    return (
        <StyledMenuItemLink href={href} selected={selected}>
            {label}
        </StyledMenuItemLink>
    )
}

LocaleItem.propTypes = {
    localeId: PropTypes.string,
    selected: PropTypes.bool
}

/**
 * Country selector item component.
 */
const CountrySelectorItem = () => {
    const {buildUrl} = useMultiSite()
    const navigateHybrid = useHybridURL()
    const href = useMemo(() => buildUrl('/country-selector'), [buildUrl])
    const onClick = useCallback(
        (e) => {
            e.preventDefault()
            navigateHybrid(HYBRID_COUNTRY_SELECTOR, '/country-selector')
        },
        [navigateHybrid]
    )

    return (
        <StyledMenuItemLink href={href} onClick={onClick}>
            <FormattedMessage
                id="locale-selector.country-selector.label"
                defaultMessage="Other region"
            />
        </StyledMenuItemLink>
    )
}

const LocaleSelector = ({variant}) => {
    const {site, locale: currentLocale} = useMultiSite()
    const country = useMemo(() => currentLocale.id.split('-')[1], [currentLocale])
    const supportedLocaleIds = useMemo(() => {
        const {l10n} = site
        return l10n?.supportedLocales.map((locale) => locale.id)
    }, [site])
    const hasMultipleLocales = useMemo(() => supportedLocaleIds?.length > 1, [supportedLocaleIds])
    const sortedLocales = useMemo(() => {
        const localesObjectsArr = supportedLocaleIds.map((localeId) => makeLocaleObject(localeId))

        return localesObjectsArr.sort((a, b) => {
            return a.englishLanguageName.localeCompare(b.englishLanguageName)
        })
    }, [supportedLocaleIds])
    const placement = useBreakpointValue({base: 'top-start', lg: 'bottom-end'})
    if (!hasMultipleLocales || site?.isContentOnlySite || site?.id === 'EUR') {
        return (
            <HybridLink
                variant={variant ? variant : 'localeSelector'}
                size="localeSelector"
                page={HYBRID_COUNTRY_SELECTOR}
                to="/country-selector"
            >
                <FlagIcon boxSize={4} countryCode={country} marginInlineEnd={2} />
                {country}
            </HybridLink>
        )
    }

    return (
        <Menu placement={placement} variant="localeSelector">
            <MenuButton
                as={Button}
                rightIcon={<DropdownIcon width={2} height="0.938rem" />}
                iconSpacing={1.5}
                variant="localeSelector"
            >
                <Flex align="center">
                    <FlagIcon boxSize={4} countryCode={country} marginInlineEnd={2} />
                    {country}
                </Flex>
            </MenuButton>
            <MenuList>
                <MenuOptionGroup type="radio">
                    {sortedLocales.map((locale) => (
                        <LocaleItem
                            {...locale}
                            key={locale.localeId}
                            selected={locale.localeId === currentLocale.id}
                        />
                    ))}
                    <CountrySelectorItem />
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    )
}

export default LocaleSelector
LocaleSelector.propTypes = {
    variant: PropTypes.string
}
