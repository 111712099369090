import {checkRequiredParameters, createOcapiFetch} from './utils'

class OcapiShopperStores {
    constructor(config) {
        this.fetch = createOcapiFetch(config)
    }

    async getStoresByIds(...args) {
        const required = ['ids']

        let requiredParametersError = checkRequiredParameters(args[0], required)
        if (requiredParametersError) {
            return requiredParametersError
        }
        const {ids} = args[0].parameters

        return await this.fetch(`stores/(${ids.toString()})`, 'GET', args, 'getStoresByIds')
    }

    async getStoresNearby(...args) {
        const params = args[0].parameters
        return await this.fetch(
            `stores?latitude=${encodeURIComponent(params.latitude)}&longitude=${encodeURIComponent(
                params.longitude
            )}&count=15`,
            'GET',
            args,
            'getStoresNearby'
        )
    }
}

export default OcapiShopperStores
