import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import {Checkbox as ChakraCheckbox, useMultiStyleConfig} from '@chakra-ui/react'
import {CheckboxDisabledIcon, CheckIcon} from '../icons'
import {useKeyboardFocus} from '../../hooks/use-keyboard-focus'

const CheckboxIcon = ({isIndeterminate, isChecked, isDisabled, ...rest}) => {
    return isDisabled ? (
        <CheckboxDisabledIcon {...rest} />
    ) : isChecked ? (
        <CheckIcon {...rest} />
    ) : null
}

CheckboxIcon.propTypes = {
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isIndeterminate: PropTypes.bool
}

const Checkbox = forwardRef(({variant = '', isDisabled, ...props}, ref) => {
    const {focusVariant, onBlur, onFocus, onMouseEvent} = useKeyboardFocus({variant})
    const styles = useMultiStyleConfig('Checkbox', {...props, isDisabled, variant: focusVariant})

    return (
        <ChakraCheckbox
            ref={ref}
            // apply icon styles as props to override default icon theme styles
            icon={<CheckboxIcon {...styles.icon} isDisabled={isDisabled} />}
            // provide container styles as props to allow override of default styles defined in chakra label component
            {...styles.container}
            isDisabled={isDisabled}
            onMouseDown={onMouseEvent}
            onFocus={onFocus}
            onBlur={onBlur}
            variant={focusVariant}
            // pass all props as chakra also needs theming props
            {...props}
        />
    )
})

if (process.env.NODE_ENV !== 'production') {
    Checkbox.displayName = 'Checkbox'
}

Checkbox.propTypes = {
    isDisabled: PropTypes.bool,
    variant: PropTypes.string
}

export default Checkbox
