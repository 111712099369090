import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {Flex, useId, VisuallyHidden} from '@chakra-ui/react'
import AddressDisplay from '../../../components/address-display'
import {CheckIcon} from '../../../components/icons'
import ActionCard from '../../../components/action-card'
import {FormattedMessage, useIntl} from 'react-intl'

const MyStokkeAddressCard = ({address, children, idx, onEdit: _onEdit, onRemove: _onRemove}) => {
    const {formatMessage} = useIntl()
    const addressId = address.addressId
    const isPreferred = address.preferred
    const onEdit = useCallback(() => _onEdit?.(address), [address, _onEdit])
    const onRemove = useCallback(() => _onRemove?.(address), [address, _onRemove])
    const hintId = useId()

    return (
        // the container replicates RadioCard but without the radio input
        <Flex
            position="relative"
            backgroundColor="stokkeCore.white"
            borderBottom="2px"
            borderColor={isPreferred ? 'stokkeFunctional.alertGreen' : 'transparent'}
            paddingX={4}
            paddingTop={4}
            paddingBottom={5}
            data-testid={`mystokke-address-${addressId}`}
            flexDirection="column"
            gap={4}
            role="listitem"
            aria-label={formatMessage(
                {defaultMessage: 'Address {number}', id: 'mystokke.addresses.list.item'},
                {number: idx + 1}
            )}
            aria-describedby={hintId}
            aria-current={isPreferred}
        >
            <VisuallyHidden id={hintId}>
                {isPreferred ? (
                    <FormattedMessage
                        defaultMessage="Preferred address for {firstName} {lastName}, {address1}, {city}"
                        id="mystokke.addresses.list.preferred_item.description"
                        values={address}
                    />
                ) : (
                    <FormattedMessage
                        defaultMessage="Address for {firstName} {lastName}, {address1}, {city}"
                        id="mystokke.addresses.list.item.description"
                        values={address}
                    />
                )}
            </VisuallyHidden>
            {isPreferred && (
                <CheckIcon
                    color="stokkeFunctional.alertGreen"
                    position="absolute"
                    boxSize={4}
                    right={4}
                    top={4}
                />
            )}
            <ActionCard
                editButtonProps={{
                    'aria-label': formatMessage(
                        {
                            defaultMessage:
                                'Edit address for {firstName} {lastName}, {address1}, {city}',
                            id: 'mystokke.addresses.list.item.edit'
                        },
                        address
                    )
                }}
                padding={0}
                border="none"
                onEdit={_onEdit && onEdit}
                onRemove={_onRemove && onRemove}
            >
                <AddressDisplay address={address} />
            </ActionCard>
            {children}
        </Flex>
    )
}

MyStokkeAddressCard.propTypes = {
    address: PropTypes.object.isRequired,
    children: PropTypes.node,
    idx: PropTypes.number,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func
}

export default MyStokkeAddressCard
