import {Box, Stack} from '@chakra-ui/react'
import React, {useState} from 'react'
import {
    PromoCodeCheck,
    PromoCodeForm,
    PromoCodeItem,
    PromoCodeRemoveItem,
    usePromoCode
} from '../promo-code'
import {useOrderSummary} from './order-summary-provider'

const OrderSummaryPromoForm = () => {
    const basket = useOrderSummary()
    const hasPromoCodes = basket.couponItems?.length > 0
    const [isOpen, setOpen] = useState(hasPromoCodes)
    const {removePromoCode, ...promoCodeProps} = usePromoCode()

    return (
        <Stack spacing={4}>
            <PromoCodeCheck
                size="sm"
                isChecked={isOpen}
                onChange={(e) => {
                    setOpen(e.target.checked)
                    if (!e.target.checked) {
                        basket.couponItems?.forEach((item) => removePromoCode(item.couponItemId))
                    }
                }}
            />
            {isOpen && (
                <Box paddingBottom={4}>
                    {hasPromoCodes ? (
                        basket.couponItems?.map((item) => (
                            <Stack key={item.couponItemId} spacing={2}>
                                <PromoCodeItem item={item} colorScheme="stokkeBlue" />
                                <Box>
                                    <PromoCodeRemoveItem
                                        onClick={() => removePromoCode(item.couponItemId)}
                                    />
                                </Box>
                            </Stack>
                        ))
                    ) : (
                        <PromoCodeForm {...promoCodeProps} />
                    )}
                </Box>
            )}
        </Stack>
    )
}

export default OrderSummaryPromoForm
