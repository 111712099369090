import React from 'react'
import PropTypes from 'prop-types'
import {Text, Flex, Button, Image} from '@chakra-ui/react'
import StyledContent from '../../styled-content'
import {FormattedMessage} from 'react-intl'
import {ArrowRight2Icon} from '../../icons'

const ProductSuggestions = ({suggestions, closeAndNavigate, onSubmitSearch, searchBarPosition}) => {
    if (!suggestions) {
        return null
    }
    return (
        <Flex gap={4} direction="column" alignItems="start" data-testid="product-suggestions">
            <Text textStyle="Regular XLarge">
                <FormattedMessage defaultMessage="Products" id="global.products" />
            </Text>
            <Flex gap={4} direction="column" width="100%">
                {suggestions.map((suggestion, idx) => (
                    <Flex
                        key={idx}
                        gap={4}
                        onMouseDown={() =>
                            closeAndNavigate(suggestion.hybrid, suggestion.link, {
                                search_bar_position: searchBarPosition,
                                flyout_section: 'Products',
                                flyout_selection: suggestion.name
                            })
                        }
                        cursor="pointer"
                        alignItems="center"
                        width="100%"
                        data-testid="product-suggestion-item"
                    >
                        <Flex
                            height="6.4375rem"
                            width="5.625rem"
                            justifyContent="center"
                            alignItems="center"
                            flex="0 0 auto"
                        >
                            <Image maxWidth="100%" maxHeight="100%" src={suggestion.image} />
                        </Flex>
                        <Flex direction="column" gap={4} alignItems="start">
                            <StyledContent
                                as={Text}
                                content={suggestion.name}
                                fontWeight="600"
                                textAlign="left"
                            />
                        </Flex>
                    </Flex>
                ))}
            </Flex>
            <Button
                variant="link"
                rightIcon={<ArrowRight2Icon boxSize={4} />}
                onMouseDown={
                    onSubmitSearch
                } /*the onBlur function is otherwise executed beforehand (in chrome)*/
            >
                <FormattedMessage defaultMessage="View all" id="search.view_all" />
            </Button>
        </Flex>
    )
}

ProductSuggestions.propTypes = {
    onSubmitSearch: PropTypes.func,
    suggestions: PropTypes.array,
    closeAndNavigate: PropTypes.func,
    searchBarPosition: PropTypes.string
}

export default ProductSuggestions
