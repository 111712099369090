import React, {createContext, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import useMultiSite from '../../hooks/use-multi-site'
import {CRO_PUBLIC_API_NAME} from '../../constants'
import PropTypes from 'prop-types'
import useEffectEvent from '../../hooks/use-effect-event'

const CroContext = createContext(null)

const useCroPublicApi = (activeSlots, setActiveExperiments) => {
    // use ref to keep access to the latest active slots without needing to trigger an effect
    const slotsRef = useRef(activeSlots)
    slotsRef.current = activeSlots

    // expose a public API for CRO
    const onSetActiveExperiments = useEffectEvent((activeExperiments) => {
        setActiveExperiments(activeExperiments)
    })
    useEffect(() => {
        const existingApi = window[CRO_PUBLIC_API_NAME] || {}

        // process any existing experiments
        const existingActiveExperiments = new Map(
            Object.entries(existingApi).filter(([, value]) => value)
        )
        onSetActiveExperiments(existingActiveExperiments)

        window[CRO_PUBLIC_API_NAME] = new Proxy(existingApi, {
            // tap into the getter to return the active slots
            get(target, slotId, receiver) {
                if (slotsRef.current.has(slotId)) {
                    return true
                }
                return Reflect.get(target, slotId, receiver)
            },
            // tap into the setter to trigger a re-render
            set(target, experimentId, variantId, receiver) {
                onSetActiveExperiments((experiments) => {
                    // skip early if nothing changes
                    if (experiments.get(experimentId) === variantId) {
                        return experiments
                    }

                    // clone the existing experiments
                    const newExperiments = new Map(experiments)
                    if (variantId) {
                        // add/update the experiment
                        newExperiments.set(experimentId, variantId)
                    } else {
                        // remove the experiment
                        newExperiments.delete(experimentId)
                    }
                    return newExperiments
                })
                return Reflect.set(target, experimentId, variantId, receiver)
            }
        })
        return () => {
            window[CRO_PUBLIC_API_NAME] = existingApi
        }
    }, [onSetActiveExperiments])
}

const useCroMarketSlots = ({registerSlot, unregisterSlot}) => {
    const {site, locale} = useMultiSite()
    const siteId = (site?.alias || site?.id)?.toLowerCase()
    const [language, country] = locale?.id?.split('-') || []
    const countryLower = country?.toLowerCase()

    useEffect(() => {
        if (siteId) {
            registerSlot(`site_${siteId}`)
        }
        if (language) {
            registerSlot(`language_${language}`)
        }
        if (countryLower) {
            registerSlot(`country_${countryLower}`)
        }
        return () => {
            if (siteId) {
                unregisterSlot(`site_${siteId}`)
            }
            if (language) {
                unregisterSlot(`language_${language}`)
            }
            if (countryLower) {
                unregisterSlot(`country_${countryLower}`)
            }
        }
    }, [countryLower, language, siteId, registerSlot, unregisterSlot])
}

export const CroProvider = ({children}) => {
    // track active experiments in state
    // key = experiment name, value = variant name
    const [activeExperiments, setActiveExperiments] = useState(new Map())

    // track active slots in state
    // key = slot name, value = reference counter
    const [activeSlots, setActiveSlots] = useState(new Map())

    const registerSlot = useCallback(
        (slotName) =>
            setActiveSlots((slots) => {
                const newActiveSlots = new Map(slots)
                const count = newActiveSlots.get(slotName) || 0
                newActiveSlots.set(slotName, count + 1)
                return newActiveSlots
            }),
        []
    )
    const unregisterSlot = useCallback((slotName) => {
        setActiveSlots((slots) => {
            const newActiveSlots = new Map(slots)
            const count = newActiveSlots.get(slotName) || 0
            if (count > 1) {
                newActiveSlots.set(slotName, count - 1)
            } else {
                newActiveSlots.delete(slotName)
            }
            return newActiveSlots
        })
    }, [])

    const resetExperiments = useCallback(() => setActiveExperiments(new Map()), [])

    // define the internal API for CRO
    const internalApi = useMemo(
        () => ({
            experiments: activeExperiments,
            resetExperiments,
            slots: activeSlots,
            registerSlot,
            unregisterSlot
        }),
        [activeExperiments, activeSlots, registerSlot, resetExperiments, unregisterSlot]
    )

    useCroPublicApi(activeSlots, setActiveExperiments)
    useCroMarketSlots(internalApi)

    return <CroContext.Provider value={internalApi}>{children}</CroContext.Provider>
}

CroProvider.propTypes = {
    children: PropTypes.any
}

const useCroApi = () => {
    const api = React.useContext(CroContext)
    if (!api) {
        throw new Error('useCroApi must be used within a CroProvider')
    }
    return api
}

export const useCroVariantActive = (experimentId, variantId) => {
    const api = useCroApi()

    return api.experiments.get(experimentId) === variantId
}

export const useCroSlot = (slotId, active = true) => {
    const {registerSlot, unregisterSlot} = useCroApi()

    useEffect(() => {
        if (!active) {
            return
        }
        registerSlot(slotId)
        return () => {
            unregisterSlot(slotId)
        }
    }, [active, registerSlot, slotId, unregisterSlot])

    return useMemo(
        () =>
            active
                ? {
                      [`data-cro-${slotId}`]: true
                  }
                : null,
        [active, slotId]
    )
}

export const useCroActiveSlots = () => useCroApi().slots

export const useCroResetExperiments = () => useCroApi().resetExperiments
