import {Box, Flex} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import ContentTile from '../../components/content-tile'

const WidgetArticleDefaultViewDesktop = ({sliderData}) => {
    const ratio_1 = [1, 510 / 612, 448 / 538, 552 / 683, 720 / 865],
        ratio_2 = [1, 510 / 612, 448 / 244, 552 / 318, 720 / 479],
        ratio_3 = [1, 510 / 612, 208 / 120, 260 / 160, 344 / 210],
        ratio_4 = [552 / 270]

    return (
        <>
            {sliderData.length === 2 && (
                <Flex gap={{base: 2, xl: 4}}>
                    <Box width="50%">
                        <ContentTile content={sliderData[0]} ratio={ratio_1} variant="extended" />
                    </Box>
                    <Box width="50%">
                        <ContentTile content={sliderData[1]} ratio={ratio_1} variant="extended" />
                    </Box>
                </Flex>
            )}
            {sliderData.length === 3 && (
                <Flex gap={{base: 2, xl: 4}}>
                    <Box width="50%">
                        <ContentTile content={sliderData[0]} ratio={ratio_1} variant="extended" />
                    </Box>

                    <Flex
                        flexDirection={{base: 'row', md: 'column-reverse'}}
                        width="50%"
                        justifyContent="flex-end"
                        gap={{base: 2, xl: 4}}
                    >
                        <Box width="100%">
                            <ContentTile
                                content={sliderData[1]}
                                ratio={ratio_4}
                                variant="extended"
                            />
                        </Box>
                        <Box width="100%">
                            <ContentTile
                                content={sliderData[2]}
                                ratio={ratio_4}
                                variant="extended"
                            />
                        </Box>
                    </Flex>
                </Flex>
            )}
            {sliderData.length >= 4 && (
                <Flex gap={{base: 2, xl: 4}}>
                    <Box width="50%">
                        <ContentTile content={sliderData[0]} ratio={ratio_1} variant="extended" />
                    </Box>

                    <Flex
                        flexDirection={{base: 'row', md: 'column-reverse'}}
                        width="50%"
                        justifyContent="flex-end"
                        gap={{base: 2, xl: 4}}
                    >
                        <Box width="100%">
                            <ContentTile
                                content={sliderData[1]}
                                ratio={ratio_2}
                                variant="extended"
                            />
                        </Box>

                        <Flex gap={{base: 2, xl: 4}}>
                            <Box width="50%">
                                <ContentTile
                                    content={sliderData[2]}
                                    ratio={ratio_3}
                                    variant="extended"
                                />
                            </Box>
                            <Box width="50%">
                                <ContentTile
                                    content={sliderData[3]}
                                    ratio={ratio_3}
                                    variant="extended"
                                />
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </>
    )
}

WidgetArticleDefaultViewDesktop.propTypes = {
    sliderData: PropTypes.array
}

export default WidgetArticleDefaultViewDesktop
