import {useEffect, useMemo} from 'react'
import useAutocomplete from '../../googlemaps-api/hooks/useAutocomplete'
import useEffectEvent from '../../hooks/use-effect-event'

const useAutocompleteFindStoreFields = (form) => {
    const autocompleteOptions = useMemo(
        () =>
            /** @type google.maps.places.AutocompleteOptions */ ({
                fields: ['name', 'geometry'],
                strictBounds: true,
                types: ['address']
            }),
        []
    )
    const {ref, place} = useAutocomplete(autocompleteOptions)
    const onPlaceFound = useEffectEvent((place) => {
        // set the location of the selected place
        if (place.geometry?.location) {
            form.setValue('latitude', place.geometry.location.lat(), {shouldValidate: false})
            form.setValue('longitude', place.geometry.location.lng(), {shouldValidate: false})
        }
        // use the place name as it is region formatted
        form.setValue('address', place.name, {shouldValidate: true})
    })
    useEffect(() => {
        if (!place || !place.name) {
            return
        }

        onPlaceFound(place)
    }, [place, onPlaceFound])

    return {ref}
}

export default useAutocompleteFindStoreFields
