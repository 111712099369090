import React, {forwardRef, useCallback, useImperativeHandle, useRef} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import useOcapiSite from '../../hooks/useOcapiSite'

/**
 * A wrapper around the ReCAPTCHA component that handles the site key
 */
const Recaptcha = forwardRef((_, ref) => {
    const {reCaptchaPublicKey} = useOcapiSite()
    const innerRef = useRef(null)
    const recaptchaFailed = useRef(false)

    // execute recaptcha and return promise resolving with the token or null if recaptcha is not enabled
    const execute = useCallback(async () => {
        if (!innerRef.current) {
            if (!reCaptchaPublicKey) {
                return null
            }
            throw new Error('Recaptcha not initialized!')
        }
        // fail fast in case recaptcha failed as the call to executeAsync hangs forever
        if (recaptchaFailed.current) {
            throw new Error('Recaptcha failed!')
        }
        return await innerRef.current.executeAsync()
    }, [innerRef, recaptchaFailed, reCaptchaPublicKey])

    // expose the execute function to the parent component
    useImperativeHandle(
        ref,
        () => ({
            execute
        }),
        [execute]
    )

    if (!reCaptchaPublicKey) {
        return null
    }

    return (
        <ReCAPTCHA
            ref={innerRef}
            data-testid="recaptcha"
            sitekey={reCaptchaPublicKey}
            size="invisible"
            badge="inline"
            onErrored={() => (recaptchaFailed.current = true)}
        />
    )
})

if (process.env.NODE_ENV !== 'production') {
    Recaptcha.displayName = 'Recaptcha'
}

export default Recaptcha
