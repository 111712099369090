import fetch from 'cross-fetch'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {DEFAULT_CURALATE_FEED_LIMIT} from '../constants'

class CuralateAPI {
    constructor(curalateConfig = {}) {
        const {proxyPath = `/mobify/caching/curalate`, ...restConfig} = curalateConfig

        // Client-side requests should be proxied via the configured path.
        const proxy = `${getAppOrigin()}${proxyPath}`

        this.config = {proxy, ...restConfig}

        this.getFeed = this.getFeed.bind(this)
    }

    /**
     * Execute a Curalate API request.
     * @param {Record<string, string>} params
     * @param {RequestInit} [options]
     * @returns {Promise<any>}
     * @private
     */
    async curalateFetch({dataSourceId = this.config.dataSourceId, ...params}, options) {
        const host = this.config.proxy
        const {id: locale} = this.config.locale

        const allParams = {
            ...params,
            locale
        }
        const url = `${host}/v1/media/${dataSourceId}?${new URLSearchParams(allParams)}`
        const response = await fetch(url, options)

        if (!response.ok) {
            throw new Error(`Failure ${response.status}: ${response.statusText}`)
        }

        return await response.json()
    }

    /**
     * @param {{filter?: string, limit?: number|string}} params
     * @param {RequestInit} [options]
     * @returns {Promise<Array<Object>>}
     * @see https://developers.bazaarvoice.com/v1.0-SocialCommerceAPI/docs/display-media
     */
    async getFeed(params, options) {
        const {data} = await this.curalateFetch(
            {
                limit: DEFAULT_CURALATE_FEED_LIMIT,
                ...params
            },
            options
        )
        return data.items
    }
}

export default CuralateAPI
