import {Flex, Stack, Text} from '@chakra-ui/react'
import React from 'react'
import {FormattedMessage, FormattedNumber} from 'react-intl'
import PromoPopover from '../promo-popover'
import {useOrderSummary} from './order-summary-provider'

const OrderSummaryShipping = () => {
    const basket = useOrderSummary()

    const shippingItem = basket.shippingItems?.[0]
    const hasShippingPromos = shippingItem?.priceAdjustments?.length > 0

    const header = (
        <Text fontWeight="bold" fontSize="md">
            <FormattedMessage defaultMessage="Promotion" id="order_summary.promo_popover.heading" />
        </Text>
    )

    return (
        <>
            <Flex alignItems="center">
                <Text>
                    <FormattedMessage defaultMessage="Shipping" id="order_summary.label.shipping" />
                </Text>
                {hasShippingPromos && (
                    <PromoPopover header={header} mx={1.5}>
                        <Stack>
                            {shippingItem?.priceAdjustments?.map((adjustment) => (
                                <Text key={adjustment.priceAdjustmentId}>
                                    {adjustment.itemText}
                                </Text>
                            ))}
                        </Stack>
                    </PromoPopover>
                )}
            </Flex>

            {shippingItem?.priceAdjustments?.some(
                ({appliedDiscount}) => appliedDiscount?.type === 'free'
            ) || basket.shippingTotal === 0 ? (
                <Text
                    as="span"
                    color="stokkeFunctional.alertGreen"
                    textTransform="uppercase"
                    data-testid="sf-order-summary-shipping-price"
                >
                    <FormattedMessage defaultMessage="Free" id="global.free" />
                </Text>
            ) : (
                <Text data-testid="sf-order-summary-shipping-price">
                    <FormattedNumber
                        value={basket.shippingTotal}
                        style="currency"
                        currency={basket.currency}
                    />
                </Text>
            )}
        </>
    )
}

export default OrderSummaryShipping
