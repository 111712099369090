import useAsync from '../../commerce-api/hooks/useAsync'
import {useBazaarvoiceAPI} from '../context'
import {useEffect, useMemo} from 'react'
import useOcapiSite from '../../hooks/useOcapiSite'
import {getBVReviewProductIds} from '../../utils/product-utils'
import useEffectEvent from '../../hooks/use-effect-event'

/**
 * @param {object} product
 * @returns {{data: {AverageOverallRating: number, TotalReviewCount: number, OverallRatingRange: number}|null, error: null|Error, loading: boolean}}
 */
const useProductRating = (product) => {
    const {bvEnabled} = useOcapiSite()
    const {getProductRating} = useBazaarvoiceAPI()
    const {execute, data, error, loading, reset} = useAsync(getProductRating)

    const pid = useMemo(() => getBVReviewProductIds(product), [product])
    useEffect(() => {
        if (!pid || !bvEnabled) {
            reset()
            return
        }

        const abortController = new AbortController()

        execute(pid, {signal: abortController.signal})
        return () => {
            abortController.abort()
        }
    }, [bvEnabled, execute, pid, reset])

    const onError = useEffectEvent((error) => {
        console.error('Error fetching product rating for', pid, error)
    })
    useEffect(() => {
        if (error) {
            onError(error)
        }
    }, [error, onError])

    return {
        data,
        error,
        loading
    }
}

export default useProductRating
