import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'
import {Button, Flex} from '@chakra-ui/react'
import AddressFields from '../../../components/forms/address-fields'
import MyStokkeFooter from './footer'
import MyStokkeLinkContainer from './link-container'

const MyStokkeAddressForm = ({form, onCancel}) => {
    return (
        <Flex
            data-testid="mystokke-address-form"
            flexDirection="column"
            padding={4}
            backgroundColor="stokkeGray.light"
        >
            <AddressFields form={form} />

            <MyStokkeFooter paddingTop={null} paddingBottom={null} paddingX={null}>
                <Button type="submit" variant="solid">
                    <FormattedMessage defaultMessage="Save" id="form_action_buttons.button.save" />
                </Button>
                <MyStokkeLinkContainer>
                    <Button variant="link" onClick={onCancel}>
                        <FormattedMessage
                            defaultMessage="Cancel"
                            id="form_action_buttons.button.cancel"
                        />
                    </Button>
                </MyStokkeLinkContainer>
            </MyStokkeFooter>
        </Flex>
    )
}

MyStokkeAddressForm.propTypes = {
    form: PropTypes.object,
    onCancel: PropTypes.func
}

export default MyStokkeAddressForm
