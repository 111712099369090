import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'
import {Box, Divider, HStack, Image, Stack, Text} from '@chakra-ui/react'
import {isMatchingAddress} from '../../utils/utils'
import {getCreditCardIcon} from '../../utils/cc-utils'
import AddressDisplay from '../../components/address-display'
import GiftMessageView from '../../components/gift-message/gift-message-view'
import useOcapiSite from '../../hooks/useOcapiSite'

const OrderView = ({colorScheme, order}) => {
    const {giftMessageEnabled} = useOcapiSite().preferences
    const shippingAddress = useMemo(() => order?.shipments?.[0]?.shippingAddress, [order])

    // IDs for Billing and Shipping Addresses can be different for the same address
    const billingSameAsShipping = useMemo(
        () => isMatchingAddress(shippingAddress || {}, order?.billingAddress || {}),
        [order?.billingAddress, shippingAddress]
    )

    const CardIcon = getCreditCardIcon(order.paymentInstruments[0].paymentCard?.cardType)

    const paymentMethod = useMemo(() => {
        try {
            return JSON.parse(order.c_Adyen_paymentData)
        } catch (error) {
            if (process.env.NODE_ENV !== 'test') {
                console.error('Failed to parse Adyen payment data', error)
            }
        }
    }, [order.c_Adyen_paymentData])

    return (
        <Stack spacing={4}>
            <Text as="h2" textStyle="Regular 2XLarge">
                <FormattedMessage defaultMessage="Order Summary" id="global.order_summary" />
            </Text>

            <Stack spacing={4}>
                <Text as="h3" textStyle="Semi Bold Medium">
                    <FormattedMessage
                        defaultMessage="Shipping Address"
                        id="global.shipping_address"
                    />
                </Text>
                <AddressDisplay address={order.shipments[0].shippingAddress} />
            </Stack>

            <Divider colorScheme={colorScheme} />

            <Stack spacing={4} py={2}>
                <HStack spacing={6}>
                    <Text textStyle="Semi Bold Medium">
                        {order.shipments[0].shippingMethod.name}
                    </Text>
                    {order.shipments[0].shippingMethod.c_image && (
                        <Image
                            layerStyle="checkoutLogo"
                            alt=""
                            src={order.shipments[0].shippingMethod.c_image}
                        />
                    )}
                </HStack>
                <Text textStyle="Regular Medium">
                    <FormattedMessage defaultMessage="Delivery:" id="global.estimated_delivery" />{' '}
                    {order.c_estimatedDelivery}
                </Text>
            </Stack>

            <Divider colorScheme={colorScheme} />

            <Stack spacing={4} data-testid="checkout-confirmation-billing_address">
                <Text as="h3" textStyle="Semi Bold Medium">
                    <FormattedMessage
                        defaultMessage="Billing Address"
                        id="global.billing_address"
                    />
                </Text>
                {billingSameAsShipping ? (
                    <Text textStyle="Regular Medium">
                        <FormattedMessage
                            defaultMessage="Same as shipping"
                            id="order_confirmation.billing_address.same_as_shipping"
                        />
                    </Text>
                ) : (
                    <AddressDisplay address={order.billingAddress} />
                )}
            </Stack>

            <Divider colorScheme={colorScheme} />

            <Stack spacing={4}>
                <Text as="h3" textStyle="Semi Bold Medium">
                    <FormattedMessage defaultMessage="Payment Info" id="global.payment_info" />
                </Text>
                {paymentMethod && (
                    <HStack>
                        {paymentMethod.icon && (
                            <Box width="40px" height="26px">
                                <Image
                                    alt=""
                                    src={paymentMethod.icon}
                                    borderRadius="4px"
                                    width="100%"
                                    height="100%"
                                ></Image>
                            </Box>
                        )}
                        {paymentMethod.displayName && (
                            <Text textStyle="Regular Medium">{paymentMethod.displayName}</Text>
                        )}
                        {CardIcon && <CardIcon layerStyle="checkoutLogo" />}
                    </HStack>
                )}
            </Stack>
            {giftMessageEnabled && order.c_giftMessageSender && (
                <>
                    <Divider />
                    <Box py={2} data-testid="checkout-confirmation-gift-message">
                        <GiftMessageView
                            data={{
                                giftMessage: order.c_giftMessageText,
                                giftFrom: order.c_giftMessageSender,
                                giftRecipientName: order.c_giftRecipientName,
                                giftRecipientEmail: order.c_giftRecipientEmail
                            }}
                        ></GiftMessageView>
                    </Box>
                </>
            )}
        </Stack>
    )
}

OrderView.propTypes = {
    colorScheme: PropTypes.string,
    order: PropTypes.object.isRequired
}

export default OrderView
