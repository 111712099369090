import {Button, Container, IconButton, Stack, Text} from '@chakra-ui/react'
import {FormattedMessage, useIntl, defineMessages} from 'react-intl'
import React, {useState, useRef, useEffect} from 'react'
import {useToast} from '../../hooks/use-toast'
import MarketingOptIn from '../marketing-opt-in'
import {useForm} from 'react-hook-form'
import PropTypes from 'prop-types'
import NewsletterBannerFields from '../forms/newsletter-banner-fields'
import {trackEvent} from '../../tracking/analytics'
import Recaptcha from '../recaptcha'
import {ArrowRight2Icon} from '../icons'
import {useOcapiSubscribeToNewsletterMutation} from '../../hooks/useOcapi'

const NewsletterBanner = ({source}) => {
    const toast = useToast()
    const {formatMessage} = useIntl()
    const form = useForm({
        mode: 'all',
        defaultValues: {
            email: '',
            firstName: '',
            lastName: ''
        }
    })

    const [optIn, setOptIn] = useState(false)
    const [step, setStep] = useState(1)
    const [validationDisabled, setValidationDisabled] = useState(true)

    const expandableContainerRef = useRef(null)
    const recaptchaRef = useRef(null)

    const {mutateAsync: subscribeToNewsletter} = useOcapiSubscribeToNewsletterMutation()

    // Transition time of the expanding container animation
    const transitionTime = '0.5s'

    const stepMessages = defineMessages({
        stepOneAndTwoTitle: {
            defaultMessage: 'Sign up for our newsletter!',
            id: 'newsletter-banner.text.sign_up_title'
        },

        stepThreeTitle: {
            defaultMessage: 'Thank you for signing up',
            id: 'newsletter-banner.text.sign_up_confirmation_title'
        },

        stepOneAndTwoDescription: {
            defaultMessage:
                'Subscribe to our newsletter to be the first to get a sneak peek at our new products, receive exclusive offers, special promotions and the latest news',
            id: 'newsletter-banner.text.sign_up_paragraph_text'
        },

        stepThreeDescription: {
            defaultMessage:
                'We’re happy to welcome you to the family and look forward to joining you on the exciting journey of parenting.',
            id: 'newsletter-banner.text.sign_up_confirmation_paragraph_text_part_one'
        }
    })

    // The validation of the email field should be initially disabled
    // We enable it after the first click on the submit icon button
    const formTrigger = form.trigger
    useEffect(() => {
        if (!validationDisabled) {
            let isMounted = true
            formTrigger('email').then((valid) => {
                if (valid && isMounted) {
                    setStep(2)
                }
            })
            return () => {
                isMounted = false
            }
        }
    }, [validationDisabled, formTrigger])

    // On step 1 the maxHeight of the expandable container is set to 0.
    // Once we proceed to step 2, we need to expand it.
    // We do it by setting the maxHeight to the height of its internals.
    const getExpandableContainerHeight = () => {
        // There are some error paragraphs under each field.
        // They are not shown initially, but they may appear if some field validation fails.
        // Their height is also taken in account and added to the container maxHeight.
        const errorParagraphsHeight = 80
        return expandableContainerRef.current.scrollHeight + errorParagraphsHeight + 'px'
    }

    const submitForm = async (data) => {
        try {
            if (step === 1) {
                // Initially the validation of the email field is disabled
                // We enable it after the first click on the submit icon button
                if (validationDisabled) {
                    setValidationDisabled(false)
                } else {
                    if (await form.trigger('email')) {
                        setStep(2)
                    }
                }
                return
            }

            form.clearErrors('global')
            const token = await recaptchaRef.current?.execute()
            trackEvent({
                event: 'sign_up',
                event_category: 'newsletter',
                event_action: 'signup',
                event_label: undefined,
                email: form.getValues('email')
            })
            await subscribeToNewsletter({
                parameters: {
                    email: data.email,
                    firstName: data.first_name,
                    lastName: data.last_name,
                    optIn: 1,
                    data: data,
                    gRecaptchaResponse: token,
                    source
                }
            })
            setStep(3)
        } catch (error) {
            if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
                console.warn('Failure to subscribe to newsletter', error)
            }
            toast({
                title: formatMessage({
                    defaultMessage: 'There was an error subscribing to newsletter!',
                    id: 'newsletter-subscription.error.generic'
                }),
                status: 'error'
            })
        }
    }

    return (
        <Container variant="newsLetterBanner">
            <Container variant="newsLetterBannerInner">
                <form onSubmit={form.handleSubmit(submitForm)} noValidate>
                    <Stack spacing={step !== 3 ? 8 : 0} transition={transitionTime}>
                        <Stack spacing={8} data-testid="newsletter-subscribe" paddingX={4}>
                            <Stack spacing={4}>
                                <Text
                                    as="h2"
                                    color="stokkeBeige.accessibleText"
                                    textStyle="Regular 2XLarge"
                                    textAlign="center"
                                >
                                    {formatMessage(
                                        step !== 3
                                            ? stepMessages.stepOneAndTwoTitle
                                            : stepMessages.stepThreeTitle
                                    )}
                                </Text>
                                <Text textStyle="Regular Medium" textAlign="center">
                                    {formatMessage(
                                        step !== 3
                                            ? stepMessages.stepOneAndTwoDescription
                                            : stepMessages.stepThreeDescription
                                    )}
                                </Text>
                                {step === 3 ? (
                                    <Text textStyle="Regular Medium" textAlign="center">
                                        <FormattedMessage
                                            defaultMessage="P.S. To ensure you receive all of our Stokke emails in the future, please add stokke@email.stokke.com to your address book."
                                            id="newsletter-banner.text.sign_up_confirmation_paragraph_text_part_two"
                                        />
                                    </Text>
                                ) : null}
                            </Stack>
                        </Stack>
                        <Stack spacing={step === 2 ? 8 : 0} transition={transitionTime}>
                            <Container
                                maxHeight={step === 3 ? 0 : '150px'}
                                opacity={step === 3 ? 0 : 1}
                                overflow={step === 3 ? 'hidden' : null}
                                transition={transitionTime}
                                maxWidth="unset"
                            >
                                <NewsletterBannerFields
                                    form={form}
                                    validationDisabled={validationDisabled}
                                    type="emailWithButton"
                                    rightElement={
                                        step === 1 && (
                                            <IconButton
                                                type="submit"
                                                icon={
                                                    <ArrowRight2Icon variant="newsletterSubscribe" />
                                                }
                                                variant="newsletterSubscribe"
                                                aria-label={formatMessage({
                                                    id: 'global.sign_up',
                                                    defaultMessage: 'Sign Up'
                                                })}
                                            />
                                        )
                                    }
                                />
                            </Container>

                            <Stack
                                spacing={8}
                                maxHeight={step === 2 ? getExpandableContainerHeight : 0}
                                opacity={step === 2 ? 1 : 0}
                                overflowY="hidden"
                                transition={transitionTime}
                                ref={expandableContainerRef}
                                paddingX={4}
                            >
                                <Stack
                                    spacing={{base: 8, lg: 0}}
                                    direction={{base: 'column', lg: 'row'}}
                                >
                                    <NewsletterBannerFields
                                        form={form}
                                        type="firstName"
                                        width={{lg: '50%'}}
                                        marginRight={{lg: '20px'}}
                                    />
                                    <NewsletterBannerFields
                                        form={form}
                                        type="lastName"
                                        width={{lg: '50%'}}
                                    />
                                </Stack>

                                <MarketingOptIn
                                    value={optIn}
                                    onChange={setOptIn}
                                    viewExtendedDescription={true}
                                />

                                <Recaptcha ref={recaptchaRef} />

                                <Button
                                    type="submit"
                                    width="full"
                                    isDisabled={!optIn}
                                    isLoading={form.formState.isSubmitting}
                                    maxWidth={{lg: '400px'}}
                                >
                                    <FormattedMessage
                                        defaultMessage="Sign Up"
                                        id="global.sign_up"
                                    />
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </form>
            </Container>
        </Container>
    )
}

NewsletterBanner.propTypes = {
    source: PropTypes.string
}

export default NewsletterBanner
