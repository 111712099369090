import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {Box, Container, Flex, Text} from '@chakra-ui/react'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import useMyStokkePageConfig from '../hooks/use-page-config'
import RecommendedProducts from '../../../components/recommended-products'
import MyStokkeBreadcrumb from '../partials/breadcrumb'
import BreadcrumbItem from '../../../components/breadcrumb/breadcrumb-item'
import {useIntl} from 'react-intl'
import InteractionStudioRecommendedProducts from '../../../components/interaction-studio/recommended-products'
import {
    ProductQuickviewModal,
    useProductQuickviewModal
} from '../../../components/product-quickview-modal'
import {getMyStokkeLabels} from '../../../utils/site-utils'

const RecommendedProductsWrapper = ({children}) => (
    <Container
        paddingX={{
            base: 4,
            lg: 6,
            xl: 8
        }}
    >
        {children}
    </Container>
)
RecommendedProductsWrapper.propTypes = {
    children: PropTypes.node
}

const MyStokkeLayoutEmpty = ({children, icon, mainLabel, secondaryLabel}) => {
    const {formatMessage} = useIntl()
    const {id, recommender} = useMyStokkePageConfig()
    const headline = useMemo(() => {
        return getMyStokkeLabels(id, 'headline', formatMessage)
    }, [id, formatMessage])

    const productQuickviewModalProps = useProductQuickviewModal()
    productQuickviewModalProps.onRecommendedProductClick = productQuickviewModalProps.onOpen

    return (
        <>
            <MyStokkeBreadcrumb>
                <BreadcrumbItem>{headline}</BreadcrumbItem>
            </MyStokkeBreadcrumb>

            <Box backgroundColor="stokkeGray.bright">
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    paddingTop={8}
                    paddingBottom={6}
                    paddingX={4}
                    gap={6}
                >
                    <Text as="h1" textAlign="center" textStyle="Regular 4XLarge">
                        {headline}
                    </Text>
                </Flex>

                <Flex
                    flexShrink="0"
                    minHeight="21.875rem"
                    flexDirection="column"
                    alignItems="center"
                    paddingY={8}
                    paddingX={4}
                    gap={4}
                >
                    <Box position="relative">
                        <Box
                            // center on the icon
                            position="absolute"
                            left="50%"
                            top="50%"
                            // use px because it must align with the border around the icon
                            height="248px"
                            width="375px"
                            marginLeft="-187.5px"
                            // not sure why this value, but this centers the circles on the icon
                            marginTop="-60.5px"
                            // use static asset instead of icons because this is only used here
                            backgroundImage={`url(${getAssetUrl(
                                'static/img/mystokke-empty-bg.svg'
                            )})`}
                        >
                            <Box
                                height="27px"
                                bgGradient="linear(to bottom, stokkeGray.bright, transparent), linear-gradient(to bottom, stokkeGray.bright, transparent);"
                            />
                        </Box>
                        <Box
                            // relative position to render over the background image
                            position="relative"
                            borderRadius="full"
                            // use px because this must align with the border and the background image
                            padding="16px"
                            border="10px solid"
                            borderColor="#FFFAF5"
                            backgroundColor="#FFF3EB"
                        >
                            {icon && React.cloneElement(icon, {boxSize: 6, color: '#C96400'})}
                        </Box>
                    </Box>
                    {mainLabel && (
                        <Text textAlign="center" textStyle="Regular 2XLarge">
                            {mainLabel}
                        </Text>
                    )}
                    {secondaryLabel && (
                        <Text
                            textAlign="center"
                            textStyle="Regular Medium"
                            color="stokkeGray.accessibleText"
                        >
                            {secondaryLabel}
                        </Text>
                    )}
                </Flex>

                <Flex flexDirection="column" alignItems="center" paddingX={4}>
                    {children}
                </Flex>

                {recommender && (
                    <>
                        <InteractionStudioRecommendedProducts id="is-dashboard-recommendations">
                            <RecommendedProducts
                                wrapper={RecommendedProductsWrapper}
                                recommender={recommender}
                                title={formatMessage({
                                    defaultMessage: 'You may also like',
                                    id: 'recommended_products.title.you_may_also_like'
                                })}
                                trackingLabel="My Stokke Recommendations"
                                onRecommendedProductClick={productQuickviewModalProps.onOpen}
                            />
                        </InteractionStudioRecommendedProducts>
                        <ProductQuickviewModal {...productQuickviewModalProps} />
                    </>
                )}
            </Box>
        </>
    )
}

MyStokkeLayoutEmpty.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.node,
    mainLabel: PropTypes.string,
    secondaryLabel: PropTypes.string
}

export default MyStokkeLayoutEmpty
