import {Text} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage, FormattedNumber} from 'react-intl'
import {useOrderSummary} from './order-summary-provider'

const OrderSummaryTotal = ({isEstimate = false}) => {
    const basket = useOrderSummary()

    return (
        <>
            {isEstimate ? (
                <Text>
                    <FormattedMessage
                        defaultMessage="Estimated Total"
                        id="order_summary.label.estimated_total"
                    />
                </Text>
            ) : (
                <Text>
                    <FormattedMessage defaultMessage="Total" id="global.total" />
                </Text>
            )}
            <Text>
                <FormattedNumber
                    style="currency"
                    currency={basket?.currency}
                    value={basket?.orderTotal || basket?.productTotal}
                />
            </Text>
        </>
    )
}

OrderSummaryTotal.propTypes = {
    isEstimate: PropTypes.bool
}

export default OrderSummaryTotal
