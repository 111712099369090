import React, {useEffect, useMemo} from 'react'
import {createPortal} from 'react-dom'
import {camelToKebabCase, kebabToCamelCase} from '../utils/utils'
import WidgetAccessories from './accessories'
import WidgetAddToCart from './add-to-cart'
import WidgetArticle from './article'
import WidgetHotspot from './hotspot'
import WidgetNewsletter from './newsletter'
import WidgetProduct from './product'
import WidgetProductInfos from './productinfos'
import WidgetSearch from './search'
import WidgetFanreel from './fanreel'
import WidgetStore from './store'

/**
 * All defined widgets - use camelCase for widget names
 */
const DEFINED_WIDGETS = {
    accessories: WidgetAccessories,
    addToCart: WidgetAddToCart,
    article: WidgetArticle,
    fanreel: WidgetFanreel,
    hotspot: WidgetHotspot,
    newsletter: WidgetNewsletter,
    product: WidgetProduct,
    productinfos: WidgetProductInfos,
    search: WidgetSearch,
    store: WidgetStore
}

/**
 * Convert widget name to tag name
 * @param {string} name
 * @returns {`pwa-widget-${string}`}
 */
const widgetNameToTagName = (name) => `pwa-widget-${camelToKebabCase(name)}`

/**
 * Hook to create widgets from the provided DOM tree
 * @param {HTMLElement} root
 * @param {string} html
 * @return {React.ReactPortal[]}
 */
export const useWidgets = (root, html) => {
    const [widgets, setWidgets] = React.useState([])

    useEffect(() => {
        if (!root || !html) return

        const newWidgets = []
        Object.entries(DEFINED_WIDGETS).map(([name, widget]) => {
            const tagName = widgetNameToTagName(name)
            const widgetElements = Array.from(root.querySelectorAll(tagName))
            widgetElements.forEach((widgetElement) => {
                // extract props from attributes
                const propsEntries = []
                for (const attr of widgetElement.attributes) {
                    propsEntries.push([kebabToCamelCase(attr.name), attr.value])
                }
                const props = Object.fromEntries(propsEntries)

                // create widget inside the element with the extracted props
                newWidgets.push({
                    widget,
                    widgetElement,
                    props
                })

                // clear the existing element content
                widgetElement.replaceChildren()
            })
        })

        setWidgets(newWidgets)
        return () => {
            setWidgets([])
        }
    }, [
        // need to rerun on change of the raw html content or the dom root
        root,
        html
    ])

    return useMemo(
        () =>
            widgets.map(({widget: Widget, widgetElement, props}) =>
                createPortal(<Widget {...props} />, widgetElement)
            ),
        [widgets]
    )
}
