import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import NavigationItem from '../navigation-item'

/**
 * Renders lvl1 items in the mobile and desktop drawer menu.
 */
const Lvl1Item = ({item, isActive, onClick: _onClick}) => {
    // handle lvl1 item click
    const onClick = useCallback(
        (e) => {
            e?.preventDefault()
            e?.stopPropagation()
            _onClick?.(item)
        },
        [item, _onClick]
    )

    return (
        <NavigationItem
            {...item}
            // as per specification images on lvl1 items should not be displayed
            image=""
            levelIdx={1}
            selected={isActive}
            onClick={onClick}
            trackingIndex={1}
        />
    )
}

Lvl1Item.propTypes = {
    item: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    onClick: PropTypes.func
}

export default Lvl1Item
