import React from 'react'
import {IconRow} from './icon-row'
import {useIntl} from 'react-intl'
import useOcapiSite from '../../hooks/useOcapiSite'

export const PaymentIcons = (props) => {
    const intl = useIntl()
    const {paymentMethods} = useOcapiSite()

    if (!paymentMethods?.length) {
        return null
    }

    return (
        <IconRow
            {...props}
            icons={paymentMethods}
            title={intl.formatMessage({
                id: 'payment-icons.pay_with',
                defaultMessage: 'Pay easily via:'
            })}
        />
    )
}

export default PaymentIcons
