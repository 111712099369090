import React, {useCallback} from 'react'
import {
    AspectRatio,
    Button,
    Flex,
    Image,
    Skeleton,
    Text,
    useMultiStyleConfig
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import {HeartIcon, RoundPlusIcon} from '../icons'
import {FormattedMessage} from 'react-intl'
import {trackFanreelClickImage} from '../../tracking/analytics'

function FanreelTile({index, item, lazy, loading, onClick: _onClick}) {
    const styles = useMultiStyleConfig('Fanreel')
    const imageSrc =
        !loading &&
        (item.media.poster ? item.media.poster.mediumSquare.link : item.media.mediumSquare.link)

    const onClick = useCallback(() => {
        if (imageSrc) {
            trackFanreelClickImage(imageSrc)
        }
        _onClick?.(item, index)
    }, [item, imageSrc, _onClick, index])

    if (loading) {
        return (
            <AspectRatio {...styles.tileAspectRatio}>
                <Skeleton data-testid="fanreel-tile-skeleton" />
            </AspectRatio>
        )
    }

    return (
        <Button
            variant="tile"
            {...styles.tileContainer}
            data-testid="fanreel-tile"
            onClick={onClick}
        >
            <AspectRatio {...styles.tileAspectRatio}>
                <Image {...styles.tileImage} loading={lazy ? 'lazy' : 'eager'} src={imageSrc} />
            </AspectRatio>
            <Flex {...styles.tileOverlay}>
                <Text>
                    <FormattedMessage id="fanreel.tile.title" defaultMessage="Shop now" />
                </Text>
                <RoundPlusIcon boxSize={12} />
                {!!item.source.likeCount && (
                    <Flex gap={1} alignItems="center">
                        <HeartIcon boxSize={4} />
                        <Text>
                            <FormattedMessage
                                id="fanreel.tile.likeCount"
                                defaultMessage="{likeCount, plural, one {# like} other {# likes}}"
                                values={item.source}
                            />
                        </Text>
                    </Flex>
                )}
            </Flex>
        </Button>
    )
}

FanreelTile.propTypes = {
    index: PropTypes.number,
    item: PropTypes.object,
    lazy: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func
}

export default FanreelTile
