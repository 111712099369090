import React from 'react'
import {IconRow} from './icon-row'
import {useIntl} from 'react-intl'
import useOcapiSite from '../../hooks/useOcapiSite'

export const ShippingIcons = (props) => {
    const intl = useIntl()
    const {shippingMethods} = useOcapiSite()

    if (!shippingMethods?.length) {
        return null
    }

    return (
        <IconRow
            {...props}
            icons={shippingMethods}
            title={intl.formatMessage({
                id: 'shipping-icons.ship_with',
                defaultMessage: 'We ship with:'
            })}
        />
    )
}

export default ShippingIcons
