import {Box, Flex, Link} from '@chakra-ui/react'
import HybridLink from '../hybrid-link'
import {CONTACT_US_HREF, CONTACT_LANDING_HREF, HYBRID_CUSTOMER_SERVICE} from '../../constants'
import {QuestionMarkIcon} from '../icons'
import {FormattedMessage} from 'react-intl'
import LocaleSelector from '../locale-selector'
import useOcapiSite from '../../hooks/useOcapiSite'
import React from 'react'
import useCmsContent from '../cms-content/hooks/use-cms-content'
import {useGetHeaderLinks} from '../../utils/utils'
import useMultiSite from '../../hooks/use-multi-site'

/**
 * Renders application links on mobile drawer bellow the dynamic portion
 */
const DrawerApplicationLinks = () => {
    const {buildUrl} = useMultiSite()
    const {kindlyChatEnabled: chatEnabled} = useOcapiSite().preferences
    const helpLink = chatEnabled ? CONTACT_LANDING_HREF : CONTACT_US_HREF

    const {data: headerLinksTemplate} = useCmsContent('header-links')
    const headerLinks = useGetHeaderLinks(headerLinksTemplate)

    return (
        <Flex
            flexDirection="column"
            alignItems="flex-start"
            gap={2}
            padding={4}
            borderColor="stokkeGray.mid"
        >
            <Box padding={2}>
                <HybridLink page={HYBRID_CUSTOMER_SERVICE} to={helpLink} size="sm">
                    <QuestionMarkIcon boxSize={4} marginInlineEnd={2} />
                    <FormattedMessage id="global.help" defaultMessage="Help" />
                </HybridLink>
            </Box>
            {headerLinks?.map((item, index) => {
                const svg = item.setupimage?.image ? window.atob(item.setupimage?.image) : null
                return (
                    <Box padding={2} key={'drawer-link-' + index}>
                        <Link
                            size="sm"
                            href={item.type === 'Link' ? `${buildUrl(item.link)}` : item.link}
                            display="flex"
                            alignItems="center"
                            _hover={{
                                path: {
                                    stroke: 'white',
                                    transition: '.5s'
                                }
                            }}
                        >
                            {!!svg && (
                                <Box
                                    dangerouslySetInnerHTML={{__html: svg}}
                                    paddingRight={1.5}
                                ></Box>
                            )}
                            {item.label}
                        </Link>
                    </Box>
                )
            })}
            <Box padding={2}>
                <LocaleSelector />
            </Box>
        </Flex>
    )
}

export default DrawerApplicationLinks
