/* Nonce context */
import {useQuery} from '@tanstack/react-query'
import {useServerContext} from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'

const useNonce = () => {
    const {data: nonce} = useQuery(
        ['nonce'],
        async () => {
            const {default: crypto} = await import('crypto')
            return crypto.randomBytes(16).toString('hex')
        },
        // must be computed on the server and never changed after that or the nonce will be invalid
        {
            networkMode: 'always',
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: false,
            retryOnMount: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
        }
    )

    // expose the nonce to the server context so helmet config can use it in ssr
    const {res} = useServerContext()
    if (res) {
        res.locals.nonce = nonce
    }

    return nonce
}

export default useNonce
