export default {
    baseStyle: {
        flexContainer: {
            width: '100%',
            justifyContent: 'space-between',
            padding: 4,
            gap: 4
        },
        alignment: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        text: {
            minWidth: 0,
            noOfLines: 1,
            textStyle: 'Light Medium'
        },
        image: {
            width: '4.438rem',
            height: '4.438rem',
            objectFit: 'cover'
        }
    },
    variants: {
        center: {
            alignment: {
                alignItems: 'center'
            }
        },
        right: {
            alignment: {
                alignItems: 'flex-end'
            }
        }
    }
}
