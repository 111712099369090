import {useGoogleMapsApiLoader} from '../contexts'
import {useEffect, useState} from 'react'
import useAsync from '../../commerce-api/hooks/useAsync'

/**
 * Create google maps place autocomplete widget
 * @param {google.maps.places.AutocompleteOptions} [opts] see <a href="https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions">docs</a>
 * @returns {{ref: (HTMLInputElement) => void, autocomplete: google.maps.places.Autocomplete, place: google.maps.places.PlaceResult}}
 */
export default function useAutocomplete(opts) {
    const getGoogleMapsAPI = useGoogleMapsApiLoader()
    const [autocomplete, setAutocomplete] = useState(
        /** @type google.maps.places.Autocomplete */ null
    )
    const [ref, setRef] = useState(/** @type HTMLInputElement */ null)
    const [place, setPlace] = useState(/** @type google.maps.places.PlaceResult */ null)
    const [inputWasFocused, setInputWasFocused] = useState(false)
    const {data: googleMapsAPI, execute} = useAsync(getGoogleMapsAPI)

    // load google maps api when focused
    useEffect(() => {
        if (inputWasFocused) {
            execute()
        }
    }, [execute, inputWasFocused])

    // create the autocomplete widget
    useEffect(() => {
        if (!googleMapsAPI) return

        const autocomplete = new googleMapsAPI.places.Autocomplete(ref)
        setAutocomplete(autocomplete)
        const listener = autocomplete.addListener('place_changed', () => {
            setPlace(autocomplete.getPlace())
        })

        // Use MutationObserver to detect changes to the 'autocomplete' attribute
        const observer = new MutationObserver(checkAutocompleteAttribute)
        observer.observe(ref, {attributes: true})

        function checkAutocompleteAttribute() {
            // Check if the 'autocomplete' attribute is 'off' and then set it to 'gmaps-address-line1'
            if (ref.getAttribute('autocomplete') === 'off') {
                // fix for disabling browser autocomplete since chrome doesn't respect autocomplete=off
                ref.setAttribute('autocomplete', 'gmaps-address-line1')
                // no need to observe further
                observer.disconnect()
            }
        }

        checkAutocompleteAttribute()

        return () => {
            listener.remove()
            observer.disconnect()
        }
    }, [googleMapsAPI, ref])

    // Apply options when changed or autocomplete is initialized
    useEffect(() => {
        if (autocomplete && opts) {
            autocomplete.setOptions(opts)
        }
    }, [autocomplete, opts])

    // wait for focus to initialize the autocomplete
    useEffect(() => {
        if (!ref) return

        const focusListener = () => {
            setInputWasFocused(true)
        }
        ref.addEventListener('focus', focusListener, {
            capture: true,
            once: true,
            passive: true
        })
        return () => {
            ref.removeEventListener('focus', focusListener)
        }
    }, [ref])

    return {autocomplete, ref: setRef, place}
}
