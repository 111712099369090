import PropTypes from 'prop-types'
import React from 'react'
import RecommendedAccessories from '../../components/recommended-accessories'
import {useProduct} from '@salesforce/commerce-sdk-react'

/**
 * Displays accessories recommendations for a product
 *
 * @param {string} pid - Product ID
 *
 * @example
 *   <pwa-widget-accessories pid="101001">
 *     $include(...)$
 *   </pwa-widget-product>
 */
const WidgetAccessories = ({pid}) => {
    const {data: product, error} = useProduct(
        {
            parameters: {id: pid, select: '(recommendations.(**),c_product-type)'}
        },
        {enabled: !!pid}
    )
    //TODO v3 to check if the new api handles the errors

    // should fail silently without an alert
    if (!pid || error) {
        return null
    }

    // prevent initial flash of undefined product
    if (!product) {
        return null
    }

    return <RecommendedAccessories products={[product]} showTitle={false} variant="widget" />
}

WidgetAccessories.propTypes = {
    pid: PropTypes.string
}

export default WidgetAccessories
