export default {
    baseStyle: {
        container: {
            direction: {base: 'column', lg: 'row'},
            columnGap: 8,
            rowGap: {base: 5, lg: 4},
            flexWrap: {base: 'nowrap', lg: 'wrap'},
            alignItems: 'flex-start'
        }
    },
    variants: {
        pdp: {
            container: {
                direction: {base: 'row-reverse', lg: 'row'},
                justifyContent: {base: 'space-between', lg: 'start'},
                paddingBottom: {base: 2, lg: 0}
            }
        }
    }
}
