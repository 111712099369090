import {Button} from '@chakra-ui/react'
import React, {useCallback} from 'react'
import {FormattedMessage} from 'react-intl'
import {useCurrentCustomer} from '../../hooks/use-current-customer'

import {StorefinderIcon} from '../icons'
import HybridLink from '../hybrid-link'

import {
    SIGN_IN_HREF,
    ACCOUNT_HREF,
    STORE_LOCATOR_HREF,
    HYBRID_ACCOUNT,
    HYBRID_AUTH,
    HYBRID_STORE_LOCATOR
} from '../../constants'

import PropTypes from 'prop-types'
import CompositeAccountIcon from '../icons/composite-account-icon'

/**
 * Renders the mobile footer actions in the drawer menu
 */
const DrawerFooterActions = ({onMyAccountClick}) => {
    const {data: customer} = useCurrentCustomer()

    const onLoginClick = useCallback(
        (event) => {
            event.stopPropagation()
            if (onMyAccountClick) {
                event.preventDefault()
                onMyAccountClick()
            }
        },
        [onMyAccountClick]
    )

    return (
        <>
            <Button
                variant="navigationFooter"
                as={HybridLink}
                {...(customer.isRegistered
                    ? {
                          page: HYBRID_ACCOUNT,
                          to: ACCOUNT_HREF,
                          hybridHref: '/my-stokke'
                      }
                    : {
                          page: HYBRID_AUTH,
                          to: SIGN_IN_HREF,
                          onClick: onLoginClick
                      })}
            >
                <CompositeAccountIcon isActive={customer.isRegistered} />
                {customer.isRegistered ? (
                    <FormattedMessage id="drawer_menu.link.account" defaultMessage="My Stokke" />
                ) : (
                    <FormattedMessage id="drawer_menu.link.sign_in" defaultMessage="Sign In" />
                )}
            </Button>
            <Button
                variant="navigationFooter"
                as={HybridLink}
                page={HYBRID_STORE_LOCATOR}
                to={STORE_LOCATOR_HREF}
                isExternal
            >
                <StorefinderIcon boxSize={4} />
                <FormattedMessage id="drawer_menu.link.store_locator" defaultMessage="Stores" />
            </Button>
        </>
    )
}

DrawerFooterActions.propTypes = {
    /**
     * Function to call when the sign-in button is clicked
     */
    onMyAccountClick: PropTypes.func
}

export default DrawerFooterActions
