export default {
    baseStyle: ({colorScheme, theme: {colors}}) => ({
        title: {
            textStyle: 'Regular 2XLarge',
            color: colors[colorScheme]?.bright ? `${colorScheme}.dark` : 'stokkeCore.black'
        }
    }),
    variants: {
        addToCart: {
            title: {
                alignSelf: 'flex-start',
                paddingBottom: 2,
                borderBottom: '2px'
            }
        }
    }
}
