export default {
    variants: {
        localeSelector: ({theme}) => ({
            list: {
                // disable base styles
                boxShadow: null,
                minW: null,
                py: null,
                borderRadius: null,
                // custom styles
                minWidth: {base: '21.4375rem', lg: '8.125rem'},
                borderColor: {base: 'stokkeGray.mid', lg: 'stokkeGray.bright'}
            },
            item: {
                // disable base styles
                px: null,
                py: null,
                _active: {
                    bg: null
                },
                _focus: {
                    bg: null
                },
                _expanded: {
                    bg: null
                },
                // custom styles
                ...theme.textStyles['Badge Regular'],
                justifyContent: 'space-between',
                paddingInline: {base: 4, lg: 3},
                paddingBlock: {base: 4, lg: 2},
                '@media(pointer: fine)': {
                    _focus: {
                        bg: 'stokkeGray.bright'
                    }
                }
            }
        }),
        menu: ({theme}) => ({
            list: {
                // disable base styles
                boxShadow: null,
                minW: null,
                py: null,
                borderRadius: null,
                // custom styles
                bg: 'stokkeCore.white',
                width: '20rem',
                borderColor: 'stokkeGray.bright',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,

                // create arrow using pseudo element
                _before: {
                    content: '""',
                    position: 'absolute',
                    zIndex: 1,
                    width: '8px',
                    height: '8px',
                    right: '4px',
                    top: '-3px',
                    transform: 'rotate(45deg)',
                    bg: 'stokkeCore.white',
                    border: '1px',
                    borderColor: 'stokkeGray.bright',
                    clipPath: "path('M0,0 L0,8 L8,0 z')"
                }
            },
            item: {
                // disable base styles
                px: null,
                py: null,
                _active: {
                    bg: null
                },
                _focus: {
                    bg: null
                },
                _expanded: {
                    bg: null
                },
                // custom styles
                ...theme.textStyles['Regular Medium'],
                padding: 2,
                '@media(pointer: fine)': {
                    _focus: {
                        bg: 'stokkeGray.bright'
                    },
                    _hover: {
                        bg: 'stokkeGray.bright'
                    }
                },
                '.chakra-menu__icon-wrapper': {
                    display: 'flex'
                },
                '.chakra-menu__icon': {
                    boxSize: 4
                }
            },
            divider: {
                my: null,
                opacity: null,
                borderColor: 'stokkeGray.bright'
            }
        })
    }
}
