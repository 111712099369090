import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Box} from '@chakra-ui/react'
import useCmsLazyImgLoad from '../cms-content/hooks/use-cms-lazy-img-load'

/**
 * A component that renders plain html content and supports lazy loading of images.
 */
const SimpleCmsContent = ({content, ...htmlProps}) => {
    const [root, setRoot] = useState()

    useCmsLazyImgLoad(root, content)

    return (
        <Box
            className="simple-cms-content"
            data-testid="simple-cms-content"
            {...htmlProps}
            ref={setRoot}
            dangerouslySetInnerHTML={{__html: content}}
        />
    )
}

SimpleCmsContent.propTypes = {
    content: PropTypes.string
}

export default SimpleCmsContent
