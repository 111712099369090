import PropTypes from 'prop-types'
import React from 'react'
import {Box} from '@chakra-ui/react'

/**
 * A component that renders plain html content as is. Does not provide styling.
 */
const PlainContent = ({content, ...htmlProps}) => (
    <Box className="simple-content" {...htmlProps} dangerouslySetInnerHTML={{__html: content}} />
)

PlainContent.propTypes = {
    content: PropTypes.string
}

export default PlainContent
