import {Text} from '@chakra-ui/react'
import React from 'react'
import {FormattedMessage, FormattedNumber} from 'react-intl'
import {useOrderSummary} from './order-summary-provider'

const OrderSummaryTax = () => {
    const basket = useOrderSummary()

    return (
        <>
            <Text>
                <FormattedMessage defaultMessage="tax included" id="global.taxes_included" />
            </Text>
            {basket.taxTotal != null ? (
                <Text>
                    <FormattedNumber
                        value={basket.taxTotal}
                        style="currency"
                        currency={basket.currency}
                    />
                </Text>
            ) : (
                <Text color="stokkeGray.accessibleText">--</Text>
            )}
        </>
    )
}

export default OrderSummaryTax
