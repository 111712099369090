import {useEffect} from 'react'
import {useKindlyChat} from '.'

// This component listens for the chat bubble button to be closed and dispatches an event when it happens
// handles the case where chat is closed by double click on the "X" close button
const KindlyCloseChatNotifier = () => {
    const {opened} = useKindlyChat()

    // register a mutation observer to detect when the chat container is removed and dispatch an event
    useEffect(() => {
        if (!opened) {
            return
        }

        const observer = new MutationObserver((mutations) => {
            if (
                mutations.some(
                    (mutation) =>
                        mutation.type === 'childList' &&
                        Array.from(mutation.removedNodes || []).some(
                            (node) => node.id === 'chatContainer'
                        )
                )
            ) {
                document.dispatchEvent(
                    new CustomEvent('kindly:ui', {
                        detail: {type: 'close-chatbubble', source: 'user'}
                    })
                )
            }
        })
        observer.observe(window.kindlyChat.shadowRoot, {
            subtree: true,
            childList: true
        })

        return () => {
            observer.disconnect()
        }
    }, [opened])

    return null
}

export default KindlyCloseChatNotifier
