import {checkRequiredParameters, createSapiFetch} from './utils'

class StorefrontAPI {
    constructor(config = {}) {
        this.fetch = createSapiFetch(config)
    }

    /**
     * @param {{paramters: {slotId: string, context?: string}}} args
     * @returns {Promise<string>}
     */
    async getSlot(...args) {
        const required = ['slotId']

        let requiredParametersError = checkRequiredParameters(args[0], required)
        if (requiredParametersError) {
            return requiredParametersError
        }
        const {slotId, context} = args[0].parameters

        const slotResult = await this.fetch(
            `slots?${new URLSearchParams({
                slots: slotId,
                ...(context && {context})
            }).toString()}`,
            'GET',
            args,
            'getSlot',
            null
        )

        return slotResult?.trim()
    }

    /**
     * @param {Object} args
     * @return {Promise<object>}
     */
    async getGeoIpData(...args) {
        const sapiResponse = await this.fetch('geoip', 'GET', args, 'getGeoIpData')
        let result
        try {
            result = JSON.parse(sapiResponse).geoData
        } catch {} // eslint-disable-line no-empty
        return result
    }
}

export default StorefrontAPI
