import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
    Flex,
    IconButton,
    useBreakpointValue
} from '@chakra-ui/react'
import {useIntl} from 'react-intl'
import {CloseIcon, SearchIcon} from '../icons'
import React, {useCallback, useState} from 'react'
import PropTypes from 'prop-types'
import Search from '../search'
import useVisualViewportSize from '../../hooks/use-visual-viewport'
import {useHeaderStyles} from './index'

const HeaderSearch = ({onShow}) => {
    const intl = useIntl()
    const styles = useHeaderStyles()
    const isDesktop = useBreakpointValue({lg: true})
    // resize the search overlay with soft keyboard
    const visualViewportSize = useVisualViewportSize()
    const [isOpen, setIsOpen] = useState(false)
    const showSearch = useCallback(() => {
        setIsOpen(true)
        onShow?.()
    }, [onShow])
    const hideSearch = useCallback(() => {
        setIsOpen(false)
    }, [])

    return (
        <>
            <IconButton
                icon={<SearchIcon {...styles.icon} data-testid="header-search-icon" />}
                aria-label={intl.formatMessage({
                    id: 'header.button.assistive_msg.search',
                    defaultMessage: 'Show search toolbar'
                })}
                variant="unstyled"
                {...styles.searchButton}
                onClick={showSearch}
            />

            <Drawer
                variant="search"
                placement="top"
                onClose={hideSearch}
                isOpen={isOpen}
                isFullHeight={false}
            >
                <DrawerOverlay />
                <DrawerContent
                    maxHeight={visualViewportSize.height ? `${visualViewportSize.height}px` : null}
                >
                    <DrawerBody>
                        <Flex direction="column" gap={2} alignItems="end">
                            <CloseIcon
                                boxSize={4}
                                onClick={hideSearch}
                                position={{lg: 'absolute'}}
                                top={{lg: 8}}
                                right={{lg: 6}}
                                cursor="pointer"
                            />
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'global.search',
                                    defaultMessage: 'Search'
                                })}
                                variant="flushed"
                                relative={!isDesktop}
                                hideSearch={hideSearch}
                                leftIcon={<SearchIcon boxSize={4} />}
                            />
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

HeaderSearch.propTypes = {
    onShow: PropTypes.func
}

export default HeaderSearch
