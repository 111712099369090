import {createContext, useContext} from 'react'

/**
 * Provider and associated hook for accessing the Google maps API in React components.
 */
export const GtmConfigContext = createContext(null)
export const GtmConfigProvider = GtmConfigContext.Provider

/**
 * @returns {{gtmId: string}|null}
 */
export const useGtmConfig = () => {
    return useContext(GtmConfigContext)
}
