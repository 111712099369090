/**
 * Gets address identifier that should be unique through all customer addresses.
 * @param {Object} - API reference that represents customer information
 * @returns {string} - address identifier that can be used for address creation
 * @return {string} the address identifier that should be used as unique identifier for customer addresses
 */
export const getAddressIdentifier = (customer, addressId) => {
    let suffix = ''
    if (customer.addresses) {
        let sameAddressIndex
        do {
            sameAddressIndex = customer.addresses.find(
                (addr) => addr.addressId === (suffix === '' ? addressId : addressId + '_' + suffix)
            )
            if (sameAddressIndex) {
                suffix = 1 + Number(suffix)
            }
        } while (sameAddressIndex)
    }
    return suffix === '' ? addressId : addressId + '_' + suffix
}
