import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Box, omitThemingProps, useStyleConfig} from '@chakra-ui/react'
import useCmsLazyImgLoad from '../cms-content/hooks/use-cms-lazy-img-load'

/**
 * A component that renders plain html content. Provides default styling and
 * allows overriding the styling via regular chakra styling props.
 * Support also textStyle prop to apply predefined text styles.
 * Also supports lazy loading of images.
 */
const StyledContent = (props) => {
    const styles = useStyleConfig('StyledContent', props)
    const {content, ...ownProps} = omitThemingProps(props)
    const [root, setRoot] = useState()
    useCmsLazyImgLoad(root)

    return (
        <Box
            {...styles}
            className="styled-content"
            {...ownProps}
            ref={setRoot}
            dangerouslySetInnerHTML={{__html: content}}
        />
    )
}

StyledContent.propTypes = {
    content: PropTypes.string
}

export default StyledContent
