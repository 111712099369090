import {RATING_MAX_STARS} from '../../constants'

export const convertBVRatingValue = (bvValue, bvRatingRange) =>
    bvRatingRange ? (bvValue ? (RATING_MAX_STARS * bvValue) / bvRatingRange : bvValue) : bvValue

/**
 * Checks if product should have BV rating (still the product might not have been imported in BV yet)
 * @param {Object} product - product loaded through shopperProducts.getProduct or shopperProducts.getProducts and has c_product-type property
 * @returns {boolean}
 */
export const hasBVProductRating = (product) =>
    !['Spare part', 'Engraving'].includes(product?.['c_product-type'])
