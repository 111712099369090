import {useMemo} from 'react'
import {EMOTIONAL_GALLERY_IMAGE_TYPE, MAIN_IMAGES_IMAGE_TYPE} from '../constants'

/**
 * Merge image groups from master and variant by calling merger for every viewType
 * @param {Object} master
 * @param {Object} variant
 * @param {Function} merger
 * @return {Array}
 */
const mergeImageGroups = (master, variant, merger) => {
    const imageGroupsByViewType = {}
    master.imageGroups?.forEach((imageGroup) => {
        if (!imageGroupsByViewType[imageGroup.viewType]) {
            imageGroupsByViewType[imageGroup.viewType] = {
                viewType: imageGroup.viewType,
                master: [],
                variant: []
            }
        }
        imageGroupsByViewType[imageGroup.viewType].master.push(imageGroup)
    })
    variant?.imageGroups?.forEach((imageGroup) => {
        if (!imageGroupsByViewType[imageGroup.viewType]) {
            imageGroupsByViewType[imageGroup.viewType] = {
                viewType: imageGroup.viewType,
                master: [],
                variant: []
            }
        }
        imageGroupsByViewType[imageGroup.viewType].variant.push(imageGroup)
    })
    return Object.values(imageGroupsByViewType).flatMap(
        ({viewType, master: masterImageGroups, variant: variantImageGroups}) =>
            merger(viewType, masterImageGroups, variantImageGroups)
    )
}

export const unifyProduct = (product, variant) => {
    if (!product) return product
    if (!product.type) {
        throw new Error('Product should have type property')
    }
    // fallback mechanism in case unifyProduct is used twice like it is in the PSDP
    // in that case type object contains variant true and throws an error
    if (product.type.variant && !product.type.master) {
        throw new Error('Variant product should be passed as second argument')
    }
    if (product.type.master) {
        if (!variant) {
            return product
        }
        if (!variant.type.variant) {
            throw new Error('Variant product is not variant')
        }
        return {
            ...product,
            ...variant,
            type: {
                ...product.type,
                ...variant.type
            },

            // Image groups are handled separately
            imageGroups: mergeImageGroups(
                product,
                variant,
                (viewType, masterImageGroups, variantImageGroups) => {
                    switch (viewType) {
                        case MAIN_IMAGES_IMAGE_TYPE:
                            return [...masterImageGroups, ...variantImageGroups]
                        case EMOTIONAL_GALLERY_IMAGE_TYPE:
                            return masterImageGroups.filter(
                                ({variationAttributes}) => !variationAttributes?.length
                            )
                        default:
                            return variantImageGroups?.length
                                ? variantImageGroups
                                : masterImageGroups
                    }
                }
            ),

            // Variant only props
            // TODO: Extra Main Image
            // TODO: Extra Main Image 2

            // MyRegistry uses the variant name
            variant: {
                name: variant.name
            },

            // Master props
            name: product.name,
            longDescription: variant.longDescription
                ? variant.longDescription
                : product.longDescription,
            // Do not use master product promotions. SFCC engine is smart enough to get the right ones for each variant
            // Note: if this changes in the future, we need to update the usage of useProductWithCustomerPromotions hook
            productPromotions: variant.productPromotions,
            // TODO: Whats Included Image
            c_masterSubType: product.c_masterSubType,
            c_faqContent: product.c_faqContent,
            // TODO: 3D configurator (the highest priority)
            c_productLinkVariationName: product.c_productLinkVariationName,
            c_extendedProductWarranty: product.c_extendedProductWarranty,
            c_suitableForWeightUpToKg: product.c_suitableForWeightUpToKg,
            c_suibtableForWeightUpToLbs: product.c_suibtableForWeightUpToLbs,
            c_suitableFromAgeinMonths: product.c_suitableFromAgeinMonths,
            c_suitableToAgeinMonths: product.c_suitableToAgeinMonths

            // Master and variant props
            // TODO: Certificate description
            // TODO: Product compatibility - seems it's taken from recommender
        }
    }
    return product
}

export const useUnifiedProduct = (product, variant) => {
    return useMemo(() => unifyProduct(product, variant), [product, variant])
}
