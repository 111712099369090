import {checkRequiredParameters, createOcapiFetch, createReportingFetch} from './utils'
import {rebuildPathWithParams} from '../utils/url'
import {app as appConfig} from '../../config/default'

class OcapiCustomObjects {
    constructor(config) {
        this.fetch = createOcapiFetch(config)
    }

    async getCustomers(...args) {
        const required = ['email']
        let requiredParametersError = checkRequiredParameters(args[0], required)
        if (requiredParametersError) {
            return requiredParametersError
        }
        const {email} = args[0].parameters
        return await this.fetch(
            `custom_objects/ocapi/customers?email=${encodeURIComponent(email)}`,
            'GET',
            args
        )
    }

    async subscribeToNewsletter(...args) {
        const required = ['email']
        let requiredParametersError = checkRequiredParameters(args[0], required)
        if (requiredParametersError) {
            return requiredParametersError
        }
        return await this.fetch(
            rebuildPathWithParams('custom_objects/ocapi/newsletter', args[0].parameters),
            'GET',
            args
        )
    }

    async reportCheckout(...args) {
        const required = ['checkotStep']
        let requiredParametersError = checkRequiredParameters(args[0], required)
        if (requiredParametersError) {
            return requiredParametersError
        }
        const checkotStep = args[0].parameters.checkotStep
        let checkoutName = ''
        if (checkotStep === 1) {
            checkoutName = 'ShippingAddress'
        } else if (checkotStep === 2) {
            checkoutName = 'ShippingMethod'
        } else if (checkotStep === 3) {
            checkoutName = 'CheckoutMethod'
        }
        const responseData = await this.fetch(
            `custom_objects/ocapi/reporting?step=${checkotStep}&name=${checkoutName}`,
            'GET',
            args
        )
        const reportURL = responseData?.c_result?.url
        if (reportURL) {
            const apiConfig = {...appConfig.commerceAPI, proxy: undefined}
            const fetch = createReportingFetch(apiConfig)
            fetch(reportURL)
        }
    }

    async getProductSetPrice(...args) {
        const required = ['id', 'selectedSetProducts']
        let requiredParametersError = checkRequiredParameters(args[0], required)
        if (requiredParametersError) {
            return requiredParametersError
        }
        const resp = await this.fetch(
            rebuildPathWithParams('custom_objects/ocapi/setprices', args[0].parameters),
            'GET',
            args
        )
        return resp && resp.c_result ? resp.c_result : {}
    }

    async basketValidation(...args) {
        const resp = await this.fetch(
            rebuildPathWithParams('custom_objects/ocapi/basket', args[0].parameters),
            'GET',
            args
        )
        return resp && resp.c_result ? resp.c_result : {}
    }
}

export default OcapiCustomObjects
