import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import useOcapiSite from '../../hooks/useOcapiSite'
import {useLocation} from 'react-router-dom'
import {removeSiteLocaleFromPath} from '../../utils/url'
import PropTypes from 'prop-types'
import useEffectEvent from '../../hooks/use-effect-event'
import KindlyChatTracking from './tracking'
import KindlyCloseChatNotifier from './close-chat-notifier'
import {API_ERROR_MESSAGE} from '../../constants'
import {useToast} from '@chakra-ui/react'
import {useIntl} from 'react-intl'

const KindlyChatContext = React.createContext()

export const KindlyChatProvider = ({children}) => {
    const {formatMessage} = useIntl()
    const location = useLocation()
    const {kindlyChatEnabled, kindlyChatExcludedPages, kindlyChatBotKey} =
        useOcapiSite().preferences || {}
    const [isLoadable, setIsLoadable] = useState(false)
    const [isScriptLoaded, setIsScriptLoaded] = useState(false)
    const [isKindlyLoaded, setIsKindlyLoaded] = useState(false)
    const scriptRef = useRef(null)
    const [isVisible, setIsVisible] = useState(true)
    const [isChatOpen, setIsChatOpen] = useState(false)
    const [isScriptLoadError, setIsScriptLoadError] = useState(false)
    const [isChatOpenRequested, setIsChatOpenRequested] = useState(false)
    const [isBubbleHidden, setIsBubbleHidden] = useState(false)
    const [bottomOffset, setBottomOffset] = useState(0)
    const toast = useToast()

    const excludedPages = useMemo(
        () => kindlyChatExcludedPages?.split(',').map((page) => page.trim()) || [],
        [kindlyChatExcludedPages]
    )

    const isEnabled = useMemo(() => {
        if (!kindlyChatEnabled || !kindlyChatBotKey) {
            return false
        }
        if (!excludedPages.length) {
            return true
        }
        const pathWithoutLocale = removeSiteLocaleFromPath(location.pathname)
        return !excludedPages.some((path) => pathWithoutLocale.startsWith(path))
    }, [excludedPages, kindlyChatBotKey, kindlyChatEnabled, location.pathname])

    // request loading when enabled
    useEffect(() => {
        if (isEnabled) {
            setIsLoadable(true)
        }
    }, [isEnabled])

    // update bubble hidden state
    useEffect(() => {
        if (isKindlyLoaded) {
            if (!isEnabled || isBubbleHidden) {
                window.kindlyChat.hideBubble()
            } else {
                window.kindlyChat.showBubble()
            }
        }
    }, [isBubbleHidden, isEnabled, isKindlyLoaded])

    // close chat when disabled or hidden
    useEffect(() => {
        if (isKindlyLoaded) {
            if (!isEnabled || !isVisible) {
                window.kindlyChat.closeChat()
            }
        }
    }, [isEnabled, isKindlyLoaded, isVisible])

    // Load kindly chat when requested
    const onLoadKindlyChat = useEffectEvent(() => {
        if (scriptRef.current) {
            // already loaded or loading
            return
        }

        const script = document.createElement('script')
        script.id = 'kindly-chat'
        script.src = 'https://chat.kindlycdn.com/kindly-chat.js'
        script.setAttribute('data-bot-key', kindlyChatBotKey)
        script.setAttribute('data-shadow-dom', '1')
        script.defer = true
        script.async = true
        script.addEventListener('load', () => {
            setIsScriptLoaded(true)
        })
        script.addEventListener('error', () => {
            console.error('Failed to load kindly chat')
            setIsScriptLoadError(true)
        })
        // kindly options
        // @see https://docs.kindly.ai/api/chat#II4st
        window.kindlyOptions = {
            bubbleHidden: isBubbleHidden,
            chatbubble: {
                autopopup: false
            },
            // position between chakra banner and overlay so overlays can cover it
            // @see https://chakra-ui.com/docs/styled-system/theme#z-index-values
            zIndex: 1250
        }
        scriptRef.current = script

        document.body.appendChild(script)
    })
    useEffect(() => {
        if (!isLoadable) {
            return
        }

        const kindlyLoadListener = () => {
            setIsKindlyLoaded(true)
        }
        document.addEventListener('kindly:load', kindlyLoadListener)
        onLoadKindlyChat()
        return () => {
            document.removeEventListener('kindly:load', kindlyLoadListener)
        }
    }, [isLoadable, onLoadKindlyChat])

    // register event listener to track chat open state
    const onKindlyUIEvent = useEffectEvent((event) => {
        switch (event.detail.type) {
            case 'open-chatbubble':
                setIsChatOpen(true)
                break
            case 'close-chatbubble':
                setIsChatOpen(false)
                break
            case 'end-chat':
                setIsChatOpen(false)
                break
        }
    })
    useEffect(() => {
        document.addEventListener('kindly:ui', onKindlyUIEvent)
        return () => {
            document.removeEventListener('kindly:ui', onKindlyUIEvent)
        }
    }, [onKindlyUIEvent])

    // apply the bottom offset
    useEffect(() => {
        if (isKindlyLoaded && bottomOffset) {
            // workaround for kindly not providing dynamic offset - use custom style injected into the shadow dom
            // that moves the button and chat container by their semi-stable ids
            const styles = document.createElement('style')
            styles.innerHTML = `
              #ChatButtonContainer { 
                transform: translateY(-${
                    typeof bottomOffset === 'number' ? `${bottomOffset}px` : bottomOffset
                }) 
              }
            `
            window.kindlyChat.shadowRoot.appendChild(styles)
            return () => {
                styles.remove()
            }
        }
    }, [isKindlyLoaded, bottomOffset])

    // open chat when requested
    const onChatOpenRequested = useEffectEvent((pathname, isKindlyLoaded, isScriptLoadError) => {
        if (pathname !== location.pathname) {
            return
        }
        if (isKindlyLoaded) {
            window.kindlyChat.openChat()
        } else if (isScriptLoadError) {
            // notify only in this case, as this is direct result of user action, otherwise it's a silent failure
            toast({
                title: formatMessage(API_ERROR_MESSAGE),
                status: 'error'
            })
        }
    })
    useEffect(() => {
        if (!isEnabled || !isChatOpenRequested) {
            return
        }
        onChatOpenRequested(isChatOpenRequested, isKindlyLoaded, isScriptLoadError)
    }, [isChatOpenRequested, isEnabled, isKindlyLoaded, isScriptLoadError, onChatOpenRequested])

    // clear the request to open chat when it's opened, so it can be requested again
    useEffect(() => {
        if (isChatOpen) {
            setIsChatOpenRequested(false)
        }
    }, [isChatOpen, isChatOpenRequested])

    // clear the request to open chat when navigating to a new page
    useEffect(() => {
        setIsChatOpenRequested(false)
    }, [location.pathname])

    const showChat = useCallback(() => {
        setIsVisible(true)
        setIsBubbleHidden(false)
    }, [])

    const hideChat = useCallback(() => {
        setIsVisible(false)
        setIsBubbleHidden(true)
    }, [])

    const openChat = useCallback(() => {
        setIsVisible(true)
        setIsChatOpenRequested(location.pathname)
    }, [location.pathname])

    const ctx = useMemo(
        () => ({
            // report as disabled in case of script load error
            enabled: isEnabled && !isScriptLoadError,
            loaded: isScriptLoaded && isKindlyLoaded,
            visible: isEnabled && isScriptLoaded && isKindlyLoaded && isVisible,
            opened: isChatOpen,
            show: showChat,
            hide: hideChat,
            open: openChat,
            setBottomOffset
        }),
        [
            isChatOpen,
            isEnabled,
            isKindlyLoaded,
            isScriptLoaded,
            isScriptLoadError,
            isVisible,
            hideChat,
            openChat,
            showChat
        ]
    )

    return (
        <KindlyChatContext.Provider value={ctx}>
            {children}
            <KindlyChatTracking />
            <KindlyCloseChatNotifier />
        </KindlyChatContext.Provider>
    )
}

KindlyChatProvider.propTypes = {
    children: PropTypes.any
}

export const useKindlyChat = () => {
    const ctx = useContext(KindlyChatContext)
    if (!ctx) {
        throw new Error('useKindlyChat must be used within a KindlyChatProvider')
    }
    return ctx
}
