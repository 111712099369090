import React, {useContext, useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useLocation} from 'react-router-dom'
import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    HStack,
    useBreakpointValue
} from '@chakra-ui/react'
import textStyles from '../theme/foundations/textStyles'
import {FormattedMessage} from 'react-intl'
import BonusProduct from '../components/bonus-products-modal/partials/bonus-product'
import Slider from '../components/slider'
import useNestedProduct from './use-nested-product'

/**
 * This is the context for managing the BonusProductsModal.
 * Used in top level App component.
 */
export const BonusProductsModalContext = React.createContext()
export const useBonusProductsModalContext = () => useContext(BonusProductsModalContext)
export const BonusProductsModalProvider = ({children}) => {
    const bonusProductsModal = useBonusProductsModal()
    return (
        <BonusProductsModalContext.Provider value={bonusProductsModal}>
            {children}
            <BonusProductsModal />
        </BonusProductsModalContext.Provider>
    )
}
BonusProductsModalProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export const BonusProductsModal = () => {
    const {isOpen, onClose, data} = useBonusProductsModalContext()
    const {
        headerIcon,
        products,
        bonusProductVariationsMap,
        colorScheme,
        fontColor,
        setSelectedItem,
        ...props
    } = data || {}
    const {NestedProduct} = useNestedProduct()

    const secondProductRef = useRef(null)
    const isCentered = useBreakpointValue({base: false, sm: true})

    if (!isOpen) {
        return null
    }

    // Return if array of products aren't passed as prop
    if (!products?.length) {
        return null
    }

    const removeRestrictedVariantsAndAttributes = (product) => {
        if (!product?.variants) {
            return product
        }

        if (bonusProductVariationsMap) {
            product.variants = product.variants.filter(({productId}) =>
                bonusProductVariationsMap[product.master.masterId].includes(productId)
            )
            product.variationAttributes?.forEach((attribute) => {
                let identifier = attribute.id
                attribute.values = attribute.values.filter((value) => {
                    let hasOrderableVariant = false
                    product?.variants.forEach(({variationValues}) => {
                        hasOrderableVariant =
                            hasOrderableVariant || variationValues[identifier] === value.value
                    })
                    return hasOrderableVariant
                })
            })

            return product
        }
    }

    return (
        <Modal
            variant="bonusOffer"
            size="lg"
            isOpen={isOpen}
            onClose={() => {
                setSelectedItem(undefined)
                onClose?.()
            }}
            isCentered={isCentered}
            colorScheme="stokkeCore"
        >
            <ModalOverlay />
            <ModalContent containerProps={{'data-testid': 'bonus-products-modal'}}>
                <ModalHeader
                    bg={`${colorScheme}.mid`}
                    color={fontColor}
                    textAlign="center"
                    {...textStyles['Regular Medium']}
                >
                    <HStack align="center" justify="center" gap={4}>
                        {headerIcon}
                        <FormattedMessage
                            defaultMessage="Choose your free bonus product."
                            id="modal.bonus.title"
                        />
                    </HStack>

                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <Slider variant="modal">
                        {products?.map((product, index) => (
                            <Box
                                flex="0 0 auto"
                                width="full"
                                maxHeight="full"
                                scrollSnapAlign="start"
                                key={index}
                                ref={index === 1 ? secondProductRef : null}
                                paddingX={{base: 4, lg: 0}}
                            >
                                {/*
                                      The bonus product is based on the product view component which is meant
                                      to display main content product and relies heavily on the current browser location.
                                      To bypass this dependency each displayed product is wrapped in memory router
                                      to have its own isolated history stack where changes to variations can be updated
                                      without affecting each other and the main page content
                                    */}
                                <NestedProduct>
                                    <BonusProduct
                                        bonusProduct={product}
                                        removeRestrictedVariantsAndAttributes={
                                            removeRestrictedVariantsAndAttributes
                                        }
                                        onClose={onClose}
                                        onClick={onClose}
                                        {...props}
                                    />
                                </NestedProduct>
                            </Box>
                        ))}
                    </Slider>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

BonusProductsModal.propTypes = {
    headerIcon: PropTypes.node,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onModalClose: PropTypes.func,
    products: PropTypes.array,
    bonusProductVariationsMap: PropTypes.object,
    colorScheme: PropTypes.string,
    fontColor: PropTypes.string,
    setSelectedItem: PropTypes.func
}

export const useBonusProductsModal = () => {
    const [state, setState] = useState({
        isOpen: false,
        data: null
    })

    const {pathname} = useLocation()
    useEffect(() => {
        setState((state) =>
            state.isOpen
                ? {
                      ...state,
                      isOpen: false
                  }
                : state
        )
    }, [pathname])

    return {
        isOpen: state.isOpen,
        data: state.data,
        onOpen: (data) => {
            setState({
                isOpen: true,
                data
            })
        },
        onClose: () => {
            setState({
                isOpen: false,
                data: null
            })
        }
    }
}
