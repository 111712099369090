import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Code, Skeleton} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import {useSapiSlot} from '../../hooks/useSapi'
import PlainContent from '../plain-content'
import SimpleCmsContent from '../simple-cms-content'
import StyledContent from '../styled-content'
import CMSContent from '../cms-content'
import {TYPE_CONTENT_SLOT} from '../cms-content/hooks/use-cms-content'

/**
 * Loads html content from content slot and render in place.
 */
const ContentSlot = ({
    slotId,
    context,
    showError = true,
    variant = 'simple',
    disableSkeleton,
    ...htmlProps
}) => {
    const {data: content, isLoading: loading, error} = useSapiSlot({parameters: {slotId, context}})

    if (loading) {
        return !disableSkeleton ? (
            <Skeleton height="7.5rem" {...htmlProps} data-testid="content-slot-loading" />
        ) : null
    }

    if (error) {
        if (showError) {
            return (
                <Alert {...htmlProps} status="error">
                    <AlertIcon />
                    <Box>
                        <AlertTitle>
                            Something bad happened while loading <Code>{slotId}</Code>!
                        </AlertTitle>
                        <AlertDescription>{error.toString()}</AlertDescription>
                    </Box>
                </Alert>
            )
        } else {
            console.error(`Error wile loading ${slotId}. ${error.toString()}`, error)
            return null
        }
    }

    if (!content) {
        return null
    }

    switch (variant) {
        case 'plain':
            return <PlainContent {...htmlProps} content={content} />
        case 'cms':
            // do not render CMSContent without content as it'll try to load the slot as cms content
            return content ? (
                <CMSContent
                    {...htmlProps}
                    id={slotId}
                    type={TYPE_CONTENT_SLOT}
                    content={{c_body: content}}
                    showError={showError}
                />
            ) : null
        case 'simple-cms':
            return <SimpleCmsContent {...htmlProps} content={content} />
        default:
            return <StyledContent {...htmlProps} content={content} />
    }
}

ContentSlot.propTypes = {
    /**
     * The slot id
     */
    slotId: PropTypes.string.isRequired,
    /**
     * The slot context could be a category or folder id
     * Atm only category id are supported
     */
    context: PropTypes.string,
    /**
     * Whether to show error message or not, when disabled the component will return null on error
     */
    showError: PropTypes.bool,
    /**
     * The variant to use for rendering the html content:
     * - plain - renders the content as is
     * - simple - renders the content with default styling and lazy loading of images
     * - cms - renders the content as is, lazy loading of images and supports widgets
     * - simple-cms - renders the content as is and lazy loading of images
     */
    variant: PropTypes.oneOf(['plain', 'simple', 'cms', 'simple-cms']),
    disableSkeleton: PropTypes.bool
}

export default ContentSlot
