import React, {useCallback, useMemo, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useForm} from 'react-hook-form'
import {Box, Button, Flex, SimpleGrid, useDisclosure} from '@chakra-ui/react'
import {useCurrentCustomer} from '../../../hooks/use-current-customer'
import {MarkerPin01Icon} from '../../../components/icons'
import LoadingSpinner from '../../../components/loading-spinner'
import {API_ERROR_MESSAGE} from '../../../constants'
import {useCountry} from '../../../hooks'
import useEffectEvent from '../../../hooks/use-effect-event'
import {useToast} from '../../../hooks/use-toast'
import MyStokkeLayoutDefault from '../layouts/default'
import MyStokkeAddressCard from '../partials/address-card'
import MyStokkeAddressForm from '../partials/address-form'
import MyStokkeBackToStokke from '../partials/back-to-stokke'
import MyStokkeFooter from '../partials/footer'
import MyStokkeLayoutEmpty from '../layouts/empty'
import ConfirmationModal from '../../../components/confirmation-modal'
import {
    ShopperCustomersMutations,
    useShopperCustomersMutation
} from '@salesforce/commerce-sdk-react'
import {nanoid} from 'nanoid'

const AddressArrowIcon = (props) => (
    <Box
        width={2}
        height={2}
        borderLeft="2px"
        borderTop="2px"
        borderColor="stokkeCore.black"
        position="absolute"
        left="50%"
        bottom="-22px"
        backgroundColor="stokkeGray.light"
        transform="rotate(45deg)"
        zIndex={1}
        {...props}
    />
)

const MyStokkeAddresses = () => {
    const {formatMessage} = useIntl()
    const country = useCountry()
    const {data: customer, refetch: refetchCustomer} = useCurrentCustomer()
    const addresses = useMemo(
        () =>
            (customer.addresses || [])
                .filter((address) => address.countryCode === country)
                .map((address, idx) => ({
                    ...address,
                    preferred: idx === 0
                })),
        [customer.addresses, country]
    )
    const toast = useToast()
    const hasAddresses = addresses.length > 0
    const form = useForm()
    const {reset: formReset, trigger: formTrigger} = form
    const [selectedAddressId, setSelectedAddressId] = useState()
    const [isEditingAddress, setIsEditingAddress] = useState(false)
    const [addressToRemove, setAddressToRemove] = useState()
    const confirmationModalProps = useDisclosure({
        isOpen: !!addressToRemove,
        onClose: () => setAddressToRemove()
    })

    const addCustomerAddress = useShopperCustomersMutation(
        ShopperCustomersMutations.CreateCustomerAddress
    )
    const updateSavedAddress = useShopperCustomersMutation(
        ShopperCustomersMutations.UpdateCustomerAddress
    )
    const {mutateAsync: removeCustomerAddress, isLoading: isRemoving} = useShopperCustomersMutation(
        ShopperCustomersMutations.RemoveCustomerAddress
    )

    const onError = useEffectEvent(() => {
        toast({
            title: formatMessage(API_ERROR_MESSAGE),
            status: 'error'
        })
    })

    const onSubmit = async (address) => {
        if (!selectedAddressId) {
            address = {...address, addressId: nanoid()}
            await addCustomerAddress.mutateAsync(
                {
                    body: address,
                    parameters: {customerId: customer.customerId}
                },
                {
                    onSuccess: () => {
                        formReset({addressId: ''})
                        setIsEditingAddress(false)
                        if (address.preferred) {
                            // when setting a new preferred address, the previous preferred address is no longer preferred
                            // but that happens on the backend and commerce-sdk-react doesn't handle it when updating
                            // the cache, so we need to refetch the customer to get the updated preferred address
                            refetchCustomer()
                        }
                    },
                    onError: (error) => {
                        console.error('Error adding address', error)
                        onError()
                    }
                }
            )
        } else {
            address = {...address, addressId: selectedAddressId}
            await updateSavedAddress.mutateAsync(
                {
                    body: address,
                    parameters: {
                        customerId: customer.customerId,
                        addressName: selectedAddressId
                    }
                },
                {
                    onSuccess: () => {
                        formReset({addressId: ''})
                        setIsEditingAddress(false)
                        if (address.preferred) {
                            // when setting a new preferred address, the previous preferred address is no longer preferred
                            // but that happens on the backend and commerce-sdk-react doesn't handle it when updating
                            // the cache, so we need to refetch the customer to get the updated preferred address
                            refetchCustomer()
                        }
                    },
                    onError: (error) => {
                        console.error('Error saving address', error)
                        onError()
                    }
                }
            )
        }
    }

    const removeAddress = (address) =>
        removeCustomerAddress(
            {
                parameters: {
                    customerId: customer.customerId,
                    addressName: address.addressId
                }
            },
            {
                onError: (error) => {
                    console.error('Error removing address', error)
                    onError()
                }
            }
        )

    const onAddNewAddress = useCallback(() => {
        setSelectedAddressId(undefined)
        formReset({addressId: ''})
        setIsEditingAddress((state) => !state)
        formTrigger()
    }, [formReset, formTrigger])
    const onEdit = useCallback(
        (address) => {
            setSelectedAddressId(address.addressId)
            formReset({...address})
            setIsEditingAddress(true)
            formTrigger()
        },
        [formReset, formTrigger]
    )
    const onCancel = useCallback(() => setIsEditingAddress(false), [])
    const onRemove = useCallback((address) => {
        setAddressToRemove(address)
    }, [])

    if (!hasAddresses && !isEditingAddress) {
        return (
            <MyStokkeLayoutEmpty
                icon={<MarkerPin01Icon />}
                mainLabel={formatMessage({
                    defaultMessage: 'No saved addresses yet.',
                    id: 'mystokke.addresses.empty.headline'
                })}
                secondaryLabel={formatMessage({
                    defaultMessage:
                        'When you save an address, you’ll see it here. Click on the button to start adding.',
                    id: 'mystokke.addresses.empty.text'
                })}
            >
                <MyStokkeFooter>
                    <Button variant="solid" onClick={onAddNewAddress}>
                        <FormattedMessage
                            defaultMessage="Add a new address"
                            id="mystokke.addresses.button.add-address"
                        />
                    </Button>
                    <MyStokkeBackToStokke />
                </MyStokkeFooter>
            </MyStokkeLayoutEmpty>
        )
    }

    return (
        <MyStokkeLayoutDefault loading={isRemoving}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Flex flexDirection="column" gap={4}>
                    <Flex flexDirection="column" gap={4}>
                        <SimpleGrid
                            columns={{base: 1, md: 2}}
                            spacing={4}
                            gridAutoFlow="row dense"
                            role="list"
                            aria-label={formatMessage({
                                defaultMessage: 'Address List',
                                id: 'mystokke.addresses.list.label'
                            })}
                        >
                            {addresses.map((address, idx) => (
                                <React.Fragment key={address.addressId}>
                                    <MyStokkeAddressCard
                                        address={address}
                                        idx={idx}
                                        onEdit={onEdit}
                                        onRemove={!address.preferred ? onRemove : null}
                                    >
                                        {/*Arrow up icon pointing to the address that is being edited*/}
                                        {isEditingAddress &&
                                            address.addressId === selectedAddressId && (
                                                <AddressArrowIcon bottom="-22px" />
                                            )}
                                    </MyStokkeAddressCard>
                                    {isEditingAddress &&
                                        address.addressId === selectedAddressId && (
                                            <Box
                                                border="2px"
                                                gridColumn={{base: 1, md: 'span 2'}}
                                                position="relative"
                                            >
                                                {form.formState.isSubmitting && <LoadingSpinner />}
                                                <MyStokkeAddressForm
                                                    form={form}
                                                    onCancel={onCancel}
                                                />
                                            </Box>
                                        )}
                                </React.Fragment>
                            ))}

                            {(hasAddresses || !isEditingAddress) && (
                                <Box
                                    role="listitem"
                                    display={
                                        isEditingAddress && !selectedAddressId
                                            ? null
                                            : {base: 'none', md: 'flex'}
                                    }
                                    maxWidth={{base: '25rem', md: 'none'}}
                                    marginX={{base: 'auto', md: 0}}
                                    width={{base: '100%', md: 'auto'}}
                                >
                                    <Button
                                        variant="blackCard"
                                        minHeight={{base: '56px', md: '156px'}}
                                        height="100%"
                                        width="100%"
                                        size="lg"
                                        onClick={onAddNewAddress}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Add a new address"
                                            id="mystokke.addresses.button.add-address"
                                        />
                                        {/*Arrow up icon pointing to the new address that is being added*/}
                                        {isEditingAddress && !selectedAddressId && (
                                            <AddressArrowIcon bottom="-22px" />
                                        )}
                                    </Button>
                                </Box>
                            )}
                        </SimpleGrid>

                        {isEditingAddress && !selectedAddressId && (
                            <Box border={hasAddresses ? '2px' : 'none'} position="relative">
                                {form.formState.isSubmitting && <LoadingSpinner />}
                                <MyStokkeAddressForm form={form} onCancel={onCancel} />
                            </Box>
                        )}
                    </Flex>

                    <MyStokkeFooter>
                        <Button
                            display={
                                isEditingAddress ? 'none' : hasAddresses ? {md: 'none'} : 'flex'
                            }
                            variant="solid"
                            onClick={onAddNewAddress}
                        >
                            <FormattedMessage
                                defaultMessage="Add a new address"
                                id="mystokke.addresses.button.add-address"
                            />
                        </Button>
                        <MyStokkeBackToStokke />
                    </MyStokkeFooter>
                </Flex>
                <ConfirmationModal
                    {...confirmationModalProps}
                    onPrimaryAction={() => {
                        setAddressToRemove()
                        removeAddress(addressToRemove)
                    }}
                    onAlternateAction={() => {
                        setAddressToRemove()
                    }}
                />
            </form>
        </MyStokkeLayoutDefault>
    )
}

export default MyStokkeAddresses
