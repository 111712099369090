import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Stack, Text, Box, Button, Divider, SimpleGrid} from '@chakra-ui/react'
import {FormattedMessage, useIntl} from 'react-intl'
import Field from '../field'
import Recaptcha from '../recaptcha'
import useNotifyFields from './useNotifyFields'
import {useToast} from '../../hooks/use-toast'
import MarketingOptIn from '../marketing-opt-in'
import TermsAndConditions from '../terms-conditions'
import {trackNotifyMe} from '../../tracking/analytics'
import {useOcapiSubscribeToNewsletterMutation} from '../../hooks/useOcapi'
import {useCreateProductNotificationMutation} from '../../hooks/use-product-notification'

const NotifyFields = ({form, product, onClose, prefix = ''}) => {
    const toast = useToast()
    const {formatMessage} = useIntl()
    const fields = useNotifyFields({form, prefix})
    const [tocOptIn, setTocOptIn] = useState(false)
    const [acceptsMarketing, setAcceptsMarketing] = useState(false)
    const recaptchaRef = useRef(null)
    const {mutateAsync: subscribeToProductNotification} = useOcapiSubscribeToNewsletterMutation()

    const {mutateAsync: createProductNotification} = useCreateProductNotificationMutation()

    const submitForm = async (data) => {
        try {
            const token = await recaptchaRef.current.execute()
            await subscribeToProductNotification({
                parameters: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phone: data.phone,
                    gRecaptchaResponse: token,
                    source: 'Web - Notify'
                }
            })

            await createProductNotification({product})

            trackNotifyMe(product)
            toast({
                title: formatMessage(
                    {
                        defaultMessage: `You will receive an email when the {0} is back in stock.`,
                        id: 'notify.modal.msg.success'
                    },
                    {0: product.name}
                ),
                status: 'success'
            })
        } catch (error) {
            if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
                console.warn('Failure to subscribe for notification', error)
            }
            toast({
                title: formatMessage({
                    defaultMessage: 'An error occurred during the notification process',
                    id: 'notify.modal.msg.error'
                }),
                status: 'error'
            })
        }
        onClose()
    }

    return (
        <form id="login-form" onSubmit={form.handleSubmit(submitForm)}>
            <Stack spacing={6} pt={2}>
                <Text textStyle="Regular 4XLarge">
                    <FormattedMessage
                        defaultMessage="Notify me when the {0} is available"
                        values={{0: product.name}}
                        id="notify.modal.title.text"
                    />
                </Text>
                <Text textStyle="Regular Medium">
                    <FormattedMessage
                        defaultMessage="Provide your email address to receive a notification email. Your email address will only be used for this purpose."
                        id="notify.modal.title.description"
                    />
                </Text>
            </Stack>
            <Stack paddingTop={6}>
                <Divider colorScheme="stokkeGray" />
            </Stack>
            <Stack paddingTop={6}>
                <Text textStyle="List Regular">
                    <FormattedMessage
                        defaultMessage="* indicates a required field"
                        id="notify.modal.required.description"
                    />
                </Text>
            </Stack>
            <Stack spacing={6} paddingTop={6}>
                <Field {...fields.firstName} />
                <Field {...fields.lastName} />
                <Field {...fields.email} />
                <Field {...fields.phone} />
            </Stack>
            <Stack spacing={4} paddingTop={6}>
                <MarketingOptIn value={acceptsMarketing} onChange={setAcceptsMarketing} />
                <TermsAndConditions value={tocOptIn} onChange={setTocOptIn} />
            </Stack>
            <Stack spacing={3} paddingTop={9}>
                <Recaptcha ref={recaptchaRef} />
                <SimpleGrid columns={{lg: 2}}>
                    <Button
                        type="submit"
                        isDisabled={!tocOptIn}
                        isLoading={form.formState.isSubmitting}
                        onClick={() => {
                            form.clearErrors('global')
                        }}
                    >
                        <FormattedMessage defaultMessage="Notify me" id="notify.modal.submit" />
                    </Button>
                    <Box>{/* recaptcha - not implemented yet */}</Box>
                </SimpleGrid>
            </Stack>
        </form>
    )
}

NotifyFields.propTypes = {
    /** Object returned from `useForm` */
    form: PropTypes.object.isRequired,

    /** Submit function of the notification modal */
    submitForm: PropTypes.func.isRequired,

    /** Close function of the notification modal */
    onClose: PropTypes.func.isRequired,

    /** Product instance */
    product: PropTypes.object.isRequired,

    /** Optional prefix for field names */
    prefix: PropTypes.string
}

export default NotifyFields
