import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'
import useMyStokkeRootPath from '../hooks/use-root-path'
import Breadcrumb from '../../../components/breadcrumb'
import BreadcrumbItem from '../../../components/breadcrumb/breadcrumb-item'
import {HYBRID_ACCOUNT} from '../../../constants'

const MyStokkeBreadcrumb = ({children, ...props}) => {
    const path = useMyStokkeRootPath()

    return (
        <Breadcrumb {...props}>
            <BreadcrumbItem href={path} page={HYBRID_ACCOUNT}>
                <FormattedMessage id="mystokke.breadcrumb.my-stokke" defaultMessage="My Stokke®" />
            </BreadcrumbItem>

            {children}
        </Breadcrumb>
    )
}

MyStokkeBreadcrumb.propTypes = {
    children: PropTypes.node
}

export default MyStokkeBreadcrumb
