import React, {useCallback, useMemo} from 'react'
import PropTypes from 'prop-types'
import {Flex, Text} from '@chakra-ui/react'
import Lvl1Item from './lvl1-item'
import DrawerMenuError from './drawer-menu-error'

/**
 * Renders lvl0 items in the mobile drawer menu.
 */
const Lvl0Item = ({item, filterItems, onClick: _onClick}) => {
    // filter lvl1 items that should be displayed
    const displayedSubItems = useMemo(() => item.items?.filter(filterItems), [item, filterItems])

    // handle lvl1 item click
    const onClick = useCallback(
        (lvl1) => {
            // because we group non nav-items into pseudo lvl0 teaser item, when such item is clicked it's used for both lvl0 and lvl1
            if (item.variant === 'teaser') {
                _onClick?.(lvl1, lvl1)
            } else {
                // otherwise pass the lvl0 and lvl1 items, so they can be synced with the desktop version
                _onClick?.(lvl1, item)
            }
        },
        [item, _onClick]
    )

    switch (item.variant) {
        case 'teaser':
            return (
                <Flex
                    className="navigation-container"
                    flexDirection="column"
                    gap={4}
                    padding={4}
                    borderBottom="1px"
                    borderColor="stokkeGray.mid"
                >
                    {displayedSubItems?.length ? (
                        displayedSubItems.map((subitem, index) => (
                            <Lvl1Item key={index} item={subitem} onClick={onClick} />
                        ))
                    ) : (
                        <DrawerMenuError error="No items to display" details={item} />
                    )}
                </Flex>
            )

        default:
            return (
                <Flex
                    className="navigation-container"
                    paddingTop={6}
                    paddingX={5}
                    paddingBottom={5}
                    flexDirection="column"
                    borderBottom="1px"
                    borderColor="stokkeGray.mid"
                    gap={2}
                >
                    <Text
                        display="flex"
                        alignItems="center"
                        color="stokkeGray.accessibleText"
                        textStyle="Badge Regular"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textTransform="uppercase"
                        paddingX={2}
                        height={4.5}
                    >
                        {item.label}
                    </Text>

                    <Flex gap={1.5} flexDirection="column">
                        {displayedSubItems?.length ? (
                            displayedSubItems.map((subitem, index) => (
                                <Lvl1Item key={index} item={subitem} onClick={onClick} />
                            ))
                        ) : (
                            <DrawerMenuError error="No items to display" details={item} />
                        )}
                    </Flex>
                </Flex>
            )
    }
}

Lvl0Item.propTypes = {
    item: PropTypes.object.isRequired,
    filterItems: PropTypes.func,
    onClick: PropTypes.func
}

export default Lvl0Item
