import {useCuralateAPI} from '../../curalate-api/contexts'
import {useEffect, useMemo} from 'react'
import {getMasterProductId} from '../../utils/product-utils'
import useAsync from '../../commerce-api/hooks/useAsync'

export const useFanreelItems = (product, category, dataSourceId) => {
    const curalateAPI = useCuralateAPI()
    const {execute, error, loading, data: items} = useAsync(curalateAPI.getFeed)

    // precompute productId to avoid reloading when product changes but the same id will be used
    const productId = useMemo(
        () =>
            product ? product.c_globalProductID || getMasterProductId(product) || product.id : null,
        [product]
    )

    // precompute params to avoid reloading when on each render
    const params = useMemo(
        () =>
            productId
                ? {
                      filter: `productId:${productId}`,
                      dataSourceId: dataSourceId
                  }
                : {
                      filter: `category:${category}`,
                      dataSourceId: dataSourceId
                  },
        [category, dataSourceId, productId]
    )

    // execute the request when params change
    useEffect(() => {
        if (!params) {
            return
        }

        const abortController = new AbortController()
        execute(params, {signal: abortController.signal})
        return () => abortController.abort()
    }, [execute, params])

    // log errors
    useEffect(() => {
        if (error) {
            console.error('Error fetching fanreel items', {error})
        }
    }, [error])

    return {
        error,
        // initially loading
        loading: loading || (!items && !error),
        items
    }
}
