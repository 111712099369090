/* istanbul ignore file */

import {useEffect} from 'react'
import {CMS_ALLOWED_EXTERNAL_SCRIPT_SOURCES, CMS_SCRIPTS_MIME_TYPE} from '../../../constants'
import {isJavascriptMimeType} from '../../../utils/mime'
import loadExternalScript from '../util/load-external-script'
import runInlineScript from '../util/run-inline-script'
import useEffectEvent from '../../../hooks/use-effect-event'

const jqueryLoader = () => import('jquery')
const scrollmagicLoader = () => import('scrollmagic')
const tnsLoader = () => import('tiny-slider')

/**
 * manually execute all script tags inside the given root element
 * this is needed due to html5 spec when injecting script tags using Element.innerHTML
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#security_considerations
 * @param {string} html - the html to search for script tags
 * @param {HTMLElement} root - the root element to search for script tags
 * @param {(any) => any} [onError] - callback to handle errors
 * @param {string} [displayName] - friendly name for logs
 */
const useCmsScripts = ({html, root, onError, displayName = 'cms'}) => {
    const onLogEvent = useEffectEvent((level, ...args) => {
        console[level](`[${displayName}]`, ...args)
    })
    const onErrorEvent = useEffectEvent((e) => onError?.(e))
    useEffect(() => {
        if (!root || !html) return

        const allScripts = [...root.querySelectorAll('script')]

        const externalScripts = allScripts.filter((script) => {
            // ignore inline scripts
            if (!script.src) return false

            // work only with scripts
            const type = script.getAttribute('type')
            if (type && type !== CMS_SCRIPTS_MIME_TYPE && !isJavascriptMimeType(type)) {
                return false
            }

            // must match at least one allowed pattern
            return CMS_ALLOWED_EXTERNAL_SCRIPT_SOURCES.some((pattern) => pattern.test(script.src))
        })

        const inlineScripts = allScripts.filter((script) => {
            // ignore external scripts
            if (script.src) return false
            // work only with scripts
            const type = script.getAttribute('type')
            if (!type || isJavascriptMimeType(type) || type === CMS_SCRIPTS_MIME_TYPE) {
                return true
            }
        })

        if (externalScripts.length === 0 && inlineScripts.length === 0) {
            return
        }

        const abortController = new AbortController()
        const signal = abortController.signal

        // keep track of clean up functions
        const cleanUps = [() => abortController.abort()]

        // wait for all libraries and external scripts to load
        Promise.all([
            jqueryLoader(),
            scrollmagicLoader(),
            tnsLoader(),
            ...externalScripts.map((externalScript) =>
                loadExternalScript(externalScript, {onCleanup: (fn) => cleanUps.push(fn)})
            )
        ])
            // sequentially run inline scripts
            .then(([{default: jQuery}, {default: ScrollMagic}, {tns}]) => {
                if (signal.aborted) return
                // keep track of registered listeners
                const eventHandlers = {}
                // libraries that are provided to inline scripts
                const libraries = {
                    jQuery,
                    $: jQuery,
                    ScrollMagic,
                    tns
                }

                // intercept event listener registrations
                // to directly trigger events related to document loading
                const createProxyHandler = ({propOverrides = {}, immediateEvents = []} = {}) => ({
                    get(target, property) {
                        if (property !== 'addEventListener') {
                            if (property in propOverrides) {
                                return propOverrides[property]
                            }
                            const res = target[property]
                            // native functions need to be bound to target
                            // otherwise they are called with the proxy as this
                            // and that results in illegal invocation error
                            return res instanceof Function ? res.bind(target) : res
                        }

                        // bail quick if not mounted
                        if (signal.aborted) {
                            return
                        }

                        return (event, cb, opts) => {
                            if (signal.aborted) {
                                return
                            }
                            event = event.toLowerCase()
                            // call original method
                            if (!immediateEvents.includes(event)) {
                                target[property](event, cb, opts)
                                // auto cleanup event listener
                                cleanUps.push(() => target.removeEventListener(event, cb, opts))
                                return
                            }

                            if (!(event in eventHandlers)) {
                                eventHandlers[event] = []
                            }
                            eventHandlers[event].push(cb)
                            cleanUps.push(() => {
                                const idx = eventHandlers[event].indexOf(cb)
                                if (idx !== -1) {
                                    eventHandlers[event].splice(idx, 1)
                                }
                            })
                        }
                    }
                })
                const proxiedDocument = new Proxy(
                    document,
                    createProxyHandler({
                        immediateEvents: 'domcontentloaded'
                    })
                )
                const proxiedWindow = new Proxy(
                    window,
                    createProxyHandler({
                        propOverrides: {
                            document: proxiedDocument,
                            ...libraries
                        },
                        immediateEvents: ['load']
                    })
                )

                inlineScripts.forEach((script) => {
                    try {
                        onLogEvent('debug', `Loading inline script ${script.innerHTML}`)
                        runInlineScript(script.innerHTML, proxiedWindow, {
                            document: proxiedDocument,
                            window: proxiedWindow,
                            addEventListener: proxiedWindow.addEventListener,
                            ...libraries
                        })
                    } catch (e) {
                        onLogEvent('warn', `Error executing inline script ${script.innerHTML}`, e)
                    }
                })

                return eventHandlers
            })
            .then((eventHandlers) => {
                if (signal.aborted) return
                for (const event of ['domcontentloaded', 'load']) {
                    // trigger all event listeners
                    if (event in eventHandlers) {
                        onLogEvent('debug', `Triggering ${event}`)
                        for (const cb of eventHandlers[event]) {
                            try {
                                cb()
                            } catch (e) {
                                onLogEvent('warn', `Error executing ${event} handler`, e)
                            }
                        }
                    }
                }
            })
            .then(
                () => {
                    onLogEvent('debug', `All scripts loaded`)
                },
                (e) => {
                    onLogEvent('error', `Error loading scripts`, e)
                    if (signal.aborted) return
                    onErrorEvent(e)
                }
            )

        return () => {
            // call all cleanup
            cleanUps.forEach((f) => {
                try {
                    f()
                } catch (e) {
                    onLogEvent('warn', `Error cleaning up`, e)
                }
            })
        }
    }, [
        // need to rerun on change of the raw html content or the dom root
        html,
        root,
        onLogEvent,
        onErrorEvent
    ])
}

export default useCmsScripts
