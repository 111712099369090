import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import ProductTile, {Skeleton as ProductTileSkeleton} from '../../components/product-tile'
import {useProduct} from '@salesforce/commerce-sdk-react'

/**
 * Display product tile for a given product ID.
 *
 * @param {string} pid - Product ID
 *
 * @param {string} type - Product tile type, optional
 *
 * @example
 *   <pwa-widget-product pid="101001" type="look">
 *     $include('Widget-Product', 'pid', '#(product.product_id)', 'type', 'plptile')$
 *   </pwa-widget-product>
 */
const WidgetProduct = ({pid, type = 'default'}) => {
    const {isLoading, error, data} = useProduct(
        {parameters: {id: pid, allImages: true}},
        {enabled: !!pid}
    )
    const productTileVariant = type === 'look' ? 'Quickshop' : 'Default'

    useEffect(() => {
        if (error) {
            console.error('Failed to load product', error)
        }
    }, [error])

    if (!pid) {
        return null
    }

    if (isLoading) {
        return <ProductTileSkeleton />
    }

    if (error) {
        return null
    }

    // prevent initial flash of undefined product
    if (!data) {
        return null
    }

    return <ProductTile product={data} variant={productTileVariant} showQuickView />
}

WidgetProduct.propTypes = {
    pid: PropTypes.string,
    type: PropTypes.string
}

export default WidgetProduct
