import React, {useMemo} from 'react'
import PropTypes from 'prop-types'

// Components
import {StarIcon, StarHalfIcon, StarEmptyIcon} from '../../components/icons'
import {Flex, HStack, Text, useMultiStyleConfig, omitThemingProps} from '@chakra-ui/react'

//Constants
import {RATING_MAX_STARS, RATING_HALFSTAR_THRESHOLD} from '../../constants'
import useOcapiSite from '../../hooks/useOcapiSite'
import RatingSkeleton from './skeleton'
import {noop} from '../../utils/utils'

export const Ratings = (props) => {
    const styles = useMultiStyleConfig('Ratings', props)
    const {count, rating, variant, onRatingsClick = noop, ...ratingsProps} = omitThemingProps(props)
    const {bvEnabled} = useOcapiSite()
    const ratingDisplayValue = useMemo(
        () => (typeof rating === 'number' ? rating.toFixed(1) : null),
        [rating]
    )

    if (!bvEnabled) {
        return null
    }

    if (rating == null) {
        return <RatingSkeleton {...props} />
    }

    const halfStarThreshold = rating * 10 - Math.trunc(rating) * 10
    const halfStar =
        halfStarThreshold >= RATING_HALFSTAR_THRESHOLD.ceilAt &&
        halfStarThreshold <= RATING_HALFSTAR_THRESHOLD.floorAt
            ? 1
            : 0
    const approximatedFullStars = Math.round(rating)
    const fullStars =
        approximatedFullStars > rating ? approximatedFullStars - halfStar : approximatedFullStars

    const emptyStars = RATING_MAX_STARS - fullStars - halfStar
    const ratingIsDefined = (fullStars && halfStar && emptyStars) != (null || undefined)

    return (
        ratingIsDefined && (
            <Flex
                flexDir="row"
                {...styles.container}
                {...ratingsProps}
                data-testid="ratings"
                onClick={onRatingsClick}
            >
                {ratingDisplayValue != null && (
                    <Text {...styles.ratingValue} data-testid="rating-value">
                        {ratingDisplayValue}
                    </Text>
                )}
                <HStack {...styles.starContainer}>
                    {Array.from({length: fullStars}).map((_, i) => (
                        <StarIcon
                            key={i}
                            {...styles.star}
                            {...styles.full}
                            data-testid="full-star"
                        />
                    ))}
                    {halfStar > 0 && <StarHalfIcon {...styles.star} data-testid="half-star" />}
                    {Array.from({length: emptyStars}).map((_, i) => (
                        <StarEmptyIcon key={i} {...styles.star} data-testid="empty-star" />
                    ))}
                </HStack>
                {count != null && (
                    <Text {...styles.ratingCount} data-testid="rating-count">
                        {`(${count})`}
                    </Text>
                )}
            </Flex>
        )
    )
}

Ratings.propTypes = {
    count: PropTypes.number,
    rating: PropTypes.number,
    variant: PropTypes.string,
    onRatingsClick: PropTypes.func
}

export default Ratings
