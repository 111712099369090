import textStyles from '../../foundations/textStyles'
import {cssVar} from '@chakra-ui/react'

export default {
    baseStyle:
        process.env.NODE_ENV !== 'test'
            ? {
                  sx: {
                      '.adyen-checkout': {
                          '&__dropdown': {
                              '&__button': {
                                  px: 2,
                                  border: 'none',
                                  borderRadius: 'none',
                                  borderBottom: '2px',
                                  borderColor: 'stokkeCore.black',
                                  color: 'stokkeCore.black',
                                  height: 13.5,
                                  ...textStyles['Regular Medium'],
                                  _placeholder: {
                                      color: 'stokkeGray.accessibleText'
                                  },
                                  _active: {
                                      boxShadow: 'none'
                                  },
                                  '&--active': {
                                      boxShadow: 'none'
                                  }
                              },
                              '&__element': {
                                  py: 3,
                                  px: 2,
                                  ...textStyles['Regular Medium'],
                                  _hover: {
                                      background: 'stokkeCore.black',
                                      color: 'stokkeCore.white'
                                  },
                                  _active: {
                                      background: 'stokkeCore.black',
                                      color: 'stokkeCore.white'
                                  },
                                  '&--active': {
                                      background: 'stokkeCore.black',
                                      color: 'stokkeCore.white'
                                  }
                              },
                              '&__list': {
                                  marginTop: 0,
                                  borderRadius: 'none',
                                  boxShadow: 'none'
                              }
                          },
                          '&__error-text': {
                              marginTop: 2,
                              color: 'stokkeFunctional.alertRed',
                              ...textStyles['Regular Small']
                          },
                          '&__field': {
                              marginBottom: 6,
                              '&:last-of-type': {
                                  marginBottom: 0
                              }
                          },
                          '&__field-wrapper': {
                              '> .adyen-checkout': {
                                  '&__field': {
                                      '&:first-child': {
                                          marginRight: 1.125
                                      },
                                      '&:nth-child(2)': {
                                          marginLeft: 1.125
                                      }
                                  }
                              }
                          },
                          '&__filter-input': {
                              color: 'stokkeCore.black',
                              caretColor: cssVar('stokkeCore-black', null, 'chakra-colors')
                                  .reference,
                              ...textStyles['Regular Medium'],
                              _placeholder: {
                                  color: 'stokkeGray.accessibleText'
                              }
                          },
                          '&__input': {
                              px: 2,
                              border: 'none',
                              borderRadius: 'none',
                              borderBottom: '2px',
                              borderColor: 'stokkeCore.black',
                              caretColor: cssVar('stokkeCore-black', null, 'chakra-colors')
                                  .reference,
                              color: 'stokkeCore.black',
                              height: 13.5,
                              ...textStyles['Regular Medium'],
                              _focus: {
                                  boxShadow: 'none'
                              },
                              '&--focus': {
                                  boxShadow: 'none'
                              },
                              _hover: {
                                  borderColor: 'stokkeCore.black',
                                  ...textStyles['Regular Medium']
                              },
                              _placeholder: {
                                  color: 'stokkeGray.accessibleText'
                              },
                              '&--error': {
                                  borderColor: 'stokkeFunctional.alertRed',
                                  _hover: {
                                      borderColor: 'stokkeFunctional.alertRed'
                                  }
                              },
                              '&--valid': {
                                  borderColor: 'stokkeFunctional.alertGreen',
                                  _hover: {
                                      borderColor: 'stokkeFunctional.alertGreen'
                                  }
                              }
                          },
                          '&__label': {
                              '&__text': {
                                  paddingBottom: 2,
                                  color: 'stokkeCore.black',
                                  ...textStyles['Regular Small']
                              }
                          },
                          '&__payment-method': {
                              bg: 'none',
                              border: 'none',
                              borderBottom: '1px',
                              borderColor: 'stokkeBeige.mid',
                              borderRadius: 'none',
                              maxHeight: 'none',
                              py: 6,
                              margin: 0,
                              _hover: {
                                  '&:not(.adyen-checkout__payment-method--selected)': {
                                      '.adyen-checkout__payment-method__radio': {
                                          borderColor: 'stokkeCore.black',
                                          boxShadow: 'none'
                                      }
                                  }
                              },
                              '&--selected': {
                                  bg: 'none',
                                  margin: 0
                              },
                              '&__brands': {
                                  marginTop: 0,
                                  marginBottom: 0,
                                  height: 'auto',
                                  img: {
                                      borderRadius: 'base',
                                      height: 6,
                                      width: 9
                                  },
                                  '.adyen-checkout__payment-method__image__wrapper': {
                                      marginTop: 4,
                                      marginRight: 4,
                                      height: 6,
                                      width: 9
                                  },
                                  '.adyen-checkout__payment-method__brand-number': {
                                      marginTop: 4,
                                      color: 'stokkeCore.black',
                                      ...textStyles['Light Medium'],
                                      // need to override the line height from text styles
                                      lineHeight: 6
                                  }
                              },
                              '&__details': {
                                  paddingLeft: 9,
                                  paddingRight: 0,
                                  '&__content': {
                                      paddingTop: 8,
                                      marginBottom: 2
                                  }
                              },
                              '&__header': {
                                  flexDirection: 'column',
                                  alignItems: 'stretch',
                                  py: 0,
                                  paddingLeft: 9,
                                  paddingRight: 0,
                                  flexWrap: 'nowrap',
                                  '&__title': {
                                      color: 'stokkeCore.black',
                                      padding: 0,
                                      flexDirection: 'row-reverse',
                                      justifyContent: 'flex-end',
                                      ...textStyles['Regular 2XLarge']
                                  }
                              },
                              '&__name': {
                                  marginEnd: 6,
                                  whiteSpace: 'normal',
                                  textAlign: 'start'
                              },
                              '&__radio': {
                                  width: 6,
                                  height: 6,
                                  left: 0,
                                  borderColor: 'stokkeCore.black',
                                  '&--selected': {
                                      bg: 'stokkeCore.black',
                                      '&::after': {
                                          width: 2,
                                          height: 2
                                      }
                                  }
                              },
                              // quick fix to hide klarna empty details that take up space
                              '&--klarna_paynow, &--klarna, &--klarna_account': {
                                  '.adyen-checkout__payment-method__details': {
                                      display: 'none'
                                  }
                              }
                          },
                          '&__iframe': {
                              maxWidth: '100%'
                          },
                          '&__threeds2': {
                              '&__challenge': {
                                  maxWidth: '100%'
                              }
                          }
                      },
                      '[dir=ltr]': {
                          '.adyen-checkout-input': {
                              '&__inline-validation': {
                                  right: 2
                              }
                          }
                      },
                      '[dir=rtl]': {
                          '.adyen-checkout': {
                              '&__field-wrapper': {
                                  '> .adyen-checkout': {
                                      '&__field': {
                                          '&:first-child': {
                                              marginLeft: 1.125
                                          },
                                          '&:nth-child(2)': {
                                              marginRight: 1.125
                                          }
                                      }
                                  }
                              }
                          },
                          '.adyen-checkout-input': {
                              '&__inline-validation': {
                                  left: 2
                              }
                          }
                      }
                  }
              }
            : {}
}
