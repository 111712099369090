import {useMemo} from 'react'
import {findImagesBy} from '../utils/image-groups-utils'

export const useProductDisplayImages = (
    product,
    viewType,
    selectedVariationAttributes,
    imagesCount
) => {
    const imageGroups = product?.imageGroups
    const frontImage = product?.c_extraMainImage
    const backImage = product?.c_extraMainImage2
    const result = useMemo(() => {
        const images = findImagesBy(imageGroups, frontImage, backImage, {
            viewType,
            selectedVariationAttributes
        })
        if (imagesCount) {
            return images?.slice(0, imagesCount) || []
        }
        return images || []
    }, [imageGroups, viewType, selectedVariationAttributes, frontImage, backImage, imagesCount])
    return result
}
