import React, {useMemo} from 'react'
import PropTypes from 'prop-types'

/* Components */
import {Flex, useMultiStyleConfig, omitThemingProps} from '@chakra-ui/react'
import Badge from './badge'

/* Hooks */
import {useBadges} from '../../hooks/use-badges'

const BadgeGroup = (props) => {
    const {
        product,
        promotions,
        max,
        maxCharsPerBadge = 18,
        maxSummedChars
    } = omitThemingProps(props)
    const rawBadges = useBadges(product, promotions)
    const styles = useMultiStyleConfig('BadgeGroup', props)

    const badges = useMemo(() => {
        const badges = []
        let characters = 0
        for (const badge of rawBadges) {
            /* honor max limit */
            if (max != null && badges.length >= max) {
                break
            }

            // skip long badges
            if (badge.text.length > maxCharsPerBadge) {
                continue
            }

            // stop once we reach the character limit
            if (maxSummedChars && characters + badge.text.length > maxSummedChars) {
                break
            }

            // add the badge
            badges.push(badge)
            characters += badge.text.length
        }
        return badges
    }, [rawBadges, max, maxCharsPerBadge, maxSummedChars])

    /* Return if badges array is empty */
    if (badges.length <= 0) {
        return null
    }

    return (
        <Flex {...styles.badgeGroup}>
            {badges.map(({text, ...props}, index) => (
                <Badge key={index} text={text} {...props} />
            ))}
        </Flex>
    )
}

BadgeGroup.propTypes = {
    product: PropTypes.object.isRequired,
    promotions: PropTypes.array,
    /* Limits visible badges to passed number */
    max: PropTypes.number,
    /* Maximum badge text length */
    maxCharsPerBadge: PropTypes.number,
    /* Maximum summed badges texts length */
    maxSummedChars: PropTypes.number
}

export default BadgeGroup
