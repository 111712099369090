import {useEffect, useState} from 'react'
import {trackViewItemList} from '../../../tracking/analytics'
import {useCurrentBasket} from '../../../hooks/use-current-basket'
import useEffectEvent from '../../../hooks/use-effect-event'
import useProductItemsDetail from '../../../hooks/useProductItemsDetail'

const useTrackViewList = ({products, trackingLabel, isRecommendationsHidden}) => {
    const {data: basket} = useCurrentBasket()
    const {data: productItemsDetail} = useProductItemsDetail(basket)
    const [tracked, setTracked] = useState(new Set())

    const onTrackViewItemList = useEffectEvent((products) => {
        const untracked = products.filter(({id}) => !tracked.has(id))

        if (!untracked.length) return

        // track cart
        trackViewItemList(products, basket, trackingLabel, productItemsDetail)

        // set products as tracked
        setTracked(new Set(products.map(({id}) => id)))
    })
    useEffect(() => {
        if (!products || !products?.length) return

        //only track if label is set
        if (!trackingLabel) {
            return
        }
        // only track if basket has currency
        if (!basket?.currency) {
            return
        }
        // only track if recommendations are available and visible
        if (isRecommendationsHidden) {
            return
        }

        onTrackViewItemList(products, trackingLabel)
    }, [products, trackingLabel, basket?.currency, isRecommendationsHidden, onTrackViewItemList])
}

export default useTrackViewList
