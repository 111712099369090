import {useIntl} from 'react-intl'
import {useMemo} from 'react'
import {orderCancellationUrlBuilder} from '../../../utils/url'
import {trackEvent} from '../../../tracking/analytics'
import useOcapiSite from '../../../hooks/useOcapiSite'
import {ReturnOrderIcon} from '../../../components/icons'

/**
 * Hook to determine the action that can be taken on an order
 * @param order
 * @return {{buttonProps: {onClick?: Function, to?: string, isDisabled: boolean}, Icon?: *, label?: string, tooltip?: string}|null}
 */
const useOrderAction = (order) => {
    const {formatMessage} = useIntl()
    const {orderCancellationEnabled, parcelLabShippingEstimateEnabled} = useOcapiSite().preferences

    return useMemo(() => {
        if (!order) return null

        if (order.shippingStatus === 'shipped') {
            const label = formatMessage({
                defaultMessage: 'Return order',
                id: 'mystokke.orders.link.return_order'
            })

            if (order.c_orderReturnEnabled) {
                return {
                    buttonProps: {
                        to: parcelLabShippingEstimateEnabled
                            ? '/return-order'
                            : '/customer-service/shipping-exchanges/shipping-exchanges.html',
                        onClick: () =>
                            trackEvent({
                                event: 'begin_refund',
                                page_type: 'My Stokke',
                                click_text: 'Return order'
                            })
                    },
                    Icon: ReturnOrderIcon,
                    label
                }
            } else {
                return {
                    buttonProps: {
                        isDisabled: true
                    },
                    label,
                    tooltip: formatMessage({
                        id: 'mystokke.orders.link.return_order.disabled.help',
                        defaultMessage:
                            'One of the following reasons may apply: Your return window has passed, or a return has already been created.'
                    })
                }
            }
        } else if (orderCancellationEnabled) {
            const label = formatMessage({
                defaultMessage: 'Cancel order',
                id: 'mystokke.orders.link.cancel_order'
            })

            if (order.c_orderCancellationEnabled) {
                return {
                    buttonProps: {
                        to: orderCancellationUrlBuilder(order),
                        onClick: () =>
                            trackEvent({
                                event: 'begin_refund',
                                page_type: 'My Stokke',
                                click_text: 'Cancel order'
                            })
                    },
                    label
                }
            } else {
                return {
                    buttonProps: {
                        isDisabled: true
                    },
                    label,
                    tooltip: formatMessage({
                        id: 'mystokke.orders.link.cancel_order.disabled.help',
                        defaultMessage:
                            'One of the following reasons may apply: Your cancellation window has passed, or your order is in progress.'
                    })
                }
            }
        }

        return null
    }, [formatMessage, order, orderCancellationEnabled, parcelLabShippingEstimateEnabled])
}

export default useOrderAction
