import {useMutation, useQueryClient} from '@tanstack/react-query'
import {FAILED_BASKET_ID} from '../constants'
// noinspection JSDeprecatedSymbols
import {useCommerceAPI} from '../commerce-api/contexts'
import {
    ShopperBasketsMutations,
    ShopperCustomersMutations,
    useConfig,
    useCustomerBaskets,
    useCustomerId,
    useShopperBasketsMutation,
    useShopperBasketsMutationHelper,
    useShopperCustomersMutation,
    useUsid
} from '@salesforce/commerce-sdk-react'
import {isServer} from '../utils/utils'
import {useCurrentBasket} from './use-current-basket'
import {getOrder} from '@salesforce/commerce-sdk-react/hooks/ShopperOrders/queryKeyHelpers'
import {getAddressIdentifier} from '../utils/address-utils'
import {useCurrentCustomer} from './use-current-customer'

// TODO v3: remove if by the end of conversion this remains unused
// conversion of pwa 2.x useBasket.getOrCreateBasket but might be unneeded, because in pwa 3.x basket is created
// when first item is added to basket using useShopperBasketsMutationHelper().addItemToNewOrExistingBasket(productItem)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetOrCreateBasketMutation = (mutationOptions) => {
    const {usid} = useUsid()
    const customerId = useCustomerId()
    // we use the query to force it to refetch and update the cache - that'll be picked on next render
    // by useCurrentBasket
    const customerBasketsQuery = useCustomerBaskets(
        {parameters: {customerId}},
        {
            enabled: !!customerId && !isServer
        }
    )
    const createBasketMutation = useShopperBasketsMutation(ShopperBasketsMutations.CreateBasket)
    const deleteBasketMutation = useShopperBasketsMutation(ShopperBasketsMutations.DeleteBasket)

    return useMutation({
        ...mutationOptions,
        mutationFn: async ({deleteOldBaskets} = {}) => {
            const {data: customerBaskets} = customerBasketsQuery.refetch({throwOnError: true})

            // We only support single baskets for now. Grab the first one.
            let basket = Array.isArray(customerBaskets?.baskets) && customerBaskets.baskets[0]

            const failedBasketId = window?.localStorage?.getItem(FAILED_BASKET_ID)
            // STOK-5330 - check and delete old failed basket
            // work-around done in order to prevent appearing of the last failed order after a successful one
            if (basket && basket.basketId === failedBasketId) {
                const deleteResponse = await deleteBasketMutation.mutateAsync({
                    parameters: {
                        basketId: failedBasketId
                    },
                    body: {}
                })
                // TODO v3: this is likely broken as the response is actually the data and not the response object
                if (deleteResponse && deleteResponse.status === 204) {
                    window.localStorage.removeItem(FAILED_BASKET_ID)
                    basket = undefined
                    customerBaskets?.baskets.shift()
                }
            }

            if (deleteOldBaskets) {
                let basketIds = customerBaskets?.baskets?.map(
                    (currentBasket) => currentBasket.basketId
                )
                basketIds.splice(basketIds.indexOf(basket?.basketId), 1)
                basketIds.forEach((id) => {
                    try {
                        // not awaiting the result!!!??? this is how it was implemented with STOK-7549
                        deleteBasketMutation.mutate({
                            parameters: {
                                basketId: id
                            },
                            body: {}
                        })
                    } catch (e) {
                        if (process.env.NODE_ENV !== 'production') {
                            console.error('basket cannot be deleted', e)
                        }
                    }
                })
            }

            if (!basket) {
                // Back to using ShopperBaskets for all basket interaction.
                basket = await createBasketMutation.mutateAsync(
                    {
                        headers: {'custom-sfdc_customer_id': usid},
                        body: {}
                    },
                    {}
                )

                // need to refetch the baskets to get the newly created one in cache and update all useCurrentBasket hooks
                const {data: customerBasketsAfterCreating} = await customerBasketsQuery.refetch({
                    throwOnError: true
                })
                // fallback to the newly created basket as per the original logic from pwa 1.x,
                // but this could only happen if two concurrent delete baskets are executing
                // unfortunately the fallback will affect only the caller - the hook will not be aware and will return nothing
                basket = customerBasketsAfterCreating?.baskets?.[0] || basket
            }

            return basket
        }
    })
}

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useStokkeOcapiFailOrderMutation = (mutationOptions) => {
    // noinspection JSDeprecatedSymbols
    const api = useCommerceAPI()
    const customerId = useCustomerId()
    const customerBasketsQuery = useCustomerBaskets(
        {parameters: {customerId}},
        {
            enabled: !!customerId && !isServer
        }
    )
    const deleteBasketMutation = useShopperBasketsMutation(ShopperBasketsMutations.DeleteBasket)

    return useMutation({
        ...mutationOptions,
        mutationFn: async ({orderToken, errorReason}) => {
            /**
             * Fails an order using the current order instance.
             * @param {String} orderToken is the unique order token that is stored inside each SFCC order (Adyen PSP reference)
             * @param {String} errorReason is additional meta data about the reason of the order fail
             */
            if (orderToken) {
                window?.localStorage?.setItem(FAILED_BASKET_ID, orderToken)
            }
            const orderNumberResponse = await api.shopperOrder.getOrderNumber({
                orderToken: orderToken
            })
            const response = await api.shopperOrders.failOrder({
                reason: errorReason,
                body: {
                    orderNo: orderNumberResponse.orderNumber,
                    merchantReference: orderToken
                }
            })

            if (response.fault || (response.title && response.type && response.detail)) {
                throw new Error(response.title)
            }

            if (response.c_newBasketId) {
                // in case we received the old basket, don't mark it for deletion
                if (response.c_newBasketId === orderToken) {
                    window?.localStorage?.removeItem(FAILED_BASKET_ID)
                }
                // TODO v3: maybe we can skip this loading and use the cached baskets from before failing the order
                // load latest baskets to delete all except the new one
                const {data: currentBaskets} = await customerBasketsQuery.refetch({
                    throwOnError: true
                })
                // delete all baskets except the new one
                // ignore failures as retries will complicate the logic,
                // just wait for the promises to finish before continuing
                await Promise.allSettled(
                    currentBaskets.baskets
                        .filter((basket) => basket.basketId !== response.c_newBasketId)
                        .map(async (basket) =>
                            deleteBasketMutation.mutate({
                                parameters: {
                                    basketId: basket.basketId
                                },
                                body: {}
                            })
                        )
                )

                // TODO v3: move to onSuccess and invalidate the cache instead of forcing a refetch
                // force reload of baskets to clear the deleted ones
                await customerBasketsQuery.refetch({
                    throwOnError: true
                })
            } // TODO v3: do we need to do something if c_newBasketId is not present?
        }
    })
}

/**
 * Updates session data of the current order instance.
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult<void, Error, {sessionData: object}>}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useStokkeOcapiUpdateBasketWithAdyenDataMutation = (mutationOptions) => {
    // noinspection JSDeprecatedSymbols
    const api = useCommerceAPI()
    const basketQuery = useCurrentBasket()

    return useMutation({
        ...mutationOptions,
        mutationFn: async ({sessionData}) => {
            const response = await api.shopperOrders.updateAdyenData({
                body: basketQuery.data,
                sessionData
            })

            if (response.fault || (response.title && response.type && response.detail)) {
                throw new Error(response.title)
            }

            // TODO v3: move to onSuccess and try to use the response to directly update the cache
            // reload the basket to get the updated data
            basketQuery.refetch()
        }
    })
}

/**
 * Updates customer identifier of the order.
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult<object, Error, {orderNo: string, customerNo: string}>}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useStokkeOcapiUpdateOrderCustomerMutation = (mutationOptions) => {
    // noinspection JSDeprecatedSymbols
    const api = useCommerceAPI()
    const clientConfig = useConfig()
    const queryClient = useQueryClient()

    return useMutation({
        ...mutationOptions,
        mutationFn: async ({orderNo, customerNo}) => {
            const response = await api.shopperOrders.updateCustomerData({
                parameters: {
                    orderNo,
                    customerNo
                }
            })

            if (response.fault || (response.title && response.type && response.detail)) {
                throw new Error(response.title)
            }

            return response
        },
        onSuccess: (data, variables, context) => {
            const qc = context?.queryClient || queryClient
            // update getOrder query cache
            qc.setQueryData(
                getOrder.queryKey({
                    ...clientConfig.parameters,
                    ...variables
                }),
                data
            )

            mutationOptions?.onSuccess?.(data, variables, context)
        }
    })
}

export const useStokkeAddItemToBasketMutation = (mutationOptions) => {
    const {addItemToNewOrExistingBasket} = useShopperBasketsMutationHelper()

    return useMutation({
        ...mutationOptions,
        mutationFn: async ({productItems}) => {
            const response = await addItemToNewOrExistingBasket(productItems)
            if (response.fault) {
                throw new Error(response)
            }

            response.productItems.forEach((pli) => {
                productItems.forEach((itemsToAdd) => {
                    if (pli.productId === itemsToAdd.productId) {
                        itemsToAdd.price = pli.price
                    }
                })
            })

            return response
        }
    })
}

/**
 * Add a new saved address.
 */
export const useStokkeAddSavedAddressMutation = (mutationOptions) => {
    const {data: customer} = useCurrentCustomer()
    const createCustomerAddressMutation = useShopperCustomersMutation(
        ShopperCustomersMutations.CreateCustomerAddress
    )
    const updateCustomerAddressMutation = useShopperCustomersMutation(
        ShopperCustomersMutations.UpdateCustomerAddress
    )

    return useMutation({
        ...mutationOptions,
        mutationFn: async ({address}) => {
            if (!customer.customerId) {
                throw new Error('Customer not yet loaded!')
            }

            // The `addressId` field is required to save the customer's address to their account.
            // There are some case when it is missing as during the process of creation of an order address
            // where `addressId` is replaced with `id` and in case it is missing we try to create a new unique one
            // that is a combination of `city` and `address1` and possible an incremental suffix
            // Rather than make the customer provide a name/id, we can generate a unique id behind
            // the scenes instead. This is only useful if you are not displaying the addr name/id
            // in the UI, which we aren't.
            const addressId = `${address.city} ${address.address1}`

            // address has address ID so it has to be updated not created
            if (address.addressId) {
                return updateCustomerAddressMutation.mutateAsync({
                    body: address,
                    parameters: {customerId: customer.customerId, addressName: address.addressId}
                })
            }

            const body = {
                ...address,
                addressId: getAddressIdentifier(customer, addressId)
            }

            return createCustomerAddressMutation.mutateAsync({
                body,
                parameters: {customerId: customer.customerId}
            })
        }
    })
}

/**
 * Update a saved address.
 */
export const useStokkeUpdateSavedAddressMutation = (mutationOptions) => {
    const customerId = useCustomerId()
    const updateCustomerAddressMutation = useShopperCustomersMutation(
        ShopperCustomersMutations.UpdateCustomerAddress
    )

    return useMutation({
        ...mutationOptions,
        mutationFn: async ({address}) => {
            await updateCustomerAddressMutation.mutateAsync({
                body: address,
                parameters: {
                    customerId: customerId,
                    addressName: address.addressId
                }
            })
        }
    })
}

/**
 * Remove a saved address.
 */
export const useStokkeRemoveSavedAddressMutation = (mutationOptions) => {
    const customerId = useCustomerId()
    const removeCustomerAddressMutation = useShopperCustomersMutation(
        ShopperCustomersMutations.RemoveCustomerAddress
    )

    return useMutation({
        ...mutationOptions,
        mutationFn: async ({addressId}) => {
            await removeCustomerAddressMutation.mutateAsync({
                parameters: {customerId, addressName: addressId}
            })
        }
    })
}
