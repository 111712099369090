export default {
    baseStyle: () => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            bg: 'stokkeCore.white',
            padding: 2,
            border: '1px',
            borderColor: 'stokkeGray.mid',
            cursor: 'pointer'
        },
        imageWrapper: {
            width: '5rem',
            height: '6.25rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        colorVariantContainer: {
            alignItems: 'center',
            gap: 2
        },
        colorSwatches: {
            alignItems: 'center',
            gap: 1
        },
        checkboxContainer: {
            display: 'none'
        },
        priceContainer: {
            display: 'none'
        }
    }),
    variants: {
        selectable: ({isSelected}) => ({
            container: {
                ...(isSelected && {
                    borderColor: 'stokkeCore.black'
                })
            },
            colorVariantContainer: {
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 1
            },
            checkboxContainer: {
                display: 'block'
            },
            priceContainer: {
                display: 'block',
                marginInlineStart: 'auto'
            }
        })
    }
}
