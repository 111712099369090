import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        icon: {
            boxSize: 4,
            marginEnd: 6
        }
    },
    variants: {
        accountBenefits: {
            container: {},
            item: {
                ...textStyles['Semi Bold Medium']
            }
        }
    }
}
