import React from 'react'
import {Global} from '@emotion/react'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'

const Fonts = () => (
    <Global
        styles={`
      /* Stokke Light */
      @font-face {
        font-family: 'Stokke-Light';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: local('CircularPro'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-Light.woff2'))}') format('woff2'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-Light.woff'))}') format('woff');
      }
      @font-face {
        font-family: 'Stokke';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: local('CircularPro'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-Light.woff2'))}') format('woff2'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-Light.woff'))}') format('woff');
      }
      @font-face {
        font-family: "circularProBook";
        src: local('CircularPro'),
            url("${encodeURI(getAssetUrl('static/fonts/Stokke-Light.woff2'))}") format("woff2"),
            url("${encodeURI(getAssetUrl('static/fonts/Stokke-Light.woff'))}") format("woff");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      
      /* Stokke Regular */
      @font-face {
        font-family: 'Stokke-Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('CircularPro'),
           url('${encodeURI(getAssetUrl('static/fonts/Stokke-Regular.woff2'))}') format('woff2'),
           url('${encodeURI(getAssetUrl('static/fonts/Stokke-Regular.woff'))}') format('woff');
      }
      @font-face {
        font-family: 'Stokke';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('CircularPro'),
           url('${encodeURI(getAssetUrl('static/fonts/Stokke-Regular.woff2'))}') format('woff2'),
           url('${encodeURI(getAssetUrl('static/fonts/Stokke-Regular.woff'))}') format('woff');
      }
      @font-face {
        font-family: "circularProBook";
        src: local('CircularPro'),
            url("${encodeURI(getAssetUrl('static/fonts/Stokke-Regular.woff2'))}") format("woff2"),
            url("${encodeURI(getAssetUrl('static/fonts/Stokke-Regular.woff'))}") format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      /* Stokke SemiBold */
      @font-face {
        font-family: 'Stokke-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: local('CircularPro'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-SemiBold.woff2'))}') format('woff2'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-SemiBold.woff'))}') format('woff');
      }
      @font-face {
        font-family: 'Stokke';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: local('CircularPro'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-SemiBold.woff2'))}') format('woff2'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-SemiBold.woff'))}') format('woff');
      }
      @font-face {
        font-family: "circularProBook";
        src: local('CircularPro'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-SemiBold.woff2'))}') format('woff2'),
            url('${encodeURI(getAssetUrl('static/fonts/Stokke-SemiBold.woff'))}') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      `}
    />
)

export default Fonts
