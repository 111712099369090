/**
 * Load an external script into the DOM
 * @param {HTMLScriptElement} externalScript
 * @param {function} [onCleanup]
 * @returns {Promise<void>}
 */
export default function loadExternalScript(externalScript, {onCleanup} = {}) {
    // clone the external script element
    const sc = document.createElement('script')
    for (let key of externalScript.getAttributeNames()) {
        // skip the type attribute as it's converted to CMS_SCRIPTS_MIME_TYPE
        if (key === 'type') continue
        sc.setAttribute(key, externalScript.getAttribute(key))
    }

    // return promise that resolve when script is loaded or rejects when script fails
    return new Promise((resolve, reject) => {
        sc.addEventListener('load', () => {
            resolve()
        })
        sc.addEventListener('error', () => {
            reject(new Error(`Error loading external script ${sc.src}`))
        })
        document.head.appendChild(sc)
        onCleanup?.(() => {
            document.head.removeChild(sc)
        })
    })
}
