import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {BV_LOCAL_STORAGE_KEY_PREFIX} from '../constants'
import {bvStorageFeedbackInappropriateKey, bvStorageFeedbackHelpfulnessKey} from './utils'
import PropTypes from 'prop-types'

const BazaarvoiceAPIContext = createContext()

/**
 * Return map of the submitted feedback and a function to add new feedback.
 *
 * The map is backed by storage and will be updated when storage changes.
 *
 * @param {Storage} storage
 * @returns {[Record<String, String>, (function(String, String): void)]}
 */
const useSubmittedFeedback = (storage) => {
    // load all submitted feedback from local storage
    const [submittedFeedback, setSubmittedFeedback] = useState(() => {
        const feedback = {}

        if (!storage) {
            return feedback
        }

        for (let idx = 0; idx < storage.length; idx++) {
            const key = storage.key(idx)
            if (key.startsWith(BV_LOCAL_STORAGE_KEY_PREFIX)) {
                feedback[key] = storage.getItem(key)
            }
        }

        return feedback
    })

    const setItem = useCallback(
        (key, value) => {
            storage?.setItem(key, value)
            setSubmittedFeedback((current) => ({
                ...current,
                [key]: value
            }))
        },
        [storage, setSubmittedFeedback]
    )

    // listen for changes to local storage and update submitted feedback
    useEffect(() => {
        const listener = (event) => {
            if (event.key.startsWith(BV_LOCAL_STORAGE_KEY_PREFIX)) {
                setItem(event.key, event.newValue)
            }
        }

        window.addEventListener('storage', listener, {passive: true})

        return () => {
            window.removeEventListener('storage', listener)
        }
    }, [setItem])

    return [submittedFeedback, setItem]
}

export const BazaarvoiceAPIProvider = ({
    value: baseApi,
    storage = global?.localStorage,
    children
}) => {
    const [submittedFeedback, setSubmittedFeedbackItem] = useSubmittedFeedback(storage)

    const api = useMemo(
        () => ({
            ...baseApi,
            submittedFeedback,
            submitHelpfulnessVote: async ({contentType, contentId, vote}) => {
                if (typeof vote === 'boolean') {
                    vote = vote ? 'Positive' : 'Negative'
                }
                await baseApi.submitHelpfulnessVote({contentType, contentId, vote})
                setSubmittedFeedbackItem(
                    bvStorageFeedbackHelpfulnessKey(contentType, contentId),
                    vote
                )
            },
            reportInappropriate: async ({contentType, contentId}) => {
                await baseApi.reportInappropriate({contentType, contentId})
                setSubmittedFeedbackItem(
                    bvStorageFeedbackInappropriateKey(contentType, contentId),
                    'true'
                )
            }
        }),
        [baseApi, submittedFeedback, setSubmittedFeedbackItem]
    )

    return <BazaarvoiceAPIContext.Provider value={api}>{children}</BazaarvoiceAPIContext.Provider>
}

BazaarvoiceAPIProvider.propTypes = {
    value: PropTypes.object,
    storage: PropTypes.object,
    children: PropTypes.node
}

/**
 * @returns {BazaarvoiceAPI}
 */
export const useBazaarvoiceAPI = () => {
    const api = useContext(BazaarvoiceAPIContext)

    if (!api) {
        throw new Error('useBazaarvoiceAPI must be used within a BazaarvoiceAPIProvider')
    }

    return api
}
