import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl'
import {
    Button,
    Flex,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text
} from '@chakra-ui/react'
import OrderStatus from './order-status'
import {ACCOUNT_HREF, API_ERROR_MESSAGE, MAX_IDS_PER_GET_PRODUCTS_REQUEST} from '../../../constants'
import Link from '../../../components/link'
import ItemVariantProvider from '../../../components/item-variant'
import CartItemVariantImage from '../../../components/item-variant/item-image'
import CartItemVariantName from '../../../components/item-variant/item-name'
import {useToast} from '../../../hooks/use-toast'
import {WarningIcon} from '../../../components/icons'
import useOrderAction from '../hooks/use-order-action'
import {useProducts} from '@salesforce/commerce-sdk-react'

const OrderTile = ({order}) => {
    const {formatDate, formatMessage} = useIntl()
    const toast = useToast()
    const orderAction = useOrderAction(order)

    const productIds = useMemo(
        () => order?.productItems?.map((item) => item.productId) || [],
        [order]
    )

    // Fetch product details for the order items
    const {data: productDetails1} = useProducts(
        {
            parameters: {
                ids: productIds.slice(0, MAX_IDS_PER_GET_PRODUCTS_REQUEST).join(','),
                allImages: true
            }
        },
        {
            enabled: productIds.length > 0,
            onError: (error) => {
                console.error('Error fetching product details', error)
                toast({
                    status: 'error',
                    title: formatMessage(API_ERROR_MESSAGE)
                })
            }
        }
    )
    const {data: productDetails2} = useProducts(
        {
            parameters: {
                ids: productIds
                    .slice(MAX_IDS_PER_GET_PRODUCTS_REQUEST, 2 * MAX_IDS_PER_GET_PRODUCTS_REQUEST)
                    .join(','),
                allImages: true
            }
        },
        {
            enabled: productIds.length > MAX_IDS_PER_GET_PRODUCTS_REQUEST,
            onError: (error) => {
                console.error('Error fetching product details', error)
                toast({
                    status: 'error',
                    title: formatMessage(API_ERROR_MESSAGE)
                })
            }
        }
    )

    const productDetails = useMemo(
        () => ({
            data: [...(productDetails1?.data || []), ...(productDetails2?.data || [])]
        }),
        [productDetails1, productDetails2]
    )

    // product items with product details to display
    const productItems = useMemo(() => {
        if (!productDetails?.data) {
            return order.productItems
        }

        const productDetailsByIds = Object.fromEntries(
            productDetails.data.map((product) => [product.id, product])
        )
        return order.productItems.map((item) => ({
            ...item,
            ...productDetailsByIds[item.productId],
            productExists: Boolean(productDetailsByIds[item.productId]),
            itemId: item.itemId,
            price: item.price
        }))
    }, [order, productDetails])
    // order date in localized format
    const orderDate = useMemo(
        () =>
            formatDate(new Date(order.creationDate), {
                year: 'numeric',
                day: 'numeric',
                month: 'numeric'
            }),
        [formatDate, order.creationDate]
    )

    return (
        <Flex paddingX={4} paddingY={6} flexDirection="column" gap={6} bgColor="stokkeCore.white">
            <Flex flexDirection="column" gap={4}>
                <Flex flexDirection="column" gap={2}>
                    <Text textStyle="Semi Bold Medium">
                        <FormattedMessage
                            defaultMessage="Order ID {orderNumber}"
                            id="mystokke.orders.label.order_number"
                            values={{orderNumber: order.orderNo}}
                        />
                    </Text>
                    <Text textStyle="Regular Medium" color="stokkeGray.accessibleText">
                        <FormattedMessage
                            defaultMessage="Order date: {orderDate}"
                            id="mystokke.orders.label.order_date"
                            values={{orderDate}}
                        />
                    </Text>
                </Flex>
                <Flex justifyContent="space-between" gap={2}>
                    <Flex gap={1}>
                        <Text textStyle="Regular Small">
                            <FormattedMessage
                                defaultMessage="Status:"
                                id="mystokke.orders.label.status"
                            />
                        </Text>

                        <OrderStatus order={order} />
                    </Flex>

                    <Text textStyle="Regular Small" color="stokkeGray.accessibleText">
                        <FormattedMessage
                            defaultMessage="Total of {count} items"
                            id="mystokke.orders.label.num_of_items"
                            description="Number of items in order"
                            values={{count: order.productItems.length}}
                        />
                    </Text>
                </Flex>
            </Flex>

            <Flex overflowX="auto" marginX={-4} paddingX={4} gap={4}>
                {productItems.map((item, idx) => (
                    <ItemVariantProvider key={item.itemId} index={idx} variant={item}>
                        <Flex flexShrink="0" flexDirection="column" spacing={2} width="8.25rem">
                            <CartItemVariantImage
                                ratio={1}
                                width="100%"
                                link={Boolean(item.productExists)}
                            />
                            <CartItemVariantName
                                textStyle="Regular Small"
                                link={Boolean(item.productExists)}
                            />
                        </Flex>
                    </ItemVariantProvider>
                ))}
            </Flex>

            <Flex justifyContent="space-between" gap={3} flexShrink={1} maxWidth="100%">
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    gap={2}
                    flexShrink={1}
                    minWidth={0}
                >
                    <Flex
                        flexDirection="column"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                        height={11}
                    >
                        <Button
                            as={Link}
                            to={`${ACCOUNT_HREF}/orders/${order.orderNo}`}
                            variant="link"
                        >
                            <FormattedMessage
                                defaultMessage="Show details"
                                id="mystokke.orders.link.view_details"
                            />
                        </Button>
                    </Flex>

                    {orderAction && (
                        <Flex
                            flexDirection="column"
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            height={11}
                            maxWidth={'100%'}
                            minWidth={0}
                        >
                            <Flex gap={2} alignItems="flex-end" minWidth={0} maxWidth="100%">
                                {orderAction.tooltip && (
                                    <Popover
                                        placement="top"
                                        variant="feedbackReportTooltip"
                                        gutter={14}
                                    >
                                        <PopoverTrigger>
                                            <IconButton
                                                minWidth="unset"
                                                height="unset"
                                                minHeight="unset"
                                                lineHeight={0}
                                                paddingBottom={1}
                                                icon={<WarningIcon boxSize={4} />}
                                                variant="unstyled"
                                            />
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <PopoverArrow />
                                            <PopoverBody>{orderAction.tooltip}</PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                )}
                                <Button
                                    {...orderAction.buttonProps}
                                    {...(orderAction.buttonProps?.to ? {as: Link} : {})}
                                    variant="link"
                                    flexShrink={1}
                                    minWidth="0"
                                    maxWidth="100%"
                                    overflow="hidden"
                                    display="block"
                                    textOverflow="ellipsis"
                                >
                                    {orderAction.label}
                                </Button>
                            </Flex>
                        </Flex>
                    )}
                </Flex>

                <Flex
                    maxWidth="11.25rem"
                    flexDirection="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    flexShrink={0}
                    gap={2}
                    alignSelf="stretch"
                >
                    <Text textStyle="Semi Bold Medium">
                        <FormattedMessage defaultMessage="Total" id="mystokke.orders.label.total" />
                    </Text>

                    <Text>
                        <FormattedMessage
                            defaultMessage="{total} incl. tax"
                            id="mystokke.orders.label.total_price"
                            values={{
                                total: (
                                    <FormattedNumber
                                        style="currency"
                                        currency={order.currency}
                                        value={order.orderTotal}
                                    />
                                )
                            }}
                        />
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

OrderTile.propTypes = {
    order: PropTypes.object
}

export default OrderTile
