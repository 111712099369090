import {Text} from '@chakra-ui/react'
import React from 'react'
import {FormattedMessage, FormattedNumber} from 'react-intl'
import {useOrderSummary} from './order-summary-provider'

const OrderSummarySubtotal = () => {
    const basket = useOrderSummary()

    return (
        <>
            <Text>
                <FormattedMessage defaultMessage="Subtotal" id="order_summary.label.subtotal" />
            </Text>
            <Text>
                <FormattedNumber
                    style="currency"
                    currency={basket?.currency}
                    value={basket?.productSubTotal}
                />
            </Text>
        </>
    )
}

export default OrderSummarySubtotal
