import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        container: {
            maxWidth: '100%',
            maxHeight: '100%'
        },
        slider: {
            position: 'relative',
            maxWidth: '100%',
            maxHeight: '100%',
            height: '100%'
        },
        itemsContainer: {
            maxHeight: '100%'
        },
        scrollBar: {
            alignItems: 'stretch',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                height: 0
            },
            touchAction: 'pan-y'
        },
        slide: {
            position: 'relative',
            flex: '0 0 auto',
            width: 'full',
            maxHeight: 'full',
            scrollSnapAlign: 'start'
        },
        sliderButton: {
            position: 'absolute',
            top: '50%',
            display: {
                base: 'none',
                lg: 'block'
            },
            zIndex: 1
        },
        leftSliderButton: {
            left: 6
        },
        rightSliderButton: {
            right: 6
        },
        paginationButton: {
            display: {lg: 'none'},
            position: 'relative'
        },
        leftPaginationButton: {},
        rightPaginationButton: {},
        pagination: {
            padding: '1.5rem 2rem',
            overflow: 'hidden',
            cursor: 'auto'
        },
        stepper: {
            width: '100%',
            textAlign: 'center',
            ...textStyles['Semi Bold Small']
        },
        progressBar: {
            height: 0.5,
            marginY: 6,
            background: 'stokkeGray.mid',
            sx: {
                '& > div': {
                    background: 'black'
                }
            }
        }
    },
    variants: {
        fanreel: {
            slider: {
                paddingX: {base: 0, md: 6, xl: 0}
            },
            sliderButton: {
                display: {
                    base: 'none',
                    md: 'block'
                },
                marginTop: -5.5
            },
            leftSliderButton: {
                left: -5.5
            },
            rightSliderButton: {
                right: -5.5
            },
            paginationButton: {
                display: {md: 'none'}
            },
            leftPaginationButton: {
                left: -1.5
            },
            rightPaginationButton: {
                right: -1.5
            }
        },
        recommendations: {
            leftSliderButton: {
                left: '-1.25rem'
            },
            rightSliderButton: {
                right: '-1.25rem'
            }
        },
        modal: {
            leftSliderButton: {
                left: -16
            },
            rightSliderButton: {
                right: -16
            },
            pagination: {
                justifyContent: 'center'
            },
            slide: {
                paddingInlineStart: {
                    base: 4,
                    lg: '3rem!important'
                },
                paddingInlineEnd: {
                    base: 4,
                    lg: '3rem!important'
                }
            }
        },
        reviews: {
            leftSliderButton: {
                left: 4
            },
            rightSliderButton: {
                right: 4
            },
            stepper: {
                display: 'none'
            },
            progressBar: {
                marginTop: 2,
                marginBottom: 0
            }
        }
    },
    sizes: {},
    defaultProps: {}
}
