import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        button: {
            position: 'absolute',
            top: '0.75rem',
            right: '1rem',
            borderRadius: 'full',
            border: '0.0625rem solid',
            borderColor: 'stokkeGray.bright',
            background: 'stokkeCore.white',
            padding: '0.25rem 0.5rem',
            height: '1.875rem',
            display: {base: 'flex', lg: 'none'},
            alignItems: 'center',
            width: 'fit-content',
            pointerEvents: 'all',
            color: 'stokkkeCore.black',
            gap: 0
        },
        arrowPinnedIcon: {
            height: '0.625rem',
            width: 'auto'
        },
        label: {
            lineHeight: '100%',
            overflow: 'hidden',
            maxWidth: '6.25rem',
            margin: '0 0.375rem',
            ...textStyles['Regular Small']
        },
        colorsIcon: {
            height: '1.125rem',
            width: 'auto'
        }
    },
    parts: ['button', 'icon', 'label', 'arrowPinnedIcon', 'colorsIcon']
}
