import React, {createContext, useEffect, useMemo} from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import {isServer} from '../utils/utils'
import {useCroActiveSlots, useCroResetExperiments} from '../components/cro'
import useEffectEvent from '../hooks/use-effect-event'

const ConvertAPIContext = createContext(null)

/**
 * Provider and associated hook for accessing the Convert API in React components.
 */
export const ConvertAPIProvider = ({children, convertScriptUrl, disabled = false}) => {
    const convertApi = useMemo(() => {
        const push = (...args) => {
            if (isServer || disabled) return
            window._conv_q = window._conv_q || []
            window._conv_q.push(...args)
        }

        return {
            triggerPoll: () => {
                push(['run', 'true'])
            }
        }
    }, [disabled])

    // trigger poll when cro slots change
    const resetCroExperiments = useCroResetExperiments()
    const croSlots = useCroActiveSlots()
    const onCroSlotsChange = useEffectEvent(() => {
        // as convert doesn't provide deactivate functionality
        // we clear all active experiments before triggering reevaluation
        // so only the remaining active experiments are marked as active
        resetCroExperiments()
        convertApi.triggerPoll()
    })
    useEffect(() => {
        onCroSlotsChange(croSlots)
    }, [croSlots, onCroSlotsChange])

    return (
        <ConvertAPIContext.Provider value={convertApi}>
            {!disabled && (
                <Helmet>
                    <script src={convertScriptUrl} async />
                </Helmet>
            )}
            {children}
        </ConvertAPIContext.Provider>
    )
}

if (process.env.NODE_ENV !== 'production') {
    ConvertAPIProvider.displayName = 'ConvertAPIProvider'
}

ConvertAPIProvider.propTypes = {
    children: PropTypes.any,
    convertScriptUrl: PropTypes.string,
    disabled: PropTypes.bool
}
