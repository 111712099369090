import {useEffect, useMemo} from 'react'
import useAutocomplete from '../../googlemaps-api/hooks/useAutocomplete'
import {stateOptions} from './state-province-options'
import useEffectEvent from '../../hooks/use-effect-event'
import {useCountry} from '../../hooks'

/**
 * A React hook that integrates google places autocomplete widget and fills address fields on place selection.
 * @param {import('react-hook-form').UseFormMethods} form - The object returned from `useForm`
 * @param {string} [prefix]
 * @returns {Object} Field definitions for use in a form
 * @returns {{ref: (HTMLInputElement) => void}} reference to assign the input that will host the widget
 */
export default function useAutocompleteAddress(form, prefix = '') {
    const countryCode = useCountry()
    const autocompleteOptions = useMemo(
        () =>
            /** @type google.maps.places.AutocompleteOptions */ ({
                componentRestrictions: {
                    country: countryCode
                },
                fields: ['address_components', 'name'],
                strictBounds: true,
                types: ['address']
            }),
        [countryCode]
    )
    const {ref, place} = useAutocomplete(autocompleteOptions)

    const onAddressChange = useEffectEvent(({address1, postcode, stateCode, city}) => {
        form.setValue(`${prefix}address1`, address1, {shouldValidate: true})
        form.setValue(`${prefix}postalCode`, postcode, {shouldValidate: true})
        if (form?.control?.fieldsRef?.current?.stateCode) {
            form.setValue(`${prefix}stateCode`, stateCode, {shouldValidate: true})
        }
        form.setValue(`${prefix}city`, city, {shouldValidate: true})
    })
    useEffect(() => {
        if (!place || !place.address_components) {
            return
        }

        // use the place name as it is region formatted
        let address1 = place.name
        let postcode = ''
        let city = ''
        let stateCode = ''

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of place.address_components) {
            const componentType = component.types[0]

            switch (componentType) {
                case 'postal_code':
                case 'postal_code_prefix':
                    postcode = `${component.long_name}${postcode}`
                    break

                case 'postal_code_suffix': {
                    postcode = `${postcode}-${component.long_name}`
                    break
                }

                case 'sublocality_level_1':
                case 'locality':
                case 'postal_town':
                    city = component.long_name
                    break

                case 'administrative_area_level_1': {
                    stateCode = getStateCode(countryCode, component.short_name)
                    break
                }

                case 'country':
                    // verify the place is in the same country as the portal
                    if (component.short_name !== countryCode) {
                        if (process.env.NODE_ENV !== 'production') {
                            console.error('received autocomplete result from another country', {
                                expected: countryCode,
                                actual: component.short_name
                            })
                        }
                        return
                    }
                    break
            }
        }

        onAddressChange({address1, postcode, stateCode, city})
    }, [countryCode, place, onAddressChange])

    return {ref}
}

export const getStateCode = (countryCode, shortName) =>
    countryCode === 'JP'
        ? stateOptions.JP.find((elem) => elem.label === shortName)?.value
        : shortName
