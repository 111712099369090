import {
    AspectRatio,
    Box,
    Flex,
    Skeleton as ChakraSkeleton,
    useMultiStyleConfig
} from '@chakra-ui/react'
import React from 'react'

/**
 * The skeleton representation of the image gallery component. Use this component while
 * you are waiting for product data to be returnd from the server.
 */
const ImageGallerySkeleton = (props) => {
    const styles = useMultiStyleConfig('ImageGallery', props)

    return (
        <Box data-testid="sf-image-gallery-skeleton">
            <Flex flexDirection="column">
                <AspectRatio ratio={7 / 8} {...styles.heroImageSkeleton}>
                    <ChakraSkeleton />
                </AspectRatio>
                <Flex>
                    {new Array(4).fill(0).map((_, index) => (
                        <AspectRatio ratio={1} {...styles.thumbnailImageSkeleton} key={index}>
                            <ChakraSkeleton />
                        </AspectRatio>
                    ))}
                </Flex>
            </Flex>
        </Box>
    )
}

export default ImageGallerySkeleton
