export default {
    parts: ['badgeGroup', 'badge'],
    baseStyle: {
        badgeGroup: {
            flexWrap: 'wrap',
            gap: 1
        },
        badge: {
            whiteSpace: 'nowrap',
            borderRadius: 5,
            padding: 1,
            textStyle: 'Badge Regular'
        }
    },
    variants: {
        babyzen: {
            badge: {
                backgroundColor: 'stokkeBabyzen.green',
                color: 'stokkeCore.black'
            }
        },
        brand: {
            badge: {
                backgroundColor: 'stokkeBeige.bright',
                color: 'stokkeCore.black'
            }
        },
        permanent: {
            badge: {
                backgroundColor: 'stokkeCore.black',
                color: 'stokkeCore.white'
            }
        },
        nonPermanent: {
            badge: {
                backgroundColor: 'stokkeGray.mid',
                color: 'stokkeCore.black'
            }
        }
    }
}
