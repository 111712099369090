import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import useOcapiSite from '../../hooks/useOcapiSite'
import useEinstein from '/app/hooks/use-einstein'
import {EMPLIFI_MAX_LOAD_DELAY} from '../../constants'
import {useUserInteraction} from '../../hooks/use-user-interaction'
import {useLocation} from 'react-router-dom'
import {removeSiteLocaleFromPath} from '../../utils/url'
import {useNonce} from '../../hooks'
import {useIntl} from 'react-intl'
import useEffectEvent from '../../hooks/use-effect-event'
import PropTypes from 'prop-types'
import {useStokkeAddItemToBasketMutation} from '../../hooks/useStokkeHelpers'

const EmplifiContext = createContext()

export const EmplifiProvider = ({children}) => {
    const {locale} = useIntl()
    const nonce = useNonce()
    const einstein = useEinstein()
    const {userInteracted} = useUserInteraction()
    const location = useLocation()
    const {
        GoInStoreAccountToken,
        GoInStoreScriptUrl,
        isGoInStoreEnabled,
        GoInStoreAppendLocale,
        GoInStoreExcludedPages = '/appcart,/appcheckout,/cancel-order'
    } = useOcapiSite().preferences
    const [isLoadable, setIsLoadable] = useState(false)
    const [isVisible, setIsVisible] = useState(true)

    const excludedPages = useMemo(
        () => GoInStoreExcludedPages?.split(',').map((page) => page.trim()) || [],
        [GoInStoreExcludedPages]
    )

    const {mutateAsync: addItemToBasket} = useStokkeAddItemToBasketMutation()

    const isEnabled = useMemo(() => {
        if (!isGoInStoreEnabled || (!GoInStoreScriptUrl && !GoInStoreAccountToken)) {
            return false
        }
        const pathWithoutLocale = removeSiteLocaleFromPath(location.pathname)
        const isExcluded = excludedPages.some((path) => pathWithoutLocale.startsWith(path))
        return !isExcluded
    }, [
        excludedPages,
        GoInStoreAccountToken,
        GoInStoreScriptUrl,
        isGoInStoreEnabled,
        location.pathname
    ])

    // update widget visibility
    const gisHide = !(isEnabled && isVisible)
    useEffect(() => {
        window.gis = window.gis || {}
        window.gis.hide = gisHide
        if (window.hideGis) {
            window.hideGis()
        }
        // fallback to trigger page change event
        else if (window.GisCustomEvent) {
            window.dispatchEvent(new window.GisCustomEvent('gisPageChange'))
        } else if (window.CustomEvent) {
            // fallback to regular custom event
            window.dispatchEvent(new window.CustomEvent('gisPageChange'))
        }
    }, [gisHide])

    // notify emplifi the page has changed
    useEffect(() => {
        if (window.GisCustomEvent) {
            window.dispatchEvent(new window.GisCustomEvent('gisPageChange'))
        } else if (window.CustomEvent) {
            // fallback to regular custom event
            window.dispatchEvent(new window.CustomEvent('gisPageChange'))
        }
    }, [location.pathname])

    // expose the addToCart function to emplifi
    const addToCart = useCallback(
        async (productId, quantity) => {
            const itemsArray = []
            for (let i = 0; i < quantity; i++) {
                itemsArray.push({productId: productId.toString(), quantity: 1})
            }
            await addItemToBasket({productItems: itemsArray})

            // einstein
            itemsArray.map((item) => einstein.sendAddToCart(item))
        },
        [einstein, addItemToBasket]
    )
    useEffect(() => {
        window['addToCart'] = addToCart
        return () => {
            delete window['addToCart']
        }
    }, [addToCart])

    // trigger loading the widget after a certain delay
    useEffect(() => {
        if (isEnabled && !isLoadable) {
            const timeout = setTimeout(() => setIsLoadable(true), EMPLIFI_MAX_LOAD_DELAY)
            return () => clearTimeout(timeout)
        }
    }, [isEnabled, isLoadable])

    // trigger loading on user interaction
    useEffect(() => {
        if (isEnabled && userInteracted) {
            setIsLoadable(true)
        }
    }, [isEnabled, userInteracted])

    // load the script manually, since helmet replicates the script tag
    const onLoadEmplifi = useEffectEvent(() => {
        let scriptSrc = GoInStoreScriptUrl
            ? GoInStoreScriptUrl
            : `//gis.GoInStore.com/gis/script/${GoInStoreAccountToken}`
        if (locale === 'en-GB' || locale === 'en-IE') {
            scriptSrc = `${scriptSrc}?locale=en_GB`
        } else if (locale.indexOf('en') === 0) {
            scriptSrc = `${scriptSrc}?locale=en_US`
        } else if (GoInStoreAppendLocale) {
            const language = locale.split('-')[0]
            scriptSrc = `${scriptSrc}?locale=${language}_${language.toUpperCase()}`
        }
        const script = document.createElement('script')
        script.src = scriptSrc
        script.nonce = nonce
        script.defer = true
        document.head.appendChild(script)
    })
    useEffect(() => {
        if (isLoadable) {
            onLoadEmplifi()
        }
    }, [isLoadable, onLoadEmplifi])

    const showWidget = useCallback(() => {
        setIsVisible(true)
    }, [])
    const hideWidget = useCallback(() => {
        setIsVisible(false)
    }, [])

    const ctx = useMemo(
        () => ({
            enabled: isEnabled,
            show: showWidget,
            hide: hideWidget
        }),
        [isEnabled, showWidget, hideWidget]
    )

    return <EmplifiContext.Provider value={ctx}>{children}</EmplifiContext.Provider>
}

EmplifiProvider.propTypes = {
    children: PropTypes.any
}

export const useEmplifi = () => {
    const ctx = useContext(EmplifiContext)
    if (!ctx) {
        throw new Error('useEmplifi must be used within EmplifiProvider')
    }
    return ctx
}
