import {
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    Flex,
    IconButton,
    Slide,
    Text,
    useMultiStyleConfig
} from '@chakra-ui/react'
import {BackArrowLeftIcon, CloseIcon} from '../icons'
import {FormattedMessage} from 'react-intl'
import Lvl0Item from './lvl0-item'
import DrawerApplicationLinks from './drawer-application-links'
import DrawerFooterActions from './drawer-footer-actions'
import Lvl2Item from './lvl2-item'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import DrawerMenuError from './drawer-menu-error'
import ContentSlot from '../content-slot'
import TeaserItem from './teaser-item'

/**
 * Helper to use when filtering items that should not be displayed in the mobile drawer menu
 * @param {Object} item - the item to filter
 * @return {boolean} - true if the item should be displayed, false otherwise
 */
const filterItems = (item) => item.hide !== 'mobile'

/**
 * Renders mobile version of the drawer menu. Initially displays lvl0 and lvl1 items. When a lvl1 item is clicked, a slide in displays its children.
 */
const DrawerMenuMobile = ({
    items,
    selectedLvl0,
    selectedLvl1,
    setSelectedLvl0,
    setSelectedLvl1,
    onClose,
    onMyAccountClick
}) => {
    const styles = useMultiStyleConfig('DrawerMenu', {variant: 'mobile'})

    // have a separate state for the item rendered on column2 to allow on back the exit animation to play
    const [column2Item, setColumn2Item] = useState(null)

    // helper for setting the selected item and its parent
    const setSelectedItem = useCallback(
        (lvl1, lvl0) => {
            setSelectedLvl0?.(lvl0)
            setSelectedLvl1?.(lvl1)
            setColumn2Item(lvl1)
        },
        [setSelectedLvl0, setSelectedLvl1]
    )

    // update column2 with currently selected item if it is visible on mobile
    useEffect(() => {
        if (selectedLvl1 && filterItems(selectedLvl1)) {
            setColumn2Item(selectedLvl1)
        } else if (selectedLvl0?.variant === 'teaser' && filterItems(selectedLvl0)) {
            setColumn2Item(selectedLvl0)
        }
    }, [selectedLvl0, selectedLvl1])

    // helper for handling back button click
    const onBackClick = useCallback(() => {
        // clear only the selected lvl1 item as we want to keep the lvl0 item selected for the desktop version
        setSelectedItem(null, selectedLvl0)
    }, [selectedLvl0, setSelectedItem])

    // filter lvl0 items and group them by teaser and regular items
    const displayedLvl0Items = useMemo(
        () =>
            items?.filter(filterItems).reduce((acc, lvl0) => {
                const lastLvl0 = acc[acc.length - 1]
                if (lvl0.variant === 'teaser' || lvl0.link || !lvl0.items?.length) {
                    if (!lastLvl0 || lastLvl0.variant !== 'teaser') {
                        acc.push({variant: 'teaser', items: [lvl0]})
                    } else {
                        lastLvl0.items.push(lvl0)
                    }
                } else {
                    acc.push(lvl0)
                }
                return acc
            }, []),
        [items]
    )

    // group children of the selected lvl1 item for display in the appropriate place
    const {
        items: displayedLvl2Items,
        contentSlots: displayedContentSlots,
        teasers: displayedLvl2TeaserItems
    } = useMemo(() => {
        const contentSlots = []
        const items = []
        const teasers = []
        column2Item?.items?.filter(filterItems).forEach((item) => {
            switch (item.type) {
                case 'ContentSlot':
                    contentSlots.push(item)
                    break
                case 'NavigationLink':
                    if (item.variant === 'teaser') {
                        teasers.push(item)
                    } else {
                        items.push(item)
                    }
                    break
                case 'NavigationItem':
                    items.push(item)
                    break
                default:
                    console.error(`Unexpected lvl2 item type "${item.type}"`, {
                        lvl2: item,
                        lvl1: column2Item
                    })
                    break
            }
        })
        return {items, contentSlots, teasers}
    }, [column2Item])

    const lvl2NavigationItems = useMemo(
        () => displayedLvl2Items.filter((item) => item.type === 'NavigationItem'),
        [displayedLvl2Items]
    )

    const lvl2NavigationLinks = useMemo(
        () => displayedLvl2Items.filter((item) => item.type === 'NavigationLink'),
        [displayedLvl2Items]
    )

    return (
        <>
            {/* Header Content */}
            <DrawerHeader
                justifyContent="space-between"
                paddingBlock={2}
                borderBottom="1px"
                borderColor="stokkeGray.mid"
            >
                <IconButton
                    {...styles.backButton}
                    visibility="hidden"
                    icon={<BackArrowLeftIcon boxSize={4} {...styles.backButtonIcon} />}
                    onClick={onBackClick}
                />

                <Text {...styles.title}>
                    <FormattedMessage id="header.title.menu" defaultMessage="Menu" />
                </Text>

                <IconButton
                    {...styles.closeButton}
                    icon={<CloseIcon boxSize={4} {...styles.closeButtonIcon} />}
                    onClick={onClose}
                />
            </DrawerHeader>
            {/* Main Content */}
            <DrawerBody>
                {displayedLvl0Items?.length ? (
                    displayedLvl0Items.map((item, index) => (
                        <Lvl0Item
                            key={index}
                            item={item}
                            filterItems={filterItems}
                            onClick={setSelectedItem}
                        />
                    ))
                ) : (
                    <DrawerMenuError error="No items to display" details={items} />
                )}

                <DrawerApplicationLinks />
            </DrawerBody>
            <DrawerFooter>
                <DrawerFooterActions onMyAccountClick={onMyAccountClick} />
            </DrawerFooter>
            {/* column 2 */}
            {column2Item ? (
                <Slide
                    in={selectedLvl1 === column2Item || selectedLvl0 === column2Item}
                    unmountOnExit
                    direction="left"
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <DrawerHeader justifyContent="flex-end">
                        <IconButton
                            {...styles.closeButton}
                            icon={<CloseIcon boxSize={4} {...styles.closeButtonIcon} />}
                            onClick={onClose}
                        />
                    </DrawerHeader>
                    <DrawerHeader
                        gap={2}
                        paddingInlineEnd={6}
                        paddingBottom={1.5}
                        borderBottom="1px"
                        borderColor="stokkeGray.mid"
                    >
                        <IconButton
                            {...styles.backButton}
                            icon={<BackArrowLeftIcon boxSize={4} {...styles.backButtonIcon} />}
                            onClick={onBackClick}
                        />

                        <Text {...styles.title}>{column2Item.label}</Text>
                    </DrawerHeader>
                    <DrawerBody padding={4} gap={4} className="navigation-container">
                        {column2Item?.variant === 'teaser' ? (
                            displayedLvl2TeaserItems?.length ? (
                                displayedLvl2TeaserItems.map((item, index) => (
                                    <TeaserItem {...item} key={index} />
                                ))
                            ) : (
                                <DrawerMenuError
                                    error="No items to display"
                                    details={column2Item}
                                />
                            )
                        ) : (
                            <>
                                {displayedLvl2Items?.length ? (
                                    <>
                                        {!!lvl2NavigationItems &&
                                            lvl2NavigationItems.map((item, index) => (
                                                <Lvl2Item
                                                    key={index}
                                                    item={item}
                                                    filterItems={filterItems}
                                                    index={index}
                                                    length={displayedLvl2Items.length}
                                                />
                                            ))}

                                        {!!lvl2NavigationLinks &&
                                            lvl2NavigationLinks.length > 0 && (
                                                <Flex gap={2} flexDirection="column">
                                                    {lvl2NavigationLinks.map((item, index) => (
                                                        <Lvl2Item
                                                            key={index}
                                                            item={item}
                                                            filterItems={filterItems}
                                                            index={index}
                                                            length={displayedLvl2Items.length}
                                                        />
                                                    ))}
                                                </Flex>
                                            )}
                                    </>
                                ) : (
                                    <DrawerMenuError
                                        error="No items to display"
                                        details={column2Item}
                                    />
                                )}

                                {displayedContentSlots?.length ? (
                                    <Flex
                                        gap={4}
                                        paddingBottom={4}
                                        flexDirection="row"
                                        flexWrap="wrap"
                                    >
                                        {displayedContentSlots.map((item, index) => (
                                            <ContentSlot
                                                key={index}
                                                slotId={item.id}
                                                showError={false}
                                                variant="simple-cms"
                                            />
                                        ))}
                                    </Flex>
                                ) : null}
                            </>
                        )}
                    </DrawerBody>
                </Slide>
            ) : null}
        </>
    )
}

DrawerMenuMobile.propTypes = {
    items: PropTypes.array,
    selectedLvl0: PropTypes.object,
    selectedLvl1: PropTypes.object,
    setSelectedLvl0: PropTypes.func,
    setSelectedLvl1: PropTypes.func,
    onClose: PropTypes.func,
    onMyAccountClick: PropTypes.func
}

export default DrawerMenuMobile
