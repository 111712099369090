import {useIntl} from 'react-intl'
import {
    VALIDATE_EMAIL_PATTERN,
    GENERAL_FORM_VALIDATION_MESSAGES as generalMessages
} from '../../constants'

export default function useNewsletterFields({
    form: {
        control,
        formState: {errors}
    },
    prefix = ''
}) {
    const {formatMessage} = useIntl()
    const invalidEmailMessage = formatMessage({
        defaultMessage: 'Please enter a valid email address.',
        id: 'use_newsletter_fields.error.required_email'
    })
    const labelFirstName = formatMessage({
        defaultMessage: 'First Name',
        id: 'newsletter_banner.text.first_name_field_label'
    })

    return {
        emailWithButton: {
            name: `${prefix}email`,
            type: 'email',
            id: 'newsletter-email',
            defaultValue: '',
            rules: {
                maxLength: {
                    message: formatMessage({
                        defaultMessage: 'Email can not be longer than 50 characters.',
                        id: 'newsletter_banner.error.email_no_longer_than_50'
                    }),
                    value: 50
                },
                required: {
                    message: invalidEmailMessage,
                    value: true
                },
                pattern: {
                    message: invalidEmailMessage,
                    value: VALIDATE_EMAIL_PATTERN
                }
            },
            error: errors[`${prefix}email`],
            inputProps: {
                autoComplete: 'email'
            },
            label: formatMessage({
                defaultMessage: 'Email',
                id: 'newsletter_banner.text.email_field_label'
            }),
            placeholder: formatMessage({
                defaultMessage: 'Enter your email address',
                id: 'newsletter_banner.text.email_field_placeholder'
            }),
            control
        },
        firstName: {
            name: `${prefix}first_name`,
            label: labelFirstName,
            placeholder: formatMessage({
                defaultMessage: 'Enter your first name',
                id: 'newsletter_banner.text.first_name_field_placeholder'
            }),
            type: 'text',
            defaultValue: '',
            rules: {
                maxLength: {
                    message: formatMessage(generalMessages.maxLength, {
                        label: labelFirstName,
                        n: 40
                    }),
                    value: 40
                }
            },
            error: errors[`${prefix}first_name`],
            inputProps: {
                autoComplete: 'given-name'
            },
            control
        },
        lastName: {
            name: `${prefix}last_name`,
            label: formatMessage({
                defaultMessage: 'Last name',
                id: 'newsletter_banner.text.last_name_field_label'
            }),
            placeholder: formatMessage({
                defaultMessage: 'Enter your last name',
                id: 'newsletter_banner.text.last_name_field_placeholder'
            }),
            type: 'text',
            defaultValue: '',
            rules: {
                maxLength: {
                    message: formatMessage({
                        defaultMessage: 'Last name can not be longer than 50 characters.',
                        id: 'newsletter_banner.error.last_name_no_longer_than_50'
                    }),
                    value: 50
                }
            },
            error: errors[`${prefix}last_name`],
            inputProps: {
                autoComplete: 'family-name'
            },
            control
        }
    }
}
