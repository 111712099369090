import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {HYBRID_CONTENT} from '../../constants'
import Checkbox from '../checkbox'
import HybridLink from '../hybrid-link'

const MarketingOptIn = ({testid, value, onChange, viewExtendedDescription}) => {
    onChange = onChange || ((newValue) => (value = newValue))

    return (
        <Checkbox
            size="sm"
            name="marketingOptIn"
            isChecked={value}
            data-testid={testid}
            onChange={(e) => {
                onChange(e.target.checked)
            }}
        >
            {!viewExtendedDescription && (
                <FormattedMessage
                    defaultMessage="Yes, I would like to be informed by email about new products, product features, gift ideas, promotions, personalised offers (e.g. abandoned cart, up- & cross selling) based on my purchase and cart history, collaborations and much more from the Stokke Group (Stokke AS and its subsidiaries). This offer is free of charge, unsubscribe link in every newsletter. What personal data we process, why and how: {privacy}."
                    id="forms.marketing"
                    values={{
                        privacy: (
                            <HybridLink
                                page={HYBRID_CONTENT}
                                href="/customer-service/legal/privacy-policy.html"
                                isExternal
                            >
                                <FormattedMessage
                                    defaultMessage="Privacy Policy"
                                    id="footer.link.privacy_policy"
                                />
                            </HybridLink>
                        )
                    }}
                />
            )}
            {viewExtendedDescription && (
                <FormattedMessage
                    defaultMessage="Yes, I would like to be informed by email about new products, product features, gift ideas, promotions, personalised offers (e.g. abandoned cart, up- & cross selling) based on my purchase and cart history, collaborations and much more from the Stokke Group (Stokke AS and its subsidiaries). This offer is free of charge, unsubscribe link in every newsletter. I agree to the privacy policy. What personal data we process, why and how: {privacy}."
                    id="forms.marketing2"
                    values={{
                        privacy: (
                            <HybridLink
                                page={HYBRID_CONTENT}
                                href="/customer-service/legal/privacy-policy.html"
                                isExternal
                                fontSize="inherit"
                            >
                                <FormattedMessage
                                    defaultMessage="Privacy Policy"
                                    id="footer.link.privacy_policy"
                                />
                            </HybridLink>
                        )
                    }}
                />
            )}
        </Checkbox>
    )
}

MarketingOptIn.propTypes = {
    testid: PropTypes.string,
    value: PropTypes.bool,
    viewExtendedDescription: PropTypes.bool,
    onChange: PropTypes.func
}

export default MarketingOptIn
