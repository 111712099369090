import identity from 'lodash/identity'

/**
 * A class that merges multiple requests into a single request, executes it and then splits the result to the individual request promises.
 */
class RequestMerger {
    /**
     * @param {number} throttleInterval - The time in milliseconds to wait before merging all pending requests and executing as a single merged one.
     * @param {function (requests: Array): any} onMerge - The function to call to merge all pending requests into a single request.
     * @param {function (mergedRequests: any): Promise} onExecute - The function to call to execute the merged request.
     * @param {function (mergedResponse: any, request: any): any} onSplit - The function to call to split the merged response into individual responses.
     */
    constructor({throttleInterval = 0, onMerge = identity, onExecute = fetch, onSplit = identity}) {
        this._throttleInterval = throttleInterval
        this._onMerge = onMerge
        this._onExecute = onExecute
        this._onSplit = onSplit
        this._pendingRequests = []
        this._throttleTimer = null
    }

    /**
     * @param {Array} requests
     * @returns {{request: any, resolve: (value: any) => any, reject: (reason: any) => any, signal?: AbortSignal}|null}
     * @private
     */
    _merge(requests) {
        // If there are no requests, return null
        if (requests.length === 0) {
            return null
        }

        // If all requests have a signal, create an AbortController to be able to abort the merged request
        const allWithSignal = requests.every(({signal}) => signal)
        const abortController = allWithSignal ? new AbortController() : null
        if (allWithSignal) {
            // Unique signals
            const signals = new Set(requests.map(({signal}) => signal).filter(Boolean))
            // Set with aborted signals
            const aborted = new Set([...signals].filter((signal) => signal.aborted))
            // Set with remaining live signals
            const live = [...signals].filter((signal) => !signal.aborted)
            // Add an abort event listener to each live signal
            live.forEach((signal) => {
                signal.addEventListener('abort', () => {
                    // Add the signal to the aborted set
                    aborted.add(signal)
                    // If all signals are aborted, abort the merged request
                    if (aborted.size === signals.size) {
                        abortController.abort()
                    }
                })
            })
            // If all signals are already aborted, return null
            if (live.length === 0) {
                return null
            }
        }
        return {
            ...(abortController && {signal: abortController.signal}),
            request: this._onMerge(requests.map(({request}) => request)),
            resolve: (response) => {
                requests.map(({signal, request, resolve}) => {
                    if (!signal?.aborted) {
                        resolve(this._onSplit(response, request))
                    }
                })
            },
            reject: (error) => {
                requests.map(({signal, reject}) => {
                    if (!signal?.aborted) {
                        reject(error)
                    }
                })
            }
        }
    }

    /**
     * @private
     */
    _throttle() {
        // If there is already a throttle timer, return
        if (this._throttleTimer) {
            return
        }

        // Create a throttle timer
        this._throttleTimer = setTimeout(() => {
            this._throttleTimer = null
            const requestsToMerge = this._pendingRequests
            this._pendingRequests = []
            const mergedRequest = this._merge(requestsToMerge)
            if (!mergedRequest) {
                return
            }
            const {signal, request, resolve, reject} = mergedRequest
            const args = [request]
            if (signal) {
                args.push({signal})
            }
            this._onExecute(...args).then(resolve, reject)
        }, this._throttleInterval)
    }

    /**
     * Queue a request to be merged and executed.
     * @param {any} request - The request to queue.
     * @param {AbortSignal} [signal] - An optional signal that can be used to abort the request.
     * @returns {Promise} A promise that resolves with the response of the request.
     */
    queue(request, {signal} = {}) {
        return new Promise((resolve, reject) => {
            signal?.addEventListener('abort', () => {
                // Remove the request from the pending requests
                this._pendingRequests = this._pendingRequests.filter(
                    (pendingRequest) => pendingRequest.signal !== signal
                )
                // Reject the promise with an AbortError
                reject(
                    signal.reason || new DOMException('The operation was aborted.', 'AbortError')
                )
            })
            // Add the request to the pending requests
            this._pendingRequests.push({
                request,
                resolve,
                reject,
                signal
            })
            // Throttle the requests
            this._throttle()
        })
    }
}

export default RequestMerger
