import {createContext, useContext} from 'react'

/**
 * Provider and associated hook for accessing the Google maps API in React components.
 */
const GoogleMapsAPIContext = createContext(null)
export const GoogleMapsAPIProvider = GoogleMapsAPIContext.Provider

/**
 * @returns {() => Promise<typeof google.maps>}
 */
export const useGoogleMapsApiLoader = () => {
    const googleMapsAPILoader = useContext(GoogleMapsAPIContext)

    if (!googleMapsAPILoader) {
        throw new Error('useGoogleMapsApiLoader must be used within a GoogleMapsAPIProvider')
    }

    return googleMapsAPILoader.loadApi
}
