import React from 'react'
import {Box, Button} from '@chakra-ui/react'
import useHybridURL from '../../hooks/use-hybrid-url'
import PropTypes from 'prop-types'
import {trackNavigationClick} from '../../tracking/analytics'

const HeaderNavigationItem = ({active, label, image, special, link, id, onClick: _onClick}) => {
    const navigateHybrid = useHybridURL()

    const iconEl = image ? (
        <Box
            as="span"
            display="inline-block"
            backgroundSize="contain"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundImage={`url(${image})`}
            boxSize="1.3em"
        />
    ) : null

    const onClick = (e) => {
        e.preventDefault()
        if (link) {
            navigateHybrid(link)
        } else {
            _onClick?.(id)
        }
        trackNavigationClick(0, label)
    }

    return (
        <Button
            variant={special ? 'headerNavigationLinkSpecial' : 'headerNavigationLink'}
            size="sm"
            isActive={active}
            isDisabled={!link && !_onClick}
            {...(link && {
                as: 'a',
                href: link
            })}
            {...(iconEl && {rightIcon: iconEl})}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}

HeaderNavigationItem.propTypes = {
    active: PropTypes.bool,
    label: PropTypes.string,
    image: PropTypes.string,
    special: PropTypes.bool,
    link: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func
}

const HeaderNavigation = ({navigation, selected, onClick}) => {
    return (
        <Box
            display={{base: 'none', lg: 'flex'}}
            columnGap={6}
            // have a huge gap between rows to make sure only the first row is visible
            rowGap="100vh"
            alignItems="center"
            alignSelf="stretch"
            // allow wrapping and hide the overflow
            flexWrap="wrap"
            textStyle="Regular Small"
            // this is the height of the logo that determines the height of the header
            maxHeight={{lg: 14, xl: 16}}
            overflow="hidden"
        >
            {/* the box serves to expand the flex row to the full height of the flex and thus the rest of the elements are visually centered */}
            <Box
                // equal to the header height
                height={{lg: 14, xl: 16}}
                // equal to the column gap to negate the existence of the box
                marginInlineEnd={-6}
            />
            {navigation
                .filter((item) => item && item.hide !== 'desktop')
                .map((item, index) => (
                    <HeaderNavigationItem
                        {...item}
                        key={index}
                        active={selected === item.id}
                        onClick={onClick}
                    />
                ))}
        </Box>
    )
}

HeaderNavigation.propTypes = {
    navigation: PropTypes.array,
    selected: PropTypes.string,
    onClick: PropTypes.func
}

export default HeaderNavigation
