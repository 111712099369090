import {useKindlyChat} from './index'
import {useEffect, useState} from 'react'
import useEffectEvent from '../../hooks/use-effect-event'
import {trackEvent} from '../../tracking/analytics'

const KindlyChatTracking = () => {
    const {enabled, opened} = useKindlyChat()

    // track based on the opened state as there are duplicate events when the chat is closed
    const [openedEffectInitialRunPassed, setOpenedEffectInitialRunPassed] = useState(false)
    const onOpenedChange = useEffectEvent((opened) => {
        if (!openedEffectInitialRunPassed) {
            setOpenedEffectInitialRunPassed(true)
            return
        }
        if (opened) {
            trackEvent({
                event: 'kindly_openchat',
                event_category: 'kindly_chat',
                event_action: 'open',
                event_label: window.location.href
            })
        } else {
            trackEvent({
                event: 'kindly_closechat',
                event_category: 'kindly_chat',
                event_action: 'close',
                event_label: window.location.href
            })
        }
    })
    useEffect(() => {
        onOpenedChange(opened)
    }, [opened, onOpenedChange])

    // track events fired by the Kindly chat
    const onKindlyChatLoad = useEffectEvent(() => {
        trackEvent({
            event: 'kindly_load',
            event_category: 'kindly_chat',
            event_action: 'load',
            event_label: window.location.href
        })
    })
    const onKindlyButtonClick = useEffectEvent((event) => {
        trackEvent({
            event: 'kindly_buttonclick',
            event_category: 'kindly_chat',
            event_action: 'button_click',
            event_label: event.detail.buttonType
        })
    })
    const onKindlyUIEvent = useEffectEvent((event) => {
        switch (event.detail.type) {
            case 'show-feedback':
                trackEvent({
                    event: 'kindly_show_feedback',
                    event_category: 'kindly_chat',
                    event_action: 'show_feedback',
                    event_label: event.detail.value.feedbackType
                })
                break
            case 'submit-feedback':
                trackEvent({
                    event: 'kindly_submit_feedback',
                    event_category: 'kindly_chat',
                    event_action: 'submit_feedback',
                    event_label: event.detail.value.feedbackType
                })
                break
        }
    })
    useEffect(() => {
        if (!enabled) return
        const cleanups = []

        document.addEventListener('kindly:load', onKindlyChatLoad)
        cleanups.push(() => {
            document.removeEventListener('kindly:load', onKindlyChatLoad)
        })

        window.addEventListener('kindly:buttonclick', onKindlyButtonClick)
        cleanups.push(() => {
            window.removeEventListener('kindly:buttonclick', onKindlyButtonClick)
        })

        document.addEventListener('kindly:ui', onKindlyUIEvent)
        cleanups.push(() => {
            document.removeEventListener('kindly:ui', onKindlyUIEvent)
        })

        return () => {
            cleanups.forEach((cleanup) => cleanup())
        }
    }, [enabled, onKindlyChatLoad, onKindlyButtonClick, onKindlyUIEvent])

    return null
}

export default KindlyChatTracking
