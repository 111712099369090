import {createContext, useContext} from 'react'

const MyStokkeRootPathContext = createContext(null)

export const MyStokkeRootPathProvider = MyStokkeRootPathContext.Provider

/**
 * Return the current my stokke root path without site and locale. Useful for constructing links within the my stokke section
 *
 * @return {string}
 */
const useMyStokkeRootPath = () => {
    const context = useContext(MyStokkeRootPathContext)

    if (!context) {
        throw new Error('useMyStokkeRootPath must be used within a MyStokkeRootPathProvider')
    }

    return context
}

export default useMyStokkeRootPath
