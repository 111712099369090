import {Box} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, {forwardRef, useMemo} from 'react'
import {FormattedMessage} from 'react-intl'
import RecommendedProducts from '../recommended-products'
import {RECOMMENDED_ACCESSORIES_TYPE, MAXIMUM_NUM_OF_RECOMMENDED_ACCESSORIES} from '../../constants'

const getRecommendationStructure = (products) => {
    const result = {
        recommendations: [],
        type: 'Product'
    }
    let resultStructureIsExtended = true
    while (
        resultStructureIsExtended &&
        result.recommendations.length <= MAXIMUM_NUM_OF_RECOMMENDED_ACCESSORIES
    ) {
        resultStructureIsExtended = false
        products.forEach((product) => {
            if (
                product?.recommendations &&
                result.recommendations.length < MAXIMUM_NUM_OF_RECOMMENDED_ACCESSORIES
            ) {
                for (let i = 0; i < product?.recommendations.length; i++) {
                    let rec = product?.recommendations[i]
                    let duplication = result.recommendations.find(
                        (res) => res.recommendedItemId === rec.recommendedItemId
                    )
                    if (
                        !duplication &&
                        rec.recommendationType?.value === RECOMMENDED_ACCESSORIES_TYPE
                    ) {
                        result.recommendations.push(rec)
                        resultStructureIsExtended = true
                        break
                    }
                }
            }
        })
    }
    return [result]
}

const RecommendedAccessories = forwardRef(
    (
        {
            products,
            showTitle = true,
            title,
            wrapper,
            shouldFetch,
            variant = 'default',
            onRecommendedProductClick,
            itemsPerSlide
        },
        ref
    ) => {
        const accessories = useMemo(() => {
            const recArray = []
            const typeArray = []
            products.forEach((product) => {
                if (product?.recommendations) {
                    product?.recommendations.forEach((rec) => {
                        recArray.push(rec)
                    })
                }
                if (product?.['c_product-type']) {
                    typeArray.push(product?.['c_product-type'])
                }
            })

            if (!recArray.length || !typeArray.length) {
                return null
            }

            return getRecommendationStructure(products)
        }, [products])

        const wrapperToUse = useMemo(() => {
            const DefaultWrapper = ({children}) => (
                <Box
                    ref={ref}
                    background="stokkeGray.bright"
                    paddingTop={10}
                    paddingBottom={12}
                    px={variant === 'default' ? {base: 4, lg: 8} : 0}
                >
                    {children}
                </Box>
            )
            DefaultWrapper.propTypes = {
                children: PropTypes.node
            }
            return wrapper || DefaultWrapper
        }, [wrapper, variant, ref])

        if (!accessories) {
            return null
        }

        return (
            <RecommendedProducts
                source={'accessories'}
                wrapper={wrapperToUse}
                title={
                    showTitle &&
                    (title || (
                        <FormattedMessage
                            defaultMessage="Accessories"
                            id="product_detail.recommended_products.title.accessories"
                        />
                    ))
                }
                recommender={'custom-Accessories'}
                products={accessories}
                shouldFetch={shouldFetch}
                trackingLabel="PDP accessories"
                variation="Quickshop"
                onRecommendedProductClick={onRecommendedProductClick}
                itemsPerSlide={itemsPerSlide}
                variant={variant}
            />
        )
    }
)

RecommendedAccessories.propTypes = {
    wrapper: PropTypes.func,
    shouldFetch: PropTypes.func,
    products: PropTypes.array,
    showTitle: PropTypes.bool,
    title: PropTypes.string,
    variant: PropTypes.string,
    onRecommendedProductClick: PropTypes.func,
    itemsPerSlide: PropTypes.object
}

if (process.env.NODE_ENV !== 'production') {
    RecommendedAccessories.displayName = 'RecommendedAccessories'
}

export default RecommendedAccessories
