import {createOcapiFetch} from './utils'

class OcapiShopperSite {
    constructor(config) {
        this.fetch = createOcapiFetch(config)
    }

    addHostToImageURLs(methodArray, host) {
        return methodArray.map(function (method) {
            if (method.image && method.image.URL) {
                method.image.URL = `https://${host}${method.image.URL}`
            }
            return method
        })
    }
    /**
     * Calls the built-in api for the host-name and then the ocapi call for site, fetching
     * available payment- and shipping methods not included in the first call
     */
    async getSite(...args) {
        const [defaultSite, customSite] = await Promise.all([
            this.fetch(`site`, 'GET', args, 'getDefaultSite'),
            this.fetch(`custom_objects/ocapi/site`, 'GET', args, 'getCustomSite')
        ])
        const host = defaultSite.httpsHostname

        if (customSite?.c_result?.shippingMethods) {
            customSite.c_result.shippingMethods = this.addHostToImageURLs(
                customSite.c_result.shippingMethods,
                host
            )
        }

        return {
            custom: customSite,
            default: defaultSite
        }
    }
}

export default OcapiShopperSite
