import useHybridURL from '../../hooks/use-hybrid-url'
import React, {useCallback} from 'react'
import {Flex, Image, Link} from '@chakra-ui/react'
import PropTypes from 'prop-types'

const TeaserItem = ({label, image, link}) => {
    const navigateHybrid = useHybridURL()

    const onClick = useCallback(
        (event) => {
            navigateHybrid(link)
            event.preventDefault()
        },
        [link, navigateHybrid]
    )

    return (
        <Flex
            flexDirection={{base: 'row', lg: 'column'}}
            alignItems={{base: 'center', lg: 'flex-start'}}
            justifyContent="flex-start"
            gap={{base: 4, lg: 3}}
            paddingInlineEnd={{base: 4, lg: 0}}
        >
            <Link
                href={link}
                onClick={onClick}
                // keep in line with label size bellow
                minWidth={{base: '9.375rem', lg: '12.5rem', '2xl': '15.625rem'}}
                height={{base: '9.375rem', lg: 'auto'}}
            >
                <Image
                    width={{base: 'auto', lg: '100%'}}
                    height={{base: '9.375rem', lg: 'auto'}}
                    objectFit="cover"
                    // fix for the added padding on the link component for the underline
                    marginBottom="calc(var(--chakra-space-linkUnderlineOffset) / -2)"
                    src={image}
                />
            </Link>
            <Link
                size="sm"
                href={link}
                // keep in line with image size above
                maxWidth={{lg: '12.5rem', '2xl': '15.625rem'}}
                onClick={onClick}
            >
                {label}
            </Link>
        </Flex>
    )
}

TeaserItem.propTypes = {
    label: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string
}

export default TeaserItem
