/**
 * transforms data-srcset to srcset and data-src to src attributes
 * so that the browser starts to load them
 * @param {HTMLElement} img the image to load
 */
import {useEffect} from 'react'

const loadImage = (img) => {
    let sources = img.children
    if (sources.length) {
        for (let i = 0; i < sources.length; i++) {
            if (sources[i].hasAttribute('data-srcset')) {
                let dataSrcset = sources[i].getAttribute('data-srcset')
                if (!sources[i].hasAttribute('srcset')) {
                    let srcset = document.createAttribute('srcset')
                    srcset.value = ''
                    sources[i].setAttribute('srcset', srcset.value)
                    if (dataSrcset.length > 0) {
                        sources[i].setAttribute('srcset', dataSrcset)
                        sources[i].setAttribute('data-srcset', srcset.value)
                    }
                } else if (sources[i].hasAttribute('srcset') && dataSrcset.length > 0) {
                    sources[i].setAttribute('srcset', dataSrcset)
                }
            } else if (
                sources[i].hasAttribute('data-src') &&
                !sources[i].hasAttribute('data-srcset')
            ) {
                let dataSrc = sources[i].getAttribute('data-src')
                if (!sources[i].hasAttribute('src')) {
                    let src = document.createAttribute('src')
                    src.value = ''
                    sources[i].setAttribute('src', src.value)
                    if (dataSrc.length > 0) {
                        sources[i].setAttribute('src', dataSrc)
                        sources[i].setAttribute('data-src', src.value)
                    }
                } else if (sources[i].hasAttribute('src') && dataSrc.length > 0) {
                    sources[i].setAttribute('src', dataSrc)
                }
            }
        }
    }
}

const useCmsLazyImgLoad = (root, html) => {
    useEffect(() => {
        if (!root || !html) return

        const images = [...root.querySelectorAll('.sk-image')]
        images.forEach(loadImage)
    }, [
        // need to rerun on change of the raw html content or the dom root
        root,
        html
    ])
}

export default useCmsLazyImgLoad
