import {useCallback, useEffect, useState} from 'react'
import useEffectEvent from '../../hooks/use-effect-event'

const useGeolocation = (options) => {
    const [trigger, setTrigger] = useState(0)
    const [state, setState] = useState({})

    // check if geolocation is supported using effect to not break ssr
    const [supported, setSupported] = useState(false)
    useEffect(() => {
        setSupported(global.navigator && 'geolocation' in global.navigator)
    }, [])

    const onGetCurrentPosition = useEffectEvent((successCallback, errorCallback) =>
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options)
    )
    useEffect(() => {
        // wait for first call to getCurrentPosition
        if (!trigger) {
            return
        }

        try {
            let canceled = false

            setState({loading: true})
            onGetCurrentPosition(
                (position) => {
                    if (canceled) return
                    setState({position})
                },
                (error) => {
                    if (canceled) return
                    setState({error})
                }
            )
            return () => {
                canceled = true
            }
        } catch (e) {
            setState({error: e.message})
            return Promise.reject(e)
        }
    }, [trigger, onGetCurrentPosition])

    const getCurrentPosition = useCallback(() => {
        setTrigger((prev) => prev + 1)
    }, [])

    return {supported, getCurrentPosition, ...state}
}

export default useGeolocation
