import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    HStack,
    Stack,
    useBreakpointValue,
    useStyleConfig
} from '@chakra-ui/react'
import textStyles from '../../theme/foundations/textStyles'
import {FormattedMessage} from 'react-intl'
import {LineIcon, ArrowLeftRoundIcon, ArrowRightRoundIcon} from '../icons'
import BonusProduct from './partials/bonus-product'
import useNestedProduct from '../../hooks/use-nested-product'
import LoadingSpinner from '../loading-spinner'

const BonusProductsModal = ({
    headerIcon,
    products,
    bonusProductVariationsMap,
    isOpen,
    onClose,
    colorScheme,
    fontColor,
    setSelectedItem,
    isLoading,
    ...props
}) => {
    const {NestedProduct} = useNestedProduct()

    const styles = useStyleConfig('ImageGallery')
    const touchStart = useRef(0)
    const scrollRef = useRef()
    const secondProductRef = useRef(null)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const isCentered = useBreakpointValue({base: false, sm: true})

    // Calculates the position of the scrollable container
    // according to the selected index
    const getScrollableContainerNextPosition = () => {
        if (!secondProductRef.current || selectedIndex === 0) {
            return 0
        }
        const computedStyle = window.getComputedStyle(secondProductRef.current)
        const productElementsLeftMargin = parseFloat(computedStyle.marginLeft)
        let elementWidth = parseFloat(computedStyle.width)
        elementWidth += productElementsLeftMargin
        const position = elementWidth * selectedIndex

        return `-${position}px`
    }

    // Sets the selected index depending on the user interactions
    const decideAndSetSelectedIndex = (direction = 1, touch) => {
        if (scrollRef.current) {
            /* Calculate slide direction (left/right) from touchevent in mobileview */
            const {touchStart, touchEnd} = touch || {}

            if (touchStart) {
                direction = touchEnd < touchStart ? 1 : -1
            }

            /* In mobileview prevent the user from scrolling just by clicking the bonus product */
            if ((touchStart && Math.abs(touchStart - touchEnd) > 50) || !touchStart) {
                if (direction < 0 && selectedIndex === 0) {
                    /* Clicking prev when the slider is on first bonus product sets the selected index to the last one */
                    setSelectedIndex(products.length - 1)
                } else if (direction > 0 && selectedIndex + 1 === products.length) {
                    /* Clicking next when the slider is on last bonus product sets the selected index to 0 */
                    setSelectedIndex(0)
                } else {
                    // Set selected index +/- 1
                    setSelectedIndex(selectedIndex + direction)
                }
            }
        }
    }

    const removeRestrictedVariantsAndAttributes = (product) => {
        if (!product?.variants) {
            return product
        }

        if (bonusProductVariationsMap) {
            product.variants = product.variants.filter(({productId}) =>
                bonusProductVariationsMap[product.master.masterId].includes(productId)
            )
            product.variationAttributes?.forEach((attribute) => {
                let identifier = attribute.id
                attribute.values = attribute.values.filter((value) => {
                    let hasOrderableVariant = false
                    product?.variants.forEach(({variationValues}) => {
                        hasOrderableVariant =
                            hasOrderableVariant || variationValues[identifier] === value.value
                    })
                    return hasOrderableVariant
                })
            })

            return product
        }

        return product
    }

    // Return if array of products aren't passed as prop
    if (!isLoading && !products?.length) {
        return null
    }

    return (
        <Modal
            variant="bonusOffer"
            size="lg"
            isOpen={isOpen}
            onClose={() => {
                setSelectedItem(undefined)
                onClose?.()
            }}
            isCentered={isCentered}
            colorScheme="stokkeCore"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    bg={`${colorScheme}.mid`}
                    color={fontColor}
                    textAlign="center"
                    {...textStyles['Regular Medium']}
                >
                    <HStack align="center" justify="center" gap={4}>
                        {headerIcon}
                        <FormattedMessage
                            defaultMessage="Choose your free bonus product."
                            id="modal.bonus.title"
                        />
                    </HStack>

                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <Box overflow="hidden">
                        {isLoading && <LoadingSpinner />}
                        <Stack
                            ref={scrollRef}
                            position="relative"
                            top={0}
                            left={getScrollableContainerNextPosition()}
                            transition="0.5s"
                            direction="row"
                            spacing={4}
                            wrap="nowrap"
                            data-testid="scrollable-container"
                            sx={{
                                scrollSnapType: 'x mandatory',
                                WebkitOverflowScrolling: 'touch' // Safari touch scrolling needed for scroll snap
                            }}
                            css={{
                                ...styles.scrollBar
                            }}
                            onTouchStart={(e) => {
                                touchStart.current =
                                    !e.target.className.includes('variation') &&
                                    e.changedTouches[0].clientX
                            }}
                            onTouchEnd={(e) => {
                                if (touchStart.current !== false) {
                                    decideAndSetSelectedIndex(1, {
                                        touchStart: touchStart.current,
                                        touchEnd: e.changedTouches[0].clientX
                                    })
                                }
                            }}
                        >
                            {products?.map((product, index) => (
                                <Box
                                    flex="0 0 auto"
                                    width="full"
                                    maxHeight="full"
                                    scrollSnapAlign="start"
                                    key={index}
                                    ref={index === 1 ? secondProductRef : null}
                                >
                                    {/*
                                      The bonus product is based on the product view component which is meant
                                      to display main content product and relies heavily on the current browser location.
                                      To bypass this dependency each displayed product is wrapped in memory router
                                      to have its own isolated history stack where changes to variations can be updated
                                      without affecting each other and the main page content
                                    */}
                                    <NestedProduct>
                                        <BonusProduct
                                            bonusProduct={product}
                                            removeRestrictedVariantsAndAttributes={
                                                removeRestrictedVariantsAndAttributes
                                            }
                                            onClose={onClose}
                                            onClick={onClose}
                                            {...props}
                                        />
                                    </NestedProduct>
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                    {/* Show scrolling functionality if bonus products > 1 */}
                    {products?.length > 1 && (
                        <>
                            <ArrowLeftRoundIcon
                                data-testid="bonus-modal-scroll-left"
                                aria-label="Scroll products left"
                                boxSize={8}
                                cursor="pointer"
                                position="absolute"
                                top="50%"
                                left={-4}
                                display={{base: 'none', lg: 'block'}}
                                onClick={() => {
                                    decideAndSetSelectedIndex(-1)
                                }}
                            />
                            <ArrowRightRoundIcon
                                data-testid="bonus-modal-scroll-right"
                                aria-label="Scroll products right"
                                boxSize={8}
                                cursor="pointer"
                                position="absolute"
                                top="50%"
                                right={-4}
                                display={{base: 'none', lg: 'block'}}
                                onClick={() => {
                                    decideAndSetSelectedIndex()
                                }}
                            />
                            <HStack
                                data-testid="bonus-modal-pagination"
                                align="end"
                                justify="center"
                                mt={{base: 7, lg: 2}}
                            >
                                {products?.map((_, index) => {
                                    return (
                                        <Stack
                                            spacing={1}
                                            key={index}
                                            cursor="pointer"
                                            onClick={() =>
                                                decideAndSetSelectedIndex(index - selectedIndex)
                                            }
                                        >
                                            <LineIcon
                                                height="1px"
                                                visibility={selectedIndex !== index && 'hidden'}
                                            />
                                            <LineIcon height="1px" />
                                        </Stack>
                                    )
                                })}
                            </HStack>
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

BonusProductsModal.propTypes = {
    headerIcon: PropTypes.node,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onModalClose: PropTypes.func,
    products: PropTypes.array,
    bonusProductVariationsMap: PropTypes.object,
    colorScheme: PropTypes.string,
    fontColor: PropTypes.string,
    setSelectedItem: PropTypes.func
}

export default BonusProductsModal
