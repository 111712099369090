import {getTargetLocale, fetchTranslations} from '../../../utils/locale'
import useMultiSite from '../../../hooks/use-multi-site'
import {DEFAULT_LOCALE} from '../../../constants'
import {isServer} from '../../../utils/utils'
import {useQuery} from '@tanstack/react-query'
import {useLocation} from 'react-router-dom'

/**
 * Fetches the translations for the target locale and provides the necessary
 * configuration for react-intl.
 * @return {{defaultLocale: string, onError: Function, messages: Object, locale: string}}
 */
const useIntlConfig = () => {
    const {site, locale} = useMultiSite()
    const location = useLocation()

    const targetLocale = getTargetLocale({
        getUserPreferredLocales: () => {
            // CONFIG: This function should return an array of preferred locales. They can be
            // derived from various sources. Below are some examples of those:
            //
            // - client side: window.navigator.languages
            // - the page URL they're on (example.com/en-GB/home)
            // - cookie (if their previous preference is saved there)
            //
            // If this function returns an empty array (e.g. there isn't locale in the page url),
            // then the app would use the default locale as the fallback.

            // NOTE: Your implementation may differ, this is just what we did.
            return [locale?.id || DEFAULT_LOCALE]
        },
        l10nConfig: site.l10n
    })

    // If the translation file exists, it'll be served directly from static folder (and won't reach this code here).
    // However, if the file is missing, the App would render a 404 page.
    const is404ForMissingTranslationFile = /\/static\/translations\/compiled\/[^.]+\.json$/.test(
        location?.pathname
    )

    // Fetch the translation message data using the target locale.
    const {data: messages} = useQuery({
        queryKey: ['app', 'translations', 'messages', targetLocale],
        queryFn: () => {
            if (is404ForMissingTranslationFile) {
                // Return early to prevent an infinite loop
                // Otherwise, it'll continue to fetch the missing translation file again
                return {}
            }
            return fetchTranslations(targetLocale)
        },
        enabled: isServer
    })

    return {
        onError: (err) => {
            if (!messages) {
                // During the ssr prepass phase the messages object has not loaded, so we can suppress
                // errors during this time.
                return
            }
            if (err.code === 'MISSING_TRANSLATION') {
                // NOTE: Remove the console error for missing translations during development,
                // as we knew translations would be added later.
                // TODO make this configurable
                // if (process.env.NODE_ENV === 'production') {
                //     logger.warn('Missing translation', err.message)
                // }
                return
            }
            throw err
        },
        locale: targetLocale,
        messages,
        // For react-intl, the _default locale_ refers to the locale that the inline `defaultMessage`s are written for.
        // NOTE: if you update this value, please also update the following npm scripts in `template-retail-react-app/package.json`:
        // - "extract-default-translations"
        // - "compile-translations:pseudo"
        defaultLocale: DEFAULT_LOCALE
    }
}

export default useIntlConfig
