import {Box} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import ContentTile from '../../components/content-tile'
import Slider from '../../components/slider'

const WidgetArticleDefaultViewMobile = ({sliderData}) => {
    return (
        <Slider itemsPerSlide={1.5}>
            {sliderData.map((item) => (
                <Box key={item.id}>
                    <ContentTile
                        key={item.id}
                        content={item}
                        variant="extended"
                        boxWidth={['100%']}
                        ratio={[1, 510 / 612]}
                        margin={{base: 0}}
                    />
                </Box>
            ))}
        </Slider>
    )
}

WidgetArticleDefaultViewMobile.propTypes = {
    sliderData: PropTypes.array
}

export default WidgetArticleDefaultViewMobile
