import {checkRequiredParameters, createOcapiFetch} from './utils'

class OcapiShopperFolders {
    constructor(config) {
        this.fetch = createOcapiFetch(config)
    }

    async getFolder(...args) {
        const required = ['fdid']

        let requiredParametersError = checkRequiredParameters(args[0], required)
        if (requiredParametersError) {
            return requiredParametersError
        }
        const {fdid, includeContent} = args[0].parameters
        const includeContentNameParam = includeContent ? '?include_content=true' : ''

        return await this.fetch(
            `folders/${encodeURIComponent(fdid)}${includeContentNameParam}`,
            'GET',
            args,
            'getFolder'
        )
    }
}

export default OcapiShopperFolders
