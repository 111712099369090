import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {Box, Link} from '@chakra-ui/react'
import DisplayPrice from '../../components/display-price'
import Arlink from './arlink'
import {useProduct} from '@salesforce/commerce-sdk-react'

/**
 * Display ProductInfos (name & price)
 *
 * @param {string} pid - productId
 * @param {string} variant - default: name & price
 *                         - onlyPrice: price
 *
 * @example
 *   <pwa-widget-productinfos pid="5968">
 *     $include(...)$
 *   </pwa-widget-productinfos>
 */
const WidgetProductinfos = ({pid, variant}) => {
    const {error, data} = useProduct({parameters: {id: pid}}, {enabled: !!pid})
    // Copied from ProductDetail.getProps
    // The `commerce-isomorphic-sdk` package does not throw errors, so
    // we have to check the returned object type to inconsistencies.

    useEffect(() => {
        if (error) {
            console.error('Failed to load product', error)
        }
    }, [error])
    if (!pid) {
        return null
    }

    if (error) {
        return null
    }

    // prevent initial flash of undefined product
    if (!data) {
        return null
    }
    return (
        <>
            {(variant === 'arLink' ||
                variant === 'arLinkPrimaryButton' ||
                variant === 'arLinkSecondaryButton') && <Arlink product={data} variant={variant} />}
            {variant === 'onlyPrice' && (
                <DisplayPrice
                    variant="tile"
                    size="sm"
                    product={data}
                    data-testid="product-tile-price"
                    display="inline-block"
                    verticalAlign="middle"
                />
            )}
            {variant === undefined && (
                <Box pr={4} color="stokkeCore.black">
                    <Link
                        href={data.slugUrl}
                        pr={1}
                        width="100%"
                        size="sm"
                        display="inline"
                        verticalAlign="middle"
                    >
                        {data.name}
                    </Link>
                    <Box as="span" display="inline-block" width="max-content">
                        <DisplayPrice
                            variant="tile"
                            size="sm"
                            product={data}
                            data-testid="product-tile-price"
                            display="flex"
                            verticalAlign="middle"
                        />
                    </Box>
                </Box>
            )}
        </>
    )
}

WidgetProductinfos.propTypes = {
    pid: PropTypes.string,
    variant: PropTypes.string
}

export default WidgetProductinfos
