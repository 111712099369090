class BrowserCache {
    /**
     * @param {string} [name]
     * @param {number} [ttl]
     */
    constructor({name = 'cache', ttl = 15 * 60 * 1000} = {}) {
        this.config = {
            name,
            ttl
        }
        this._memoryCache = new Map()

        // trigger the browser cache to open as it takes time
        this._openBrowserCache()
    }

    /**
     * @param {any} value
     * @param {number} expires
     * @returns {Response}
     * @private
     */
    _createBrowserResponse(value, expires) {
        const content = JSON.stringify(value)
        return new Response(content, {
            headers: {
                'Content-Length': `${content.length}`,
                'Content-Type': 'application/json',
                Expires: `${expires}`
            }
        })
    }

    /**
     * @param {string|object} key
     * @returns {Request}
     * @private
     */
    _getBrowserKey(key) {
        let url
        try {
            url = new URL(key)
        } catch {
            url = `${location.origin}/${encodeURIComponent(
                this.config.name
            )}?key=${encodeURIComponent(typeof key === 'string' ? key : JSON.stringify(key))}`
        }
        return new Request(url)
    }

    /**
     * @param {string|object} key
     * @returns {string}
     * @private
     */
    _getInternalKey(key) {
        return JSON.stringify(key)
    }

    /**
     * @param {null|Response} response
     * @returns {boolean}
     * @private
     */
    _isResponseExpired(response) {
        // use negative check to handle undefined and null
        return !(Number.parseInt(response?.headers.get('Expires'), 10) > Date.now())
    }

    /**
     * @param {null|number} expires
     * @returns {boolean}
     * @private
     */
    _isValueExpired(expires) {
        return expires != null && expires < Date.now()
    }

    /**
     * @returns {Promise<Cache> | undefined}
     * @private
     */
    _openBrowserCache() {
        if (!this._browserCachePromise) {
            this._browserCachePromise = global.caches?.open(this.config.name)
        }
        return this._browserCachePromise
    }

    /**
     * Retrieve from cache or process and cache the result
     * @param {string|object} key
     * @param {function} execute
     * @returns {any|Promise<unknown>}
     */
    getOrProcess(key, execute) {
        const internalKey = this._getInternalKey(key)

        // check if the key is in internal cache - either the value or the promise of resolving the value
        if (this._memoryCache.has(internalKey)) {
            const {value, expires} = this._memoryCache.get(internalKey)
            if (!this._isValueExpired(expires)) {
                return value
            } else {
                // free up the space
                this._memoryCache.delete(internalKey)
            }
        }

        // create a promise that will resolve to the value - either from browser cache or from executing the function
        const promise = (async () => {
            try {
                /**
                 * @type {Cache|undefined}
                 */
                const browserCache = await this._openBrowserCache()
                const browserKey = browserCache ? this._getBrowserKey(key) : null
                if (browserCache) {
                    const response = await browserCache.match(browserKey)
                    if (response) {
                        // check if the response is expired
                        if (!this._isResponseExpired(response)) {
                            // fresh enough to use
                            const value = await response.json()
                            // cleanup the internal memory, since we are using the browser cache
                            this._memoryCache.delete(internalKey)
                            return value
                        } else {
                            // free up the space
                            await browserCache.delete(browserKey)
                        }
                    }
                }

                // compute the value and record the expiration time
                const value = await execute()
                const expires = Date.now() + this.config.ttl

                // try to put in browser cache
                try {
                    if (browserCache) {
                        const syntheticResponse = this._createBrowserResponse(value, expires)
                        await browserCache.put(browserKey, syntheticResponse)
                        this._memoryCache.delete(internalKey)
                        return value
                    }
                } catch (e) {
                    if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production') {
                        console.warn('failed to put in browser cache', e)
                    }
                }

                // fallback to memory cache and set expiration
                this._memoryCache.set(internalKey, {value, expires})
                return value
            } catch (e) {
                if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production') {
                    console.error('process failed', e)
                }
                // cleanup the internal memory, since we are not going to cache the error
                this._memoryCache.delete(internalKey)
                throw e
            }
        })()

        // store the promise in the internal cache, so another request can wait for it
        this._memoryCache.set(internalKey, {
            value: promise,
            // promise doesn't expire
            expires: null
        })

        // return the promise
        return promise
    }
}

export default BrowserCache
