/* istanbul ignore file */

import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import React from 'react'
import {Helmet} from 'react-helmet'
import useMultiSite from '../../hooks/use-multi-site'
import {isServer} from '../../utils/utils'

const CMSGlobalStyles = () => {
    const {staticAPI: staticAPIConfig} = getConfig().app || {}
    const {site, locale} = useMultiSite()

    // TODO: extract into hook/utility
    const staticHost = staticAPIConfig?.host
        ? `https://${staticAPIConfig.host}`
        : staticAPIConfig?.proxyPath
        ? `${getAppOrigin()}${staticAPIConfig.proxyPath}`
        : ''

    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href={`${staticHost}/on/demandware.static/Sites-${
                        site.alias || site.id
                    }-Site/-/${locale.alias || locale.id}/css/cms-style${
                        process.env.NODE_ENV === 'production' ? '.min' : ''
                    }.css`}
                    media={
                        // use print styles for server side rendering so the css is not blocking and
                        // switch to all media types on client side after hydration
                        isServer ? 'print' : 'all'
                    }
                />
            </Helmet>
        </>
    )
}

export default CMSGlobalStyles
