import React, {useCallback, useMemo} from 'react'
import {Box, Flex, SimpleGrid, Text, Container} from '@chakra-ui/react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useCurrentCustomer} from '../../../hooks/use-current-customer'
import useMyStokkeRootPath from '../hooks/use-root-path'
import ContentSlot from '../../../components/content-slot'
import {
    CONTACT_US_HREF,
    FAQ_HREF,
    HYBRID_CUSTOMER_SERVICE,
    MY_STOKKE_PERSONAL_INFORMATION_PAGE_ID
} from '../../../constants'
import {LogoutIcon, MessageQuestionMarkIcon, SendIcon} from '../../../components/icons'
import RecommendedProducts from '../../../components/recommended-products'
import useMultiSite from '../../../hooks/use-multi-site'
import useMyStokkeSubroutes from '../hooks/use-subroutes'
import TeaserLink from '../../../components/teaser-link'
import MyStokkeMenuTile from '../partials/menu-tile'
import {trackEvent} from '../../../tracking/analytics'
import InteractionStudioRecommendedProducts from '../../../components/interaction-studio/recommended-products'
import {
    ProductQuickviewModal,
    useProductQuickviewModal
} from '../../../components/product-quickview-modal'
import {AuthHelpers, useAuthHelper} from '@salesforce/commerce-sdk-react'
import useOcapiSite from '../../../hooks/useOcapiSite'
import PropTypes from 'prop-types'

const RecommendedProductsWrapper = ({children}) => (
    <Box paddingTop={10} paddingBottom={12} paddingX={{base: 4, lg: 6, xl: 8}}>
        {children}
    </Box>
)
RecommendedProductsWrapper.propTypes = {
    children: PropTypes.node
}

const MyStokkeDashboard = () => {
    const {formatMessage} = useIntl()
    const {site} = useMultiSite()
    const {data: customer} = useCurrentCustomer()
    const subroutes = useMyStokkeSubroutes()
    const rootPath = useMyStokkeRootPath()
    const {designerProgramEnabled} = useOcapiSite()

    const productQuickviewModalProps = useProductQuickviewModal()
    productQuickviewModalProps.onRecommendedProductClick = productQuickviewModalProps.onOpen

    const {list: subpagesList, map: subpagesMap} = useMemo(() => {
        const list = subroutes
            .filter((route) => route.config)
            .sort((a, b) => a.config.order - b.config.order)
        const map = Object.fromEntries(list.map((route) => [route.configId, route]))
        return {list: list.filter((item) => item.configId !== 'personal-information'), map}
    }, [subroutes])

    const trackDashboardClick = (clickedID) => {
        trackEvent({
            event: 'dashboard_cta_click',
            page_type: 'My Stokke',
            click_text: clickedID
        })
    }

    const logout = useAuthHelper(AuthHelpers.Logout)
    const onLogoutClick = useCallback(async () => {
        try {
            await logout.mutateAsync()
            // my-stokke page will redirect to home page after logout
            trackDashboardClick('logout')
        } catch (error) {
            console.error('Error logging out', error)
        }
    }, [logout])

    if (!customer?.isRegistered) {
        return null
    }

    return (
        <Flex
            flexDirection="column"
            alignItems="center"
            data-testid="my-stokke-dashboard"
            bgColor="stokkeGray.bright"
        >
            <Flex flexDirection="column" alignItems="center" gap={2} paddingY={8}>
                <Text as="h1" textStyle="Regular 45XLarge">
                    <FormattedMessage
                        id="mystokke.dashboard.headline"
                        defaultMessage="My Stokke®"
                    />
                </Text>
                <Text textStyle="Regular XLarge">
                    <FormattedMessage
                        id="mystokke.dashboard.greeting"
                        defaultMessage="Hello, {firstName}! 👋"
                        values={{firstName: customer.firstName}}
                    />
                </Text>
            </Flex>
            <SimpleGrid
                columns={{base: 2, md: `${subpagesList.length ? subpagesList.length : 3}`}}
                paddingTop={{base: 4, md: 6, lg: 8}}
                paddingBottom={{base: 4, md: 10}}
                gap={{base: 4, md: 6, lg: 8}}
            >
                {subpagesList.map((subpage, idx) => (
                    <MyStokkeMenuTile
                        key={idx}
                        page={subpage.hybridPageType}
                        href={subpage.config.link}
                        isExternal={!!subpage.config.link}
                        to={subpage.path && rootPath + subpage.path}
                        icon={subpage.icon}
                        label={subpage.config.headline}
                        onClick={() => trackDashboardClick(subpage.config.headline)}
                    />
                ))}
            </SimpleGrid>

            <Container variant="no-padding">
                <SimpleGrid
                    alignSelf="stretch"
                    columns={2}
                    padding={{base: 4, lg: 8}}
                    gap={{base: 4, lg: 8}}
                >
                    <ContentSlot
                        slotId="account-promo"
                        sx={{gridColumn: '1 / span 2'}}
                        backgroundColor="stokkeCore.white"
                    />

                    {designerProgramEnabled &&
                        customer?.c_isDesignerProgramMember &&
                        customer?.c_accountType === 'designer-program' && (
                            <TeaserLink
                                sx={{gridColumn: '1 / span 2'}}
                                page="page"
                                to="/ct/campaigns/trade-program.html"
                                title={
                                    <FormattedMessage
                                        id="mystokke.dashboard.designer-program.headline"
                                        defaultMessage="US Designer program"
                                    />
                                }
                                description={
                                    <FormattedMessage
                                        id="mystokke.dashboard.designer-program.text"
                                        // todo: defaultMessage - https://dept-de.atlassian.net/browse/STOK-11249
                                        defaultMessage="TBD"
                                    />
                                }
                            />
                        )}

                    {subpagesMap[MY_STOKKE_PERSONAL_INFORMATION_PAGE_ID] && (
                        <TeaserLink
                            sx={{gridColumn: '1 / span 2'}}
                            page={
                                subpagesMap[MY_STOKKE_PERSONAL_INFORMATION_PAGE_ID].hybridPageType
                            }
                            to={rootPath + subpagesMap[MY_STOKKE_PERSONAL_INFORMATION_PAGE_ID].path}
                            title={
                                subpagesMap[MY_STOKKE_PERSONAL_INFORMATION_PAGE_ID].config.headline
                            }
                            description={
                                subpagesMap[MY_STOKKE_PERSONAL_INFORMATION_PAGE_ID].config.subline
                            }
                            onClick={() => trackDashboardClick('Personal Information')}
                        />
                    )}

                    <TeaserLink
                        page={HYBRID_CUSTOMER_SERVICE}
                        to={FAQ_HREF}
                        icon={<MessageQuestionMarkIcon />}
                        title={<FormattedMessage id="global.link.faq" defaultMessage="FAQ" />}
                        onClick={() => trackDashboardClick('FAQ')}
                    />

                    <TeaserLink
                        page={HYBRID_CUSTOMER_SERVICE}
                        to={CONTACT_US_HREF}
                        icon={<SendIcon />}
                        title={
                            <FormattedMessage
                                id="global.link.contact_us"
                                defaultMessage="Contact Us"
                            />
                        }
                        onClick={() => trackDashboardClick('ContactUs')}
                    />

                    <TeaserLink
                        sx={{gridColumn: '1 / span 2'}}
                        onClick={onLogoutClick}
                        icon={<LogoutIcon />}
                        title={
                            <FormattedMessage defaultMessage="Log Out" id="global.button.log_out" />
                        }
                    />
                </SimpleGrid>
                <InteractionStudioRecommendedProducts id="is-dashboard-recommendations">
                    <RecommendedProducts
                        recommender={`HP_recom_${site.id}`}
                        title={formatMessage({
                            defaultMessage: 'We think you’ll like these',
                            id: 'mystokke.dashboard.recommended_products.title'
                        })}
                        wrapper={RecommendedProductsWrapper}
                        trackingLabel="My Stokke Recommendations"
                        onRecommendedProductClick={productQuickviewModalProps.onOpen}
                    />
                </InteractionStudioRecommendedProducts>
                <ProductQuickviewModal {...productQuickviewModalProps} />
            </Container>
        </Flex>
    )
}

export default MyStokkeDashboard
