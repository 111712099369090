import {checkRequiredParameters, createOcapiFetch} from './utils'

class OcapiShopperContent {
    constructor(config) {
        this.fetch = createOcapiFetch(config)
    }

    async getContent(...args) {
        const required = ['cid']

        let requiredParametersError = checkRequiredParameters(args[0], required)
        if (requiredParametersError) {
            return requiredParametersError
        }
        const {cid, html} = args[0].parameters
        return await this.fetch(
            `content/${encodeURIComponent(cid)}?${html ? 'html' : ''}`,
            'GET',
            args,
            'getContent'
        )
    }
}

export default OcapiShopperContent
