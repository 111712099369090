import {camelCaseKeysToUnderscore, checkRequiredParameters, createOcapiFetch} from './utils'

class OcapiShopperCustomers {
    constructor(config) {
        this.fetch = createOcapiFetch(config)
    }

    async passwordReset(...args) {
        const required = ['body']
        let requiredParametersError = checkRequiredParameters(args[0], required)
        if (requiredParametersError) {
            return requiredParametersError
        }
        const {body} = args[0]
        return await this.fetch(
            `customers/password_reset`,
            'POST',
            args,
            'getCustomers',
            camelCaseKeysToUnderscore(body)
        )
    }
}

export default OcapiShopperCustomers
