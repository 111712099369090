import React from 'react'
import PropTypes from 'prop-types'
import HybridLink from '../../../components/hybrid-link'
import {Button, Flex, Text} from '@chakra-ui/react'
import {ArrowRight2Icon} from '../../../components/icons'

const size = {
    base: '10.25rem',
    md: '14rem'
}

const MyStokkeMenuTile = ({colorScheme = 'stokkePink', label, icon: Icon, ...props}) => {
    const Component = props.href || props.to ? HybridLink : Button

    return (
        <Component variant="unstyled" {...props}>
            <Flex
                flexDirection="column"
                alignItems="stretch"
                gap={2.5}
                minWidth={size}
                width={size}
                maxWidth={size}
                flexBasis={size}
                flexShrink={0}
                flexGrow={0}
            >
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    color={`${colorScheme}.dark`}
                    backgroundColor={`${colorScheme}.mid`}
                    boxSize={size}
                >
                    {Icon && <Icon boxSize={{base: '5.625rem', md: '6.5rem'}} />}
                </Flex>

                {label && (
                    <Flex
                        justifyContent="space-between"
                        alignItems="flex-start"
                        gap={2}
                        maxWidth={size}
                    >
                        <Text
                            minWidth={0}
                            textAlign="start"
                            textStyle="Regular XLarge"
                            whiteSpace="normal"
                        >
                            {label}
                        </Text>
                        <ArrowRight2Icon boxSize={4} />
                    </Flex>
                )}
            </Flex>
        </Component>
    )
}

MyStokkeMenuTile.propTypes = {
    colorScheme: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.elementType,
    href: PropTypes.string,
    to: PropTypes.string
}

export default MyStokkeMenuTile
