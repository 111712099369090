import {Flex, HStack, Text, Button, Link} from '@chakra-ui/react'
import {HYBRID_3D_CONFIGURATOR} from '../../constants'
import {ARIcon, Configurator3dIcon} from '../icons'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import React from 'react'
import HybridLink from '../hybrid-link'
import {useMultiStyleConfig} from '@chakra-ui/react'

const ProductVRLinks = ({
    hasAR,
    href: ARLinkHref,
    onARLinkClick,
    onConfiguratorClick,
    configurator3DLink,
    product,
    variant
}) => {
    const styles = useMultiStyleConfig('ProductVRLinks', {variant})
    const Component =
        variant === 'arLinkPrimaryButton' || variant === 'arLinkSecondaryButton' ? Button : Link
    const arLinkStyling = () => {
        switch (variant) {
            case 'arLinkPrimaryButton':
                return 'solid'
            case 'arLinkSecondaryButton':
                return 'outline'
            default:
                return 'link'
        }
    }

    return configurator3DLink || hasAR ? (
        <Flex {...styles.container}>
            {configurator3DLink && (
                <HybridLink
                    page={HYBRID_3D_CONFIGURATOR}
                    to={`/3dconfigurator?family=${configurator3DLink}&variant=${product.id}#/model/${configurator3DLink}/configurator`}
                    onClick={onConfiguratorClick}
                >
                    <HStack spacing={2} align="center">
                        <Configurator3dIcon boxSize={4} />
                        <Text textStyle="Regular Medium">
                            <FormattedMessage
                                id="product_detail.link.3d_configurator"
                                defaultMessage="Configure in 3D"
                            />
                        </Text>
                    </HStack>
                </HybridLink>
            )}
            {hasAR && (
                <Component
                    variant={arLinkStyling()}
                    as={Link}
                    href={ARLinkHref || '#'}
                    onClick={onARLinkClick}
                    rel="ar"
                    tabIndex="0"
                >
                    {/*
                        QuickView AR Viewer requires an img element as a first child of the AR anchor element.
                        It won't trigger the viewer without this element
                    */}
                    <img alt="" />
                    <HStack spacing={2} align="center">
                        <ARIcon boxSize={4} />
                        <Text textStyle="Regular Medium">
                            <FormattedMessage
                                id="product_detail.link.show_in_ar"
                                defaultMessage="Show in AR"
                            />
                        </Text>
                    </HStack>
                </Component>
            )}
        </Flex>
    ) : null
}

ProductVRLinks.propTypes = {
    hasAR: PropTypes.bool,
    href: PropTypes.string,
    configurator3DLink: PropTypes.string,
    product: PropTypes.object,
    onConfiguratorClick: PropTypes.func,
    onARLinkClick: PropTypes.func,
    variant: PropTypes.string
}

export default ProductVRLinks
