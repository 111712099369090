import {Flex, SkeletonCircle, SkeletonText, Text} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, {useMemo} from 'react'
import {AvailabilityIcon} from '../../../components/icons'

const AVAILABILITIES = {
    instock: {
        color: 'stokkeFunctional.alertGreen'
    },
    lowstock: {
        color: 'stokkeFunctional.alertOrange'
    },
    outofstock: {
        color: 'stokkeFunctional.alertRed'
    },
    comingsoon: {
        color: 'stokkeFunctional.alertRed'
    },
    discontinued: {
        color: 'stokkeFunctional.alertOrange'
    }
}

const ProductAvailability = ({product, loading, ...props}) => {
    const productAvailability = useMemo(() => product?.c_availability?.toLowerCase(), [product])
    const availability = useMemo(
        () => (loading ? null : AVAILABILITIES[productAvailability]),
        [loading, productAvailability]
    )
    return (
        <Flex gap={2} alignItems="center" color={availability?.color} {...props}>
            {availability ? <AvailabilityIcon boxSize={2} /> : <SkeletonCircle size={2} />}
            {availability ? (
                <Text textStyle="Regular Small">{product?.c_availabilityMessage}</Text>
            ) : (
                <SkeletonText
                    noOfLines={1}
                    isLoaded={availability}
                    skeletonHeight="1.3em"
                    textStyle="Regular Small"
                    width="10rem"
                />
            )}
        </Flex>
    )
}

ProductAvailability.propTypes = {
    product: PropTypes.object,
    loading: PropTypes.bool
}

export default ProductAvailability
