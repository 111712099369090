import React, {useState} from 'react'
import Cookies from 'js-cookie'
import {
    Button,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    useBreakpointValue,
    Text,
    Flex
} from '@chakra-ui/react'
import {FormattedMessage, useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {FlagIcon} from '../icons'
import {trackChangeCountry} from '../../tracking/analytics'
import useMultiSite from '../../hooks/use-multi-site'

export const CountrySelectorModal = ({isOpen, onClose, geoIp}) => {
    const {formatMessage} = useIntl()
    const size = useBreakpointValue({base: 'sm', sm: 'sm', md: 'md', lg: 'lg'})
    const country = geoIp?.country
    const siteLink = geoIp?.url
    const countryCode = geoIp?.countryCode
    const siteId = geoIp?.siteId
    const locale = geoIp?.locale

    const {site, locale: currentLocale} = useMultiSite()
    const currentSiteId = site?.id

    const closeModal = () => {
        trackChangeCountry(
            formatMessage({
                id: 'geoip.modal.stay_here',
                defaultMessage: 'No, stay here'
            })
        )
        Cookies.set(
            'dw_country_and_locale',
            currentSiteId + '|' + currentLocale.id.replace('-', '_')
        )
        onClose()
    }

    return (
        <Modal
            size={size}
            variant="countrySelector"
            isOpen={isOpen}
            onClose={closeModal}
            isCentered={true}
            colorScheme="stokkeCore"
            returnFocusOnClose={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <Stack padding="2rem 0 1.5rem">
                        <Flex gap={4} alignItems="center">
                            <Text textStyle="Regular 4XLarge">
                                <FormattedMessage
                                    id="geoip.modal.headline"
                                    values={{0: country}}
                                    defaultMessage="Looks like you’re in {0}"
                                />
                            </Text>

                            <FlagIcon countryCode={countryCode} verticalAlign="middle" />
                        </Flex>
                        <Text textStyle="Regular Medium" paddingY={5}>
                            <FormattedMessage
                                id="geoip.modal.subline"
                                values={{
                                    0: (
                                        <Link href={siteLink} isExternal={false}>
                                            <Text as="span" textTransform="uppercase">
                                                <FormattedMessage
                                                    id="global.brand.name"
                                                    defaultMessage="Stokke"
                                                />
                                            </Text>

                                            {' ' + country}
                                        </Link>
                                    )
                                }}
                                defaultMessage="Would you rather continue your experience on {0}?"
                            />
                        </Text>
                        <Flex gap={8} direction={{base: 'column', lg: 'row'}} alignItems="center">
                            <Button
                                as={Link}
                                isExternal={false}
                                href={siteLink}
                                variant="solid"
                                maxWidth={{lg: '17.5rem'}}
                                onClick={(e) => {
                                    trackChangeCountry(e.target.textContent)
                                    Cookies.set('dw_country_and_locale', siteId + '|' + locale)
                                }}
                                width="100%"
                            >
                                <FormattedMessage
                                    id="geoip.modal.go_to_country"
                                    values={{0: country}}
                                    defaultMessage="Go to {0}"
                                />
                            </Button>
                            <Button
                                variant="link"
                                onClick={closeModal}
                                textAlign={{
                                    base: 'center',
                                    lg: 'left'
                                }}
                            >
                                <Text textStyle="Regular Medium">
                                    <FormattedMessage
                                        id="geoip.modal.stay_here"
                                        defaultMessage="No, stay here"
                                    />
                                </Text>
                            </Button>
                        </Flex>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

CountrySelectorModal.propTypes = {
    /**
     * Prop where all the country data is stored
     */
    geoIp: PropTypes.object,
    /**
     * Prop to check if modal is open
     */
    isOpen: PropTypes.bool.isRequired,
    /**
     * Callback invoked to close the modal
     */
    onClose: PropTypes.func.isRequired
}

export const useGeoIPModal = () => {
    const [geoIp, setGeoIp] = useState(null)

    return {
        geoIp,
        isOpen: !!geoIp,
        onClose: () => setGeoIp(null),
        onOpen: setGeoIp
    }
}
