import {Flex} from '@chakra-ui/react'
import {AccountIcon, DropdownIcon, PointIcon} from './index'
import React from 'react'
import PropTypes from 'prop-types'
import useOcapiSite from '../../hooks/useOcapiSite'

const CompositeAccountIcon = ({isActive, ...props}) => {
    const {myStokkeEnabled} = useOcapiSite().preferences
    return (
        <Flex display="flex" alignItems="center" gap={1} position="relative">
            <AccountIcon boxSize={4} {...props} />
            {isActive && (
                <>
                    <PointIcon
                        display={myStokkeEnabled ? {lg: 'none'} : null}
                        boxSize={1}
                        position="absolute"
                        top={-0.5}
                        right={-0.5}
                    />
                    {myStokkeEnabled && (
                        <DropdownIcon
                            display={{base: 'none', lg: 'block'}}
                            width={2}
                            height="0.938rem"
                        />
                    )}
                </>
            )}
        </Flex>
    )
}

CompositeAccountIcon.propTypes = {
    isActive: PropTypes.bool
}

export default CompositeAccountIcon
