import React from 'react'
import PropTypes from 'prop-types'
import {Alert} from '@chakra-ui/react'

/**
 * Temporary error component to be used in the drawer menu while we are still working on the new navigation
 */
const DrawerMenuError = ({error, details}) => {
    console.error(error, details)
    return (
        <Alert status="error" variant="solid" flexShrink="0">
            <details>
                <summary>{error}</summary>
                <pre>{JSON.stringify(details, null, 2)}</pre>
            </details>
        </Alert>
    )
}

DrawerMenuError.propTypes = {
    error: PropTypes.string,
    details: PropTypes.any
}

export default DrawerMenuError
