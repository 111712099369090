import {MemoryRouter, useLocation} from 'react-router-dom'
import React, {useCallback, useEffect} from 'react'
import useNavigation from './use-navigation'
import PropTypes from 'prop-types'
import useEffectEvent from './use-effect-event'

/**
 * This component is used to listen to navigation events that change the pathname and notify the parent component
 * @param {function} onNavigate
 * @returns {null}
 */
const NavPathListener = ({onNavigate}) => {
    const location = useLocation()
    const onLocationChange = useEffectEvent((pathname) => onNavigate(pathname))
    useEffect(() => {
        onLocationChange(location.pathname)
    }, [location.pathname, onLocationChange])
    return null
}

NavPathListener.propTypes = {
    onNavigate: PropTypes.func
}

/**
 * Helper hook to wrap a product component inside a memory router
 * to prevent it from changing the actual location for product variant selection,
 * but to allow navigation from the component
 * @returns {{NestedProduct: unknown}}
 */
export default function useNestedProduct() {
    // we need to use the router navigation or else the navigation cannot be wrapped within a memory router
    // noinspection JSDeprecatedSymbols
    const navigate = useNavigation()

    // This is a hack to trigger navigations from the wrapped component inside the memory router to the actual router
    // but skipping product variant selection which only changes the query params
    const performNavigation = useCallback(
        (pathname) => {
            // Only navigate if the pathname is not the root
            if (pathname !== '/') {
                navigate(pathname, 'push')
            }
        },
        [navigate]
    )

    const component = useCallback(
        ({children}) => (
            <MemoryRouter>
                <NavPathListener onNavigate={performNavigation} />
                {children}
            </MemoryRouter>
        ),
        [performNavigation]
    )

    return {
        NestedProduct: component
    }
}
