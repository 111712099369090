import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'
import {Box, Button, Flex, Stack, Text} from '@chakra-ui/react'
import Field from '../../../components/field'
import {LockIcon} from '../../../components/icons'
import MyStokkeLinkContainer from './link-container'

const MyStokkeChildForm = ({fields, setEditChildData, isLoading, onCancel, showBorder}) => {
    return (
        <Box
            border={showBorder ? '2px' : null}
            gridColumn={{base: 1, md: 'span 2'}}
            position="relative"
            backgroundColor="stokkeCore.white"
            marginBottom={6}
        >
            <Stack
                spacing={6}
                backgroundColor={showBorder ? 'stokkeGray.bright' : 'stokkeGray.light'}
                padding={4}
            >
                <Field {...fields.ID} />
                <Field {...fields.childName} />
                <Field {...fields.childBirthdate} />
                <Flex justifyContent="center" alignItems="center">
                    <LockIcon width={3} height={3} mr={1.5} />
                    <Text textStyle="Regular Extra Small">
                        <FormattedMessage
                            defaultMessage="Your information is stored securely."
                            id="mystokke.personal-information.stored-securely"
                        />
                    </Text>
                </Flex>
                <Stack spacing={3} alignItems="center">
                    <Button type="submit" isLoading={isLoading} width="100%">
                        <FormattedMessage
                            defaultMessage="Save"
                            id="form_action_buttons.button.save"
                        />
                    </Button>
                    <MyStokkeLinkContainer>
                        <Button
                            variant="link"
                            onClick={() => {
                                onCancel()
                                setEditChildData(null)
                            }}
                        >
                            {
                                <FormattedMessage
                                    id="form_action_buttons.button.cancel"
                                    defaultMessage="Cancel"
                                />
                            }
                        </Button>
                    </MyStokkeLinkContainer>
                </Stack>
            </Stack>
        </Box>
    )
}

MyStokkeChildForm.propTypes = {
    fields: PropTypes.object,
    setEditChildData: PropTypes.func,
    isLoading: PropTypes.bool,
    onCancel: PropTypes.func,
    showBorder: PropTypes.bool
}

export default MyStokkeChildForm
