import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useForm} from 'react-hook-form'
import {GenericModal} from './use-generic-modal'
import NotifyFields from '../components/forms/notify-fields'
import {useCurrentCustomer} from '../hooks/use-current-customer'

/**
 * A Modal that contains Product View
 */
export const NotifyModal = ({product, isOpen, onClose, onOpen}) => {
    const {data: customer} = useCurrentCustomer()
    const form = useForm({
        defaultValues: {
            firstName: customer.firstName,
            lastName: customer.lastName,
            email: customer.email,
            phone: customer.phoneHome
        }
    })

    return (
        <GenericModal
            colorScheme="stokkeGray"
            size="2xl"
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            paddingTop={0}
            bodyStyles={{paddingY: 0}}
            contentStyles={{
                paddingTop: {base: 8, lg: 10},
                containerProps: {'data-testid': 'notify-me-modal'}
            }}
        >
            <NotifyFields form={form} product={product} onClose={onClose} />
        </GenericModal>
    )
}

NotifyModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    product: PropTypes.object
}

export const useNotifyMeModal = () => {
    const [product, setProduct] = useState(null)

    return {
        product,
        isOpen: product ? true : false,
        onClose: () => setProduct(null),
        onOpen: setProduct
    }
}
