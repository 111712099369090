import {useLocation} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'

const useDisableGtm = () => {
    const {search} = useLocation()

    const {data: disabled} = useQuery(
        ['disableGtm'],
        async () => {
            const query = new URLSearchParams(search)
            return !!query.get('disableGtm')
        },
        // this config replicates the existing behavior where gtm disabled is controlled by the initial url
        {
            networkMode: 'always',
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: false,
            retryOnMount: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
        }
    )

    return disabled
}

export default useDisableGtm
