import {ShopperSearch} from 'commerce-sdk-isomorphic'

class CustomShopperSearch extends ShopperSearch {
    constructor(config = {}) {
        super(config)
    }

    async productSearch(...args) {
        args?.[0]?.parameters?.refine?.push('available-on-web=1')
        if (!args[0].parameters.expand) {
            args[0].parameters.expand = 'availability,images,prices,variations,promotions'
        }
        const searchResult = await super.productSearch(...args)
        delete searchResult?.selectedRefinements?.['available-on-web']
        return searchResult
    }
}

export default CustomShopperSearch
