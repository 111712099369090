import React from 'react'
import PropTypes from 'prop-types'
import {Box, Flex, Image, Text, useMultiStyleConfig} from '@chakra-ui/react'
import {capitalize} from '../../utils/utils'
import {HYBRID_EXTERNAL} from '../../constants'
import HybridLink from '../../components/hybrid-link'

const WidgetStoreTile = ({store, color, showImages, variant}) => {
    const styles = useMultiStyleConfig('Stores', {variant})
    const colorWorld = `stokke${capitalize(color || 'gray')}.bright`

    return (
        <Flex {...styles.flexContainer} backgroundColor={colorWorld}>
            <HybridLink
                page={HYBRID_EXTERNAL}
                href={`/store-details?StoreID=${store.id}`}
                variant="unstyled"
                isExternal={true}
            >
                <Flex {...styles.alignment}>
                    {!!store?.name && <Text {...styles.text}>{store.name}</Text>}
                    {!!store?.address1 && <Text {...styles.text}>{store.address1}</Text>}
                    <Text {...styles.text}>
                        {!!store?.city && store.city}
                        {!!store?.city && !!store?.postalCode && ' '}
                        {!!store?.postalCode && store.postalCode}
                    </Text>
                </Flex>
                {showImages &&
                    (store?.image ? (
                        <Image {...styles.image} src={store.image} loading="lazy" />
                    ) : (
                        <Box {...styles.image}></Box>
                    ))}
            </HybridLink>
        </Flex>
    )
}

WidgetStoreTile.propTypes = {
    store: PropTypes.object,
    color: PropTypes.string,
    showImages: PropTypes.bool,
    variant: PropTypes.string
}

export default WidgetStoreTile
