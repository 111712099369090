import React, {useMemo} from 'react'
import {
    Image,
    Stack,
    Text,
    omitThemingProps,
    useMultiStyleConfig,
    List,
    ListItem
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

// remove duplicate icons
const deduplicateUrls = (paymentMethod, idx, arr) =>
    idx === arr.findIndex((value) => paymentMethod?.image?.URL === value?.image?.URL)

export const IconRow = (props) => {
    const styles = useMultiStyleConfig('IconRow', props)
    const {icons, title, ...ownProps} = omitThemingProps(props)
    const deduplicatedIcons = useMemo(() => icons.filter(deduplicateUrls), [icons])
    return (
        <Stack {...styles.container} {...ownProps}>
            <Text {...styles.title}>{title}</Text>
            <List {...styles.imagesContainer} listStyleType="none">
                {deduplicatedIcons.map((paymentMethod) =>
                    paymentMethod.image.URL ? (
                        <ListItem key={paymentMethod.id} {...styles.imageContainer}>
                            <Image
                                src={paymentMethod.image.URL}
                                alt={paymentMethod.image.alt}
                                title={paymentMethod.image.title}
                                loading="lazy"
                                {...styles.image}
                            />
                        </ListItem>
                    ) : null
                )}
            </List>
        </Stack>
    )
}

IconRow.propTypes = {
    icons: PropTypes.array,
    title: PropTypes.string
}

export default IconRow
