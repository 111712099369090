import textStyles from '../../foundations/textStyles'

const generateLinkVariant = ({colorScheme: colorScheme = 'black', theme}) => {
    return colorScheme === 'black'
        ? {
              bg: theme.gradients.linkGradients.black,
              backgroundSize: '100% 210%',
              transition: '.5s',
              color: 'stokkeCore.black',
              paddingBottom: 'calc(var(--chakra-space-linkUnderlineOffset) / 2)',
              textDecoration: 'underline',
              minWidth: '1em',
              width: 'max-content',
              '@media(pointer: fine)': {
                  _hover: {
                      backgroundPosition: '0 97%',
                      color: 'stokkeCore.white',
                      textDecoration: 'none',
                      textDecorationColor: 'stokkeCore.black',
                      _disabled: {
                          color: 'stokkeCore.mid'
                      }
                  },
                  _active: {
                      bg: 'stokkeCore.black',
                      color: 'stokkeCore.white',
                      textDecoration: 'none'
                  }
              },
              _disabled: {
                  color: 'stokkeGray.mid'
              }
          }
        : {
              bg: theme.gradients.linkGradients.core({colorScheme: colorScheme}),

              backgroundSize: '100% 210%',
              transition: '.5s',
              color:
                  colorScheme !== 'stokkeBeige'
                      ? `${colorScheme}.dark`
                      : `${colorScheme}.accessibleText`,
              paddingBottom: 'calc(var(--chakra-space-linkUnderlineOffset) / 2)',
              textDecoration: 'underline',
              minWidth: '1em',
              width: 'max-content',
              '@media(pointer: fine)': {
                  _hover: {
                      backgroundPosition: '0 97%',
                      color: `${colorScheme}.bright`,
                      textDecoration: 'none',
                      textDecorationColor: `${colorScheme}.dark`,
                      _disabled: {
                          color: 'stokkeCore.mid'
                      }
                  },
                  _active: {
                      bg: `${colorScheme}.dark`,
                      color: `${colorScheme}.bright`,
                      textDecoration: 'none'
                  }
              },
              _disabled: {
                  color: 'stokkeGray.mid'
              }
          }
}

export default {
    baseStyle: {
        /* This is to make regular links fit into the stokke theme without enforcing color */
        textDecoration: 'underline',
        textUnderlineOffset: 'var(--chakra-space-linkUnderlineOffset)'
    },
    variants: {
        core: generateLinkVariant,
        navigationSpecial: ({theme}) => ({
            bg: theme.gradients.linkGradients.special,
            backgroundSize: '100% 210%',
            transition: '.5s',
            color: `stokkeCore.orange`,
            paddingBottom: 'calc(var(--chakra-space-linkUnderlineOffset) / 2)',
            textDecoration: 'underline',
            minWidth: '1em',
            width: 'max-content',
            '@media(pointer: fine)': {
                _hover: {
                    backgroundPosition: '0 97%',
                    color: `stokkeCore.white`,
                    textDecoration: 'none',
                    textDecorationColor: `stokkeCore.orange`,
                    _disabled: {
                        color: 'stokkeCore.mid'
                    }
                },
                _active: {
                    bg: `stokkeCore.orange`,
                    color: `stokkeCore.white`,
                    textDecoration: 'none'
                }
            },
            _disabled: {
                color: 'stokkeGray.mid'
            }
        }),
        image: {
            textDecoration: 'none',
            textUnderlineOffset: null,
            paddingBottom: 0,
            _hover: {
                textDecoration: 'none',
                '@media(pointer: fine)': {
                    textDecoration: 'none'
                }
            },
            _focus: {
                boxShadow: 'none'
            }
        },
        navigationLink: {
            textDecoration: 'none',
            _hover: {
                textDecoration: 'none'
            }
        },
        localeSelector: (ctx) => ({
            ...generateLinkVariant(ctx),
            display: 'flex',
            alignItems: 'center'
        }),
        underlineLink: {
            textDecoration: 'none',
            _hover: {
                textDecoration: 'underline',
                textDecorationColor: 'stokkeCore.black'
            }
        },
        unstyled: {
            textDecoration: 'none',
            textUnderlineOffset: null,
            paddingBottom: 0,
            _hover: {
                textDecoration: 'none',
                '@media(pointer: fine)': {
                    textDecoration: 'none'
                }
            },
            _focus: {
                boxShadow: 'none'
            }
        },
        teaserLink: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 4,
            width: '100%',
            paddingX: 4,
            paddingY: 8,
            bgColor: 'stokkeCore.white',
            textDecoration: 'none',
            _hover: {
                textDecoration: 'none'
            }
        }
    },
    sizes: {
        // Not in style guide but used for header helper links
        xs: {
            ...textStyles['Badge Regular']
        },
        sm: {
            ...textStyles['Regular Small']
        },
        md: {
            ...textStyles['Regular Medium']
        },
        localeSelector: {
            ...textStyles['Locale Selector']
        }
    },
    defaultProps: {
        colorScheme: 'black',
        size: 'md',
        variant: 'core'
    }
}
