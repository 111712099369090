import PropTypes from 'prop-types'
import React from 'react'
import {useDerivedProduct} from '../../hooks'
import useAR from '../../hooks/use-ar'
import ProductVRLinks from '../../components/product-vr-links'

const ArLink = ({product, variant}) => {
    const {ARModel} = useDerivedProduct(product)
    const ARProps = useAR(ARModel)
    return <ProductVRLinks variant={variant} {...ARProps} />
}

ArLink.propTypes = {
    product: PropTypes.object,
    variant: PropTypes.string
}

export default ArLink
