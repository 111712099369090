// noinspection JSDeprecatedSymbols
import {useCommerceAPI} from '../commerce-api/contexts'
import {useQuery} from '@tanstack/react-query'

/**
 *
 * @param {string} methodName
 * @param {{parameters: Object}|null} [apiOptions]
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value.
 * @private exported only for reuse from useScapiCustomApi until upgrade to commerce-sdk-isomorphic@2+
 */
const useSapi = (methodName, apiOptions, queryOptions) => {
    // noinspection JSDeprecatedSymbols
    const {sapi} = useCommerceAPI()

    if (!(sapi[methodName] instanceof Function)) {
        throw new Error(`Method ${methodName} not found in SAPI CommerceAPI`)
    }

    return useQuery(
        ['sapi', methodName, apiOptions?.parameters ?? {}],
        ({queryKey: [, methodName, parameters]}) => sapi[methodName]({parameters}),
        queryOptions
    )
}

/**
 * @param {{properties: {slotId: string, context?: string}}} apiOptions
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value.
 */
export const useSapiSlot = (apiOptions, queryOptions) =>
    useSapi('getSlot', apiOptions, queryOptions)

/**
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value.
 */
export const useSapiGeoIpData = (queryOptions) => useSapi('getGeoIpData', null, queryOptions)
