import {mergeWith} from '@chakra-ui/utils'
// Styles for the StyledContent component
export default {
    baseStyle: (ctx) => {
        const {theme} = ctx
        return {
            sx: {
                h1: {
                    ...theme.textStyles['Regular 45XLarge']
                },
                h2: {
                    ...theme.textStyles['Regular 2XLarge']
                },
                h3: {
                    ...theme.textStyles['Regular XLarge']
                },
                h4: {
                    ...theme.textStyles['Semi Bold Medium']
                },
                p: {
                    ...theme.textStyles['Regular Medium']
                },
                a: mergeWith(
                    {},
                    theme.components.Link.baseStyle,
                    theme.components.Link.variants.core(ctx)
                ),
                table: {
                    border: '1px solid',
                    borderCollapse: 'collapse',
                    padding: '1.07375rem'
                },
                th: {
                    ...theme.textStyles['Semi Bold Small'],
                    border: '1px solid',
                    borderCollapse: 'collapse',
                    padding: '1.07375rem',
                    textAlign: 'left'
                },
                td: {
                    ...theme.textStyles['Regular Small'],
                    border: '1px solid',
                    borderCollapse: 'collapse',
                    padding: '1.07375rem'
                },
                ul: {
                    paddingLeft: '1.375rem'
                },
                '.sk-regular-xl': {
                    ...theme.textStyles['Regular XLarge']
                },
                '.sk-regular-4_5xl': {
                    ...theme.textStyles['Regular 4.5XLarge']
                },
                '.sk-regular-4xl': {
                    ...theme.textStyles['Regular 4XLarge']
                },
                '.sk-regular-2xl': {
                    ...theme.textStyles['Regular 2XLarge']
                },
                '.sk-semibold-medium': {
                    ...theme.textStyles['Semi Bold Medium']
                },
                '.sk-regular-medium': {
                    ...theme.textStyles['Regular Medium']
                },
                '.sk-light-medium': {
                    ...theme.textStyles['Light Medium']
                },
                '.sk-regular-small': {
                    ...theme.textStyles['Regular Small']
                },
                '.sk-semibold-small': {
                    ...theme.textStyles['Semi Bold Small']
                },
                '.sk-regular-badges': {
                    ...theme.textStyles['Regular Badges']
                }
            }
        }
    }
}
