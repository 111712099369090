import {useCallback} from 'react'
import useOcapiSite from './useOcapiSite'
import {guessHybrid, isHybrid} from '../utils/site-utils'
import useMultiSite from './use-multi-site'
import useNavigation from './use-navigation'
import {rebuildPathWithParams} from '../utils/url'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'

/**
 * @callback NavigateHybridWithType is a function that redirects to the given url based on dynamic hybrid configuration and provided page type.
 * @param {String} pageType - is the page type the link should redirect to - used to check if hybrid mode is enabled
 * @param {String} relativeUrl - is the relative path that should be used for redirecting
 * @param {Object} [params] - is an object with all the data that should be kept after redirecting
 * @param {String} [externalURL] - is the relative path that should be used for redirecting to external page
 * @returns {void}
 */

/**
 * @callback NavigateHybridGuessType is a function that redirects to the given url based on dynamic hybrid configuration and guessing page type from the url.
 * @param {String} url - is the url that should be used for redirecting, can be relative or absolute
 * @returns {void}
 */

/**
 * This hook returns the current url functionality for redirecting to hybrid link.
 * @returns {NavigateHybridWithType|NavigateHybridGuessType} - the function that redirects to the given url
 */
const useHybridURL = () => {
    const {buildUrl} = useMultiSite()
    const {hybridConfig} = useOcapiSite()
    // intentionally use useNavigation for the case where the link is not hybrid
    // noinspection JSDeprecatedSymbols
    const navigate = useNavigation()
    return useCallback(
        /**
         * @type {NavigateHybridWithType|NavigateHybridGuessType}
         */
        (pageTypeOrUrl, relativeUrl, params = {}, externalURL) => {
            if (pageTypeOrUrl && !relativeUrl) {
                // called only with link
                if (guessHybrid(hybridConfig, pageTypeOrUrl)) {
                    window.location.href = pageTypeOrUrl
                } else {
                    const link = new URL(pageTypeOrUrl, getAppOrigin())
                    if (process.env.NODE_ENV === 'development') {
                        // dev02 returns links starting with `/s/` for some reason,
                        // and that breaks the navigation
                        link.pathname = link.pathname.replace(/^\/s\//, '/')
                    }
                    navigate(`${link.pathname}${link.search}${link.hash}`)
                }
                return
            }

            // in case of redirect we only know what source URL type is but we cannot be sure what the target URL type is
            // so we call the guessHybrid in order to find it out: e.g. search leads to FAQ page
            if (externalURL && guessHybrid(hybridConfig, externalURL)) {
                if (typeof window !== 'undefined') {
                    let updatedHref = buildUrl(externalURL)
                    updatedHref = rebuildPathWithParams(updatedHref, {
                        pwaSerializedState: JSON.stringify(params)
                    })
                    window.location.href = updatedHref
                } else {
                    navigate(externalURL, 'push', params)
                }
            } else if (isHybrid(hybridConfig, pageTypeOrUrl)) {
                let updatedHref = buildUrl(externalURL || relativeUrl)
                updatedHref = rebuildPathWithParams(updatedHref, {
                    pwaSerializedState: JSON.stringify(params)
                })
                window.location.href = updatedHref
            } else {
                navigate(relativeUrl, 'push', params)
            }
        },
        [buildUrl, hybridConfig, navigate]
    )
}

export default useHybridURL
