import {
    AspectRatio,
    Box,
    Flex,
    IconButton,
    Image,
    SimpleGrid,
    Text,
    useMultiStyleConfig
} from '@chakra-ui/react'
import {ArrowLeftRoundIcon, ArrowRightRoundIcon, BrandLogoSmall, HeartIcon} from '../icons'
import {FormattedMessage, useIntl} from 'react-intl'
import React, {Fragment, useEffect, useMemo, useState} from 'react'
import ProductTile, {Skeleton as ProductTileSkeleton} from '../product-tile'
import {useProducts} from '@salesforce/commerce-sdk-react'
import PropTypes from 'prop-types'
import Video from '../video'
import {TILE_CONTEXT_FANREEL_RELATED_PRODUCTS} from '../../constants'

const FanreelOverlayItem = ({
    active,
    item,
    prefetch,
    onScrollPrev,
    onScrollNext,
    onQuickViewModalOpen
}) => {
    const styles = useMultiStyleConfig('FanreelOverlay')
    const {formatMessage} = useIntl()
    const videoRef = React.useRef(null)
    const [allowedToLoadProducts, setAllowedToLoadProducts] = useState(Boolean(active && item))
    const pids = useMemo(
        () => item.products.map((p) => p.metadata.productId).join(','),
        [item.products]
    )

    const {data, isInitialLoading: loading} = useProducts(
        {
            parameters: {
                ids: pids,
                allImages: true
            }
        },
        {
            enabled: allowedToLoadProducts
        }
    )
    const products = useMemo(
        () =>
            // fix for wrongly referenced products
            data?.data?.filter(Boolean),
        [data]
    )

    // allow loading products when active and item is set
    useEffect(() => {
        if (!item) {
            setAllowedToLoadProducts(false)
        } else if (active) {
            setAllowedToLoadProducts(true)
        }
    }, [active, item])

    // allow loading products when prefetching with delay
    useEffect(() => {
        if (!item || !prefetch) {
            return
        }

        const timer = setTimeout(
            () => {
                setAllowedToLoadProducts(true)
            },
            // 1s delay to not interrupt the scroll animation
            1000
        )
        return () => {
            clearTimeout(timer)
        }
    }, [prefetch, item])

    // start playing video when active and stop when inactive
    useEffect(() => {
        const videoEl = videoRef.current
        if (videoEl) {
            if (active) {
                // double negative to handle unsupported browsers
                if (!(navigator.getAutoplayPolicy?.(videoEl) !== 'disallowed')) {
                    return
                }

                try {
                    videoEl.currentTime = 0
                    videoEl.play()
                    return () => {
                        try {
                            videoEl.muted = true
                            videoEl.pause()
                        } catch (e) {
                            // ignore error
                            console.error('Failed to pause video', e)
                        }
                    }
                } catch (e) {
                    // ignore error
                    console.error('Failed to play video', e)
                }
            } else {
                // reset video when inactive but after timeout to not interrupt the scroll animation
                const timer = setTimeout(() => {
                    if (videoRef.current) {
                        try {
                            videoRef.current.currentTime = 0
                        } catch (e) {
                            // ignore error
                            console.error('Failed to reset video', e)
                        }
                    }
                }, 1000)
                return () => {
                    clearTimeout(timer)
                }
            }
        }
    }, [active])

    return (
        <Flex
            {...styles.itemContainer}
            // disable focusing offscreen items
            inert={!active ? '' : undefined}
        >
            <AspectRatio
                {...styles.itemMediaAspectRatio}
                backgroundImage={
                    item.media.poster
                        ? item.media.poster.smallSquare.link
                        : item.media.smallSquare.link
                }
            >
                <Box {...styles.itemMediaContainer}>
                    {item.media.type === 'video' ? (
                        <Video
                            {...styles.itemMedia}
                            {...styles.itemMediaVideo}
                            data-testid="video"
                            ref={videoRef}
                            controls
                            controlsList="play timeline volume nodownload"
                            disablePictureInPicture
                            src={item.media.original.link}
                            muted
                            playsInline
                            preload={prefetch ? 'metadata' : 'none'}
                            poster={item.media.poster.large.link}
                        />
                    ) : (
                        <Image
                            {...styles.itemMedia}
                            {...styles.itemMediaImage}
                            data-testid="photo"
                            src={
                                item.media.poster
                                    ? item.media.poster.large.link
                                    : item.media.large.link
                            }
                        />
                    )}
                    <Flex {...styles.itemSliderControls}>
                        <IconButton
                            {...styles.itemSliderControl}
                            aria-label={formatMessage({
                                id: 'global.gallery.scroll_prev',
                                defaultMessage: 'Scroll previous'
                            })}
                            icon={<ArrowLeftRoundIcon boxSize={8} />}
                            variant="flush"
                            onClick={onScrollPrev}
                        />

                        <IconButton
                            {...styles.itemSliderControl}
                            aria-label={formatMessage({
                                id: 'global.gallery.scroll_next',
                                defaultMessage: 'Scroll next'
                            })}
                            icon={<ArrowRightRoundIcon boxSize={8} />}
                            variant="flush"
                            onClick={onScrollNext}
                        />
                    </Flex>
                </Box>
            </AspectRatio>

            <Flex {...styles.itemContentContainer}>
                <Flex {...styles.itemContentHeaderContainer}>
                    <Flex {...styles.itemContentHeaderLine1}>
                        <Flex {...styles.instagramAccountContainer}>
                            <BrandLogoSmall />
                            <Text {...styles.instagramAccount}>
                                <FormattedMessage
                                    id="global.brand.instagram_account"
                                    defaultMessage="@stokkebaby"
                                />
                            </Text>
                        </Flex>
                        <Flex {...styles.likesContainer}>
                            <HeartIcon {...styles.likesIcon} />
                            <Text {...styles.likes}>
                                <FormattedMessage
                                    id="fanreel.overlay.like_count"
                                    defaultMessage="{likeCount, plural, one {# Like} other {# Likes}}"
                                    values={item.source}
                                />
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex {...styles.itemContentHeaderLine2}>
                        <Flex {...styles.titleContainer}>
                            <Text {...styles.title}>
                                <FormattedMessage
                                    id="fanreel.overlay.shop_this_look"
                                    defaultMessage="Shop this look"
                                />
                            </Text>
                            <Text {...styles.titleSub}>
                                <FormattedMessage
                                    id="fanreel.overlay.shop_this_look_items"
                                    defaultMessage="({itemsCount, plural, one {# item} other {# items}})"
                                    values={{
                                        itemsCount:
                                            // if we have loaded products, use the length of the products array
                                            // as it might be less than the item's products due to
                                            // duplicated products or wrong product ids
                                            products?.length != null
                                                ? products.length
                                                : item.products.length
                                    }}
                                />
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>

                <SimpleGrid {...styles.itemContentProductsContainer}>
                    {loading ? (
                        <Fragment>
                            <ProductTileSkeleton />
                            <ProductTileSkeleton />
                        </Fragment>
                    ) : (
                        products?.map((product, idx) => (
                            <ProductTile
                                key={idx}
                                isExternal
                                product={product}
                                variant="Extended"
                                context={TILE_CONTEXT_FANREEL_RELATED_PRODUCTS}
                                onRecommendedProductClick={onQuickViewModalOpen}
                            />
                        ))
                    )}
                </SimpleGrid>
            </Flex>
        </Flex>
    )
}

FanreelOverlayItem.propTypes = {
    active: PropTypes.bool,
    item: PropTypes.object,
    prefetch: PropTypes.bool,
    onScrollPrev: PropTypes.func,
    onScrollNext: PropTypes.func,
    productId: PropTypes.string,
    onQuickViewModalOpen: PropTypes.func
}

export default FanreelOverlayItem
