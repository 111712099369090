import React from 'react'
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

/**
 * Component to render a generic modal
 *
 * @param children - The children to render inside the modal
 * @param header - The header to render inside the modal
 * @param footer - The footer to render inside the modal
 * @param isOpen - The state of the modal
 * @param onClose - The function to call when the modal is closed
 * @param colorScheme - The color scheme of the modal
 * @returns {JSX.Element} - The generic modal
 * @constructor
 * @example
 * const { isOpen, onOpen, onClose } = useGenericModal()
 * <GenericModal
 *    header="Header"
 *    footer="Footer"
 *    isOpen={isOpen}
 *    onClose={onClose}
 *    colorScheme="stokkeGray"
 * >
 *   <Text>Modal content</Text>
 * </GenericModal>
 */
export const GenericModal = ({
    children,
    colorScheme,
    footer,
    header,
    isOpen,
    onClose,
    bodyStyles,
    size,
    contentStyles,
    isCentered,
    variant = 'generic',
    scrollBehavior
}) => {
    return (
        <Modal
            colorScheme={colorScheme}
            variant={variant}
            isOpen={isOpen}
            closeOnOverlayClick={true}
            onClose={onClose}
            size={size}
            isCentered={isCentered}
            scrollBehavior={scrollBehavior}
            returnFocusOnClose={false}
        >
            <ModalOverlay />
            <ModalContent {...contentStyles}>
                {header && <ModalHeader>{header}</ModalHeader>}
                <ModalCloseButton size="lgMin" zIndex={1} />
                <ModalBody {...bodyStyles} width="full">
                    {children}
                </ModalBody>
                {footer && <ModalFooter>{footer}</ModalFooter>}
            </ModalContent>
        </Modal>
    )
}

GenericModal.propTypes = {
    children: PropTypes.node.isRequired,
    colorScheme: PropTypes.string,
    footer: PropTypes.any,
    header: PropTypes.any,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    bodyStyles: PropTypes.object,
    size: PropTypes.string,
    contentStyles: PropTypes.object,
    isCentered: PropTypes.bool,
    variant: PropTypes.string,
    scrollBehavior: PropTypes.string
}

/**
 * Hook with the logic to open and close a generic modal
 * @returns {{onClose: () => void, isOpen: boolean, onOpen: () => void}}
 */
export const useGenericModal = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()

    return {
        isOpen,
        onOpen,
        onClose
    }
}
