import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {Box, Link} from '@chakra-ui/react'
import useHybridURL from '../../hooks/use-hybrid-url'
import DrawerMenuError from './drawer-menu-error'
import {trackNavigationClick} from '../../tracking/analytics'

/**
 * Renders lvl2 link as link and handles the hybrid navigation
 */
const Lvl2Link = ({item}) => {
    const navigateHybrid = useHybridURL()

    const onClick = useCallback(
        (event) => {
            trackNavigationClick(3, item.label)
            navigateHybrid(item.link)
            event.preventDefault()
        },
        [item, navigateHybrid]
    )

    if (!item.link) {
        return <DrawerMenuError error="Navigation link without link property" details={item} />
    }

    return (
        <Box paddingX={2} paddingY={{base: 1, lg: 3.5}}>
            <Link
                href={item.link}
                onClick={onClick}
                variant={item.special ? 'navigationSpecial' : 'core'}
                size="sm"
            >
                {item.label}
            </Link>
        </Box>
    )
}

Lvl2Link.propTypes = {
    item: PropTypes.object.isRequired
}

export default Lvl2Link
