import {useProducts} from '@salesforce/commerce-sdk-react'
import {useMemo} from 'react'
import {getMasterProductId} from '../utils/product-utils'
import {MAX_IDS_PER_GET_PRODUCTS_REQUEST} from '../constants'

// TODO v3: add tests - duplicate items, items without master product, no items, no basket, api error

/**
 * @param {Object|null} basket - basket or order to load product items detail for
 * @param {{parameters: Object}|null} [apiOptions={parameters: {allImages: true}}]
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 */
const useProductItemsDetail = (
    basket,
    apiOptions = {parameters: {allImages: true}},
    queryOptions
) => {
    const basketItemIds = useMemo(
        () => [...new Set(basket?.productItems?.map((item) => item.productId))],
        [basket]
    )
    const {data: productVariants1, isInitialLoading: isProductVariantsLoading1} = useProducts(
        {
            ...apiOptions,
            parameters: {
                ...apiOptions?.parameters,
                ids: basketItemIds.slice(0, MAX_IDS_PER_GET_PRODUCTS_REQUEST).join(',')
            }
        },
        {
            ...queryOptions,
            enabled: basketItemIds.length > 0 && queryOptions?.enabled !== false
        }
    )
    const {data: productVariants2, isInitialLoading: isProductVariantsLoading2} = useProducts(
        {
            ...apiOptions,
            parameters: {
                ...apiOptions?.parameters,
                ids: basketItemIds
                    .slice(MAX_IDS_PER_GET_PRODUCTS_REQUEST, 2 * MAX_IDS_PER_GET_PRODUCTS_REQUEST)
                    .join(',')
            }
        },
        {
            ...queryOptions,
            enabled:
                basketItemIds.length > MAX_IDS_PER_GET_PRODUCTS_REQUEST &&
                queryOptions?.enabled !== false
        }
    )
    const productVariants = useMemo(
        () => ({
            data: [...(productVariants1?.data || []), ...(productVariants2?.data || [])]
        }),
        [productVariants1, productVariants2]
    )
    const isProductVariantsLoading = isProductVariantsLoading1 || isProductVariantsLoading2

    const masterProductIds = useMemo(
        () => [
            ...new Set(
                productVariants?.data?.map((product) => getMasterProductId(product)).filter(Boolean)
            )
        ],
        [productVariants]
    )
    const {data: productMasters1, isInitialLoading: isProductMastersLoading1} = useProducts(
        {
            ...apiOptions,
            parameters: {
                ...apiOptions?.parameters,
                ids: masterProductIds.slice(0, MAX_IDS_PER_GET_PRODUCTS_REQUEST).join(',')
            }
        },
        {
            ...queryOptions,
            enabled: masterProductIds.length > 0 && queryOptions?.enabled !== false
        }
    )
    const {data: productMasters2, isInitialLoading: isProductMastersLoading2} = useProducts(
        {
            ...apiOptions,
            parameters: {
                ...apiOptions?.parameters,
                ids: masterProductIds
                    .slice(MAX_IDS_PER_GET_PRODUCTS_REQUEST, 2 * MAX_IDS_PER_GET_PRODUCTS_REQUEST)
                    .join(',')
            }
        },
        {
            ...queryOptions,
            enabled:
                masterProductIds.length > MAX_IDS_PER_GET_PRODUCTS_REQUEST &&
                queryOptions?.enabled !== false
        }
    )
    const productMasters = useMemo(
        () => ({
            data: [...(productMasters1?.data || []), ...(productMasters2?.data || [])]
        }),
        [productMasters1, productMasters2]
    )
    const isProductMastersLoading = isProductMastersLoading1 || isProductMastersLoading2

    return {
        data: useMemo(
            () =>
                Object.fromEntries(
                    [...(productVariants?.data || []), ...(productMasters?.data || [])].map(
                        (product) => [product.id, product]
                    )
                ),
            [productVariants, productMasters]
        ),
        isLoading: isProductVariantsLoading || isProductMastersLoading
    }
}

export default useProductItemsDetail
