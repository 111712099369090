import {isJavascriptMimeType} from '../../../utils/mime'
import {CMS_SCRIPTS_MIME_TYPE} from '../../../constants'
import {isServer} from '../../../utils/utils'
import {parseFragment, serialize} from 'parse5'

function walk(documentFragment, fn) {
    for (let node of documentFragment.childNodes) {
        fn(node)
        if (node.childNodes) {
            walk(node, fn)
        }
    }
}

const prepareHtmlForInjection = async (html) => {
    if (!isServer || !html) {
        return html
    }

    const documentFragment = parseFragment(html)

    const convertScript = (node) => {
        // ignore non script tags
        if (node.tagName?.toLowerCase() !== 'script') {
            return
        }
        node.attrs = node.attrs || []
        // ignore non javascript script tags
        if (
            node.attrs.some(({name, value}) => {
                if (name !== 'type') return false
                const validScript = isJavascriptMimeType(value)
                return !validScript
            })
        ) {
            return
        }

        node.attrs = node.attrs.filter(({name}) => name !== 'type')
        node.attrs.push({
            name: 'type',
            value: CMS_SCRIPTS_MIME_TYPE
        })
    }

    const convertSkImage = (node) => {
        // if doesn't have .sk-image class, ignore
        if (
            !node.attrs?.some(
                ({name, value}) => name === 'class' && value.split(' ').includes('sk-image')
            )
        ) {
            return
        }

        // walk the child nodes and convert data-srcset and data-src to srcset and src
        node.childNodes.forEach((child) => {
            child.attrs?.forEach((attr) => {
                if (attr.name === 'data-srcset') {
                    attr.name = 'srcset'
                }
                if (attr.name === 'data-src') {
                    attr.name = 'src'
                }
                return attr
            })
        })
    }

    walk(documentFragment, (node) => {
        convertScript(node)
        convertSkImage(node)
    })

    return serialize(documentFragment)
}

export default prepareHtmlForInjection
