import {Image} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, {useMemo} from 'react'
import {HYBRID_FOOTER_LINKS} from '../../../constants'
import HybridLink from '../../hybrid-link'
import {fixAmpersand} from '../../../utils/url'

const SpecialOffer = ({alt, image, url, locationId}) => {
    const creativeName = useMemo(() => {
        // doesn't matter the base url, as we use just the pathname and getting the real url in ssr is a pain
        return new URL(image, 'http://localhost').pathname.split('/').pop()
    }, [image])

    url = fixAmpersand(url)

    return (
        <HybridLink
            page={HYBRID_FOOTER_LINKS}
            hybridHref={url}
            href={url}
            w="100%"
            variant="image"
            data-creative-name={creativeName}
            data-location-id={locationId}
            data-promotion-name={alt}
        >
            <Image alt={alt} src={image} loading="lazy"></Image>
        </HybridLink>
    )
}

SpecialOffer.propTypes = {
    alt: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
    locationId: PropTypes.string
}

export default SpecialOffer
