const commonWrapperStyles = {
    background: 'stokkeGray.bright',
    paddingTop: 10,
    paddingBottom: 12,
    px: {base: 4, lg: 8},
    textAlign: 'left'
}
export default {
    baseStyle: ({theme}) => ({
        container: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: 0
        },
        cantFindForCategory: {
            marginBottom: '0.5rem',
            textStyle: {base: 'Regular 2XLarge', xl: 'Regular 3XLarge'},
            textAlign: {base: 'left', xl: 'center'}
        },
        searchAndSuggestionsContainer: {
            flexDirection: 'column',
            alignItems: {base: 'flex-start', xl: 'center'},
            padding: {base: '2rem 1rem', xl: '2.5rem 2rem'},
            width: '100%'
        },
        searchContainer: {
            maxWidth: '50rem',
            width: '100%',
            paddingBottom: '2rem'
        },
        cantFindForQuery: {
            textStyle: {base: 'Regular 2XLarge', xl: 'Regular 3XLarge'},
            textAlign: {base: 'left', xl: 'center'},
            marginBottom: {base: '1rem', xl: '1.5rem'}
        },
        pleaseStartNewSearch: {
            textStyle: 'Regular Medium',
            textAlign: {base: 'left', xl: 'center'}
        },
        suggestionsContainer: {
            paddingTop: '1rem',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            columnGap: '1rem',
            rowGap: '0.5rem'
        },
        didYouMean: {
            display: {base: 'block', xl: 'inline-block'},
            width: {base: '100%', xl: 'auto'},
            textStyle: 'Regular Small',
            textAlign: 'left'
        },
        slotsAndRecommendationContainer: {
            flexDirection: 'column',
            width: '100%',
            maxWidth: 'container.xxl'
        },
        recommendedProductsWrapper: {
            ...commonWrapperStyles,
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        recentlyViewedProductsWrapper: {
            ...commonWrapperStyles,
            background: `${theme.colors.stokkeGray.mid}80`
        },
        backToTopLink: {
            margin: '1.5rem auto'
        }
    })
}
