import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: ({product}) => ({
        accordion: {
            width: 'full'
        },
        accordionItem: {
            paddingY: 8,
            _first: {
                borderTop: 'none'
            },
            _last: {
                borderBottom: 'none'
            }
        },
        accordionPanel: {
            paddingX: 0
        },
        arrowIcon: {
            marginLeft: 2
        },
        container: {
            px: [4, 4, 4, 8],
            paddingTop: {base: '1rem', xl: '1.5rem'},
            backgroundColor: 'stokkeGray.bright'
        },
        title: {
            align: 'center',
            width: 'full',
            pb: [8, 8, 8, 10],
            ...textStyles['Regular 2XLarge']
        },
        productScore: {
            spacing: 4
        },
        certificates: {
            flexWrap: 'wrap',
            columnGap: {base: '0.313rem'},
            rowGap: '0.938rem'
        },
        certificateImage: {
            height: '3.625rem',
            cursor: 'pointer'
        },
        certificateModalContentWrapper: {
            flexDirection: 'column',
            maxWidth: {base: '17.625rem', lg: '100%'},
            marginRight: 'auto'
        },
        certificateModalTitle: {
            ...textStyles['Regular 4XLarge'],
            marginBottom: '2rem',
            paddingTop: '1rem'
        },
        certificateModalImageContainer: {
            padding: '0.825rem 1.33125rem 0.93125rem 0',
            marginBottom: '0.5rem'
        },
        certificateModalImage: {
            maxWidth: '9.666666875rem',
            maxHeight: '6.142450625rem'
        },
        certificateModalName: {
            ...textStyles['Semi Bold Small'],
            marginBottom: '0.5rem'
        },
        certificateModalDescription: {
            ...textStyles['List Regular']
        },
        productData: {
            width: '100%'
        },
        ratings: {
            width: 'full'
        },
        characteristicsImage: {
            width: ['100%', '100%', '100%', '30.75rem'],
            height: ['100%', '100%', '100%', '35.5rem']
        },
        whatsIncludedImage: {
            pt: 8,
            flex: '1 1 0',
            width: {base: 'auto', lg: 0}
        },
        module: {
            justifyContent: 'center',
            direction: ['column-reverse', 'column-reverse', 'column-reverse', 'row'],
            gap: [8, 8, 8, '6.25rem'],
            px: [0, 0, 0, '4.25rem']
        },
        compatibleProductsHeading: {
            ...textStyles['Regular XLarge'],
            paddingBottom: '1rem'
        },
        compatibleProductsContainer: {
            paddingBottom: product?.c_productCompatibility && 4
        },
        compatibilityText: {
            ...textStyles['Regular Medium']
        },
        includedItemsContainer: {
            direction: ['column', 'column', 'column', 'row'],
            width: 'full',
            height: '100%',
            pt: [8, 8, 8, '1.5rem'],
            pb: [2, 2, 2, '1.5rem'],
            pr: [0, 0, 0, 8],
            backgroundColor: 'stokkeCore.white'
        },
        includedItems: {
            justifyContent: 'center',
            alignItems: 'center',
            flex: '1 1 0',
            width: {base: 'auto', lg: 0},
            px: 6
        },
        includedText: {
            direction: 'row',
            gap: 3,
            alignItems: 'center'
        },
        divider: {
            borderColor: 'stokkeGray.mid'
        }
    })
}
