import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        container: {
            flexDirection: 'column'
        },
        innerContainer: {
            flexDirection: {base: 'column', xl: 'row'},
            rowGap: '2rem',
            columnGap: '4.5rem'
        },
        topContentWrapper: {
            flexDirection: 'column',
            rowGap: '2rem',
            flexBasis: {base: '100%', xl: '50%'}
        },
        reviewsContainer: {
            flexDirection: 'column',
            rowGap: '2rem',
            flexBasis: {base: '100%', xl: '50%'}
        },
        heading: {
            ...textStyles['Regular 2XLarge']
        },
        divider: {
            colorScheme: 'stokkeGray',
            margin: '2rem 0'
        },
        seeAllReviewsBtn: {
            variant: 'outline',
            width: {base: '100%', md: '25rem'},
            maxWidth: '100%',
            margin: '2rem auto 0'
        },
        skeleton: {
            height: '31rem',
            width: '100%'
        }
    },
    variants: {
        default: {
            container: {
                padding: {base: '2rem 1rem', xl: '2rem'}
            }
        },
        'no-review': {
            container: {
                paddingX: {base: 4, lg: 6, xl: 8},
                paddingY: {base: 8, lg: 16}
            },
            noReviews: {
                textStyle: 'Regular Medium'
            }
        }
    }
}
