import React from 'react'
import PropTypes from 'prop-types'
import {
    BreadcrumbItem as ChakraBreadcrumbItem,
    BreadcrumbLink as ChakraBreadcrumbLink
} from '@chakra-ui/react'
import HybridLink from '../hybrid-link'

// helper to create an unstyled hybrid link as chakra passes the styles as __css prop to the link
const UnstyledHybridLink = (props) => <HybridLink {...props} variant="unstyled" size="unstyled" />

const BreadcrumbItem = ({
    children,
    href,
    page,
    isLastChild,
    // default to true when the item is the last child
    isCurrentPage = isLastChild,
    ...props
}) => {
    return (
        <ChakraBreadcrumbItem {...props} isCurrentPage={isCurrentPage} isLastChild={isLastChild}>
            <ChakraBreadcrumbLink {...(href && {as: UnstyledHybridLink, page: page, to: href})}>
                {children}
            </ChakraBreadcrumbLink>
        </ChakraBreadcrumbItem>
    )
}

BreadcrumbItem.propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
    page: PropTypes.string,
    isCurrentPage: PropTypes.bool,
    isLastChild: PropTypes.bool
}

export default BreadcrumbItem
