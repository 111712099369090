import React, {useMemo} from 'react'
import {Flex, useBreakpointValue} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import Slider from '../../components/slider'
import WidgetStoreTile from './widget-store-tile'
import {useQuery} from '@tanstack/react-query'
import {useCommerceAPI} from '../../commerce-api/contexts'
import useOcapiSite from '../../hooks/useOcapiSite'
import useMultiSite from '../../hooks/use-multi-site'
import {tryParseJSONObject} from '../../utils/utils'

/**
 * Display store tile for a given store ID or get stores nearby current geolocation.
 *
 * if stores is defined always use the storeIds, if its not than use the geoLocation
 *
 * @param {string} stores - object with storeIds and alignment for text
 *
 * @param {string} color - backgroundcolor for StoreTiles
 *
 * @param {string} images - yes or no - should images be shown or not
 *
 * @example
 *   <pwa-widget-store stores="[{"id": "001D000000mYr6ZIAS", "alignment": "right"},{"id": "001D000000mYr6ZIAS", "alignment": "left"}]" color="pink" images="yes">
 *     $include('Widget-Store', 'sid', '#(store.store_id)')$
 *   </pwa-widget-product>
 */
const WidgetStore = ({stores, color, images}) => {
    const {sitePrefGeoLocation} = useOcapiSite()
    const api = useCommerceAPI()
    const itemsPerSlide = useBreakpointValue({base: 1, md: 2, lg: 3, '2xl': 4})
    const isSm = useBreakpointValue({base: true, md: false})
    const showImages = images === 'yes'
    const {site} = useMultiSite()

    const getSitePrefGeocoordinates = async () => {
        if (sitePrefGeoLocation?.[site.id]) {
            const latitude = sitePrefGeoLocation[site.id].latitude
            const longitude = sitePrefGeoLocation[site.id].longitude
            return await api.shopperStore.getStoresNearby({
                parameters: {latitude, longitude}
            })
        } else {
            console.error('no sitePreferences were added for site ' + site.id)
            return null
        }
    }

    const {data: result} = useQuery({
        queryKey: ['widget', 'store', stores],
        queryFn: async () => {
            let latitude, longitude
            if (stores === '[]' || stores === '') {
                if (navigator?.geolocation?.getCurrentPosition) {
                    try {
                        const pos = await new Promise((resolve, reject) =>
                            navigator?.geolocation?.getCurrentPosition(resolve, reject, {
                                enableHighAccuracy: false,
                                maximumAge: Infinity
                            })
                        )
                        latitude = pos.coords.latitude
                        longitude = pos.coords.longitude
                        return api.shopperStore.getStoresNearby({
                            parameters: {latitude, longitude}
                        })
                    } catch (e) {
                        console.error('error while getting geoLocation', e)
                        return getSitePrefGeocoordinates()
                    }
                } else {
                    return getSitePrefGeocoordinates()
                }
            } else {
                const ids = tryParseJSONObject(stores)?.map((store) => store.id)
                if (ids) {
                    return api.shopperStore.getStoresByIds({parameters: {ids}})
                }
                return null
            }
        }
    })

    const tiles = useMemo(() => {
        if (result) {
            const data = result.data
            if (!isSm) {
                return data
            } else {
                const out = []
                data.forEach((item, index) => {
                    if (index % 2 === 0) {
                        out.push([item, data[index + 1]])
                    }
                })
                return out
            }
        }
        return null
    }, [result, isSm])

    return (
        <>
            {!!tiles && (
                <Slider itemsPerSlide={itemsPerSlide}>
                    {isSm
                        ? tiles.map((store) => {
                              return (
                                  <Flex
                                      key={store[0].id}
                                      gap={4}
                                      flexDirection="column"
                                      cursor="auto" //the slider adds cursor='pointer', so we have to overwrite this
                                  >
                                      <WidgetStoreTile
                                          store={store[0]}
                                          key={store[0].id}
                                          color={color}
                                          showImages={showImages}
                                          variant={store[0].alignment}
                                      />
                                      {!!store[1] && (
                                          <WidgetStoreTile
                                              store={store[1]}
                                              key={store[1].id}
                                              color={color}
                                              showImages={showImages}
                                              variant={store[1].alignment}
                                          />
                                      )}
                                  </Flex>
                              )
                          })
                        : tiles.map((store) => {
                              return (
                                  <WidgetStoreTile
                                      store={store}
                                      key={store.id}
                                      color={color}
                                      showImages={showImages}
                                      variant={store.alignment}
                                  />
                              )
                          })}
                </Slider>
            )}
        </>
    )
}

WidgetStore.propTypes = {
    stores: PropTypes.string,
    color: PropTypes.string,
    images: PropTypes.string
}

export default WidgetStore
