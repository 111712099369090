import React from 'react'
import PropTypes from 'prop-types'
import {AspectRatio, Box, Image, Text} from '@chakra-ui/react'
import useOcapiSite from '../../hooks/useOcapiSite'
import {HYBRID_CONTENT} from '../../constants'
import {isHybrid} from '../../utils/site-utils'

const ContentTile = ({content, variant, ratio}) => {
    const {hybridConfig} = useOcapiSite()
    const onTileClick = () => {
        isHybrid(hybridConfig, HYBRID_CONTENT)
            ? (window.location.href = content.c_slugUrl)
            : history.push(content.c_slugUrl)
    }
    const boldText = {base: 'Semi Bold Small', xl: 'Semi Bold Medium'}
    const regularText = {base: 'Regular 2XLarge', xl: 'Regular 3XLarge'}
    return (
        <Box
            data-testid="content-tile"
            width="100%"
            cursor="pointer"
            onClick={() => {
                onTileClick()
            }}
        >
            <AspectRatio ratio={ratio}>
                {content.c_pageImage ? (
                    <Image
                        src={content.c_pageImage}
                        width="100%"
                        data-testid="content-tile-image"
                    />
                ) : (
                    <Box
                        width="100%"
                        background="stokkeGray.mid"
                        data-testid="content-tile-image"
                    ></Box>
                )}
            </AspectRatio>
            <Box paddingTop={4}>
                {variant === 'extended' && !!content.c_folderName && (
                    <Text textStyle={boldText} marginTop={2} data-testid="subline-element">
                        {content.c_folderName}
                    </Text>
                )}
            </Box>
            <Text
                textStyle={variant === 'extended' ? regularText : boldText}
                data-testid="headline-element"
                dangerouslySetInnerHTML={{
                    __html: content.name
                }}
            ></Text>
        </Box>
    )
}

ContentTile.propTypes = {
    content: PropTypes.shape({
        name: PropTypes.string,
        c_pageImage: PropTypes.string,
        c_folderName: PropTypes.string,
        c_slugUrl: PropTypes.string
    }),
    variant: PropTypes.string,
    ratio: PropTypes.array
}

export default ContentTile
