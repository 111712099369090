import React from 'react'
import {Box, forwardRef, omitThemingProps, useStyleConfig} from '@chakra-ui/react'

const Video = forwardRef((props, ref) => {
    const style = useStyleConfig('Video', props)
    const {src, ...htmlProps} = omitThemingProps(props)
    return <Box {...style} as="video" src={src} {...htmlProps} ref={ref} />
})

if (process.env.NODE_ENV === 'development') {
    Video.displayName = 'Video'
}

Video.propTypes = {}

export default Video
