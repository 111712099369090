import React from 'react'
import PropTypes from 'prop-types'
import {Flex} from '@chakra-ui/react'
import MyStokkeBackToStokke from './back-to-stokke'

const MyStokkeFooter = ({children, ...props}) => (
    <Flex
        // based on addresses and orders designs - might need update for other pages
        alignItems="center"
        flexDirection="column"
        paddingTop={6}
        paddingX={4}
        paddingBottom={8}
        width="full"
        {...props}
    >
        <Flex flexDirection="column" alignItems="stretch" gap={2} width="full" maxWidth="25rem">
            {React.Children.count(children) > 0 ? children : <MyStokkeBackToStokke />}
        </Flex>
    </Flex>
)

MyStokkeFooter.propTypes = {
    children: PropTypes.node
}

export default MyStokkeFooter
