import React, {useEffect, useMemo} from 'react'
import {useRouteMatch} from 'react-router-dom'
import useOcapiSite from '../../../hooks/useOcapiSite'
import {
    ACCOUNT_HREF,
    API_ERROR_MESSAGE,
    HYBRID_ACCOUNT,
    MAX_IDS_PER_GET_PRODUCTS_REQUEST,
    MY_STOKKE_ORDERS_PAGE_ID
} from '../../../constants'
import {getMyStokkeLabels} from '../../../utils/site-utils'
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Flex,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Skeleton,
    Text
} from '@chakra-ui/react'
import BreadcrumbItem from '../../../components/breadcrumb/breadcrumb-item'
import {FormattedMessage, useIntl} from 'react-intl'
import useEffectEvent from '../../../hooks/use-effect-event'
import {useToast} from '../../../hooks/use-toast'
import OrderStatus from '../partials/order-status'
import Link from '../../../components/link'
import {EditIcon, WarningIcon} from '../../../components/icons'
import ProductItem from '../../../components/product-item'
import useWriteProductReviewModal from '../../../bazaarvoice-api/hooks/use-write-product-review-modal'
import {CartItemSkeleton} from '../../cart/partials/cart-skeleton'
import OrderView from '../../../partials/order-view'
import OrderSummary from '../../../components/order-summary'
import MyStokkeLayoutDefault from '../layouts/default'
import {MyStokkePageConfigProvider} from '../hooks/use-page-config'
import MyStokkeFooter from '../partials/footer'
import MyStokkeLinkContainer from '../partials/link-container'
import useOrderAction from '../hooks/use-order-action'
import {useProducts} from '@salesforce/commerce-sdk-react'
import {useOrder} from '@salesforce/commerce-sdk-react'

const MyStokkeOrderDetails = () => {
    const {formatDate, formatMessage} = useIntl()
    const toast = useToast()
    const {
        myStokkeConfig: {[MY_STOKKE_ORDERS_PAGE_ID]: ordersPageConfig}
    } = useOcapiSite()
    const {
        params: {orderId}
    } = useRouteMatch()

    const {
        data: order,
        isLoading: orderLoading,
        error: orderError
    } = useOrder(
        {
            parameters: {orderNo: orderId}
        },
        {
            enabled: !!orderId
        }
    )

    // log error and show toast
    const onOrderError = useEffectEvent(() => {
        toast({
            status: 'error',
            title: formatMessage(API_ERROR_MESSAGE)
        })
    })
    useEffect(() => {
        if (orderError) {
            console.error('Error fetching order', orderError)
            onOrderError()
        }
    }, [orderError, onOrderError])

    const ids = useMemo(
        () => order?.productItems.map((item) => item.productId) || [],
        [order?.productItems]
    )
    const {
        data: productDetails1,
        error: productsError1,
        isInitialLoading: productDetailsLoading1
    } = useProducts(
        {
            parameters: {
                ids: ids.slice(0, MAX_IDS_PER_GET_PRODUCTS_REQUEST).join(',')
            }
        },
        {
            enabled: ids.length > 0
        }
    )
    const {
        data: productDetails2,
        error: productsError2,
        isInitialLoading: productDetailsLoading2
    } = useProducts(
        {
            parameters: {
                ids: ids
                    .slice(MAX_IDS_PER_GET_PRODUCTS_REQUEST, 2 * MAX_IDS_PER_GET_PRODUCTS_REQUEST)
                    .join(',')
            }
        },
        {
            enabled: ids.length > MAX_IDS_PER_GET_PRODUCTS_REQUEST
        }
    )

    const productDetails = useMemo(
        () => ({
            data: [...(productDetails1?.data || []), ...(productDetails2?.data || [])]
        }),
        [productDetails1, productDetails2]
    )
    const productsError = productsError1 || productsError2
    const productDetailsLoading = productDetailsLoading1 || productDetailsLoading2

    // log error and show toast
    const onProductsError = useEffectEvent(() => {
        toast({
            status: 'error',
            title: formatMessage(API_ERROR_MESSAGE)
        })
    })
    useEffect(() => {
        if (productsError) {
            console.error('Error fetching product details', productsError)
            onProductsError()
        }
    }, [productsError, onProductsError])

    // product items with product details to display
    const productItems = useMemo(() => {
        if (!productDetails?.data) {
            return order?.productItems
        }

        const productDetailsByIds = Object.fromEntries(
            productDetails.data.map((product) => [product.id, product])
        )
        return order?.productItems?.map((item) => ({
            ...item,
            ...productDetailsByIds[item.productId],
            productExists: Boolean(productDetailsByIds[item.productId]),
            price: item.price
        }))
    }, [order, productDetails])

    // order date in localized format
    const orderDate = useMemo(
        () =>
            order &&
            formatDate(new Date(order.creationDate), {
                year: 'numeric',
                day: 'numeric',
                month: 'numeric'
            }),
        [formatDate, order]
    )

    const ordersPageConfigHeadline = useMemo(() => {
        return getMyStokkeLabels(ordersPageConfig.id, 'headline', formatMessage)
    }, [ordersPageConfig, formatMessage])

    const orderAction = useOrderAction(order)

    const {loading: writeReviewLoading, onOpen: openWriteReviewModal} = useWriteProductReviewModal()

    return (
        <MyStokkePageConfigProvider
            value={{
                ...ordersPageConfig,
                headline: null,
                subline: null
            }}
        >
            <MyStokkeLayoutDefault
                breadcrumbs={[
                    <BreadcrumbItem key={1} href={`${ACCOUNT_HREF}/orders`} page={HYBRID_ACCOUNT}>
                        {ordersPageConfigHeadline}
                    </BreadcrumbItem>,
                    <BreadcrumbItem key={2}>
                        <FormattedMessage
                            defaultMessage="Order ID {orderNumber}"
                            id="mystokke.orders.label.order_number"
                            values={{orderNumber: orderId}}
                        />
                    </BreadcrumbItem>
                ]}
                loading={writeReviewLoading}
            >
                <Flex flexDirection="column" paddingTop={8} gap={8}>
                    <Flex flexDirection="column" gap={4}>
                        <Text textStyle="Regular 45XLarge">
                            <FormattedMessage
                                id="mystokke.order_details.heading"
                                defaultMessage="Order details"
                            />
                        </Text>

                        <Flex flexDirection="column" gap={2}>
                            <Text textStyle="Regular Medium">
                                <FormattedMessage
                                    defaultMessage="Order ID {orderNumber}"
                                    id="mystokke.orders.label.order_number"
                                    values={{orderNumber: orderId}}
                                />
                            </Text>

                            <Skeleton isLoaded={!orderLoading}>
                                <Text textStyle="Regular Medium">
                                    <FormattedMessage
                                        defaultMessage="Order date: {orderDate}"
                                        id="mystokke.orders.label.order_date"
                                        values={{orderDate}}
                                    />
                                </Text>
                            </Skeleton>
                        </Flex>

                        <Flex alignItems="center" justifyContent="space-between">
                            <Flex gap={1}>
                                <Text textStyle="Regular Small">
                                    <FormattedMessage
                                        defaultMessage="Status:"
                                        id="mystokke.orders.label.status"
                                    />
                                </Text>

                                <Skeleton isLoaded={!orderLoading}>
                                    <OrderStatus order={order} />
                                </Skeleton>
                            </Flex>
                            {orderAction && (
                                <Flex alignItems="center" gap={2}>
                                    <Button
                                        {...orderAction.buttonProps}
                                        {...(orderAction.buttonProps?.to ? {as: Link} : {})}
                                        variant="link"
                                        leftIcon={
                                            orderAction.Icon && <orderAction.Icon boxSize={4} />
                                        }
                                    >
                                        {orderAction.label}
                                    </Button>
                                    {orderAction.tooltip && (
                                        <Popover
                                            placement="top"
                                            variant="feedbackReportTooltip"
                                            gutter={14}
                                        >
                                            <PopoverTrigger>
                                                <IconButton
                                                    minWidth="unset"
                                                    height="unset"
                                                    minHeight="unset"
                                                    lineHeight={0}
                                                    paddingBottom={0.5}
                                                    icon={<WarningIcon boxSize={4} />}
                                                    variant="unstyled"
                                                />
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverBody>{orderAction.tooltip}</PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    )}
                                </Flex>
                            )}
                        </Flex>
                    </Flex>

                    <Divider colorScheme="stokkeGray" />
                </Flex>

                {/* products */}
                <Flex flexDirection="column">
                    <Box textStyle="Semi Bold Medium" paddingTop={6} paddingBottom={4}>
                        {productItems ? (
                            <Text>
                                <FormattedMessage
                                    defaultMessage="{count} products"
                                    id="mystokke.order_details.products_count"
                                    values={{count: productItems.length}}
                                />
                            </Text>
                        ) : (
                            <Skeleton />
                        )}
                    </Box>
                    <Flex
                        flexDirection="column"
                        gap={4}
                        paddingBottom={4}
                        data-testid="order-products"
                    >
                        {productItems ? (
                            productItems.map((item, idx) => (
                                <ProductItem
                                    key={idx}
                                    index={idx}
                                    product={item}
                                    productLineItem={item}
                                    variant="orderHistory"
                                    showLink={Boolean(item.productExists)}
                                    showLoading={productDetailsLoading}
                                    secondaryActions={
                                        <ButtonGroup spacing={6}>
                                            <Button
                                                variant="link"
                                                size="sm"
                                                leftIcon={<EditIcon boxSize={4} />}
                                                isDisabled={
                                                    !item.productExists ||
                                                    !order.c_productReviewsEnabled
                                                }
                                                onClick={() => openWriteReviewModal(item.productId)}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Write a review"
                                                    id="mystokke.order_details.product_tile.write_review"
                                                />
                                            </Button>
                                        </ButtonGroup>
                                    }
                                />
                            ))
                        ) : (
                            <>
                                <CartItemSkeleton />
                                <CartItemSkeleton />
                            </>
                        )}
                    </Flex>
                </Flex>

                {/* shipment */}
                <Flex flexDirection="column" paddingBottom={4}>
                    {orderLoading ? (
                        <Skeleton height="40rem" />
                    ) : (
                        order && (
                            <Box paddingX={4} paddingY={6} backgroundColor="stokkeCore.white">
                                <OrderView order={order} colorScheme="stokkeGray" />
                            </Box>
                        )
                    )}
                </Flex>

                {/* payment */}
                <Flex flexDirection="column">
                    {orderLoading ? (
                        <Skeleton height="25rem" />
                    ) : (
                        order && (
                            <Box paddingX={4} paddingY={6} backgroundColor="stokkeCore.white">
                                <OrderSummary
                                    basket={order}
                                    colorScheme="stokkeGray"
                                    heading={formatMessage({
                                        defaultMessage: "Here's what you've ordered",
                                        id: 'order_confirmation.heading.order_summary'
                                    })}
                                    showCartItems={false}
                                />
                            </Box>
                        )
                    )}

                    <MyStokkeFooter>
                        <MyStokkeLinkContainer>
                            <Link to={`${ACCOUNT_HREF}/orders`}>
                                <FormattedMessage
                                    defaultMessage="Go back to orders"
                                    id="mystokke.order_details.go_back_to_orders"
                                />
                            </Link>
                        </MyStokkeLinkContainer>
                    </MyStokkeFooter>
                </Flex>
            </MyStokkeLayoutDefault>
        </MyStokkePageConfigProvider>
    )
}

export default MyStokkeOrderDetails
