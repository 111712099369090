export default {
    baseStyle: {
        container: {
            spacing: 2
        },
        title: {
            color: 'stokkeCore.black',
            textStyle: 'Semi Bold Small'
        },
        imagesContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            gap: 2.5
        },
        imageContainer: {
            padding: 1
        },
        image: {
            width: '60.8px',
            height: 4,
            objectFit: 'contain'
        }
    },
    variants: {
        center: {
            container: {
                alignItems: 'center'
            },
            imagesContainer: {
                justifyContent: 'center'
            }
        }
    }
}
