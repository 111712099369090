import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        text: {
            color: 'stokkeFunctional.alertRed',
            ...textStyles['Regular Small']
        }
    }
}
