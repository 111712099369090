import {useState} from 'react'

export const useKeyboardFocus = ({variant = ''} = {}) => {
    const [focusVariant, setFocusVariant] = useState(variant)
    const [usingMouse, setUsingMouse] = useState(false)

    // toggles using mouse flag so next focus uses mouse focus
    const onMouseEvent = () => {
        setUsingMouse(true)
    }

    const onFocus = () => {
        if (usingMouse) {
            setFocusVariant(variant)
            // resets mouse flag so that if next action is tab highlight is shown
            setUsingMouse(false)
        } else {
            setFocusVariant(`focused`)
        }
    }

    const onBlur = () => {
        setFocusVariant(variant)
        setUsingMouse(false)
    }

    return {
        onMouseEvent,
        onFocus,
        onBlur,
        focusVariant
    }
}
