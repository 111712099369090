import React, {useMemo} from 'react'
import useProductRating from '../../bazaarvoice-api/hooks/use-product-rating'
import Ratings from '../ratings'
import PropTypes from 'prop-types'
import RatingSkeleton from '../ratings/skeleton'
import {convertBVRatingValue} from './utils'

const BVProductRating = ({product, variant, ...props}) => {
    const {data, loading, error} = useProductRating(product)

    const rating = useMemo(
        () => convertBVRatingValue(data?.AverageOverallRating, data?.OverallRatingRange),
        [data]
    )

    if (loading) {
        return <RatingSkeleton variant={variant} />
    }

    if (error || !data) {
        return null
    }

    return <Ratings count={data?.TotalReviewCount} rating={rating} variant={variant} {...props} />
}

BVProductRating.propTypes = {
    product: PropTypes.object,
    variant: PropTypes.string
}

export default BVProductRating
