export default {
    baseStyle: {
        color: 'yellow'
    },
    variants: {
        pagination: {
            track: {
                bg: 'stokkeGray.mid'
            },
            filledTrack: {
                bg: 'stokkeCore.black',
                borderRadius: '7px'
            }
        }
    },
    sizes: {
        xs: {
            track: {
                h: '2px'
            }
        }
    }
}
