import {useIntl} from 'react-intl'

export default function useAccountChildrenFields({form: {control}, prefix = ''}) {
    const {formatMessage} = useIntl()

    return {
        ID: {
            name: `${prefix}ID`,
            defaultValue: '',
            type: 'hidden',
            control
        },
        childName: {
            name: `${prefix}childName`,
            label: formatMessage({
                defaultMessage: 'Childs name',
                id: 'mystokke.personal-information.child-name'
            }),
            type: 'text',
            defaultValue: '',
            inputProps: {
                variant: 'flushed'
            },
            control
        },
        childBirthdate: {
            name: `${prefix}childBirthdate`,
            label: formatMessage({
                defaultMessage: 'Childs birth date',
                id: 'mystokke.personal-information.child-birthdate'
            }),
            type: 'date',
            defaultValue: '',
            inputProps: {
                variant: 'flushed'
            },
            control
        }
    }
}
