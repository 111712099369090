import React from 'react'
import {HYBRID_ACCOUNT} from '../../../constants'
import {FormattedMessage} from 'react-intl'
import useMyStokkeRootPath from '../hooks/use-root-path'
import MyStokkeLinkContainer from './link-container'
import HybridLink from '../../../components/hybrid-link'

const MyStokkeBackToStokke = () => {
    const path = useMyStokkeRootPath()

    return (
        <MyStokkeLinkContainer>
            <HybridLink page={HYBRID_ACCOUNT} to={path}>
                <FormattedMessage
                    id="mystokke.back-to-stokke"
                    defaultMessage="Back to My Stokke®"
                />
            </HybridLink>
        </MyStokkeLinkContainer>
    )
}

export default MyStokkeBackToStokke
