export default {
    baseStyle: {
        heading: {
            alignItems: 'start',
            gap: 2
        },
        icon: {
            boxSize: 4,
            mt: 0.75 //align icon with text
        },
        title: {
            textStyle: 'Semi Bold Medium',
            width: 'auto'
        },
        description: {
            textStyle: 'Regular Medium'
        }
    }
}
