import PropTypes from 'prop-types'
import React from 'react'
import BadgeGroup from '../../../components/badge-group'

const CockpitTags = ({product, promotions}) => {
    return <BadgeGroup product={product} promotions={promotions} />
}

CockpitTags.propTypes = {
    product: PropTypes.object,
    promotions: PropTypes.array
}

export default CockpitTags
