const breakpoints = {
    base: '0em',
    sm: '480px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    '2xl': '1536px'
}

export default breakpoints
