import MyStokkePersonalData from './subpages/personal-data'
import MyStokkeAddresses from './subpages/addresses'
import MyStokkeOrders from './subpages/orders'
import {
    HYBRID_ACCOUNT,
    HYBRID_EXTERNAL,
    MY_STOKKE_ADDRESSES_PAGE_ID,
    MY_STOKKE_DASHBOARD_PAGE_ID,
    MY_STOKKE_ORDERS_PAGE_ID,
    MY_STOKKE_PERSONAL_INFORMATION_PAGE_ID,
    MY_STOKKE_WARRANTY_PAGE_ID
} from '../../constants'
import MyStokkeDashboard from './subpages/dashboard'
import {BasketIcon, AccountIcon, StorefinderIcon, WarrantyIcon} from '../../components/icons'
import {defineMessage} from 'react-intl'
import MyStokkeOrderDetails from './subpages/order-details'

export default [
    {
        configId: MY_STOKKE_DASHBOARD_PAGE_ID,
        component: MyStokkeDashboard,
        hybridPageType: HYBRID_ACCOUNT,
        path: '/',
        exact: true,
        icon: AccountIcon,
        label: defineMessage({id: 'mystokke.dashboard.headline', defaultMessage: 'My Stokke®'})
    },
    {
        configId: MY_STOKKE_ORDERS_PAGE_ID,
        component: MyStokkeOrders,
        hybridPageType: HYBRID_ACCOUNT,
        path: '/orders',
        exact: true,
        icon: BasketIcon
    },
    {
        component: MyStokkeOrderDetails,
        hybridPageType: HYBRID_ACCOUNT,
        path: '/orders/:orderId',
        exact: true
    },
    {
        configId: MY_STOKKE_WARRANTY_PAGE_ID,
        hybridPageType: HYBRID_EXTERNAL,
        icon: WarrantyIcon
    },
    {
        configId: MY_STOKKE_PERSONAL_INFORMATION_PAGE_ID,
        component: MyStokkePersonalData,
        hybridPageType: HYBRID_ACCOUNT,
        path: '/personal-information',
        exact: true
    },
    {
        configId: MY_STOKKE_ADDRESSES_PAGE_ID,
        component: MyStokkeAddresses,
        hybridPageType: HYBRID_ACCOUNT,
        path: '/addresses',
        exact: true,
        icon: StorefinderIcon
    }
]
