import React from 'react'
import {Alert, Button, Flex, Stack, Text, Tooltip} from '@chakra-ui/react'
import {FormattedMessage, useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {AlertIcon, EditIcon, InfoIconFilled} from '../../../components/icons'
import Field from '../../../components/field'
import PasswordRequirements from '../../../components/forms/password-requirements'
import useUpdatePasswordFields from '../../../components/forms/useUpdatePasswordFields'
import MyStokkeLinkContainer from './link-container'
import {useWatch} from 'react-hook-form'

const MyStokkePersonalDataCredentials = ({
    form,
    submitForm,
    data,
    isEditing,
    onEdit,
    onCancel,
    isLoading
}) => {
    const fields = useUpdatePasswordFields({form})
    const password = useWatch({control: form.control, name: 'password'})

    const {formatMessage} = useIntl()

    return (
        <>
            <Text as="h2" textStyle={{base: 'Regular 2XLarge'}} paddingTop={6} paddingBottom={6}>
                <FormattedMessage
                    defaultMessage="Credentials"
                    id="mystokke.personal-information.credentials"
                />
            </Text>
            <Stack
                bgColor={!isEditing ? 'stokkeCore.white' : 'stokkeGray.light'}
                padding={4}
                spacing={6}
            >
                {!isEditing && (
                    <>
                        <Stack spacing={4}>
                            <Text textStyle="Semi Bold Medium">
                                <FormattedMessage
                                    defaultMessage="E-Mail Address"
                                    id="use_newsletter_fields.label.email"
                                />
                                <Tooltip
                                    hasArrow
                                    placement="top"
                                    label={formatMessage({
                                        defaultMessage:
                                            'Your email address is linked to your current and past orders and cannot be changed. If you need to use a different email, please create a new account.',
                                        id: 'mystokke.personal-information.email-helptext'
                                    })}
                                >
                                    <InfoIconFilled variant="tooltip" />
                                </Tooltip>
                            </Text>
                            <Text textStyle="Regular Medium">{data.email}</Text>
                        </Stack>
                        <Stack spacing={4}>
                            <Text textStyle="Semi Bold Medium">
                                <FormattedMessage
                                    defaultMessage="Password"
                                    id="password_card.label.password"
                                />
                            </Text>
                            <Text textStyle="Regular Medium">•••••••••••••••••</Text>
                        </Stack>
                        <Button
                            variant="link"
                            width="fit-content"
                            onClick={() => {
                                form.reset({...data})
                                onEdit()
                            }}
                        >
                            <Flex gap={2} alignItems="center">
                                <EditIcon width={4} height={4} />
                                <FormattedMessage defaultMessage="Edit" id="global.edit" />
                            </Flex>
                        </Button>
                    </>
                )}
                {!!isEditing && (
                    <form
                        onSubmit={form.handleSubmit(submitForm)}
                        noValidate
                        name="personal-data-credentials"
                        data-testid="mystokke-credentials-form"
                    >
                        <Stack spacing={6}>
                            <Field
                                {...fields.email}
                                // remove the tick icon
                                rightElement={<></>}
                            />
                            <Field {...fields.currentPassword} />
                            <Field {...fields.password} />
                            <Field {...fields.confirmPassword} />
                            <PasswordRequirements value={password} />
                            {form.formState.errors.global && (
                                <Alert status="error">
                                    <AlertIcon color="red.500" boxSize={4} />
                                    <Text fontSize="sm" ml={3}>
                                        {form.formState.errors.global.message}
                                    </Text>
                                </Alert>
                            )}
                            <Stack spacing={3} alignItems="center">
                                <Button
                                    type="submit"
                                    onClick={() => form.clearErrors('global')}
                                    isLoading={isLoading}
                                    width="100%"
                                >
                                    <FormattedMessage
                                        defaultMessage="Save"
                                        id="form_action_buttons.button.save"
                                    />
                                </Button>
                                <MyStokkeLinkContainer>
                                    <Button variant="link" onClick={onCancel}>
                                        {
                                            <FormattedMessage
                                                id="form_action_buttons.button.cancel"
                                                defaultMessage="Cancel"
                                            />
                                        }
                                    </Button>
                                </MyStokkeLinkContainer>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Stack>
        </>
    )
}

export default MyStokkePersonalDataCredentials

MyStokkePersonalDataCredentials.propTypes = {
    form: PropTypes.object,
    submitForm: PropTypes.func,
    data: PropTypes.object,
    isEditing: PropTypes.bool,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool
}
