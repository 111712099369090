import {Flex} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'

const OrderSummaryListItem = ({children, ...props}) => {
    if (React.Children.count(children) === 0) {
        return null
    }

    return (
        <Flex as="li" justify="space-between" alignItems="center" {...props}>
            {children}
        </Flex>
    )
}

OrderSummaryListItem.propTypes = {
    children: PropTypes.node
}

export default OrderSummaryListItem
