import textStyles from '../../foundations/textStyles'

export default {
    parts: ['container', 'item', 'heading', 'headingLabel', 'headingIcon', 'content'],
    baseStyle: {
        container: {
            color: 'stokkeCore.black'
        },
        heading: {},
        headingLabel: {
            ...textStyles['Semi Bold Small']
        }
    },
    variants: {
        collapsable: {
            container: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                gap: 8,
                // Accordion elements doesn't support inline styling so we use variant to style it
                variant: 'footer'
            }
        },
        fixed: {
            container: {
                display: 'grid',
                gridTemplateColumns: {
                    base: '1fr',
                    md: 'repeat(3, 1fr)',
                    lg: 'repeat(4, 1fr)'
                },
                rowGap: 10,
                columnGap: 8
            },
            item: {
                display: 'flex',
                flexDirection: 'column',
                gap: 6,
                minWidth: 0
            }
        }
    }
}
