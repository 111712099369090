import {IconButton, HStack, Box, Spinner} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import {useIntl} from 'react-intl'
import Field from '../field'
import {GpsLocationIcon, SearchIcon} from '../icons'
import useAutocompleteFindStoreFields from './useAutocompleteFindStoreFields'
import useFindStoreFields from './useFindStoreFields'
import useGeolocationFindStoreFields from './useGeolocationFindStoreFields'
import {useWatch} from 'react-hook-form'

const FindStoreFields = ({form, prefix = '', onGpsLocation, onError}) => {
    const fields = useFindStoreFields({form, prefix})
    const intl = useIntl()
    const address = useWatch({control: form.control, name: 'address'})
    const {ref} = useAutocompleteFindStoreFields(form)
    const {supported, loading, getCurrentPosition} = useGeolocationFindStoreFields({
        form,
        onGpsLocation,
        onError
    })
    const disabled = address?.length < 1

    return (
        <Box>
            <Field {...fields.latitude} />
            <Field {...fields.longitude} />
            <HStack spacing={2}>
                <Field
                    {...fields.address}
                    inputProps={{paddingRight: (disabled ? 0 : 7) + (supported ? 7 : 0)}}
                    ref={ref}
                    rightElement={
                        loading ? (
                            <Box>
                                <Spinner size="sm" />
                            </Box>
                        ) : (
                            supported && (
                                <IconButton
                                    aria-label={intl.formatMessage({
                                        id: 'find_store_fields.button.assistive_msg.gps_location',
                                        defaultMessage: 'Use current GPS location'
                                    })}
                                    boxSize={8}
                                    minWidth={8}
                                    lineHeight="1"
                                    icon={<GpsLocationIcon boxSize={4} />}
                                    variant="unstyled"
                                    onClick={getCurrentPosition}
                                />
                            )
                        )
                    }
                />
                <IconButton
                    type="submit"
                    aria-label={intl.formatMessage({
                        id: 'find_store_fields.button.assistive_msg.submit',
                        defaultMessage: 'Show nearby stores'
                    })}
                    boxSize={14}
                    minWidth={14}
                    variant={disabled ? 'outline' : 'solid'}
                    icon={<SearchIcon boxSize={6} />}
                    isLoading={form.formState.isSubmitting}
                    disabled={disabled}
                />
            </HStack>
        </Box>
    )
}

FindStoreFields.propTypes = {
    /** Object returned from `useForm` */
    form: PropTypes.object.isRequired,

    /** Optional prefix for field names */
    prefix: PropTypes.string,

    /** Function to call when gps location is obtained */
    onGpsLocation: PropTypes.func,

    /** Function to call when an error occurs */
    onError: PropTypes.func
}

export default FindStoreFields
