import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Code} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import AddToCartCTA, {Skeleton as AddToCartSkeleton} from '../../components/add-to-cart-cta'
import {AddToCartModalProvider} from '../../hooks/use-add-to-cart-modal'
import {BonusProductsModalProvider} from '../../hooks/use-bonus-products-modal'
import {useProduct} from '@salesforce/commerce-sdk-react'

/**
 * Display add to cart button for a given product ID.
 *
 * @param {string} pid - Product ID
 * @param {string} label - Label for the button
 *
 * @example
 *   <pwa-widget-add-to-cart pid="101001" label="add to cart">
 *     $include(...)$
 *   </pwa-widget-add-to-cart>
 */
const WidgetAddToCart = ({pid, label}) => {
    const {
        data: product,
        isLoading,
        error
    } = useProduct({parameters: {id: pid, allImages: true}}, {enabled: !!pid})

    if (!pid) {
        return <Alert status={'error'}>No product ID provided</Alert>
    }

    if (isLoading) {
        return <AddToCartSkeleton />
    }

    if (error) {
        return (
            <Alert status="error">
                <AlertIcon />
                <Box>
                    <AlertTitle>
                        Something bad happened while loading <Code>{pid}</Code>!
                    </AlertTitle>
                    <AlertDescription>{error.toString()}</AlertDescription>
                </Box>
            </Alert>
        )
    }

    // prevent initial flash of undefined product
    if (!product) {
        return null
    }

    return (
        <AddToCartModalProvider>
            <BonusProductsModalProvider>
                <AddToCartCTA label={label} product={product} scrollToTop={false} />
            </BonusProductsModalProvider>
        </AddToCartModalProvider>
    )
}

WidgetAddToCart.propTypes = {
    pid: PropTypes.string,
    label: PropTypes.string
}

export default WidgetAddToCart
