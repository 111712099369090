import React from 'react'
import hoistNonReactStatic from 'hoist-non-react-statics'
import ssrPrepass from 'react-ssr-prepass'
import {dehydrate, Hydrate, QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {FetchStrategy} from '@salesforce/pwa-kit-react-sdk/ssr/universal/components/fetch-strategy'

const STATE_KEY = '__reactQuery'

/**
 * A HoC for adding React Query support to your application and handling waterfall queries.
 *
 * based on withReactQuery from pwa-kit-react-sdk
 *
 * @param {React.ReactElement} Wrapped The component to be wrapped
 * @param {Object} options
 * @param {Object} options.queryClientConfig The react query client configuration object to be used.
 *
 * @returns {React.ReactElement}
 */
export const withWaterfallReactQuery = (Wrapped, options = {}) => {
    const isServerSide = typeof window === 'undefined'
    const wrappedComponentName = Wrapped.displayName || Wrapped.name
    const queryClientConfig = options.queryClientConfig
    const maxWaterfallDepth = options.maxWaterfallDepth || 5
    const warnWaterfallTime = options.warnWaterfallTime || 2500
    const warnPrepassTime = options.warnPrepassTime || 250
    const warnQueryTime = options.warnQueryTime || 1000

    /**
     * @private
     */
    class WithWaterfallReactQuery extends FetchStrategy {
        render() {
            this.props.locals.__queryClient =
                this.props.locals.__queryClient || new QueryClient(queryClientConfig)

            return (
                <QueryClientProvider client={this.props.locals.__queryClient}>
                    <Hydrate state={isServerSide ? {} : window.__PRELOADED_STATE__?.[STATE_KEY]}>
                        <Wrapped {...this.props} />
                    </Hydrate>
                </QueryClientProvider>
            )
        }

        /**
         * @private
         */
        static async doInitAppState({res, appJSX}) {
            const queryClient = (res.locals.__queryClient =
                res.locals.__queryClient || new QueryClient(queryClientConfig))

            const queryCache = queryClient.getQueryCache()

            const __startWaterfall = Date.now()

            // Fetch all pending queries in a waterfall pattern
            for (let depth = 0; depth < maxWaterfallDepth; depth++) {
                // Use `ssrPrepass` to collect all uses of `useQuery`.
                const __startPrepass = Date.now()
                await ssrPrepass(appJSX)
                const __endPrepass = Date.now()
                if (__endPrepass - __startPrepass > warnPrepassTime) {
                    console.warn(
                        `Slow prepass during iteration ${depth + 1} - ${
                            __endPrepass - __startPrepass
                        }ms`
                    )
                }

                const queries = queryCache
                    .getAll()
                    .filter((q) => q.options.enabled !== false && q.state.status === 'loading')

                // short-circuit if there are no more pending queries
                if (!queries.length) {
                    break
                }

                await Promise.all(
                    queries.map((q) => {
                        const __startQuery = Date.now()
                        // If there's an error in this fetch, react-query will log the error
                        return q
                            .fetch()
                            .catch(() => {
                                // On our end, simply catch any error and move on to the next query
                            })
                            .finally(() => {
                                const __endQuery = Date.now()
                                if (__endQuery - __startQuery > warnQueryTime) {
                                    console.warn(
                                        `Slow query ${q.queryHash} during iteration ${
                                            depth + 1
                                        } - ${__endQuery - __startQuery}ms`
                                    )
                                }
                            })
                    })
                )
            }

            const __endWaterfall = Date.now()
            if (__endWaterfall - __startWaterfall > warnWaterfallTime) {
                console.warn(
                    `Slow fetching all pending queries - ${__endWaterfall - __startWaterfall}ms`
                )
            }

            if (
                queryCache
                    .getAll()
                    .some((q) => q.options.enabled !== false && q.state.status === 'loading')
            ) {
                console.error(
                    `Failed to fetch all pending queries in ${maxWaterfallDepth} iterations`
                )
            }

            return {[STATE_KEY]: dehydrate(queryClient)}
        }

        /**
         * @private
         */
        static getInitializers() {
            return [WithWaterfallReactQuery.doInitAppState, ...(Wrapped.getInitializers?.() ?? [])]
        }

        /**
         * @private
         */
        static getHOCsInUse() {
            return [withWaterfallReactQuery, ...(Wrapped.getHOCsInUse?.() ?? [])]
        }
    }

    WithWaterfallReactQuery.displayName = `withWaterfallReactQuery(${wrappedComponentName})`

    const exclude = {
        doInitAppState: true,
        getInitializers: true,
        initAppState: true,
        getHOCsInUse: true
    }
    hoistNonReactStatic(WithWaterfallReactQuery, Wrapped, exclude)

    return WithWaterfallReactQuery
}
