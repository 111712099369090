import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        container: {
            rowGap: 2,
            columnGap: 6,
            direction: {base: 'column', lg: 'row'},
            wrap: 'wrap'
        },
        label: {
            ...textStyles['Regular Medium'],
            flexBasis: {lg: '100%', xl: 'auto'}
        },
        itemContainer: {
            spacing: 2,
            direction: 'row',
            alignItems: 'center'
        },
        image: {},
        itemLabel: {
            ...textStyles['"Semi Bold Medium"']
        }
    },
    variants: {},
    parts: ['container', 'stackContainer', 'itemContainer', 'image', 'itemLabel', 'label']
}
