import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {Box, Flex} from '@chakra-ui/react'

import RecommendedProducts from '../../../components/recommended-products'

import {getMyStokkeLabels} from '../../../utils/site-utils'
import useMyStokkePageConfig from '../hooks/use-page-config'
import MyStokkeBreadcrumb from '../partials/breadcrumb'
import BreadcrumbItem from '../../../components/breadcrumb/breadcrumb-item'
import MyStokkeHeader from '../partials/header'
import LoadingSpinner from '../../../components/loading-spinner'
import {useIntl} from 'react-intl'
import InteractionStudioRecommendedProducts from '../../../components/interaction-studio/recommended-products'
import {
    ProductQuickviewModal,
    useProductQuickviewModal
} from '../../../components/product-quickview-modal'

const MyStokkeLayoutDefault = ({breadcrumbs, children, loading}) => {
    const {formatMessage} = useIntl()
    const {id, recommender} = useMyStokkePageConfig()
    const headline = useMemo(() => {
        return getMyStokkeLabels(id, 'headline', formatMessage)
    }, [id, formatMessage])
    const subline = useMemo(() => {
        return getMyStokkeLabels(id, 'subline', formatMessage)
    }, [id, formatMessage])

    const productQuickviewModalProps = useProductQuickviewModal()
    productQuickviewModalProps.onRecommendedProductClick = productQuickviewModalProps.onOpen

    return (
        <>
            <MyStokkeBreadcrumb>
                {breadcrumbs ? breadcrumbs : <BreadcrumbItem>{headline}</BreadcrumbItem>}
            </MyStokkeBreadcrumb>
            <Box backgroundColor="stokkeGray.bright" position="relative">
                {loading && <LoadingSpinner />}
                <Flex
                    flexDirection="column"
                    alignItems="stretch"
                    maxWidth={{base: '34.375rem', lg: '37.5rem', xl: '40.625rem'}}
                    marginX="auto"
                    paddingX={{base: 4, md: 0}}
                >
                    {headline && <MyStokkeHeader headline={headline} subline={subline} />}
                    {children}
                    {recommender && (
                        <>
                            <InteractionStudioRecommendedProducts id="is-dashboard-recommendations">
                                <RecommendedProducts
                                    recommender={recommender}
                                    title={formatMessage({
                                        defaultMessage: 'You may also like',
                                        id: 'recommended_products.title.you_may_also_like'
                                    })}
                                    trackingLabel="My Stokke Recommendations"
                                    onRecommendedProductClick={productQuickviewModalProps.onOpen}
                                />
                            </InteractionStudioRecommendedProducts>
                            <ProductQuickviewModal {...productQuickviewModalProps} />
                        </>
                    )}
                </Flex>
            </Box>
        </>
    )
}

MyStokkeLayoutDefault.propTypes = {
    breadcrumbs: PropTypes.node,
    children: PropTypes.node,
    loading: PropTypes.bool
}

export default MyStokkeLayoutDefault
