/**
 * Text styles to repeatably apply a collection of text properties
 * (like line height and size) to any component.
 * @see https://chakra-ui.com/docs/features/text-and-layer-styles#text-styles text styles documentation
 * @see https://chakra-ui.com/docs/theming/theme#typography default theme typography
 * @example
 * <Box textStyle='Regular 2XLarge'>This is a box</Box>
 */
export default {
    /**
     * Regular for headlines and large texts.
     */
    // dot omitted from name because chakra special processes it
    'Regular 45XLarge': {
        fontFamily: 'stokkeRegular',
        fontSize: {
            base: '4.5xl',
            lg: '5xl',
            xl: '6xl'
        },
        fontWeight: 'normal',
        lineHeight: 'none',
        letterSpacing: 'normal'
    },
    /**
     * Regular for headlines and large texts.
     */
    'Regular 4XLarge': {
        fontFamily: 'stokkeRegular',
        fontSize: {
            base: '4xl',
            lg: '4.5xl'
        },
        fontWeight: 'normal',
        lineHeight: '120%',
        letterSpacing: 'normal'
    },
    /**
     * Stokke Regular works just perfectly fine for longer medium sized texts
     * where legibility is still good.
     */
    'Regular 2XLarge': {
        fontFamily: 'stokkeRegular',
        fontSize: {
            base: '2xl',
            xl: '3xl'
        },
        fontWeight: 'normal',
        lineHeight: '120%',
        letterSpacing: 'normal'
    },
    'Regular XLarge': {
        fontFamily: 'stokkeRegular',
        fontSize: 'xl',
        fontWeight: 'normal',
        lineHeight: '110%',
        letterSpacing: 'normal'
    },
    /**
     * Semi Bold for smaller subheadline
     */
    'Semi Bold Medium': {
        fontFamily: 'stokkeSemiBold',
        fontSize: 'md',
        fontWeight: 'semibold',
        lineHeight: '130%',
        letterSpacing: 'normal'
    },
    /**
     * Regular should be used for longer body texts as long as the legibility is good.
     * When text sizes becomes smaller than this and legibility might be compromised
     * and difficult to read longer texts, then Stokke Light should be considered.
     */
    'Regular Medium': {
        fontFamily: 'stokkeRegular',
        fontSize: 'md',
        fontWeight: 'normal',
        lineHeight: '130%', // Corresponds to: 20.8px on fontsize 16px
        letterSpacing: 'normal'
    },
    /**
     * The light version of the font is an alternative weight for body texts
     * where small and long texts might be difficult to read, for example
     * in digital use. The light weight gives a clearer impression and increases
     * readability in smaller sizes.
     */
    'Light Medium': {
        fontFamily: 'stokkeLight',
        fontSize: 'md',
        fontWeight: 'normal',
        lineHeight: '130%',
        letterSpacing: 'normal'
    },
    /**
     * From PDP headlights list
     */
    'List Regular': {
        fontFamily: 'stokkeRegular',
        fontSize: 'sm',
        fontWeight: 'normal',
        lineHeight: '140%',
        letterSpacing: 'normal'
    },
    /**
     * Regular for marginal notes
     */
    'Regular Small': {
        fontFamily: 'stokkeRegular',
        fontSize: 'sm',
        fontWeight: 'normal',
        lineHeight: '130%',
        letterSpacing: 'normal'
    },
    /**
     * Semi Bold for marginal notes
     */
    'Semi Bold Small': {
        fontFamily: 'stokkeSemiBold',
        fontSize: 'sm',
        fontWeight: 'semibold',
        lineHeight: '130%',
        letterSpacing: 'normal'
    },
    /**
     * Product Badges Regular
     * Regular Small Chip
     */
    'Badge Regular': {
        fontFamily: 'stokkeRegular',
        fontSize: 'xs',
        fontWeight: 'normal',
        lineHeight: '120%',
        letterSpacing: 'normal'
    },
    /**
     * Regular for extra small texts like footer text
     */
    'Regular Extra Small': {
        fontFamily: 'stokkeRegular',
        fontSize: 'xs',
        fontWeight: 'normal',
        lineHeight: '130%',
        letterSpacing: 'normal'
    },
    /**
     * Semi Bold for extra small texts like footer text
     */
    'Semi Bold Extra Small': {
        fontFamily: 'stokkeSemiBold',
        fontSize: 'xs',
        fontWeight: 'semibold',
        lineHeight: '130%',
        letterSpacing: 'normal'
    },
    /**
     * Locale selector uses different font size for different breakpoints
     */
    'Locale Selector': {
        fontFamily: 'stokkeRegular',
        fontSize: {base: 'sm', lg: 'xs'},
        fontWeight: 'normal',
        lineHeight: {base: '130%', lg: '120%'},
        letterSpacing: 'normal'
    },
    /**
     * Regular for extra small texts like badge text in navigation
     */
    'Descriptive Tags': {
        fontFamily: 'stokkeRegular',
        fontSize: '2xs',
        fontWeight: 'semibold',
        lineHeight: '130%',
        letterSpacing: 'normal'
    }
}
