import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    createStylesContext,
    Divider,
    Heading,
    Text,
    useBreakpointValue,
    useMultiStyleConfig
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, {Children, Fragment, useContext} from 'react'
import {ChevronDownIcon, ChevronUpIcon} from '../icons'

const [StylesProvider, useStyles] = createStylesContext('FooterSection')
const CollapsableContext = React.createContext(true)

export const FooterSectionContainer = ({children}) => {
    const collapsable = useBreakpointValue({base: true, md: false})
    const styles = useMultiStyleConfig('FooterSection', {
        variant: collapsable ? 'collapsable' : 'fixed'
    })

    return (
        <StylesProvider value={styles}>
            <CollapsableContext.Provider value={collapsable}>
                {collapsable ? (
                    <Accordion key="container" allowMultiple {...styles.container}>
                        {Children.toArray(children).map((child, idx) => (
                            <Fragment key={idx}>
                                {idx > 0 && <Divider />}
                                {child}
                            </Fragment>
                        ))}
                    </Accordion>
                ) : (
                    <Box key="container" {...styles.container}>
                        {children}
                    </Box>
                )}
            </CollapsableContext.Provider>
        </StylesProvider>
    )
}

FooterSectionContainer.propTypes = {
    children: PropTypes.any
}

export const FooterSection = ({label, children, ...ownProps}) => {
    const styles = useStyles()
    const collapsable = useContext(CollapsableContext)
    return collapsable ? (
        <AccordionItem key="item" {...styles.item}>
            {({isExpanded}) => (
                <>
                    <AccordionButton key="heading" {...styles.heading}>
                        <Text key="label" {...styles.headingLabel} textStyle="Semi Bold Small">
                            {label}
                        </Text>
                        {isExpanded ? (
                            <ChevronUpIcon boxSize={4} />
                        ) : (
                            <ChevronDownIcon boxSize={4} />
                        )}
                    </AccordionButton>
                    <AccordionPanel key="content" {...styles.content}>
                        {children}
                    </AccordionPanel>
                </>
            )}
        </AccordionItem>
    ) : (
        <Box key="item" {...styles.item} {...ownProps}>
            <Heading key="heading" {...styles.heading}>
                <Text key="label" {...styles.headingLabel}>
                    {label}
                </Text>
            </Heading>
            <Box key="content" {...styles.content}>
                {children}
            </Box>
        </Box>
    )
}

FooterSection.propTypes = {
    label: PropTypes.string,
    children: PropTypes.any
}
