import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: ({selected, orderable}) => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            height: 'auto',
            padding: 'none',
            borderStyle: orderable ? 'solid' : 'dashed',
            borderWidth: selected ? '2px' : '1px',
            borderColor: selected ? 'stokkeCore.black' : 'stokkeGray.mid',
            borderRadius: 5,
            _hover: {
                borderWidth: '2px',
                borderColor: 'stokkeCore.black'
            },
            _active: {
                borderWidth: '2px',
                borderColor: 'stokkeCore.black'
            }
        },
        image: {
            color: 'grey'
        },
        label: {}
    }),
    sizes: {
        sm: {
            container: {
                paddingLeft: 2,
                paddingRight: 4,
                height: 37,
                ...textStyles['Regular Small']
            },
            image: {
                boxSize: 6
            },
            label: {
                paddingLeft: 2
            }
        },
        md: ({leftIcon}) => {
            return {
                container: {
                    paddingLeft: leftIcon ? 6 : 3,
                    paddingRight: 6,
                    height: 45,
                    ...textStyles['Regular Medium']
                },
                image: {
                    boxSize: 6
                },
                label: {
                    paddingLeft: leftIcon ? 0 : 3
                }
            }
        }
    },
    defaultProps: {
        size: 'sm'
    },
    parts: ['container', 'image', 'label']
}
