import React from 'react'
import PropTypes from 'prop-types'
import useOcapiSite from '../../hooks/useOcapiSite'
import {isHybrid} from '../../utils/site-utils'
import Link from '../link'
import {Link as ChakraLink} from '@chakra-ui/react'
import {isAbsoluteUrl} from '../../utils/url'
import useMultiSite from '../../hooks/use-multi-site'

const HybridLink = React.forwardRef(
    ({isExternal, href, to, hybridHref, useNavLink = false, styles, page, ...props}, ref) => {
        const {buildUrl} = useMultiSite()
        const {hybridConfig} = useOcapiSite()

        if (isExternal || isHybrid(hybridConfig, page)) {
            const _href = hybridHref || to || href || '/'
            const updatedHref = !isAbsoluteUrl(_href) ? buildUrl(_href) : _href
            return (
                <ChakraLink
                    {...props}
                    href={updatedHref}
                    isExternal={isExternal}
                    ref={ref}
                    {...styles}
                />
            )
        }

        // if alias is not defined, use site id

        return <Link {...props} to={to} href={href} useNavLink={useNavLink} ref={ref} {...styles} />
    }
)

HybridLink.displayName = 'HybridLink'

HybridLink.propTypes = {
    isExternal: PropTypes.bool,
    page: PropTypes.string.isRequired,
    href: PropTypes.string,
    to: PropTypes.string,
    useNavLink: PropTypes.bool,
    hybridHref: PropTypes.string,
    children: PropTypes.any,
    styles: PropTypes.any
}

export default React.memo(HybridLink)
