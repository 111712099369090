import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Text} from '@chakra-ui/react'

const MyStokkeHeader = ({headline, subline}) => {
    return (
        <Flex flexDirection="column" paddingTop={8} paddingBottom={6} gap={2}>
            <Text as="h1" textStyle="Regular 45XLarge">
                {headline}
            </Text>
            {subline && <Text textStyle="Regular Medium">{subline}</Text>}
        </Flex>
    )
}

MyStokkeHeader.propTypes = {
    headline: PropTypes.string,
    subline: PropTypes.string
}

export default MyStokkeHeader
