import {useCallback, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {useToast} from './use-toast'
import {useIntl} from 'react-intl'
import {isServer} from '../utils/utils'

function launchIOSQuickLookAR(usdzSrc) {
    const anchor = document.createElement('a')
    anchor.setAttribute('rel', 'ar')
    anchor.appendChild(document.createElement('img'))
    anchor.setAttribute('href', usdzSrc)
    anchor.click()
}

const createARModelUrl = (ARModel, isQuickLook) => {
    const assetEndpointBaseUrl = 'https://stokparmods.blob.core.windows.net'
    const location = window.location.toString()
    const locationUrl = new URL(location)
    const usdzPath = new URL('/armods/USDZ/', assetEndpointBaseUrl)
    const glbPath = new URL('/armods/GLB/', assetEndpointBaseUrl)
    let result = null

    if (isQuickLook) {
        result = usdzPath + encodeURIComponent(ARModel.file) + '.usdz'
    } else {
        const modelUrl = new URL(ARModel.file + '.glb', glbPath)
        const params = new URLSearchParams(modelUrl.search)
        params.set('mode', 'ar_preferred')
        params.set('disable_occlusion', 'true')
        params.set('resizable', 'false')
        params.set('enable_vertical_placement', 'false')

        result =
            'intent://arvr.google.com/scene-viewer/1.0?' +
            (params.toString() + '&file=' + encodeURIComponent(modelUrl.toString())) +
            '#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=' +
            encodeURIComponent(locationUrl.toString()) +
            ';end;'
    }

    return result
}

const useAR = (ARModel) => {
    const toast = useToast()
    const [href, setHref] = useState(null)
    const {formatMessage} = useIntl()

    const isQuickLook = useMemo(() => {
        if (!isServer) {
            const tempAnchor = document.createElement('a')
            try {
                return tempAnchor?.relList?.supports?.('ar')
            } catch {
                return false
            }
        }
        return null
    }, [])

    const isSceneViewer = useMemo(() => {
        if (!isServer) {
            const isAndroid = /android/i.test(window.navigator.userAgent)
            const isFirefox = /firefox/i.test(window.navigator.userAgent)
            const isOculus = /OculusBrowser/.test(window.navigator.userAgent)
            return isAndroid && !isFirefox && !isOculus
        }
        return null
    }, [])

    useEffect(() => {
        if (ARModel?.file) {
            if (isQuickLook || isSceneViewer) {
                setHref(createARModelUrl(ARModel, isQuickLook))
            }
        }
    }, [ARModel, isQuickLook, isSceneViewer])

    const deviceNotSupported = useCallback(
        (ev) => {
            ev?.preventDefault?.()
            toast({
                title: formatMessage({
                    defaultMessage:
                        'To view the product in AR, visit this page on your device with AR functionality',
                    id: 'use_ar.device_not_supported'
                })
            })
        },
        [formatMessage, toast]
    )

    const onARLinkClick = useCallback(
        (event) => {
            if (isQuickLook) {
                event?.preventDefault()
                launchIOSQuickLookAR(href)
            } else if (!isSceneViewer) {
                deviceNotSupported(event)
            }
        },
        [deviceNotSupported, href, isQuickLook, isSceneViewer]
    )

    return {
        hasAR: !!ARModel,
        href,
        onARLinkClick
    }
}

useAR.propTypes = {
    ARModel: PropTypes.object
}

export default useAR
