import React from 'react'
import PropTypes from 'prop-types'
import {Flex} from '@chakra-ui/react'

/**
 * A visual link with a button size - introduced with my stokke designs
 */
const MyStokkeLinkContainer = ({children, ...props}) => (
    <Flex
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        maxWidth="25rem"
        height={14}
        {...props}
    >
        {children}
    </Flex>
)

MyStokkeLinkContainer.propTypes = {
    children: PropTypes.node
}

export default MyStokkeLinkContainer
