import fetch from 'cross-fetch'

class GoogleTranslateAPI {
    constructor(config = {}) {
        const {host, apiKey, apiEnabled} = config
        this.host = host
        this.apiKey = apiKey
        this.apiEnabled = apiEnabled && !!apiKey
        this.translate = this.translate.bind(this)
    }

    async translate(text, source, target, format = 'text', signal) {
        if (!this.apiEnabled) {
            return text
        }
        const headers = {
            'X-goog-api-key': this.apiKey,
            'Content-Type': 'application/json'
        }

        const response = await fetch(this.host, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                q: text,
                source,
                target,
                format
            }),
            signal
        })

        // Handle the response as needed
        const result = await response.json()
        return result
    }
}

export default GoogleTranslateAPI
