import React, {useMemo, useCallback} from 'react'
import PropTypes from 'prop-types'
import {Button, Flex, HStack, Image, Text, useBreakpointValue} from '@chakra-ui/react'
import {ArrowRightIcon, NavigationRightIcon} from '../icons'
import useHybridURL from '../../hooks/use-hybrid-url'
import DrawerMenuError from '../drawer-menu/drawer-menu-error'
import {trackNavigationClick} from '../../tracking/analytics'

/**
 *  Navigation Item
 * @param image - url of image to display
 * @param label - Label of navigation item
 * @param badge - text to display in badge, if none is provided, no badge is displayed
 * @param href - url to navigate to when clicked, if none is provided, onClick is called
 * @param onClick - function to call when clicked, only called if href is not provided
 * @param selected - determines if the item is selected, if true, the arrow is displayed
 * @param trackingIndex - index to use for tracking
 * @returns {JSX.Element} - Navigation Item
 * @constructor - Navigation Item
 */
const NavigationItem = ({levelIdx, selected, onClick, ...props}) => {
    const {image, label, badge, special, link, type, trackingIndex} = props
    const navigateHybrid = useHybridURL()

    const handleClick = useCallback(
        (event) => {
            trackNavigationClick(trackingIndex, label)
            if (link) {
                navigateHybrid(link)
                event.preventDefault()
            } else {
                onClick?.()
            }
        },
        [link, navigateHybrid, onClick, label, trackingIndex]
    )

    const containerProps = useMemo(
        () =>
            link
                ? {
                      as: 'a',
                      href: link,
                      onClick: handleClick
                  }
                : {onClick: handleClick},
        [link, handleClick]
    )
    const arrowVisibility = selected ? 'visible' : 'hidden'
    // if we have neither a valid link nor onClick, we disable the button
    const disabled = !link && !onClick
    const isMobile = useBreakpointValue({base: true, lg: false})
    const color = special ? 'stokkeCore.orange' : 'stokkeCore.black'

    switch (type) {
        case 'NavigationLink':
            if (!link) {
                return (
                    <DrawerMenuError
                        error="Navigation link without link property"
                        details={props}
                    />
                )
            }
            break
        case 'NavigationItem':
            if (!props.items?.length) {
                return (
                    <DrawerMenuError
                        error="Navigation item without items property"
                        details={props}
                    />
                )
            }
            break
        case 'HeaderNavigationElement':
            if (!link && !props.items?.length) {
                return (
                    <DrawerMenuError
                        error="Header navigation element without link or items property"
                        details={props}
                    />
                )
            }
            break
        default:
            return <DrawerMenuError error="Unknown type" details={props} />
    }

    return (
        <Button
            {...containerProps}
            variant="navigationLink"
            paddingInlineStart={levelIdx === 3 ? 1 : null}
            size="min"
            minHeight="3.24rem"
            width="full"
            className="navigation-item-container"
            justifyContent="space-between"
            alignItems="center"
            disabled={disabled}
            color={color}
            paddingY={1}
            marginBottom={1.5}
            sx={
                !selected && {
                    '@media(pointer: fine)': {
                        '.navigation-container:hover &:not(:hover)': special
                            ? {
                                  opacity: 0.5
                              }
                            : {
                                  color: 'stokkeGray.accessibleText'
                              }
                    }
                }
            }
        >
            <Flex gap={2.5} alignItems="center">
                {image && <Image w="auto" h="2.8125rem" src={image} />}
                <Flex gap="0.5" flexDirection="column">
                    {!!badge && (
                        <Text
                            textStyle="Descriptive Tags"
                            color="stokkeCore.orange"
                            align="left"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            maxHeight="1.15em"
                            textTransform="uppercase"
                        >
                            {badge}
                        </Text>
                    )}
                    <Text
                        color="inherit"
                        textStyle="Regular Medium"
                        whiteSpace="normal"
                        align="left"
                        wordBreak="break-word"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxHeight={badge ? '1.3em' : '2.6em'}
                        sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: badge ? 1 : 2,
                            WebkitBoxOrient: 'vertical'
                        }}
                        title={label}
                    >
                        {label}
                    </Text>
                </Flex>
            </Flex>
            <HStack>
                {!link && levelIdx < 2 && (
                    <NavigationRightIcon
                        variant="navigation"
                        sx={{
                            visibility: arrowVisibility,
                            '.navigation-item-container:hover &': {
                                visibility: 'visible'
                            }
                        }}
                    />
                )}
            </HStack>
            {isMobile && levelIdx < 2 && (
                <HStack>
                    <ArrowRightIcon boxSize={4} />
                </HStack>
            )}
        </Button>
    )
}

NavigationItem.propTypes = {
    badge: PropTypes.string,
    image: PropTypes.string,
    items: PropTypes.array,
    link: PropTypes.string,
    label: PropTypes.string,
    selected: PropTypes.bool,
    special: PropTypes.bool,
    type: PropTypes.string,
    onClick: PropTypes.func,
    levelIdx: PropTypes.number,
    trackingIndex: PropTypes.number
}

export default NavigationItem
