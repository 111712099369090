import {Text} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedNumber} from 'react-intl'
import {useOrderSummary} from './order-summary-provider'

const OrderSummaryPriceAdjustmentItem = ({adjustment}) => {
    const basket = useOrderSummary()
    return (
        <>
            <Text>{adjustment.itemText}</Text>
            <Text whiteSpace="nowrap">
                <FormattedNumber
                    style="currency"
                    currency={basket?.currency}
                    value={adjustment.price}
                />
            </Text>
        </>
    )
}

OrderSummaryPriceAdjustmentItem.propTypes = {
    adjustment: PropTypes.object.isRequired
}

export default OrderSummaryPriceAdjustmentItem
