import {
    Box,
    Flex,
    omitThemingProps,
    SkeletonText,
    Text,
    useMultiStyleConfig
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, {Fragment} from 'react'
import {FormattedMessage, FormattedNumber} from 'react-intl'
import {useCurrency} from '../../hooks'
import useProductPricing from '../../hooks/use-product-pricing'
import useMultiSite from '../../hooks/use-multi-site'

const DisplayPrice = (props) => {
    const {site} = useMultiSite()
    const {currency: activeCurrency} = useCurrency()

    const {currency, product, isBonusProduct, loading, ...ownProps} = omitThemingProps(props)

    const {price, displayPrice, hasDiscount, isFree} =
        useProductPricing(product, isBonusProduct) || {}

    const styles = useMultiStyleConfig('ItemVariantPrice', {
        variant: 'pdp',
        ...props,
        isFree,
        hasDiscount
    })

    // content only sites do not render prices
    if (site?.isContentOnlySite) {
        return null
    }

    const SkeletonComponent = loading ? SkeletonText : Fragment
    const skeletonProps = loading ? {noOfLines: 1, isLoaded: !loading, skeletonHeight: null} : {}

    return (
        <Flex {...styles.container} {...ownProps}>
            {hasDiscount && (
                <SkeletonComponent {...skeletonProps}>
                    <Text {...styles.discount}>
                        <FormattedNumber
                            style="currency"
                            currency={currency || product?.currency || activeCurrency}
                            value={price}
                        />
                    </Text>
                </SkeletonComponent>
            )}
            <SkeletonComponent {...skeletonProps}>
                <Box {...styles.priceContainer}>
                    <Text {...styles.price} {...(isFree && styles.priceFreeNumber)}>
                        <FormattedNumber
                            style="currency"
                            currency={currency || product?.currency || activeCurrency}
                            value={displayPrice}
                        />
                    </Text>
                    {isFree && (
                        <Text {...styles.price} {...styles.priceFreeLabel}>
                            <FormattedMessage defaultMessage="Free" id="global.free" />
                        </Text>
                    )}
                </Box>
            </SkeletonComponent>
        </Flex>
    )
}

DisplayPrice.propTypes = {
    product: PropTypes.object,
    isBonusProduct: PropTypes.bool,
    loading: PropTypes.bool
}

export default DisplayPrice
