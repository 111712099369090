/**
 * The configuration details for the connecting to the API.
 * @typedef {Object & import('@googlemaps/js-api-loader').LoaderOptions} ClientConfig
 * @property {string} [locale] - Locale to fill language and region parameters
 */

/**
 * A wrapper class that loads googlemaps apis
 */
class GoogleMapsAPI {
    /**
     * Create an instance of the API with the given config.
     * @param {ClientConfig} config - The config used to instantiate SDK apis.
     */
    constructor(config = {}) {
        const {locale = {id: ''}, ...loaderConfig} = config

        const [language, region] = locale.id.split('-')

        this._config = {language, region, ...loaderConfig}

        // Bind the loadApi method to the current instance
        this.loadApi = this.loadApi.bind(this)
    }

    /**
     * @returns {Promise<typeof google>}
     * @private
     */
    async _loadApi() {
        try {
            const {Loader} = await import('@googlemaps/js-api-loader')
            const api = await new Loader(this._config).load()
            return api.maps
        } catch (error) {
            throw new Error('Error loading Google Maps API: ' + error.message)
        }
    }

    /**
     * @returns {Promise<typeof google>}
     */
    loadApi() {
        if (!this.loaderPromise) {
            this.loaderPromise = this._loadApi()
        }

        return this.loaderPromise
    }
}

export default GoogleMapsAPI
