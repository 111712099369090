import {
    Box,
    Button,
    HStack,
    Text,
    Flex,
    Tooltip,
    Image,
    useTheme,
    useDisclosure
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, {useState, useEffect, useMemo} from 'react'
import {capitalize} from '../../utils/utils'
import {FormattedMessage, useIntl} from 'react-intl'
import {TooltipIcon} from '../icons'
import BonusProductsModal from '../bonus-products-modal'
import {PROMOTION_BADGE_MESSAGES} from '../../constants'
import StyledContent from '../styled-content'
import {useProducts} from '@salesforce/commerce-sdk-react'

export const useSpecialOffer = (promotion, isEnabled = true) => {
    // precompute ids in stringified form to trigger loading only when it changes
    const ids = useMemo(
        () => promotion?.c_discountProducts?.join(','),
        [promotion?.c_discountProducts]
    )

    const {data: products, isInitialLoading: loading} = useProducts(
        {
            parameters: {ids, allImages: true}
        },
        {enabled: !!ids && isEnabled}
    )
    const theme = useTheme()

    const hasSelectedBonusProduct = promotion?.productItem?.[0]?.itemId

    const colorWorld = `stokke${capitalize(promotion?.c_colorWorld || 'gray')}`
    const colorScheme = theme.colors[colorWorld]?.mid ? colorWorld : 'stokkeGray'

    const fontColorException = ['stokkeGray', 'stokkeBeige'].includes(colorWorld)
    const fontColor = fontColorException ? `${colorScheme}.accessibleText` : `${colorScheme}.dark`

    return {
        products,
        colorScheme,
        fontColor,
        hasSelectedBonusProduct,
        hasBonusProducts: Boolean(ids),
        loading
    }
}

export const SpecialOffer = ({
    product,
    promotion,
    variant = 'default',
    setSelectedItem,
    notifyMe,
    ...props
}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [loadMore, setLoadMore] = useState(false)
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const [isIconURLValid, setIsIconURLValid] = useState(true)
    const {formatMessage} = useIntl()
    const {
        products: bonusProducts,
        colorScheme,
        fontColor,
        hasSelectedBonusProduct,
        hasBonusProducts,
        loading: isBonusProductsLoading
    } = useSpecialOffer(promotion, isOpen)

    // reset promotion icon validity on change
    useEffect(() => setIsIconURLValid(true), [promotion?.c_promotionIcon])

    const IconContainer = ({children}) => {
        return (
            <Flex
                bg={`${colorScheme}.bright`}
                minWidth={12}
                height={12}
                justify="center"
                align="center"
                rounded="24"
                padding={3}
            >
                {children}
            </Flex>
        )
    }

    IconContainer.propTypes = {
        children: PropTypes.node
    }

    return !promotion.calloutMsg && !promotion.c_badge ? null : (
        <HStack bg={`${colorScheme}.mid`} color={fontColor} padding={4} spacing={4} w="100%">
            {promotion?.c_promotionIcon && isIconURLValid && (
                <IconContainer>
                    <Image
                        alt=""
                        src={promotion.c_promotionIcon}
                        width={6}
                        height={6}
                        onError={() => {
                            setIsIconURLValid(false)
                        }}
                    />
                </IconContainer>
            )}

            <Flex direction="column" gap={variant === 'bonus' ? 6 : 2}>
                <Flex direction="column" gap={variant === 'bonus' ? 2 : 1}>
                    {PROMOTION_BADGE_MESSAGES[promotion?.c_badge] && (
                        <Text textStyle="Semi Bold Medium">
                            {formatMessage(PROMOTION_BADGE_MESSAGES[promotion.c_badge])}
                        </Text>
                    )}

                    <Flex gap={1}>
                        <StyledContent
                            colorScheme={colorScheme}
                            content={promotion?.calloutMsg}
                            textStyle={variant === 'bonus' ? 'Regular' : 'Regular Small'}
                        />

                        {promotion?.details && variant === 'bonus' && (
                            <Tooltip hasArrow label={promotion.details} isOpen={isTooltipOpen}>
                                <TooltipIcon
                                    boxSize={3}
                                    onMouseEnter={() => setIsTooltipOpen(true)}
                                    onMouseLeave={() => setIsTooltipOpen(false)}
                                    onClick={() => setIsTooltipOpen(true)}
                                />
                            </Tooltip>
                        )}
                    </Flex>
                </Flex>

                {variant === 'default' ? (
                    promotion?.details && (
                        <Box>
                            {loadMore && (
                                <StyledContent
                                    content={promotion?.details}
                                    textStyle="Regular Extra Small"
                                    mt={1}
                                />
                            )}
                            <Button
                                variant="link"
                                colorScheme={colorScheme}
                                size="sm"
                                onClick={() => setLoadMore(!loadMore)}
                                mt={2}
                            >
                                {loadMore ? (
                                    <FormattedMessage
                                        defaultMessage="Load less"
                                        id="global.loadless"
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Load more"
                                        id="global.loadmore"
                                    />
                                )}
                            </Button>
                        </Box>
                    )
                ) : (
                    <Box>
                        <Button
                            variant="link"
                            colorScheme={colorScheme}
                            onClick={() => {
                                setSelectedItem(product)
                                onOpen()
                            }}
                        >
                            {hasSelectedBonusProduct ? (
                                <FormattedMessage
                                    defaultMessage="Edit your bonus product"
                                    id="product_detail.special_offer.edit_your_bonus_product"
                                />
                            ) : (
                                <FormattedMessage
                                    defaultMessage="Choose your product"
                                    id="product_detail.special_offer.choose_your_product"
                                />
                            )}
                        </Button>
                        {isOpen && hasBonusProducts && (
                            <BonusProductsModal
                                headerIcon={
                                    promotion?.c_promotionIcon && (
                                        <Image
                                            src={promotion.c_promotionIcon}
                                            width={6}
                                            height={6}
                                        />
                                    )
                                }
                                isOpen={isOpen}
                                onOpen={onOpen}
                                onClose={onClose}
                                products={bonusProducts?.data}
                                isLoading={isBonusProductsLoading}
                                bonusProductVariationsMap={promotion?.c_discountVariantProducts}
                                colorScheme={colorScheme}
                                fontColor={fontColor}
                                setSelectedItem={setSelectedItem}
                                {...props}
                                updateCart={hasSelectedBonusProduct ? props.updateCart : undefined}
                                addToCart={!hasSelectedBonusProduct ? props.addToCart : undefined}
                                promotion={promotion}
                                notifyMe={(product) => {
                                    onClose()
                                    notifyMe(product)
                                }}
                            />
                        )}
                    </Box>
                )}
            </Flex>
        </HStack>
    )
}

SpecialOffer.propTypes = {
    product: PropTypes.object,
    promotion: PropTypes.object,
    variant: PropTypes.oneOf(['default', 'bonus']),
    addToCart: PropTypes.func,
    updateCart: PropTypes.func,
    setSelectedItem: PropTypes.func,
    notifyMe: PropTypes.func
}
