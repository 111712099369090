import {useCallback, useEffect, useRef, useState} from 'react'
import {useBazaarvoiceAPI} from '../context'
import useOcapiSite from '../../hooks/useOcapiSite'
import {useToast} from '../../hooks/use-toast'
import {useIntl} from 'react-intl'

const useWriteProductReviewModal = () => {
    const {formatMessage} = useIntl()
    const {bvEnabled} = useOcapiSite()
    const toast = useToast()
    const [loading, setLoading] = useState(false)
    const {showReviewSubmissionForm} = useBazaarvoiceAPI()
    const abortControllerRef = useRef(null)

    const onOpen = useCallback(
        async (productId) => {
            // If there's already a request to show the review submission form for a different product, cancel it
            abortControllerRef.current?.abort()

            if (!bvEnabled || !productId) {
                return
            }

            const currentAbortController = (abortControllerRef.current = new AbortController())
            setLoading(true)
            try {
                await showReviewSubmissionForm(productId, {
                    signal: abortControllerRef.current.signal
                })
            } catch (err) {
                if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
                    console.warn('Failure to show review submission form', err)
                }
                toast({
                    title: formatMessage({
                        id: 'global.error.something_went_wrong',
                        defaultMessage: 'Hmmm! Something went wrong. Please try again.'
                    }),
                    status: 'error'
                })
            } finally {
                if (currentAbortController === abortControllerRef.current) {
                    setLoading(false)
                    abortControllerRef.current = null
                }
            }
        },
        [bvEnabled, formatMessage, showReviewSubmissionForm, toast]
    )

    useEffect(
        () => () => {
            // cancel any pending requests when the component unmounts
            abortControllerRef.current?.abort()
            abortControllerRef.current = null
        },
        []
    )

    return {
        loading,
        onOpen
    }
}

export default useWriteProductReviewModal
