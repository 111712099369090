import {useEffect, useState} from 'react'
import useGeocoder from '../../googlemaps-api/hooks/useGeocoder'
import useGeolocation from './useGeolocation'
import useEffectEvent from '../../hooks/use-effect-event'

const useGeolocationFindStoreFields = ({form, onGpsLocation, onError}) => {
    const {
        supported,
        loading: geolocationLoading,
        error: geolocationError,
        position: geolocationPosition,
        getCurrentPosition
    } = useGeolocation()

    const [geocoderRequest, setGeocoderRequest] = useState(null)

    // handle new geolocation position
    const onGeolocationPositionChange = useEffectEvent((geolocationPosition) => {
        if (geolocationPosition?.coords) {
            form.setValue('latitude', geolocationPosition.coords.latitude, {shouldValidate: true})
            form.setValue('longitude', geolocationPosition.coords.longitude, {shouldValidate: true})
            setGeocoderRequest({position: geolocationPosition})
        } else {
            setGeocoderRequest(null)
        }
    })
    useEffect(() => {
        onGeolocationPositionChange(geolocationPosition)
    }, [geolocationPosition, onGeolocationPositionChange])

    const {
        loading: geocoderLoading,
        error: geocoderError,
        results: geocoderResults
    } = useGeocoder(geocoderRequest)

    const loading = geolocationLoading || geocoderLoading
    const error = geolocationError || geocoderError

    // notify the fields were filled through geolocation
    const onGeocoderResultsChange = useEffectEvent((geocoderResults) => {
        if (geocoderResults?.[0]?.formatted_address) {
            form.setValue('address', geocoderResults[0].formatted_address, {shouldValidate: true})
            if (onGpsLocation) {
                onGpsLocation()
            }
        }
    })
    useEffect(() => {
        onGeocoderResultsChange(geocoderResults)
    }, [geocoderResults, onGeocoderResultsChange])

    // show errors from geolocation or geocoder
    const onErrorChange = useEffectEvent((error) => {
        if (!error) return
        console.log('error', error)
        if (onError) {
            onError(error.message || error)
        }
    })
    useEffect(() => {
        onErrorChange(error)
    }, [error, onErrorChange])

    return {
        supported,
        loading,
        getCurrentPosition
    }
}

export default useGeolocationFindStoreFields
