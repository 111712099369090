import PropTypes from 'prop-types'
import React from 'react'
import Fanreel from '../../components/fanreel'
import {capitalize} from '../../utils/utils'
import {useProduct} from '@salesforce/commerce-sdk-react'

/**
 * Display Fanreel Component
 *
 * @param {string} pid - productId
 * @param {string} category - category
 * @param {string} headline - Headlinetext
 * @param {string} color - color of Headline
 * @param {string} dataSourceId - dataSourceId
 * @param {string} headlineAlignment - left or center alignment of Headline
 *
 * @example
 *   <pwa-widget-productinfos pid="1001" headline="Tripp Trapp® Chair in social" color="Pink" dataSourceId="yPTzASKbwXstIPxt" headlineAlignment="left">
 *     $include(...)$
 *   </pwa-widget-productinfos>
 */
const WidgetFanreel = ({pid, category, headline, color, dataSourceId, headlineAlignment}) => {
    const {data: product} = useProduct(
        {
            parameters: {
                id: pid,
                allImages: true
            }
        },
        {enabled: !!pid}
    )

    if (!pid && !category) {
        console.error(`No product ID or category provided`)
        return null
    }

    return (
        <Fanreel
            product={product}
            category={category}
            headline={headline}
            dataSourceId={dataSourceId}
            variant="widget"
            headlineHtmlProps={{
                textAlign: headlineAlignment || 'left',
                color: 'stokke' + capitalize(color) + '.dark'
            }}
        />
    )
}

WidgetFanreel.propTypes = {
    pid: PropTypes.string,
    category: PropTypes.string,
    headline: PropTypes.string,
    color: PropTypes.string,
    dataSourceId: PropTypes.string,
    headlineAlignment: PropTypes.string
}

export default WidgetFanreel
