import {RECOMMENDED_ACCESSORIES_TYPE, RECOMMENDED_SPARE_PARTS_TYPE} from '../constants'
import {useCallback, useMemo} from 'react'
import useCommerceApi from '@salesforce/commerce-sdk-react/hooks/useCommerceApi'
import useAuthContext from '@salesforce/commerce-sdk-react/hooks/useAuthContext'

/**
 * Helper to prepare parameters for recommendations that are based on recommendation type
 * @param {Array} products - list of products from which to extract recommendations
 * @param {number} type - recommendation type
 * @return {string[]|null} - list of recommended item ids or null if no recommendations found
 */
const prepareRecommendationsByType = (products, type) => {
    return products
        ?.find((product) =>
            product?.recommendations?.some((r) => r?.recommendationType?.value === type)
        )
        ?.recommendations.filter((r) => r?.recommendationType?.value === type)
        .map((r) => r?.recommendedItemId)
}

export class CustomRecommendations {
    constructor(commerceApi) {
        this.api = commerceApi
    }

    /**
     * Prepare arguments to fetch accessories recommendations
     * @param {Array} products
     * @return {string[]|null}
     */
    prepareAccessories(products) {
        return prepareRecommendationsByType(products, RECOMMENDED_ACCESSORIES_TYPE)
    }

    /**
     * Fetch accessories recommendations based on the prepared arguments from #prepareAccessories
     * @param {string[]|null} recs
     * @return {Promise<{recs: {id: string}[]}|null>}
     */
    async getAccessories(recs) {
        if (!recs) return null
        return {
            recs: recs.map((rec) => ({id: rec}))
        }
    }

    /**
     * Prepare arguments to fetch spare parts recommendations
     * @param {Array} products
     * @return {string[]|null}
     */
    prepareSpareParts(products) {
        return prepareRecommendationsByType(products, RECOMMENDED_SPARE_PARTS_TYPE)
    }

    /**
     * Fetch spare parts recommendations based on the prepared arguments from #prepareSpareParts
     * @param {string[]|null} recs
     * @return {Promise<{recs: {id: string}[]}|null>}
     */
    async getSpareParts(recs) {
        if (!recs) return null
        return {
            recs: recs.map((rec) => ({id: rec}))
        }
    }

    /**
     * Prepare arguments to fetch product sets recommendations
     * @param {{productSetId}[]|null} items
     * @return {string|null}
     */
    prepareProductSets(items) {
        return items?.find((item) => item?.productSetId)?.productSetId
    }

    /**
     * Fetch product sets recommendations based on the prepared arguments from #prepareProductSets
     * @param {string|null} productSetId
     * @param {object} headers
     * @return {Promise<{recs: {id: string}[]}|null>}
     */
    async getProductSets(productSetId, headers) {
        if (!productSetId) return null

        /* Fetch the set products from dedicated product set id */
        const {setProducts} = await this.api.shopperProducts.getProduct({
            parameters: {id: productSetId, select: '(setProducts.(id))'},
            headers
        })

        return {recs: setProducts}
    }
}

const useCustomRecommendations = () => {
    const auth = useAuthContext()
    const commerceApi = useCommerceApi()

    const customRecommendations = useMemo(
        () => new CustomRecommendations(commerceApi),
        [commerceApi]
    )

    const getAuthorizationHeader = useCallback(
        async () => ({
            Authorization: `Bearer ${await auth.ready().then(({access_token}) => access_token)}`
        }),
        [auth]
    )

    return useMemo(
        () => ({
            prepareAccessories: (...args) => customRecommendations.prepareAccessories(...args),
            getAccessories: (...args) => customRecommendations.getAccessories(...args),
            prepareSpareParts: (...args) => customRecommendations.prepareSpareParts(...args),
            getSpareParts: (...args) => customRecommendations.getSpareParts(...args),
            prepareProductSets: (...args) => customRecommendations.prepareProductSets(...args),
            getProductSets: async (productSetId) =>
                customRecommendations.getProductSets(productSetId, await getAuthorizationHeader())
        }),
        [customRecommendations, getAuthorizationHeader]
    )
}

export default useCustomRecommendations
