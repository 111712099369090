import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        container: {
            flexDirection: 'column',
            ...textStyles['Regular Medium'],
            gap: 8
        },
        textContainer: {
            flexDirection: 'column',
            gap: 6,
            alignItems: 'start'
        },
        reviewText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box'
        },
        buyerInfoContainer: {
            flexDirection: 'column',
            gap: 4
        },
        rating: {
            variant: 'review'
        },
        feedbackContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 4
        },
        feedbackHelpfulnessContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 2
        },
        feedbackHelpfulnessText: {
            textStyle: 'Regular Small'
        },
        feedbackHelpfulnessButtonsContainer: {
            alignItems: 'flex-start'
        },
        feedbackInappropriateContainer: {
            alignItems: 'center',
            gap: 2
        },
        feedbackInappropriateText: {
            textStyle: 'Regular Medium'
        }
    }
}
