import React from 'react'
import PropTypes from 'prop-types'
import {HStack, Skeleton, Text, Button, Spinner} from '@chakra-ui/react'
import {useAddGiftToMyRegistry} from '../../myregistry-api'
import {HeartIcon} from '../icons'
import {FormattedMessage} from 'react-intl'
import {trackAddToWishlist} from '../../tracking/analytics'
import {useCurrentBasket} from '../../hooks/use-current-basket'
import useProductItemsDetail from '../../hooks/useProductItemsDetail'

const AddToRegistryCTA = ({product, loading = false}) => {
    const {data: basket} = useCurrentBasket()
    const {data: productItemsDetail} = useProductItemsDetail(basket)
    const {
        enabled,
        execute: addGiftToMrRegistry,
        loading: apiLoading
    } = useAddGiftToMyRegistry(product)

    if (!enabled) {
        return null
    }
    if (loading) {
        return <Skeleton data-testid="add-to-registry-skeleton" width={32} height={6} />
    }
    const handleClick = async (event) => {
        event.preventDefault()
        try {
            await addGiftToMrRegistry(product)
            trackAddToWishlist(product, basket, productItemsDetail)
        } catch (error) {
            console.error('Error adding gift to registry', error)
        }
    }

    return (
        <Button variant="link" onClick={handleClick}>
            <HStack spacing={2} align="center">
                {apiLoading ? <Spinner size="sm" /> : <HeartIcon boxSize={4} />}
                <Text textStyle="Regular Small">
                    <FormattedMessage
                        id="product_detail.link.add_to_registry"
                        defaultMessage="Add to registry"
                    />
                </Text>
            </HStack>
        </Button>
    )
}

AddToRegistryCTA.propTypes = {
    product: PropTypes.object,
    loading: PropTypes.bool
}

export default AddToRegistryCTA
