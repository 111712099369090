import {createOcapiFetch} from './utils'

class OcapiShopperOrder {
    constructor(config) {
        this.fetch = createOcapiFetch(config)
    }

    /**
     * Calls the built-in api for the host-name and then the ocapi call for site, fetching
     * available payment- and shipping methods not included in the first call
     */
    async getOrderNumber(...args) {
        var orderResult = await this.fetch(
            `custom_objects/ocapi/order?token=${args[0].orderToken}`,
            'GET',
            args,
            'getOrderNumber'
        )

        return {
            orderNumber: orderResult.c_result.orderNo
        }
    }
}

export default OcapiShopperOrder
