const checkout = {
    container: {
        backgroundColor: 'stokkePink.bright',
        layerStyle: 'checkoutCard'
    },
    content: {
        pt: 10,
        pb: 8,
        _editing: {
            pt: {base: 10, lg: 16},
            pb: {base: 12, md: 16, xl: 32, '2xl': 32},
            _cro001: {
                pb: {base: 12, md: 16, xl: 20}
            }
        }
    },
    divider: {
        colorScheme: 'stokkePink',
        d: null,
        _editing: {
            d: 'none',
            _cro001: {
                d: null
            }
        }
    }
}

const checkoutContact = {
    ...checkout,
    content: {
        ...checkout.content,
        pt: 4.5,
        pb: 4.5
    }
}

const checkoutPayment = {
    ...checkout,
    content: {
        ...checkout.content,
        _editing: {
            ...checkout.content._editing,
            pb: {base: 12, lg: 16, xl: 12},
            _cro001: {
                pb: {
                    base: 0,
                    lg: 16,
                    xl: 12
                }
            }
        }
    }
}

export default {
    baseStyle: {
        container: {
            px: {base: 4, lg: 6, xl: 8}
        },
        content: {
            // {editing || (!editing && !disabled) ? 4 : 0}
            spacing: 4,
            _editing: {
                spacing: 4,
                _disabled: {
                    spacing: 4
                }
            },
            _disabled: {
                spacing: 0
            }
        },
        divider: {
            d: 'none'
        }
    },
    variants: {
        card: {
            container: {
                layerStyle: 'card'
            }
        },
        checkout,
        checkoutContact,
        checkoutPayment,
        cro001Checkout: {
            ...checkout,
            content: {
                paddingY: {base: 7, xl: 10},
                spacing: 0
            }
        }
    },
    defaultProps: {},
    parts: ['container', 'content', 'divider']
}
