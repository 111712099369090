import React from 'react'
import PropTypes from 'prop-types'
import {Box, Breadcrumb as ChakraBreadcrumb, useMultiStyleConfig} from '@chakra-ui/react'
import {BreadcrumbSeparatorIcon} from '../icons'

const Breadcrumb = ({children, ...props}) => {
    const styles = useMultiStyleConfig('Breadcrumb', props)

    return (
        <Box {...styles.wrapper}>
            <ChakraBreadcrumb separator={<BreadcrumbSeparatorIcon {...styles.separatorIcon} />}>
                {children}
            </ChakraBreadcrumb>
        </Box>
    )
}

Breadcrumb.propTypes = {
    children: PropTypes.node
}

export default Breadcrumb
