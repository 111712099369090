import React from 'react'
import {FormattedMessage} from 'react-intl'
import {COUNTRY_MESSAGES, HYBRID_COUNTRY_SELECTOR} from '../../constants'
import {useCountry} from '../../hooks'
import HybridLink from '../hybrid-link'
import {FlagIcon} from '../icons'
import {useFooterStyles} from './index'

const LanguageLink = ({...props}) => {
    const country = useCountry()
    const styles = useFooterStyles()

    return (
        <HybridLink
            page={HYBRID_COUNTRY_SELECTOR}
            to="/country-selector"
            {...styles.languageLink}
            {...props}
        >
            <FlagIcon {...styles.languageLinkIcon} countryCode={country} />
            <FormattedMessage {...COUNTRY_MESSAGES[country]} />
        </HybridLink>
    )
}

export default LanguageLink
