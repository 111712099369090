import React, {useEffect, useState} from 'react'
import {Box, Button, Flex, SimpleGrid, Stack, Text, useDisclosure} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import {defineMessage, FormattedMessage, useIntl} from 'react-intl'
import {EditIcon, DeleteIcon} from '../../../components/icons'
import ConfirmationModal from '../../../components/confirmation-modal'
import {useCurrentCustomer} from '../../../hooks/use-current-customer'
import useAccountChildrenFields from '../../../components/forms/useAccountChildrenFields'
import {API_ERROR_MESSAGE} from '../../../constants'
import {useToast} from '../../../hooks/use-toast'
import useEffectEvent from '../../../hooks/use-effect-event'
import MyStokkeChildForm from './child-form'
import {
    ShopperCustomersMutations,
    useShopperCustomersMutation
} from '@salesforce/commerce-sdk-react'

export const REMOVE_CHILD_CONFIRMATION_DIALOG_CONFIG = {
    dialogTitle: defineMessage({
        defaultMessage: 'Remove data',
        id: 'mystokke.personal-information.child-remove'
    }),
    primaryActionLabel: defineMessage({
        defaultMessage: 'Yes, remove data',
        id: 'mystokke.personal-information.child-remove-confirm'
    }),
    alternateActionLabel: defineMessage({
        defaultMessage: 'Cancel',
        id: 'form_action_buttons.button.cancel'
    })
}
const ChildArrowIcon = (props) => (
    <Box
        width={2}
        height={2}
        borderLeft="2px"
        borderTop="2px"
        borderColor="stokkeCore.black"
        position="absolute"
        left="50%"
        backgroundColor="stokkeGray.light"
        transform="rotate(45deg)"
        zIndex={1}
        {...props}
    />
)

const MyStokkePersonalDataChildren = ({
    form,
    submitForm,
    data,
    isEditing,
    onEdit,
    onCancel,
    isLoading
}) => {
    const showToast = useToast()
    const {formatMessage} = useIntl()
    const {data: customer} = useCurrentCustomer()
    const [removeChild, setRemoveChild] = useState(null)
    const modalProps = useDisclosure()
    const [editChildData, setEditChildData] = useState(null)
    const fields = useAccountChildrenFields({form})
    const {
        mutateAsync: updateCustomer,
        isLoading: isLoadingRemoveChild,
        error
    } = useShopperCustomersMutation(ShopperCustomersMutations.UpdateCustomer)

    const handleRemoveChild = (ID) => {
        const updatedChildren = data.filter((child) => child.ID !== ID)
        return updateCustomer({
            body: {c_children: JSON.stringify(updatedChildren)},
            parameters: {customerId: customer.customerId}
        })
    }

    const onError = useEffectEvent(() => {
        showToast({
            title: formatMessage(API_ERROR_MESSAGE),
            status: 'error'
        })
    })

    useEffect(() => {
        if (error) {
            console.error('something went wrong when deleting children', error)
            onError()
        }
    }, [error, onError])

    return (
        <>
            <Text as="h2" textStyle={{base: 'Regular 2XLarge'}} paddingTop={6} paddingBottom={6}>
                <FormattedMessage
                    defaultMessage="Children"
                    id="mystokke.personal-information.children"
                />
            </Text>
            <form
                onSubmit={form.handleSubmit(submitForm)}
                noValidate
                data-testid="mystokke-children-form"
            >
                <ConfirmationModal
                    {...REMOVE_CHILD_CONFIRMATION_DIALOG_CONFIG}
                    body={
                        <Stack spacing={4}>
                            <Text textStyle="Regular Small">
                                <FormattedMessage
                                    defaultMessage="Are you sure you want to remove this data from your account?"
                                    id="mystokke.personal-information.child-remove-childname"
                                />
                            </Text>
                        </Stack>
                    }
                    onPrimaryAction={() => {
                        handleRemoveChild(removeChild.ID)
                    }}
                    {...modalProps}
                />
                <SimpleGrid
                    columns={{base: 1, md: 2}}
                    spacing={4}
                    gridAutoFlow="row dense"
                    role="list"
                    aria-label={formatMessage({
                        defaultMessage: 'Address List',
                        id: 'mystokke.addresses.list.label'
                    })}
                >
                    {data.map((child) => {
                        return (
                            <React.Fragment key={child.ID}>
                                <Flex
                                    height="9.75rem"
                                    bgColor="stokkeCore.white"
                                    paddingTop={4}
                                    paddingRight={4}
                                    paddingBottom={5}
                                    paddingLeft={4}
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    boxSizing="border-box"
                                    position="relative"
                                >
                                    <Box>
                                        <Text textStyle="Regular Medium">{child.childName}</Text>
                                        <Text textStyle="Regular Medium">
                                            {child.childBirthdateFormated}
                                        </Text>
                                    </Box>
                                    {isLoadingRemoveChild && removeChild?.ID === child.ID && (
                                        <Flex
                                            justifyContent="center"
                                            data-testid="child-loading-spinner"
                                        >
                                            <Button isLoading={true} variant="link"></Button>
                                        </Flex>
                                    )}
                                    <Flex justifyContent="space-between" gap={2}>
                                        <Button
                                            variant="link"
                                            width="fit-content"
                                            maxWidth="50%"
                                            onClick={() => {
                                                setEditChildData(child)
                                                form.reset({...child})
                                                onEdit()
                                            }}
                                        >
                                            <Flex gap={2} alignItems="center">
                                                <EditIcon width={4} height={4} />
                                                <Text whiteSpace="normal" wordBreak="normal">
                                                    <FormattedMessage
                                                        defaultMessage="Edit"
                                                        id="global.edit"
                                                    />
                                                </Text>
                                            </Flex>
                                        </Button>
                                        <Button
                                            variant="link"
                                            width="fit-content"
                                            maxWidth="50%"
                                            onClick={() => {
                                                modalProps.onOpen()
                                                setRemoveChild(child)
                                            }}
                                        >
                                            <Flex gap={2} alignItems="center">
                                                <DeleteIcon width={4} height={4} />
                                                <Text whiteSpace="normal" wordBreak="normal">
                                                    <FormattedMessage
                                                        defaultMessage="Remove Data"
                                                        id="mystokke.personal-information.child-remove-from-overview"
                                                    />
                                                </Text>
                                            </Flex>
                                        </Button>
                                    </Flex>
                                    {/*update child*/}
                                    {/*Arrow up icon pointing to the address that is being edited*/}
                                    {!!isEditing && editChildData?.ID === child.ID && (
                                        <ChildArrowIcon bottom="-20px" />
                                    )}
                                </Flex>
                                {!!isEditing && editChildData?.ID === child.ID && (
                                    <MyStokkeChildForm
                                        fields={fields}
                                        setEditChildData={setEditChildData}
                                        isLoading={isLoading}
                                        onCancel={onCancel}
                                        showBorder={true}
                                    />
                                )}
                            </React.Fragment>
                        )
                    })}
                    {/*add Child*/}
                    {(!isEditing || data.length > 0) && (
                        <Button
                            variant="blackCard"
                            height={{base: '3.5rem', md: '9.75rem'}}
                            width="100%"
                            boxSizing="border-box"
                            onClick={() => {
                                form.reset({...data})
                                setEditChildData(null)
                                onEdit()
                            }}
                        >
                            <FormattedMessage
                                defaultMessage="Add child"
                                id="mystokke.personal-information.child-add"
                            />
                            {/*Arrow up icon pointing to the address that is being edited*/}
                            {!!isEditing && !editChildData?.ID && <ChildArrowIcon bottom="-22px" />}
                        </Button>
                    )}
                    {!!isEditing && !editChildData?.ID && (
                        <MyStokkeChildForm
                            fields={fields}
                            setEditChildData={setEditChildData}
                            isLoading={isLoading}
                            onCancel={onCancel}
                            showBorder={data.length > 0}
                        />
                    )}
                </SimpleGrid>
            </form>
        </>
    )
}

export default MyStokkePersonalDataChildren

MyStokkePersonalDataChildren.propTypes = {
    form: PropTypes.object,
    submitForm: PropTypes.func,
    data: PropTypes.array,
    isEditing: PropTypes.bool,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool
}
