const runInlineScript = (script, context = global, args = {}) => {
    const argsArray = Object.entries(args)
    // NOTE: using Function as a safer alternative to eval
    const f = new Function(...argsArray.map(([key]) => key), script)
    // execute
    return f.apply(
        context,
        argsArray.map(([, value]) => value)
    )
}

export default runInlineScript
