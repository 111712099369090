/**
 * Hook for protected async calls that change the state of the component
 * Prevents memory leak when component is detached until the asynchronous function has completed
 * @param {async} asyncFunc Async function
 * @returns {execute} Function that executes the async function. Accepts parameters
 * @returns {loading} Async still fetching indicator (true, false)
 * @returns {data} Data returned from async fetching
 * @returns {error} Async fetching error indicator (true, false)
 * @example
 *   const { execute, loading, data, error } = useAync(async () => { return 'data' })
 */

import {useState, useEffect, useRef, useCallback} from 'react'
import useEffectEvent from '../../hooks/use-effect-event'

const useAsync = (asyncFunc) => {
    if (typeof asyncFunc !== 'function') {
        throw `useAsync accepts function as parameter (Passing parameter of type ${typeof asyncFunc})`
    }

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const mountedRef = useRef(true)
    const loadingToken = useRef(null)

    // use the useEffectEvent hook to prevent recreating the execute function when the asyncFunc changes
    const onExecute = useEffectEvent(asyncFunc)
    const execute = useCallback(
        (...args) => {
            if (!mountedRef.current) return null
            // we need a unique value that will pass the strict equality test
            // and a new object is the easiest way to do it
            const currentToken = {}
            loadingToken.current = currentToken
            setLoading(true)
            setData(null)
            setError(null)
            const promise = Promise.resolve(onExecute(...args))
                .then((res) => {
                    if (!mountedRef.current || loadingToken.current !== currentToken) return null
                    setData(res)
                    return res
                })
                .catch((err) => {
                    if (!mountedRef.current || loadingToken.current !== currentToken) return null
                    setError(err)
                    return Promise.reject(err)
                })
                .finally(() => {
                    if (!mountedRef.current || loadingToken.current !== currentToken) return null
                    loadingToken.current = null
                    setLoading(false)
                })
            // prevent unhandled promise rejection as the error is already tracked inside the error state, but we still want to reject the returned promise
            promise.catch(() => {})
            return promise
        },
        [onExecute]
    )

    /**
     * Reset the loaded data and cancel any pending requests
     *
     * @param {any} [value] Value to set data to
     */
    const reset = useCallback((value = null) => {
        loadingToken.current = null
        setLoading(false)
        setData(value)
        setError(null)
    }, [])

    useEffect(() => {
        mountedRef.current = true
        return () => {
            mountedRef.current = false
        }
    }, [])

    return {
        execute,
        loading,
        data,
        error,
        reset
    }
}

export default useAsync
