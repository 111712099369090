import React from 'react'
import PropTypes from 'prop-types'
import {HStack, useMultiStyleConfig} from '@chakra-ui/react'
import {RATING_MAX_STARS} from '../../constants'
import {StarEmptyIcon} from '../icons'

const RatingSkeleton = (props) => {
    const styles = useMultiStyleConfig('Ratings', props)

    return (
        <HStack {...styles.container} onClick={props.onRatingsClick}>
            {Array.from({length: RATING_MAX_STARS}).map((_, i) => (
                <StarEmptyIcon key={i} {...styles.star} data-testid="empty-star" />
            ))}
        </HStack>
    )
}

RatingSkeleton.propTypes = {
    onRatingsClick: PropTypes.func
}

export default RatingSkeleton
