import PropTypes from 'prop-types'
import React, {useContext} from 'react'

const OrderSummaryContext = React.createContext()

export const useOrderSummary = () => {
    return useContext(OrderSummaryContext)
}

const OrderSummaryProvider = ({basket, children}) => {
    return <OrderSummaryContext.Provider value={basket}>{children}</OrderSummaryContext.Provider>
}

OrderSummaryProvider.propTypes = {
    basket: PropTypes.object,
    children: PropTypes.node
}

export default OrderSummaryProvider
