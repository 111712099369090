export default {
    baseStyle: {
        itemContainer: {
            flexDirection: {base: 'column', lg: 'row'},
            maxHeight: '100%'
        },
        itemMediaAspectRatio: {
            flex: '0 0 auto',
            ratio: 1,
            backgroundSize: 'cover',
            width: {lg: '37.5rem'},
            marginTop: {base: 11.25, lg: '0'}
        },
        itemMediaContainer: {
            backdropFilter: 'blur(10px)'
        },
        itemMedia: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        itemSliderControls: {
            position: 'absolute',
            top: '50%',
            marginTop: -5.5,
            justifyContent: 'space-between',
            width: 'full',
            pointerEvents: 'none'
        },
        itemSliderControl: {
            pointerEvents: 'auto'
        },
        itemContentContainer: {
            flex: '1 1 auto',
            flexDirection: 'column',
            minHeight: '0',
            maxHeight: '100%',
            marginTop: {lg: 11.25}
        },
        itemContentHeaderContainer: {
            flexDirection: 'column',
            padding: 4,
            gap: 4,
            flex: '0 0 auto'
        },
        itemContentHeaderLine1: {
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        instagramAccountContainer: {
            alignItems: 'center',
            gap: 2.5
        },
        instagramAccount: {
            textStyle: 'Semi Bold Small'
        },
        likesContainer: {
            alignItems: 'center',
            gap: 1
        },
        likesIcon: {
            boxSize: 3.5
        },
        likes: {
            textStyle: 'Badge Regular'
        },
        itemContentHeaderLine2: {
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        titleContainer: {
            alignItems: 'center',
            gap: 2
        },
        title: {
            textStyle: 'Semi Bold Small'
        },
        titleSub: {
            textStyle: 'Regular Small'
        },
        itemContentProductsContainer: {
            columns: 2,
            overflowY: 'auto',
            padding: 4,
            spacingX: {base: 0, lg: 4},
            spacingY: 4
        }
    }
}
