import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        container: {
            ...textStyles['Regular Medium']
        },
        priceOrderContainer: {
            direction: 'row'
        },
        discount: {
            textDecoration: 'line-through'
        },
        discountText: {
            pt: 1,
            textStyle: 'Regular Small',
            color: 'stokkeBlue.dark'
        },
        priceContainer: {},
        price: {},
        priceFreeLabel: {
            display: 'none'
        }
    },
    variants: {
        orderHistory: ({hasDiscount}) => ({
            container: {
                direction: 'column',
                textAlign: 'right',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                spacing: 1
            },
            priceContainer: {
                layerStyle: hasDiscount ? 'discountedPrice' : 'none'
            },
            price: {
                textStyle: 'Regular XLarge'
            },
            priceOrderContainer: {
                direction: {base: 'column-reverse', md: 'row', lg: 'column-reverse'}
            }
        }),
        cart: ({hasDiscount}) => ({
            container: {
                direction: 'column',
                textAlign: 'right',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                spacing: 1
            },
            priceContainer: {
                layerStyle: hasDiscount ? 'discountedPrice' : 'none'
            },
            price: {
                textStyle: 'Regular XLarge'
            },
            discountText: {
                color: 'stokkeBlue.dark'
            }
        }),
        // AddToCartModal
        miniCart: ({hasDiscount}) => ({
            container: {
                direction: 'column',
                spacing: 1
            },
            priceContainer: {
                layerStyle: hasDiscount ? 'discountedPrice' : 'none'
            },
            price: {
                textStyle: 'Regular XLarge'
            }
        }),
        pdp: ({hasDiscount}) => ({
            container: {
                flexWrap: 'wrap',
                columnGap: 2,
                rowGap: 1,
                textAlign: 'right',
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
            },
            priceContainer: {
                layerStyle: hasDiscount ? 'discountedPrice' : 'none'
            },
            priceFreeNumber: {
                display: 'none'
            },
            priceFreeLabel: {
                display: null
            }
        }),
        order: {
            container: {
                direction: 'row',
                textAlign: 'right',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingTop: 2,
                paddingBottom: 3,
                spacing: 4
            },
            discount: {
                color: 'stokkeGray.dark'
            }
        },
        tile: ({hasDiscount}) => ({
            container: {
                direction: 'row-reverse',
                justifyContent: 'start',
                gap: 1
            },
            discount: {
                textStyle: 'Regular Small'
            },
            price: {
                textStyle: 'Regular Small'
            },
            priceContainer: {
                layerStyle: hasDiscount ? 'discountedPrice' : 'none'
            }
        })
    },
    sizes: {
        sm: {
            price: {
                textStyle: 'Regular Small'
            }
        },
        xl: {
            price: {
                textStyle: 'Regular XLarge'
            }
        },
        xxl: {
            discount: {
                textStyle: 'Regular XLarge'
            },
            price: {
                textStyle: 'Regular 2XLarge'
            }
        }
    },
    defaultProps: {
        variant: 'cart'
    }
}
