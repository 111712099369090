import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        container: {
            flexDirection: 'column'
        },
        reviewCount: {
            flexDirection: 'row',
            justifyContent: 'center',
            ...textStyles['Semi Bold Small'],
            pt: 6
        }
    }
}
