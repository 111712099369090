import {Box, useMergeRefs} from '@chakra-ui/react'
import loadable from '@loadable/component'
import React, {forwardRef, useState} from 'react'
import useIntersectionObserver from '../hooks/use-intersection-observer'
import PropTypes from 'prop-types'

const ReferencedFallback = forwardRef(({fallback, ...props}, ref) => (
    <Box ref={ref}>{fallback ? React.cloneElement(fallback, props) : null}</Box>
))
if (process.env.NODE_ENV !== 'production') {
    ReferencedFallback.displayName = 'ReferencedFallback'
}

ReferencedFallback.propTypes = {
    fallback: PropTypes.node.isRequired
}

const ioOptions = {useOnce: true}

/**
 * Lazy load a component after a delay or when it becomes visible.
 * @param {function(): Promise} importFunc
 * @param {object} [opts]
 * @param {any} [opts.fallback]
 */
const lazyLoadable = (importFunc, {fallback = null} = {}) => {
    const LoadableComponent = loadable(importFunc, {fallback, ssr: false})

    const LazyLoadable = forwardRef((props, forwardedRef) => {
        // use a state to trigger rerender so the intersection observer can be set up
        const [fallbackElement, setFallbackElement] = useState(null)
        const combinedRef = useMergeRefs(setFallbackElement, forwardedRef)
        const [triggered, setTriggered] = useState(false)

        // trigger loading of the component when it becomes visible
        const visible = useIntersectionObserver(fallbackElement, ioOptions)
        if (visible && !triggered) {
            setTriggered(true)
        }

        const referencedFallback = <ReferencedFallback ref={combinedRef} fallback={fallback} />

        // if the component is visible or has been triggered, render it
        if (visible || triggered) {
            return <LoadableComponent {...props} ref={forwardedRef} fallback={referencedFallback} />
        }

        // otherwise, render the fallback
        return referencedFallback
    })

    if (process.env.NODE_ENV !== 'production') {
        const name = importFunc.name || 'Unknown'
        LazyLoadable.displayName = `LazyLoadable(${name})`
    }

    return LazyLoadable
}

export default lazyLoadable
