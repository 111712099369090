import textStyles from '../../foundations/textStyles'

export default {
    baseStyle: {
        // this is provided as style prop to the toast container holding the alert
        // does not support theme references - e.g. sizes
        container: {
            width: '100%',
            maxWidth: '100%',
            margin: 0,
            padding: 0
        },
        // specifies theme overrides for use inside the toast
        theme: {
            components: {
                Alert: {
                    baseStyle: {
                        container: {
                            // allow the close button to position based on the container
                            position: 'relative',
                            // define margin here instead of toast container, because toast container doesn't
                            // support responsive values
                            mx: {
                                base: 4,
                                lg: 6,
                                xl: 8
                            },
                            mb: 2,
                            // clear the component default width: 100% due to the margins
                            width: null,
                            borderRadius: 'base',
                            minHeight: 11.5,
                            // space for the close button
                            paddingEnd: 12
                        },
                        title: {
                            // reset
                            marginEnd: null,
                            // inherit from container
                            fontWeight: null,
                            lineHeight: null,
                            //
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            ...textStyles['Semi Bold Medium']
                        }
                    }
                },
                CloseButton: {
                    baseStyle: {
                        position: 'absolute',
                        top: '0.1875rem',
                        // the button itself adds 12px visual spacing
                        right: 0
                    },
                    defaultProps: {
                        size: 'lg'
                    }
                },
                Link: {
                    defaultProps: {
                        colorScheme: 'white'
                    }
                }
            }
        }
    },
    parts: ['container', 'theme', 'Alert', 'CloseButton']
}
