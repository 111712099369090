import {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {NEW_PRODUCT_BADGE_DURATION} from '../constants'
import useMultiSite from './use-multi-site'
import {PROMOTION_BADGE_MESSAGES} from '../constants'
import {usePromotions} from '@salesforce/commerce-sdk-react/hooks/ShopperPromotions/query'

export const useBadges = (product = {}, promotions = []) => {
    const {validFrom = {}} = product
    const {formatMessage} = useIntl()
    const {site} = useMultiSite()

    // precompute the badge promotion ids to avoid re-rendering
    const badgePromotionsIds = useMemo(
        () => product.productPromotions?.map(({promotionId}) => promotionId) || [],
        [product]
    )

    // get the promotions which are missing in the preloaded list
    const missingPromotionIds = useMemo(
        () =>
            badgePromotionsIds?.filter(
                (promotionId) => promotionId && !promotions?.find(({id}) => id === promotionId)
            ),
        [promotions, badgePromotionsIds]
    )

    // Fetch additional promotions for badges
    // stringify the params to avoid re-executing the effect when the same ids are passed
    const missingPromotionsIdsParam = useMemo(
        () => missingPromotionIds?.sort((a, b) => String(a).localeCompare(b)).join(','),
        [missingPromotionIds]
    )

    const {data} = usePromotions(
        {parameters: {ids: missingPromotionsIdsParam}},
        {enabled: !!missingPromotionsIdsParam}
    )
    // the list of badge promotions to display - combining preloaded and fetched
    const badgePromotions = useMemo(
        () =>
            badgePromotionsIds
                ?.map((promotionId) =>
                    [...(promotions || []), ...(data?.data || [])].find((p) => p.id === promotionId)
                )
                .filter(Boolean),
        [promotions, data, badgePromotionsIds]
    )

    /* Current & Online From timestamps in milliseconds */
    const currentMs = Date.now()
    const onlineFromMs = new Date(validFrom[`default@${site.id}`] || validFrom.default).getTime()

    /* Returns true if product is added less than 6 months ago */
    const isNew = onlineFromMs && currentMs - onlineFromMs < NEW_PRODUCT_BADGE_DURATION

    const newBadge = isNew && {
        text: formatMessage({
            id: 'product_detail.badge.new',
            defaultMessage: 'New'
        }),
        variant: 'permanent'
    }

    const promotionalBadge = product?.c_PromotionalTags && {
        text: product?.c_PromotionalTags,
        variant: 'nonPermanent'
    }

    const promotionBadges =
        (badgePromotionsIds?.length > 0 ? badgePromotions : [])
            ?.filter(({c_badge}) => PROMOTION_BADGE_MESSAGES[c_badge])
            .map(({c_badge}) => ({
                text: formatMessage(PROMOTION_BADGE_MESSAGES[c_badge]),
                variant: 'nonPermanent'
            })) || []

    const plpSearchHitPromotionBadges =
        product?.c_badges?.map?.((badge) => ({
            text: formatMessage(PROMOTION_BADGE_MESSAGES[badge]),
            variant: 'nonPermanent'
        })) || []

    return [newBadge, promotionalBadge, ...promotionBadges, ...plpSearchHitPromotionBadges].filter(
        Boolean
    )
}
