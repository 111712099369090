import {Box, Flex, Center, Button, useBreakpointValue} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import {useOcapiInfiniteContentSearch} from '../../hooks/useOcapi'
import {Skeleton as AddToCartSkeleton} from '../../components/add-to-cart-cta'
import WidgetArticleDefaultViewMobile from './widget-article-default-view-mobile'
import WidgetArticleDefaultViewDesktop from './widget-article-default-view-desktop'
import ContentTile from '../../components/content-tile'
import {FormattedMessage} from 'react-intl'

/**
 * Display items from content_search
 *
 * @param {string} folder - folder name
 * @param {string} count - number of results
 * @param {string} type - default or grid, defines which layout is choosen
 * @param {string} q - searchquery
 * @param {string} showLoadMore - show or hide "load more" button, only effects if type=grid
 *
 * @example
 *   <pwa-widget-article folder=stokke-stories" count={3} type="default">
 *     $include(...)$
 *   </pwa-widget-article>
 */
const WidgetArticle = ({folder, q, type, count: countStr, showLoadMore: showLoadMoreStr}) => {
    const count = Number.parseInt(countStr, 10)
    const showLoadMore = showLoadMoreStr && showLoadMoreStr !== 'false'
    const smallOrBigView = useBreakpointValue({base: 'small', lg: 'big'})

    const {data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage} =
        useOcapiInfiniteContentSearch(
            {
                parameters: {
                    queryString: q,
                    fdid: folder,
                    count: count,
                    ...(folder && {sortAttribute: 'storiesPriority'})
                }
            },
            {
                select: (data) => data?.pages?.flatMap(({hits}) => hits)
            }
        )

    return (
        <Box>
            {isLoading && <AddToCartSkeleton />}
            {data?.length > 1 && (
                <>
                    {type === 'grid' ? (
                        <Box data-testid="grid-element">
                            <Flex
                                flexWrap="wrap"
                                justifyContent={['center', 'flex-start']}
                                gap={['0', '24px']}
                                padding={['16px 32px 8px 32px', '16px 32px 32px 32px']}
                            >
                                {data.map((item) => (
                                    <Box
                                        width={['100%', 'calc(100% / 3 - 17px)']}
                                        paddingBottom={['24px', '0']}
                                        key={item.id}
                                    >
                                        <ContentTile
                                            content={item}
                                            variant="default"
                                            ratio={4 / 3}
                                        />
                                    </Box>
                                ))}
                            </Flex>
                            {isFetchingNextPage && <AddToCartSkeleton />}
                            {showLoadMore && hasNextPage && (
                                <Center padding="2.5rem 1rem">
                                    <Button
                                        variant="outline"
                                        width={['full', '25rem']}
                                        data-testid="more-button"
                                        onClick={() => fetchNextPage()}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Load more"
                                            id="global.loadmore"
                                        />
                                    </Button>
                                </Center>
                            )}
                        </Box>
                    ) : (
                        <Box data-testid="default-element">
                            {smallOrBigView === 'small' ? (
                                <WidgetArticleDefaultViewMobile sliderData={data} />
                            ) : (
                                <WidgetArticleDefaultViewDesktop sliderData={data} />
                            )}
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

WidgetArticle.propTypes = {
    folder: PropTypes.string,
    q: PropTypes.string,
    type: PropTypes.string,
    count: PropTypes.string,
    showLoadMore: PropTypes.string
}

export default WidgetArticle
