import {useMemo} from 'react'
import {LOCALE_NAMES} from '../../constants'
import {capitalize} from '../../utils/utils'

/**
 * A hook to get the localized locale name by its shortcode.
 * @param {string} localeId - The locale shortcode that you would like the localized text for.
 * @param {string} [fallback] - The fallback text to use if the locale name is not found.
 * @return {string} - The localized locale name.
 */
export const useLocaleName = (localeId, fallback = localeId) => {
    return (
        useMemo(() => {
            const localeName = LOCALE_NAMES[localeId]
            if (localeName) {
                return localeName
            }

            console.error(
                `No locale name found for "${localeId}". Please update the list accordingly.`
            )

            const languageId = localeId.split('-')[0]

            // try browser locale specific language database
            const browserLocalLanguageNames = new Intl.DisplayNames([localeId], {type: 'language'})
            const localLanguageName = browserLocalLanguageNames.of(languageId)
            // when not found the browser returns the language id
            if (localLanguageName && localLanguageName !== languageId) {
                // browser labels are lowercase, so we capitalize them
                return capitalize(localLanguageName)
            }

            // try browser english language database
            const browserEnlgishLanguageNames = new Intl.DisplayNames(['en'], {type: 'language'})
            const englishLanguageName = browserEnlgishLanguageNames.of(languageId)
            if (englishLanguageName && englishLanguageName !== languageId) {
                return capitalize(englishLanguageName)
            }
        }, [localeId]) || fallback
    )
}
