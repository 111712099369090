import {createContext, useContext} from 'react'

const MyStokkePageConfigContext = createContext(null)

export const MyStokkePageConfigProvider = MyStokkePageConfigContext.Provider

/**
 * Return the current my stokke page config.
 *
 * @return {{id: string, image: string, headline: string, subline: string, recommender: string}}
 */
const useMyStokkePageConfig = () => {
    const context = useContext(MyStokkePageConfigContext)

    if (!context) {
        throw new Error('useMyStokkePageConfig must be used within a MyStokkePageConfigProvider')
    }

    return context
}

export default useMyStokkePageConfig
