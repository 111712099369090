// noinspection JSDeprecatedSymbols
import {useCommerceAPI} from '../commerce-api/contexts'
import {useMutation, useQuery, useInfiniteQuery} from '@tanstack/react-query'

/**
 *
 * @param {'customObjects'} apiName
 * @param {String} methodName
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
const useOcapiMutation = (apiName, methodName, mutationOptions) => {
    // noinspection JSDeprecatedSymbols
    const api = useCommerceAPI()

    if (!(api[apiName] instanceof Object)) {
        throw new Error(`API ${apiName} not found in OCAPI CommerceAPI`)
    }
    if (!(api[apiName][methodName] instanceof Function)) {
        throw new Error(`Method ${methodName} not found in ${apiName} OCAPI CommerceAPI`)
    }

    return useMutation({
        mutationFn: (...args) => api[apiName][methodName](...args),
        ...mutationOptions
    })
}

/**
 *
 * @param {'customObjects'|'shopperCustomersOcapi'|'shopperOrders'|'shopperContent'|'shopperFolders'|'shopperOrder'|'shopperSite'|'contentSearch'|'shopperStore'} apiName
 * @param {string} methodName
 * @param {{parameters: Object}|null} [apiOptions]
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value.
 * @private
 */
const useOcapi = (apiName, methodName, apiOptions, queryOptions) => {
    // noinspection JSDeprecatedSymbols
    const api = useCommerceAPI()

    if (!(api[apiName] instanceof Object)) {
        throw new Error(`API ${apiName} not found in OCAPI CommerceAPI`)
    }
    if (!(api[apiName][methodName] instanceof Function)) {
        throw new Error(`Method ${methodName} not found in ${apiName} OCAPI CommerceAPI`)
    }

    return useQuery(
        ['ocapi', apiName, methodName, apiOptions?.parameters ?? {}],
        ({queryKey: [, apiName, methodName, parameters]}) => api[apiName][methodName]({parameters}),
        queryOptions
    )
}

/**
 * @param {{parameters: {email: string}}} apiOptions
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value.
 */
export const useOcapiCustomObjectsCustomers = (apiOptions, queryOptions) =>
    useOcapi('customObjects', 'getCustomers', apiOptions, queryOptions)

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useOcapiSubscribeToNewsletterMutation = (mutationOptions) =>
    useOcapiMutation('customObjects', 'subscribeToNewsletter', mutationOptions)

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useOcapiReportCheckoutMutation = (mutationOptions) =>
    useOcapiMutation('customObjects', 'reportCheckout', mutationOptions)

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useOcapiPasswordResetMutation = (mutationOptions) =>
    useOcapiMutation('shopperCustomersOcapi', 'passwordReset', mutationOptions)

/**
 * @param {{parameters: {cid: string, html?: boolean}}} apiOptions
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value
 */
export const useOcapiContent = (apiOptions, queryOptions) =>
    useOcapi('shopperContent', 'getContent', apiOptions, queryOptions)

/**
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value
 */
export const useOcapiSiteRaw = (queryOptions) =>
    useOcapi('shopperSite', 'getSite', null, queryOptions)

/**
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value
 */
export const useOcapiFolder = (queryOptions) =>
    useOcapi('shopperFolders', 'getFolder', null, queryOptions)

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useOcapiFailOrderMutation = (mutationOptions) =>
    useOcapiMutation('shopperOrders', 'failOrder', mutationOptions)

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useOcapiCancelOrderMutation = (mutationOptions) =>
    useOcapiMutation('shopperOrders', 'cancelOrder', mutationOptions)

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useOcapiUpdateAdyenReferenceMutation = (mutationOptions) =>
    useOcapiMutation('shopperOrders', 'updateAdyenReference', mutationOptions)

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useOcapiUpdateAdyenDataMutation = (mutationOptions) =>
    useOcapiMutation('shopperOrders', 'updateAdyenData', mutationOptions)

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} [mutationOptions]
 * @return {import("@tanstack/react-query").UseMutationResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useMutation">TanStack Query `useMutation` reference</a> for more information about the return value.
 */
export const useOcapiUpdateCustomerDataMutation = (mutationOptions) =>
    useOcapiMutation('shopperOrders', 'updateCustomerData', mutationOptions)

/**
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value.
 */
export const useBasketValidation = (queryOptions) =>
    useOcapi('customObjects', 'basketValidation', null, queryOptions)

/**
 * @param {{parameters: {queryString: string, fdid: string, count: number, sortAttribute?: string}}} apiOptions
 * @param {import("@tanstack/react-query").UseInfiniteQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseInfiniteQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/framework/react/reference/useInfiniteQuery">TanStack Query `useInfiniteQuery` reference</a> for more information about the return value.
 */
export const useOcapiInfiniteContentSearch = (apiOptions, queryOptions) => {
    // noinspection JSDeprecatedSymbols
    const api = useCommerceAPI()

    return useInfiniteQuery({
        queryKey: ['ocapi', 'infinite', 'contentSearch', apiOptions?.parameters ?? {}],
        queryFn: ({pageParam: next}) => {
            if (next) {
                return api.contentSearch.getNextResults({parameters: {next}})
            }

            return api.contentSearch.getSearchResult({
                parameters: {
                    ...apiOptions.parameters
                }
            })
        },
        getNextPageParam: (lastPage) => lastPage.next,
        ...queryOptions
    })
}

export const useOcapiCustomerPasswordResetMutations = (mutationOptions) =>
    useOcapiMutation('shopperCustomersOcapi', 'passwordReset', mutationOptions)

/**
 * @param {{parameters: {id: string, selectedSetProducts: string[]}}} apiOptions
 * @param {import("@tanstack/react-query").UseQueryOptions} [queryOptions]
 * @return {import("@tanstack/react-query").UseQueryResult}
 * @see <a href="https://tanstack.com/query/latest/docs/react/reference/useQuery">TanStack Query `useQuery` reference</a> for more information about the return value.
 */
export const useOcapiProductSetPrice = (apiOptions, queryOptions) =>
    useOcapi('customObjects', 'getProductSetPrice', apiOptions, queryOptions)
